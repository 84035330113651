
type HTMLStyleElementIE8 = HTMLStyleElement & {styleSheet: {cssText: string}}

function _getOrCreateStyleTag(id: string): HTMLStyleElementIE8 {
  let elStyle = document.getElementById(id) as HTMLStyleElementIE8;
  if (elStyle) return elStyle;

  elStyle = document.createElement('style') as HTMLStyleElementIE8;
  elStyle.setAttribute('type', 'text/css');
  elStyle.media = 'all';
  elStyle.id = id;
  document.getElementsByTagName("head")[0].appendChild(elStyle);

  return elStyle;
}


function destroyStyle(id: string) {
  const s = document.getElementById(id);
  if (s) s.parentNode.removeChild(s);
}

function appendToStyle(id: string, css: string) {
  const s = _getOrCreateStyleTag(id);
  if (s.styleSheet) s.styleSheet.cssText += css; // ie8
  else s.appendChild(document.createTextNode(css));
}
function writeStyle(id: string, css: string) {
  const s = _getOrCreateStyleTag(id);
  if (s.styleSheet) s.styleSheet.cssText = css; // ie8
  else s.innerHTML = css;
}

export class StyleWriter {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  write(css: string) {
    return writeStyle(this.id, css);
  }

  clear() {
    this.write('');
  }

  append(css: string) {
    return appendToStyle(this.id, css);
  }

  destroy() {
    destroyStyle(this.id);
    delete this.id;
  }
}

export {
  destroyStyle,
  appendToStyle,
  writeStyle
};

export default StyleWriter;
