import {useCallback} from 'react';
import {normalizeSourceText, isSubmittableState} from '../dictHelpers';
import {unicodeHelper} from '../../../lib/util';
import {numOfMaxWords} from '../../../setup.js';
import globals from '../../../controller/globals';
import { DEV_MODE } from '../../../controller/devel';

// const disabled = !DEV_MODE;
const disabled = false;
const history = [];

// This class is used to optimize API calls,
// compares the words of the previous search with the words
// of the new search to be performed, and if the words of the
// new search are a subset of the previous one, it will augment
// the previous search result with the API result of the new words,
// ensuring that only words that were not already in the
//  previous request are included in the API request.

class UseOnDictFormSubmit {
  constructor({
    refSource,
    refTrsSourceArea,
    state,
    userData,
    dispatch,
    isMounted
  }) {
    this.refSource = refSource;
    this.refTrsSourceArea = refTrsSourceArea;
    this.state = state;
    this.userData = userData;
    this.dispatch = dispatch;
    this.isMounted = isMounted;
    this.optimizationEnabled = true;

    this.prevResult = this.state.trResult?.resultInstance ? {
      ...this.state.trResult,
      resultInstance: {...this.state.trResult.resultInstance}
    } : null;
    this.prevResultInstance = this.prevResult?.resultInstance || null;
    this.prevFullWord = this.prevResultInstance?.fullWord || null;
    this.prevWordsLimited = (this.prevResultInstance?.raw.wordsLimited || []);
    this.prevDict = this.prevResultInstance?.dict || null;
    this.prevLastQuery = this.state.lastQuery || null;
    this.prevUser = this.prevLastQuery?.userData || null;
    this.userDataChanged = this.prevUser?.email !== this.userData?.email;
    // console.log('UseOnDictFormSubmit.constructor FFFFFFFFFFFFFFFFFFFFFFFFFFFFF', this.userDataChanged, {
    //   prevUser: this.prevUser,
    //   userData: this.userData,
    //   'this.prevResult': this.prevResult,
    //   'this.prevLastQuery': this.prevLastQuery,
    // });
    this.payloadLastQuery = {};
    this.newWords = [];
    this.deletedWords = [];
  }

  getWords(text) {
    const wordsWithDupes = unicodeHelper.getWords(text.toLowerCase(), false);
    const words = unicodeHelper.uniqueArray(wordsWithDupes);
    const wordsLimited = words.length > numOfMaxWords ? words.slice(0, numOfMaxWords) : words;
    const wordsOverLimit = words.length > numOfMaxWords
      ? words.slice(numOfMaxWords)
      : []
    ;
    return {words, wordsLimited, wordsOverLimit, wordsWithDupes};
  }

  init(_e) {
    if (_e?.preventDefault) { _e.preventDefault(); }

    if (!this.refSource?.current) {
      console.warn('UseOnDictFormSubmit.init: refSource.current is not defined');
      return;
    }

    const text = normalizeSourceText(this.refSource.current.textContent);
    if (!text) {
      DEV_MODE && console.info('UseOnDictFormSubmit.init: submit prevented by empty text');
      return;
    }

    const {words, wordsLimited, wordsOverLimit, wordsWithDupes} = this.getWords(text);

    const submittable = isSubmittableState(this.state, words, this.userData);

    if (!submittable) {
      DEV_MODE && console.info('UseOnDictFormSubmit.init: submit prevented by isSubmittableState');
      return;
    }

    const dict = this.state.curDictId;

    this.payloadLastQuery = {
      text,
      dict,
      src: this.state.curSrc,
      trg: this.state.curTrg,
      userData: this.userData,
      wordsLimited,
      wordsOverLimit,
      wordsWithDupes,
      words
    };

    if (!disabled && !this.userDataChanged && this.isOptimizationNeeded({wordsLimited})) {
      this.optimizeCall({text, wordsLimited, dict});
      DEV_MODE && console.info('UseOnDictFormSubmit.init: optimization needed', this);
    }
    else {
      this.apiCall({text, words, dict}, false);
    }
  }

  isOptimizationNeeded({wordsLimited}) {
    let reason = '';
    const prevWordsLimitedWithNoFullWord = (
      this.prevWordsLimited.length === 1 && this.prevWordsLimited[0] === this.prevFullWord
        ? this.prevWordsLimited
        : this.prevWordsLimited.filter((word) => word !== this.prevFullWord)
    );

    if ((!this.prevResult && (reason = "no prev result")) ||
      (this.prevDict !== this.state.curDictId && (reason = "dict no match")) ||
      (prevWordsLimitedWithNoFullWord.filter((word) => wordsLimited.includes(word)).length === 0 &&
        (reason = "no word in the prev result that included in the new search")
      )
    ) {
      DEV_MODE && console.info(
        'UseOnDictFormSubmit.init: optimization NOT needed for the following reason: ' + reason,
        {
          prevWordsLimitedWithNoFullWord,
          wordsLimited,
          that: this
        }
      );

      return false;
    }
    return true;
  }

  optimizeCall({text, wordsLimited, dict}) {

    this.newWords = wordsLimited.filter((word) => !this.prevWordsLimited.includes(word));
    this.deletedWords = this.prevWordsLimited.filter((word) => !wordsLimited.includes(word));

    if (
      this.newWords.length === 0 &&
      this.deletedWords.length === 0 &&
      text === this.prevFullWord
    ) {
      DEV_MODE && console.info('submit prevented by optimizeCall: no new words / text change and no deletions found');
      return;
    }
    // if (this.newWords.length === 0 && this.deletedWords.length > 0) {
    //   // if there are no new words, but there are deleted words,
    //   // we can return the old result without the deleted words
    //   // BUT: because of full word match,
    //   this.returnOldResultWithoutDeletions(text);
    //   return;
    // }

    this.apiCall({text, words: this.newWords, dict}, true);
  }
  // returnOldResultWithoutDeletions(text) {
  //   if (!this.prevResult) return;
  //   const { result: oldResult} = this.prevResult;

  //   const result = oldResult.map((r) => {
  //     if (r.dict_id === this.state.curDictId) {
  //       r.queries = r.queries.filter((q) => !this.deletedWords.includes(q.searched_word));
  //     }
  //     return r;
  //   });

  //   this.dispatch({ type: 'lastQuery', payload: this.payloadLastQuery });

  //   this.dispatch({
  //     type: 'trResult',
  //     payload: {
  //       errormsg: [],
  //       error: false,
  //       result,
  //       dict: this.state.curDictId,
  //       fullWord: text
  //     }
  //   });
  //   this.resetScroll();
  // }


  mergeResult(data, _newText) {
    if (this.prevResult) {
      // console.time('UseOnDictFormSubmit.mergeResult');


      // const {result: oldResult, resultInstance: _, ...restPrevResult} = this.prevResult;
      const {result: oldResult} = this.prevResult;
      const {result: newResult} = data;
      const allDicts = [...new Set(oldResult.map((r) => r.dict_id).concat(newResult.map((r) => r.dict_id)))];

      const result = allDicts.map((dict_id) => {
        const ret = { dict_id, queries: [] };

        const oldResultByDict = oldResult.find((r) => r.dict_id === dict_id);
        const newResultByDict = newResult.find((r) => r.dict_id === dict_id);
        const newWords = newResultByDict?.queries.map((q) => q.searched_word) || [];
        const oldWords = (oldResultByDict?.queries.map((q) => q.searched_word) || []);
        const oldWordsWithNoFullWord = oldWords.length === 1 && oldWords[0] === this.prevFullWord ? oldWords : oldWords.filter(
          (word) => word !== this.prevFullWord
        );
        const allSearchWords = [
          ...new Set([...oldWordsWithNoFullWord, ...newWords])
        ].filter((word) => !this.deletedWords.includes(word));

        DEV_MODE && console.log('UseOnDictFormSubmit.searched_word', {
          oldWords,
          newWords,
          oldWordsWithNoFullWord,
          allSearchWords,
          this: this
        });

        ret.queries = allSearchWords.map((searched_word) => {

          const oldEntries = oldResultByDict?.queries.find((q) => q.searched_word === searched_word)?.entries || [];
          const newEntries = newResultByDict?.queries.find((q) => q.searched_word === searched_word)?.entries || [];

          const uniqueEntries = oldEntries.concat(newEntries).reduce((acc, entry) => {
            entry._mrgid = entry.id + '_' + entry.headword_id + '_' + entry.uuid;
            if (!acc.find((e) => e._mrgid === entry._mrgid)) {
              acc.push(entry);
            }
            return acc;
          }, []);

          return {
            entries: uniqueEntries,
            searched_word
          };
        });

        return ret;
      });

      // console.timeEnd('UseOnDictFormSubmit.mergeResult');
      return {
        // prevResult: restPrevResult,
        ...data,
        result
      };
    }
    return data;
  }

  resetScroll() {
    if (this.refSource?.current) {
      this.refSource.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    if (this.refTrsSourceArea?.current) {
      this.refTrsSourceArea.current.updateScroll();
    }
  }

  apiCall({text, words, dict}, optimized = true) {
    const appPub = globals.get('appPub');
    const {api} = appPub;

    this.dispatch({ type: 'lastQuery', payload: this.payloadLastQuery });

    const params = {
      words: [text, ...words],
      dict: [dict]
    };

    // DEV_MODE && console.log('onSubmit', {that: this, prevResult: this.prevResult, dict, words });


    api.getEndpointByAlias('freedict-dict').fetch({requestData: { params }}, (status, data) => {

      const mergedData = !disabled && optimized ? this.mergeResult(data, text) : data;

      if (DEV_MODE) {
        history.push({...params, payloadLastQuery: this.payloadLastQuery, mergedData});
        if (history.length > 10) history.shift();
      }

      DEV_MODE && console.log('UseOnDictFormSubmit.onSubmit', {
        mergedData,
        that: this,
        words,
        history,
        optimized,
        data,
        fullWord: text,
        prevResult: this.prevResult
      });

      return api.fetchResponseHelper({
        status,
        data,
        // eslint-disable-next-line react/no-is-mounted
        isMounted: () => this.isMounted(),
        onAny: (succeeded) => {
          this.dispatch({
            type: 'lastQueryEnd',
            payload: { succeeded }
          });
        },
        onSuccess: () => {
          this.dispatch({
            type: 'trResult',
            payload: {
              ...mergedData,
              dict,
              fullWord: text
            }
          });
          this.resetScroll();
        },
        onErrorMsg: (msg) => {
          DEV_MODE && console.warn(msg);
          this.dispatch({
            type: 'trResult',
            payload: {
              ...mergedData,
              dict,
              fullWord: text
            }
          });
          this.resetScroll();
        }
      });
    }, false);
  }
}


const useOnDictFormSubmit = ({
  refSource, refTrsSourceArea, state, userData, dispatch, isMounted
}) => {
  return useCallback((_e) => {
    const methodClass = new UseOnDictFormSubmit({
      refSource, refTrsSourceArea, state, userData, dispatch, isMounted
    });
    methodClass.init(_e);
  }, [refSource, refTrsSourceArea, state, userData, dispatch, isMounted]);
};

export default useOnDictFormSubmit;
