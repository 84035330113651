/* eslint-disable react/prop-types */
import React from 'react';

import StaticPageMustLogin from '../../../StaticPageMustLogin';
import ModuleLoader from '../../../components/ModuleLoader';

import { Route } from 'react-router-dom';
import { meta } from '../../../lib/util';
import messages from '../../../messages';

const lazyImports = {
  paperCode: () => React.lazy(() => import('../../../base/paperCode/'))
};


const getTransactionRoutes = (pageProps) => {
  const isLoggedIn = pageProps.appPub.isLoggedIn;

  return [
    <Route path={[
      "/papercode"
    ]} key={'view-papercode'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.papercode'));
      return isLoggedIn()
        ? <ModuleLoader moduleImportFn={lazyImports.paperCode}
          { ...{...pageProps, ...props} }
        />
        : <StaticPageMustLogin { ...{...pageProps, ...props} } />;
    }} />,
    <Route path={[
      "/legacycode"
    ]} key={'view-legacy'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.legacycode'));
      return isLoggedIn()
        ? <ModuleLoader moduleImportFn={lazyImports.paperCode}
          { ...{legacyCode: true, ...pageProps, ...props} }
        />
        : <StaticPageMustLogin { ...{...pageProps, ...props} } />;
    }} />

  ];
};

export default getTransactionRoutes;
