import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentLink, {propTypeTooltip} from './contentLink';
import Img from './img';
import img_wrap_text from '../img/icon/wrap_text.svg';
import img_keyboard_return from '../img/icon/keyboard_return.svg';

const faMap = {
  'wrap-text': { src: img_wrap_text, alt: 'sortörés' },
  'keyboard-return': { src: img_keyboard_return, alt: 'enter' }
};

// import globals from '../controller/globals';

const ContentIconItem = React.forwardRef(({
  _parentId,
  id,
  type,
  passedData = {},
  baseClass = "cmp-ContentIconItem",
  className,
  children,
  onClick,
  onKeyDown,
  title,
  tooltip,
  region,
  items,
  data
}, ref) => {
  const {
    icon = "fa-flag",
    link,
    title: _title,
    tooltip: dataTooltip,
    onClick: _onClick,
    onKeyDown: _onKeyDown,
    formButton
  } = data;
  const {className: attrClassName, href, target, rel, ...attrs} = data.attrs || {};

  return (
    <ContentLink
      id={id || null}
      data-id={id || null}
      baseClass={baseClass}
      passedData={passedData}
      tooltip={tooltip || dataTooltip}
      ref={ref}
      data={{
        href,
        target,
        formButton,
        rel,
        ...(link || {}),
        iconLeft: !faMap[icon] ? icon : void 0,
        attrs: {
          className: classNames(
            className,
            attrClassName
          ),
          title: title || _title,
          onClick: onClick || _onClick,
          onKeyDown: onKeyDown || _onKeyDown,
          role: 'button',
          ...(attrs || {})
        }
      }}
    >
      {faMap[icon] ? (
        <Img
          className={`${baseClass}__icon`}
          width="24"
          hight="24"
          src={faMap[icon].src}
          alt={faMap[icon].alt}
        />
      ) : null }
      {children}
    </ContentLink>
  );
});



ContentIconItem.propTypes = {
  _item: PropTypes.shape({
    getItemData: PropTypes.func
  }),

  _parentId: PropTypes.string,

  id: PropTypes.string,
  tooltip: propTypeTooltip,
  type: PropTypes.string,
  region: PropTypes.string,
  baseClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  passedData: PropTypes.object,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  // @title: bubi szövege
  title: PropTypes.string,
  data: PropTypes.shape({
    tooltip: propTypeTooltip,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,

    formButton: PropTypes.string,
    // @title: bubi szövege
    title: PropTypes.string,
    // @icon fa icon
    icon: PropTypes.string,

    // @link:  a contentLink komponens data paraméterei adhatóak át (az itt megadott text felülírja a fentit)
    link: PropTypes.shape({
      // ezeket az értékeket a data tulajdonságaként is meg lehet adni
      href: PropTypes.string
    }),

    // @disabled: amennyiben true, disabled és aria-disabled attribokat kap.
    disabled: PropTypes.bool,

    // @attrs: elem attribútumok, (href, target felülírja a fent megadottat)
    attrs: PropTypes.object
  }).isRequired,
  items: PropTypes.array
};

export default ContentIconItem;
