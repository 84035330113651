import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';

import { isExternalUrl } from '../lib/util';
import { filterHtml } from '../lib/filterHtml';
import jsLinkActions from '../controller/jsLinkActions';

const rx_jslink = /^javascript:(.*)/;
const matchJsLinkMethod = s => (((s || '') + '').match(rx_jslink) || [])[1] || null;

const Hyperlink = React.forwardRef(({
  href,
  className,
  to,
  id,
  children,
  html,
  text,
  passedData = {},
  baseClass = "cmp-Hyperlink",
  method = 'auto',
  preferredMethod = 'router-navlink',
  replace,
  ariaCurrent,
  activeClassName,
  exact,
  ...attrs
}, ref) => {
  if (method === 'auto') {
    method = (!href || isExternalUrl(href)) ? 'native' : preferredMethod;
  }
  const RouterLink = method === 'router-link'
    ? Link : (method === 'router-navlink' ? NavLink : null)
  ;

  const klass = classNames(baseClass, className, `m-${method.replace('router-', 'rt')}`);

  if (typeof html === "string") {
    attrs.dangerouslySetInnerHTML = {
      __html: filterHtml(html)
    };
  }

  let link = to || href || '';
  const jsMethod = matchJsLinkMethod(link);
  if (jsMethod) {
    attrs = {
      ...attrs,
      'data-cta-method': jsMethod,
      ...jsLinkActions.bind(jsMethod)
    };

    if (typeof link === 'string') {
      method = 'native';
      link = undefined;
    }
  }

  const r = (
    method === 'native' ? (
      <a
        ref={ref}
        data-id={id}
        id={id}
        href={link || void 0}
        className={klass}
        {...attrs}
      >{text}{children}</a>
    ) : (
      <RouterLink
        ref={ref}
        className={klass}
        data-id={id}
        id={id}
        replace={replace}
        to={link}
        aria-current={ariaCurrent}
        exact={exact}
        // isActive={(match, location) => {
        //   if (id === 'mid_474_link') {
        //     console.log('mid_474_link', match, location);
        //   }
        //   return match;
        // }}
        {...attrs}
      >{text}{children}</RouterLink>
    )
  );

  // if (id === 'mid_474_link') {
  //   console.log('mid_474_link', r);
  // }

  return r;
});


Hyperlink.propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  method: PropTypes.oneOf(['auto', 'native', 'router-link', 'router-navlink']),
  preferredMethod: PropTypes.oneOf(['native', 'router-link', 'router-navlink']),
  href: PropTypes.string,
  id: PropTypes.string,
  activeClassName: PropTypes.string,
  text: PropTypes.string,
  html: PropTypes.string,
  replace: PropTypes.bool,
  exact: PropTypes.bool,
  ariaCurrent: PropTypes.oneOf(['page', 'step', 'location', 'date', 'time', 'true', 'false']),
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object
  }),
  rel: PropTypes.string,
  target: PropTypes.string,
  passedData: PropTypes.object,
  children: PropTypes.node
};

export default Hyperlink;
