import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import Entry from './Entry';
import ContentParagraph from '../../components/contentParagraph';
import ContentHeadline from '../../components/contentHeadline';
import ContentButton from '../../components/contentButton';
import {ttsSpeakLiveBind} from '../qrcode/TtsSpeakButton';
import Results from './Results';
import globals from '../../controller/globals';
import messages from '../../messages';
import classNames from 'classnames';
import { tldMap, lsPropNames } from '../../setup';
import { keyCodes } from '../../lib/util';
import EntriesFormatIcon from './EntriesFormatIcon';
import EntriesA11ySelect from './EntriesA11ySelect';
// ttsSpeak({text, lang});
import { sesStore } from '../../lib/BrowserStore';

const lsPropNameA11yView = lsPropNames.getSess('entryViewA11y');

const Entries = ({baseClass, lastQuery, result, params, error, errors, resultInstance, activeWord, session_auth, onWordSelect}) => {
  // console.log('Entries', {lastQuery, result, error, params, activeWord, resultInstance});

  const {ttsSpeak} = globals.get('appPub');
  const refEntries = useRef();
  const [accessibleStyle, setAccessibleStyle] = useState(
    sesStore.get(lsPropNameA11yView, 'true') === 'true'
  );
  const [showOthers, setShowOthers] = useState(false);

  useEffect(() => {
    const unbindFn = ttsSpeakLiveBind(refEntries.current, ttsSpeak);
    return () => unbindFn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleStyle = useCallback((e) => {
    if (e.type === "click" ||
      (e.type === "keydown" && (e.keyCode === keyCodes.ENTER || e.keyCode === keyCodes.SPACE))
    ) {
      e.preventDefault();
      const newValue = !accessibleStyle;
      sesStore.set(lsPropNameA11yView, newValue + '');
      setAccessibleStyle(newValue);
    }
  }, [accessibleStyle]);

  const resultWords = resultInstance?.searchedWords || null;
  const base = `${baseClass}__entries`;
  // const className = classNames(base, 'style-accessible');

  const className = classNames(base,
    accessibleStyle ? 'style-accessible' : 'style-printformat'
  );
  const resultContId = 'trs-entries';

  const hasResult = resultInstance && resultInstance.entries.length;
  const entriesMain = hasResult ? resultInstance.getEntriesBySearchWord(activeWord, false, accessibleStyle, false) : [];
  const entriesExpOnly = hasResult ? resultInstance.getEntriesBySearchWord(activeWord, false, accessibleStyle, true) : [];

  const forceShowOthers = (entriesExpOnly.length && !entriesMain.length);

  // console.log('\n\nEntries', {entriesMain, entriesExpOnly});

  return (
    <section aria-live="polite" aria-atomic="true" className={className} aria-label={messages.get('tr.entriesSelectedResult')}>
      <ContentHeadline className={`${base}__listhl seo-title`} data={{ seoLevel: 2, level: 4 }}>
        {messages.get('Dictionary.entries.results')} <small>({entriesMain.length})</small>
      </ContentHeadline>

      {resultWords && resultWords.length ? (<>
        <EntriesFormatIcon resultContId={resultContId} base={base} accessibleStyle={accessibleStyle} onToggleStyle={onToggleStyle} />
        <EntriesA11ySelect resultContId={resultContId} onWordSelect={onWordSelect} activeWord={activeWord} base={base} resultWords={resultWords} />
      </>) : null}

      {entriesMain.length === 0 ? (
        <ContentParagraph className={`${base}__noresult`} data={{
          attrs: { role: 'alert' }, theme: 'warning', level: 2
        }}>
          {messages.get('tr.noResult', {
            sourceLang: tldMap[lastQuery.src],
            targetLang: tldMap[lastQuery.trg]
          })}
        </ContentParagraph>
      ) : null}
      <div className={`${base}__result ${base}__result--main`} ref={refEntries} id={resultContId}>
        {entriesMain.length ? (
          <div className={`${base}__list ${base}__list--main`} ref={refEntries}>
            {entriesMain.map((entry, i) => (
              <Entry
                key={i + entry.fid + '__' + entry.sid}
                index={i}
                accessibleStyle={accessibleStyle}
                baseClass={baseClass}
                entry={entry}
              />
            ))}
          </div>
        ) : null}

        {entriesExpOnly.length ? (
          <div className={`${base}__list ${base}__list--others`} ref={refEntries}>
            <ContentHeadline className={`${base}__listhl`} data={{ seoLevel: 2, level: 4 }}>
              {forceShowOthers ? messages.get('Dictionary.entries.resultsOthers') : (
                <ContentButton className={`${base}__listhl__btn`} onClick={() => setShowOthers(!showOthers)} data={{
                  attrs: { 'aria-expanded': showOthers },
                  theme: 'inline-link'
                }}>
                  {messages.get('Dictionary.entries.resultsOthers')} {' '}
                  {showOthers ? messages.get('Dictionary.entries.resultsOthersHide') : messages.get('Dictionary.entries.resultsOthersShow')}
                  {' '} <small>({entriesExpOnly.length})</small>
                </ContentButton>
              )}
            </ContentHeadline>
            {
              (showOthers || forceShowOthers) && entriesExpOnly.map((entry, i) => (
                <Entry
                  key={i + entry.fid + '__' + entry.sid}
                  index={i}
                  accessibleStyle={accessibleStyle}
                  baseClass={baseClass}
                  entry={entry}
                />
              ))
            }
          </div>
        ) : null}

      </div>
    </section>
  );
};

Entries.propTypes = {
  appPub: PropTypes.shape({
    ttsSpeak: PropTypes.func
  }),
  lastQuery: PropTypes.shape({
    trg: PropTypes.string,
    src: PropTypes.string,
    dict: PropTypes.string
  }),
  accessibleStyle: PropTypes.bool,
  baseClass: PropTypes.string,
  onWordSelect: PropTypes.func,
  result: PropTypes.array,
  params: PropTypes.object,
  error: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  resultInstance: PropTypes.instanceOf(Results),
  activeWord: PropTypes.string,
  session_auth: PropTypes.bool
};

export default Entries;
