/* eslint-disable react/prop-types */
import React, {useEffect, useState, Suspense } from 'react';
import LoaderSpinner from './misc/LoaderSpinner';
import PropTypes from 'prop-types';

// import Lofasz from '../base/subscription/';

const ModuleLoader = ({
  moduleImportFn = null,
  moduleProps = {},
  showSpiner = true,
  ...props
}) => {
  const [Module, setModule] = useState(null);

  useEffect(() => {
    setModule(moduleImportFn());
  }, []);

  return (
    Module === null ? (
      <LoaderSpinner variant='light' className="module-loader" />
    ) : (
      <Suspense fallback={showSpiner ? <LoaderSpinner variant='light' className="module-loader" /> : null}>
        <Module
          {
            ...{
              ...(moduleProps || {}),
              ...(props || {})
            }
          }
        />
      </Suspense>
    )
  );
};

ModuleLoader.propTypes = {
  moduleImportFn: PropTypes.func,
  moduleExportName: PropTypes.string,
  moduleProps: PropTypes.object,
  showSpiner: PropTypes.bool
};


export default ModuleLoader;
