import unicode_helper from './lib/unicode_helper';
import {selectorsToTts, entryReplacementsMap} from './setup.js';

const htmlSrHidden = (html, textSrOnly) => (
  (textSrOnly ? `<span class="sr-only">${textSrOnly}</span>` : '') +
  (textSrOnly || html
    ? `<span class="sr-hidden" ${textSrOnly ? `title="${textSrOnly}"` : ''} aria-hidden="true">${html}</span>`
    : ''
  )
);


const ttsMap = {
  _langCache: {},
  _rxCache: {},

  getMapByLang(lang) {
    if (!entryReplacementsMap[lang]) return null;
    if (this._langCache[lang]) return this._langCache[lang];

    return (
      this._langCache[lang] = entryReplacementsMap[lang].sort(this._sortFn)
    );
  },

  createHtmlSafeRx(abbrv) {
    if (this._rxCache[abbrv]) return this._rxCache[abbrv];

    const prefix = '[>(\\s/]|^';
    const text = unicode_helper.escapeRegExp(abbrv);
    const suffix = '[)\\s/<]|$';
    const rx = new RegExp(`(${prefix})(${text})(${suffix})`, 'gim');
    this._rxCache[abbrv] = rx;
    return rx;
  },

  _sortFn: (a, b) => {
    if (a[0].length > b[0].length) return -1;
    if (a[0].length < b[0].length) return 1;
    return 0;
  },

  replacer(text, lang, screenReaderHtml) {
    const map = this.getMapByLang(lang);
    if (!map) { return text; }
    let modText = text || '';


    for (let i = 0; i < map.length; i++) {
      const [rx, toTxt, htmlSafe] = map[i];
      if (screenReaderHtml && !htmlSafe) {
        continue;
      }

      const replacement = htmlSafe ? (m, prefix, modText, suffix) => {
        // console.log('replacement.htmlSafe.match', screenReaderHtml, lang, prefix, modText, suffix);
        return prefix + (screenReaderHtml ? htmlSrHidden(modText, toTxt) : toTxt) + suffix;
      } : toTxt;
      try {
        modText = modText.replace(
          htmlSafe && typeof rx === "string" ? this.createHtmlSafeRx(rx) : rx,
          replacement
        );
      }
      catch (error) {
        console.log('abbrv.replacer.error', error, rx, toTxt, htmlSafe);
      }


      if (modText.length < 100) {
        // console.log('replacement.debug', text, rx, {
        //   screenReaderHtml, lang, toTxt, htmlSafe, modText, rx
        // });
      }

    }
    return modText;
  }
};

export {
  ttsMap,
  selectorsToTts,
  htmlSrHidden
};

export default ttsMap;
