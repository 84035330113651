import React from 'react';
import PropTypes from 'prop-types';
import ContentIconItem from '../../components/contentIconItem';
import classNames from 'classnames';
import messages from '../../messages';

const EntriesFormatIcon = ({base, accessibleStyle, onToggleStyle, resultContId}) => (
  <ContentIconItem
    className={classNames(
      `${base}__format`,
      `${base}__format--${accessibleStyle ? 'printformat' : 'accessible'}`
    )}
    title={
      messages.get(`Dictionary.entries.style.${accessibleStyle ? 'printformat' : 'accessible'}`)
    }
    onClick={onToggleStyle}
    onKeyDown={onToggleStyle}
    data={{
      icon: accessibleStyle ? 'align-left' : 'list-ol',
      attrs: {
        'aria-pressed': !accessibleStyle,
        'aria-controls': resultContId,
        'aria-label': accessibleStyle ? '' : messages.get(`Dictionary.entries.style.printformatAria`)
      }
    // formButton: 'button',
    }}
  />
);

EntriesFormatIcon.propTypes = {
  base: PropTypes.string,
  resultContId: PropTypes.string,
  accessibleStyle: PropTypes.bool,
  onToggleStyle: PropTypes.func
};

export default EntriesFormatIcon;
