import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../img/grimmszotar-new.svg';
import messages from '../../messages';

const GrimmBrand = ({linkedToFront = true, label}) => {
  const TagWrp = linkedToFront ? 'a' : 'div';
  const attrs = linkedToFront ? {
    href: "/",
    "aria-label": label || messages.get('meta.pageTitle.frontPage')
  } : {};
  return (
    <TagWrp role="banner" className="brand-logo" {...attrs}>
      <img src={logo} alt={messages.get('Branding.fullName')} />
    </TagWrp>
  );
};

GrimmBrand.propTypes = {
  label: PropTypes.string,
  linkedToFront: PropTypes.bool
};

export default GrimmBrand;
