
import React from 'react';
import PropTypes from 'prop-types';
import LayoutUnavailable from './base/layout/LayoutUnavailable';

import messages from './messages';
const baseClass = "Page404";

const Page404 = ({appPub, ...props}) => {
  return <LayoutUnavailable
    headline={messages.get('Page404.headline')}
    actionHref="/"
    actionText={messages.get('Page404.action')}
    baseClass={baseClass}
    appPub={appPub}
  />;
};

Page404.propTypes = {
  appPub: PropTypes.object.isRequired
};

export default Page404;
