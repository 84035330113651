import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import ContentLink, {propTypeTooltip} from './contentLink';
import {isExternalUrl} from '../lib/util';

const rxTypes = [
  [/.pdf$/i, 'file-pdf'],
  [/.docx?|odf|txt$/i, 'file-alt'],
  [/.xlsx?$/i, 'file-excel'],
  [/https?:\/\/[a-zA-Z0-9]+\.grimm(ny|e)p\.hu\/[-_a-zA-Z0-9]+\/download\/document\/[a-zA-Z0-9]+/i, 'file'],
  [isExternalUrl, 'external-link-alt']
];

const getTypeIcon = (href = "") => {
  for (let i = 0; i < rxTypes.length; i++) {
    const [rxType, icon] = rxTypes[i];
    if (
      (typeof rxType === "function" && rxType(href)) ||
      (rxType instanceof RegExp && href.search(rxType) > -1)
    ) {
      return icon;
    }
  }
};


const ContentButtonForFwRef = ({
  _parentId,
  id,
  tabIndex,
  tooltip,
  passedData = {},
  baseClass = "cmp-ContentButton",
  className,
  children,
  // region,
  // items,
  data = {},
  ...rest
}, ref) => {

  const {text, theme = 'primary', disabled, tooltip: tooltipData} = data;

  const {link, autoIcon = false} = data;

  const linkHref = (link?.href || data.href) ?? null;

  const type = linkHref ? 'link' : data.type || 'button';

  const {className: attrClassName, ...attrs} = data.attrs || {};

  let _text = text;

  if ((theme === "icon" || theme === "icon-dark")) {

    if (!attrs['title'] && text) {
      attrs['title'] = text;
    }

    if (!attrs['aria-label'] && text && attrs['title'] !== text) {
      attrs['aria-label'] = text;
    }

    if (!attrs['aria-label'] && attrs['title'] && (tooltipData || tooltip)) {
      attrs['aria-label'] = attrs['title'];
    }
    _text = '';
  }


  let typeIcon = null;
  if (autoIcon && !link.iconRight && (typeIcon = getTypeIcon(linkHref))) {
    link.iconRight = typeIcon;
  }
  const formButton = type !== 'link' ? (type || null) : null;


  return (
    <ContentLink
      ref={ref}
      tabIndex={tabIndex}
      tooltip={tooltip}
      id={id || null}
      baseClass={baseClass}
      passedData={passedData}
      innerWrap={'span'}
      textWrap={'em'}
      onClick={rest ? rest.onClick : null}
      onKeyDown={rest ? rest.onKeyDown : null}
      data={{
        tooltip: tooltipData,
        formButton,
        text: _text,
        ...(link || {href: linkHref}),
        attrs: {
          className: classNames(
            theme ? `${baseClass}--${theme}` : null,
            disabled ? 'disabled' : null,
            (link?.iconLeft || link?.iconRight) ? 'with-icon' : null,
            className,
            attrClassName
          ),
          "aria-disabled": disabled ? "true" : void 0,
          role: 'button',
          ...(attrs || {})
        }
      }}
    >{children}
    </ContentLink>
  );
};


const ContentButton = React.forwardRef(ContentButtonForFwRef);



ContentButtonForFwRef.propTypes = {
  _item: PropTypes.shape({
    getItemData: PropTypes.func
  }),

  _parentId: PropTypes.string,

  id: PropTypes.string,
  tabIndex: PropTypes.number,
  region: PropTypes.string,
  baseClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  passedData: PropTypes.object,
  tooltip: propTypeTooltip,
  data: PropTypes.shape({
    // @text link szövege
    text: PropTypes.string,

    // @theme: az előzetes ui-n elég sokféle gomb szerepel, véglegesítés után kigyűjtöm a válaszható lehetőségeket
    // - primary: piros háttér, fehér szöveg
    // - grey: szürke háttér, fehér szöveg
    // - lightgrey: világosszürke háttér, fekete szöveg
    // - red_ghost: piros keretes áttetsző háttér, piros szöveg
    // - grey_ghost: szürke keretes áttetsző háttér, szürke szöveg (piros ikon)
    // - blue: "Magán ág" kék + fehér felirat
    // - icon: a gomb csak egy ikont tartalmaz
    // - link: kék, aláhúzott
    theme: PropTypes.oneOf([
      'primary',
      'grey',
      'gray',
      'lightgrey',
      'red_ghost',
      'grey_ghost',
      'blue',
      'link',
      'inline-link',
      'red-link',
      'grimm-ghost',
      'grimm-ghost-blue',
      'grimm-ghost-orange',
      'grimm-orange',
      'grimm-orange-def',
      'grimm-orange-big',
      'icon',
      'icon-dark'
    ]),

    type: PropTypes.oneOf(['submit', 'reset', 'link']),

    // @link:  a contentLink komponens data paraméterei adhatóak át (az itt megadott text felülírja a fentit)
    link: PropTypes.shape({
      // ezeket az értékeket a data tulajdonságaként is meg lehet adni
      iconRight: PropTypes.string,
      iconLeft: PropTypes.string,
      href: PropTypes.string
    }),
    href: PropTypes.string,

    // @disabled: amennyiben true, disabled és aria-disabled attribokat kap.
    disabled: PropTypes.bool,

    // @autoIcon: true esetén a gomb jobb oldali ikonja a href-nek megfelelően alakul, pl: pdf, doc, file, stb
    autoIcon: PropTypes.bool,

    // @attrs: elem attribútumok, (href, target felülírja a fent megadottat)
    attrs: PropTypes.object,
    // @tooltip: a gombhoz tartozó tooltip szövege vagy true esetén a title attribútumot használja
    tooltip: propTypeTooltip
  }).isRequired,
  items: PropTypes.array
};

export {propTypeTooltip};

export default ContentButton;
