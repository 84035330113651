/* eslint-disable no-use-before-define */

import React, {useRef, useReducer, useCallback} from 'react';

import PropTypes from 'prop-types';
import AuthRelatedPage from "./AuthRelatedPage";
import { useIsMounted } from '../../hooks';
import { userCheckMail } from './userHelper';
import { parseParams, isExternalUrl } from '../../lib/util';
// import TestPhaseWarn from './components/TestPhaseWarn';
import ActivateLink from './components/ActivateLink';
import RegisterLink from './components/RegisterLink';
import ForgotPwLink from './components/ForgotPwLink';
import * as schemes from './data/schemes';
import { formDataExtend } from './data/formDataHelper';
import globals from '../../controller/globals';
import messages from '../../messages';

function reducer(state, {type, payload}) {
  switch (type) {
    case 'page':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}

const formData = {
  formId: 'login',
  title: messages.get('user.pageLogIn.title'),
  primaryBtn: {text: messages.get('user.pageLogIn.primaryBtn') },
  // warn: <TestPhaseWarn />,
  secondaryBtn: (getEventData) => {
    const {defUsername} = (getEventData().pageData || {});
    const history = globals.get('history');
    return !defUsername ? null : {
      action: () => {
        history.push('/user/signin', defUsername ? {
          username: defUsername,
          divertedToLogin: defUsername
        } : {});
      }
    };
  },
  desc: (getEventData) => {
    // const {pageData, useFormInstance, statesOfFields} = getEventData();
    // const defUsername = pageData?.defUsername;
    // const curUsernameVal = useFormInstance.getValues('username');

    // const username = (
    //   curUsernameVal && statesOfFields &&
    //   statesOfFields?.username?.fieldState === "emailRegistered"
    // )
    //   ? curUsernameVal
    //   : defUsername
    // ;

    return <>
      {messages.get('user.pageLogIn.pleaseEnterPwToSignIn')}
      {/* <br /> <ForgotPwLink username={username} /> */}
    </>;
  },
  fieldsStates: {
    emailRegistrationDisabled: {
      primaryBtn: {
        disabled: true
      }
    },
    emailRegisteredButNotActivated: {
      primaryBtn: {
        disabled: true
      }
    },
    emailNotRegistered: {
      primaryBtn: {
        disabled: true
      }
    },
    emailRegistered: {
      primaryBtn: {
        disabled: false
      }
    },
    apiError: {
      error: (getEventData, {msg}) => msg
    },
    apiSuccess: {
      success: messages.get('user.login.loginSuccess')
    }
  },
  fields: [
    {
      name: "username",
      label: messages.get('user.fields.username'),
      autoComplete: "username",
      type: 'email',
      schema: schemes.username,
      helperText: null,
      fieldStates: {
        emailRegistered: {
          helperText: null
        },
        emailNotValid: {
          helperText: (getEventData, {msg}) => (
            <span style={{color: 'red'}}>{msg}</span>
          )
        },
        emailRegisteredButNotActivated: {
          helperText: (getEventData) => {
            const {value} = getEventData();
            return <>
              <span style={{color: 'darkorange'}}>
                {messages.get('user.pageLogIn.emailRegisteredButNotActivated.helperText')}
              </span>{' '}
              <ActivateLink username={value} />
            </>;
          }
        },
        emailNotRegistered: {
          helperText: (getEventData) => {
            const {value} = getEventData();
            return <>
              <span style={{color: 'darkorange'}}>
                {messages.get('user.pageLogIn.emailNotRegistered.helperText')}
              </span>{' '}<RegisterLink username={value} />
            </>;
          }
        },
        emailRegistrationDisabled: {
          helperText: (
            <span style={{color: 'red'}}>
              {messages.get('user.pageLogIn.emailRegistrationDisabled.helperText')}
            </span>
          )
        }
      }
    },
    {
      name: "password",
      label: messages.get('user.fields.password'),
      autoComplete: "current-password",
      type: 'password',
      schema: schemes.password,
      helperText: (getEventData) => {
        const {pageData, useFormInstance, statesOfFields} = getEventData();
        const defUsername = pageData?.defUsername;
        const curUsernameVal = useFormInstance.getValues('username');

        const username = (
          curUsernameVal && statesOfFields &&
          statesOfFields?.username?.fieldState === "emailRegistered"
        )
          ? curUsernameVal
          : defUsername
        ;

        return <ForgotPwLink username={username} />;
      }
    }
  ]
};



const PageLogIn = ({appPub, history, location, ...props}) => {
  const params = parseParams(location.search);
  const paramUsername = location?.state?.username || params.username || '';
  const paramTarget = decodeURIComponent(location?.state?.target || params.target || '');
  const targetUrl = !isExternalUrl(paramTarget) ? paramTarget : null;
  const defUsername = schemes.email.validate(paramUsername) ? '' : paramUsername.toLowerCase();
  const refForm = useRef();

  const {api, toastErrorMsg} = appPub;
  const isMounted = useIsMounted();


  const setFieldsState = useCallback((fieldsState, fieldsStateArgs = null) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch({ type: 'page', payload: { fieldsState, fieldsStateArgs } });
  }, []);

  const onUserNameChange = useCallback((e, {
    value, schema, fieldState, setFieldState, ...rest}
  ) => {

    const email = value + ''.trim();
    const validFormat = !schema.validate(value);

    if (validFormat) {
      userCheckMail(email, {
        isMounted,
        api,
        toastErrorMsg
      }, ({
        fieldsState,
        fieldsStateArgs,
        fieldState,
        fieldStateArgs
      }) => {
        setFieldsState(fieldsState, fieldsStateArgs);
        setFieldState(fieldState, fieldStateArgs);
      });
    }
    else {
      setFieldsState(null, null);
      setFieldState(null, null);
    }
  }, [api, isMounted, setFieldsState, toastErrorMsg]);

  const [state, dispatch] = useReducer(reducer, {
    fieldsState: null,
    fieldsStateArgs: null,
    loading: false,
    pageData: {
      defUsername
    },
    currentForm: (
      formDataExtend(formData, {
        fields: [
          {
            name: 'username',
            onChange: onUserNameChange,
            onBlur: onUserNameChange,
            onInit: onUserNameChange,
            defaultValue: defUsername
          },
          {
            name: 'password',
            ...(defUsername ? {autoFocus: true} : {})
          }
        ]
      })
    )
  });


  const onSubmit = useCallback(({username, password}) => {
    if (!state.loading) {
      dispatch({ type: 'page', payload: { loading: true } });

      api.getEndpointByAlias('user-login').fetch({
        requestData: {
          params: {
            login: username,
            password: password
          }
        }
      }, (status, data) => api.fetchResponseHelper({
        status,
        data,
        isMounted: () => isMounted(),
        onAny: () => dispatch({ type: 'page', payload: { loading: false } }),
        onSuccess: () => {
          if (data.loggedin && data.user) {
            setFieldsState('apiSuccess');
            appPub.login(data.user, targetUrl ? targetUrl : true, true);
          }
          else {
            const msg = messages.get('user.pageLogIn.apiUnexpectedError');
            toastErrorMsg(msg, 'user-login');
            setFieldsState('apiError', [{msg}]);
          }

        },
        onErrorMsg: (msg) => {
          toastErrorMsg(msg, 'user-login');
          setFieldsState('apiError', [{msg}]);
        }
      }), true);
    }
  }, [api, appPub, isMounted, setFieldsState, state, toastErrorMsg]);



  // console.log('APP PROPS', props);
  return (
    <AuthRelatedPage
      appPub={appPub}
      // appProps={props}
      loading={state.loading}
      refForm={refForm}
      onSubmit={onSubmit}
      fieldsState={state.fieldsState}
      fieldsStateArgs={state.fieldsStateArgs}
      formData={state.currentForm}
      pageData={state.pageData}
    />
  );
};

PageLogIn.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PageLogIn;
