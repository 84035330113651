/* eslint-disable react/prop-types */

import React from 'react';
import ContentLink from "../components/contentLink";
import { href_pdfTerms, href_pdfPrivacy, pubFeature } from '../setup';
import messages from '../messages';
import globals from '../controller/globals';

const PageHeaderMenuItem = ({separator = false, href, text, target, attrs, currentPath = "/"}) => (
  separator ? (
    <li className="separator"></li>
  ) : (
    text ? (
      <li>
        <ContentLink aria-current={currentPath === href ? 'page' : null} data={{ href, text, target, attrs }} />
      </li>
    ) : null)
);



const PageHeaderMenu = React.memo(({loggedin, onClose}) => {
  const appPub = globals.get('appPub');
  const currentPath = appPub?.locationData?.location?.pathname;


  const items = [
    loggedin ? null : {href: '/user/signin', text: 'Belépés / Regisztráció'},
    {href: '/', text: messages.get('mainMenu.dict')},
    {href: '/about', text: messages.get('mainMenu.about')},
    pubFeature.szotarnaplo ? {href: '/entrylog', text: messages.get('mainMenu.entrylog')} : null,
    {separator: true},
    {href: href_pdfTerms, text: messages.get('mainMenu.terms'), target: '_blank'},
    {href: href_pdfPrivacy, text: messages.get('mainMenu.privacy'), target: '_blank'},
    {text: messages.get('mainMenu.cookies'), attrs: { tabIndex: 0, className: 'show-cookie-settings'}},
    {separator: true},
    {href: 'https://konyvesbolt.grimm.hu/', text: messages.get('mainMenu.bookstore'), target: '_blank'}
  ];


  return (
    <ul className="main-navigation__menu" aria-label="Főmenü" onClick={onClose} onKeyPress={onClose}>
      {items.map((item, i) => (
        item ? <PageHeaderMenuItem key={i} {...item} currentPath={currentPath} /> : null
      ))}
    </ul>
  );
});


PageHeaderMenu.displayName = 'PageHeaderMenu';

export default PageHeaderMenu;
