/* eslint-disable react/prop-types */
import React from 'react';
import ReferralLink from './ReferralLink';
import messages from '../../../messages';

const LoginLink = ({username, prefix}) => (
  <ReferralLink
    link="/user/login/"
    username={username}
    prefix={prefix || messages.get('user.loginLink.prefix')}
    linkText={messages.get('user.loginLink.linkText')}
    suffix={messages.get('user.loginLink.suffix')}
  />
);

export default LoginLink;
