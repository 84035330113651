
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import messages from '../../messages';


const EntriesA11ySelect = ({onWordSelect, activeWord, base, resultWords, resultContId}) => {
  const onSelect = useCallback((e) => {
    if (e.target.value && typeof onWordSelect === "function") {
      onWordSelect(e, e.target.value, 0);
    }
  }, [onWordSelect]);

  return (
    <div className={`${base}__a11y-activeword`}>
      <label htmlFor="trs-a11y-activeword">{
        messages.get(resultWords.length > 1 ? 'tr.entriesA11yAriaLabel' : 'tr.entriesA11yAriaLabelOne')
      }</label>
      <select
        aria-label={messages.get('tr.entriesSelectDictA11yAriaLabel')}
        id="trs-a11y-activeword"
        accessKey='t'
        onChange={onSelect}
        value={activeWord}
        aria-controls={resultContId}
      >
        {resultWords.map((w, i) => (
          <option value={w} key={i}>{w}</option>
        ))}
      </select>
    </div>
  );
};

EntriesA11ySelect.propTypes = {
  onWordSelect: PropTypes.func,
  resultContId: PropTypes.string,
  activeWord: PropTypes.string,
  base: PropTypes.string,
  resultWords: PropTypes.arrayOf(PropTypes.string)
};

export default EntriesA11ySelect;
