
import React, {useRef, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import ContentButton from '../components/contentButton';
import Overlay from 'react-bootstrap/Overlay';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import classNames from 'classnames';
import Popover from 'react-bootstrap/Popover';
import ContentDropDownList from './contentDropDownList';
// import globals from '../controller/globals';
import { keyCodes } from '../lib/util';

const ContentDropDown = React.memo(({
  children,
  className,
  contentLabel,
  ariaListLabel,
  baseClass = "contentDropDown",
  onVisibilityChange,
  propOverClass,
  icon = "user-circle",
  content,
  activeKey,
  items,
  buttonTitle = '',
  buttonText = '',
  refContainer,
  href,
  id,
  onChange = () => void 0
}) => {
  const refOpener = useRef(null);
  const refToTarget = useRef(null);
  const refFirstItem = useRef();
  const [visible, setVisible] = useState(false);

  // const appPub = globals.get('appPub');
  // const history = globals.get('history');

  const hide = useCallback(
    () => {
      refOpener.current = null;
      setVisible(false);
      if (typeof onVisibilityChange === 'function') {
        onVisibilityChange(false);
      }
    },
    [onVisibilityChange]
  );

  const show = useCallback(() => {
    setVisible(true);
    if (typeof onVisibilityChange === 'function') {
      onVisibilityChange(true);
    }
  }, [onVisibilityChange]);

  const _onChange = useCallback((eventKey, ...rest) => {
    const item = Array.isArray(items)
      ? items.find(item => item.eventKey === eventKey)
      : null
    ;
    const disabled = item ? item.disabled : false;

    if (typeof onChange === 'function' && !disabled) {
      onChange(eventKey, rest);
    }
    if (item && typeof item.onSelect === 'function' && !disabled) {
      item.onSelect(item, rest);
    }
    if (!disabled) {
      hide();
    }
  }, [hide, onChange, items]);

  const onClickOrKeyDown = useCallback((e) => {
    if (e.type === "keydown" &&
      !(
        e.which === keyCodes.SPACE ||
        e.which === keyCodes.ENTER ||
        e.which === keyCodes.DOWN ||
        // e.which === keyCodes.UP ||
        e.which === keyCodes.ESC ||
        (e.which === keyCodes.TAB && !e.shiftKey)
      )
    ) {
      return;
    }
    if (e.which === keyCodes.ESC) {
      if (visible) hide();
      e.preventDefault();
      return;
    }
    if (
      (e.which === keyCodes.TAB && !e.shiftKey) ||
      (e.which === keyCodes.DOWN && visible)
      // (e.which === keyCodes.UP && visible)
    ) {
      if (visible && refFirstItem.current) {
        e.preventDefault();
        refOpener.current = e.target;
        refFirstItem.current.focus();
      }
      return;
    }

    if (e.which === keyCodes.DOWN && !visible) {
      refOpener.current = e.target;
      e.preventDefault();
      show();
      return;
    }

    e.preventDefault();

    if (!visible) {
      refOpener.current = e.target;
      show();
    }
    else {
      hide();
    }
  }, [hide, show, visible]);



  return (
    <>
      {typeof children === "function" ? (
        children({refToTarget, refFirstItem, onClickOrKeyDown, show, hide, visible})
      ) : (
        <ContentButton
          id={id}
          ref={refToTarget}
          data={{
            attrs: {
              onClick: !href ? onClickOrKeyDown : null,
              onKeyDown: !href ? onClickOrKeyDown : null,
              'aria-haspopup': 'menu',
              'aria-controls': id + '-menu',
              'aria-expanded': visible ? 'true' : 'false',
              className: classNames(
                baseClass,
                className
              ),
              tabIndex: 0,
              title: buttonTitle
            },
            theme: icon ? 'icon' : 'link',
            link: {
              href: href ? href : null,
              iconLeft: icon
            },
            text: buttonText
          }}
        >{children}</ContentButton>
      )}

      <Overlay
        rootClose={true}
        rootCloseEvent="click" // click
        onHide={hide}
        show={visible}
        target={refToTarget.current}
        placement="bottom"
        container={refContainer.current}
        containerPadding={20}
      >
        <Popover id={id + '-popover'}
          className={classNames(`${baseClass}__content`, propOverClass)}
        >
          {contentLabel && <Popover.Title as="h3" className={`${baseClass}__label`}>{contentLabel}</Popover.Title>}
          <Popover.Content>
            {Array.isArray(items) ? (
              <ContentDropDownList
                id={id + '-menu'}
                refOpener={refOpener}
                ariaListLabel={ariaListLabel}
                refFirstItem={refFirstItem}
                activeKey={activeKey}
                items={items}
                onSelect={_onChange}
              />
            ) : null}
            {typeof content === "function" ? content({show, hide, visible}) : content}
          </Popover.Content>
        </Popover>
      </Overlay>

    </>
  );
});

ContentDropDown.propTypes = {
  id: PropTypes.string.isRequired,
  href: PropTypes.string,
  activeKey: PropTypes.string,
  icon: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonText: PropTypes.string,
  ariaListLabel: PropTypes.string,
  className: PropTypes.string,
  onVisibilityChange: PropTypes.func,
  propOverClass: PropTypes.string,
  baseClass: PropTypes.string,
  contentLabel: PropTypes.string,
  onChange: PropTypes.func,
  refContainer: PropTypes.object.isRequired,
  items: PropTypes.array,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

ContentDropDown.displayName = 'ContentDropDown';


export default ContentDropDown;
