/* eslint-disable no-use-before-define */

import React, {useRef, useReducer, useCallback, useContext} from 'react';

import PropTypes from 'prop-types';
import AuthRelatedPage from "./AuthRelatedPage";
import { useIsMounted } from '../../hooks';
import { userCheckMail } from './userHelper';
import { parseParams } from '../../lib/util';
import { userSetup } from '../../setup';

import ActivateLink from './components/ActivateLink';
import RegisterLink from './components/RegisterLink';
import globals from '../../controller/globals';

import * as schemes from './data/schemes';
import { formDataExtend } from './data/formDataHelper';
import messages from '../../messages';
import { UserContext } from '../../Contexts';


function reducer(state, {type, payload}) {
  switch (type) {
    case 'page':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}

const formData = {
  formId: 'new-password-request',
  title: messages.get('user.PagePwRequest.title'),
  primaryBtn: {text: messages.get('user.PagePwRequest.primaryBtn') },
  desc: messages.get('user.PagePwRequest.desc'),
  secondaryBtn: (getEventData) => {
    const {userData, pageData} = getEventData();
    const {defUsername} = (pageData || {});
    const history = globals.get('history');
    return userData.loggedin ? null : {
      action: () => {
        history.push('/user/login', defUsername ? {username: defUsername} : {});
      }
    };
  },
  fieldsStates: {
    emailRegistrationDisabled: {
      primaryBtn: {
        disabled: true
      }
    },
    emailRegisteredButNotActivated: {
      primaryBtn: {
        disabled: true
      }
    },
    emailNotRegistered: {
      primaryBtn: {
        disabled: true
      }
    },
    emailRegistered: {
      primaryBtn: {
        disabled: false
      }
    },
    apiError: {
      error: (getEventData, {msg}) => msg
    },
    apiSuccess: {
    }
  },
  fields: [
    {
      name: "username",
      label: messages.get('user.fields.username'),
      autoComplete: "username",
      type: 'email',
      schema: schemes.username,
      helperText: null,
      fieldStates: {
        emailRegistered: {

        },
        emailRegisteredButNotActivated: {
          helperText: (getEventData) => {
            const {value} = getEventData();
            return <>
              <span style={{color: 'darkorange'}}>
                {messages.get('user.PagePwRequest.emailRegisteredButNotActivated.helperText')}
              </span>{' '}
              <ActivateLink username={value} />
            </>;
          }
        },
        emailRegistrationDisabled: {
          helperText: (getEventData) => {
            // const {value} = getEventData();
            return <>
              <span style={{color: 'red'}}>
                {messages.get('user.PagePwRequest.emailRegistrationDisabled.helperText')}
              </span>
            </>;
          }
        },
        emailNotRegistered: {
          helperText: (getEventData) => {
            const {value} = getEventData();
            return <>
              <span style={{color: 'red'}}>
                {messages.get('user.PagePwRequest.emailNotRegistered.helperText')}
              </span>{' '}
              <RegisterLink username={value} />
            </>;
          }
        }
      }
    }
  ]
};



const minWaitMs = userSetup.verifyMailMinWaitMs;

const PagePwRequest = ({appPub, history, location, ...props}) => {
  const paramUsername = location?.state?.username || parseParams(location.search).username || '';
  const defUsername = schemes.email.validate(paramUsername) ? '' : paramUsername.toLowerCase();
  const refForm = useRef();

  const {api, toastErrorMsg, toastSuccessMsg, toastWarnMsg} = appPub;
  const isMounted = useIsMounted();
  const userData = useContext(UserContext);


  const setFieldsState = useCallback((fieldsState, fieldsStateArgs = null) => {
    dispatch({ type: 'page', payload: { fieldsState, fieldsStateArgs } });
  }, []);


  const onUserNameChange = useCallback((e, {
    value, schema, fieldState, setFieldState, ...rest}
  ) => {
    const email = value + ''.trim();
    const validFormat = !schema.validate(value);
    if (validFormat) {
      userCheckMail(email, {
        isMounted,
        api,
        toastErrorMsg
      }, ({
        fieldsState,
        fieldsStateArgs,
        fieldState,
        fieldStateArgs
      }) => {
        setFieldsState(fieldsState, fieldsStateArgs);
        setFieldState(fieldState, fieldStateArgs);
      });
    }
    else {
      setFieldsState(null, null);
      setFieldState(null, null);
    }

  }, [api, isMounted, setFieldsState, toastErrorMsg]);


  const [state, dispatch] = useReducer(reducer, {
    fieldsState: null,
    fieldsStateArgs: null,
    loading: false,
    pageData: {
      defUsername
    },
    currentForm: (
      formDataExtend(formData, {
        fields: [
          {
            name: 'username',
            onChange: onUserNameChange,
            onBlur: onUserNameChange,
            onInit: onUserNameChange,
            defaultValue: defUsername
          }
        ]
      })
    )
  });


  const onSubmit = useCallback(({username}) => {
    if (!state.loading && (
      state.fieldsState === 'emailRegistered' || state.fieldsState === 'apiError'
    )) {
      const now = (new Date()) - 0;
      const {lastSentAt, lastSentTo} = appPub.getStateDeep('user.pwRequest') || 0;


      if (lastSentTo && lastSentTo === username && now - lastSentAt < minWaitMs) {
        const timeLeft = Math.round((minWaitMs - (now - lastSentAt)) / 1000);
        toastWarnMsg(
          messages.get('user.resendEmail.pwVerifyCodeAlreadySent', { timeLeft }),
          'user-reminduser-timeleft'
        );
        history.push('/user/changepass', {sentto: username});

        return;
      }

      dispatch({ type: 'page', payload: { loading: true } });

      api.getEndpointByAlias('user-reminduser').fetch({
        requestData: {
          params: {
            email: username.toLowerCase(),
            action: 'PASSWORD'
          }
        }
      }, (status, data) => api.fetchResponseHelper({
        status,
        data,
        isMounted: () => isMounted(),
        onAny: () => dispatch({ type: 'page', payload: { loading: false } }),
        onSuccess: () => {
          history.push('/user/changepass', {sentto: username});
          appPub.setStateDeep('user.pwRequest', {
            ...appPub.getStateDeep('user.pwRequest'),
            lastSentAt: (new Date()) - 0,
            lastSentTo: username
          });
          toastSuccessMsg(messages.get('user.resendEmail.pwVerifyCodeJustSent'), 'user-reqpw-success');
        },
        onErrorMsg: (msg) => {
          toastErrorMsg(msg, 'user-reqpw-error');
          setFieldsState('apiError', [{msg}]);
        }
      }), true);
    }
  }, [api, appPub, history, isMounted, setFieldsState, state, toastErrorMsg, toastSuccessMsg, toastWarnMsg]);



  // console.log('APP PROPS', props);
  return (
    <AuthRelatedPage
      appPub={appPub}
      // appProps={props}
      loading={state.loading}
      refForm={refForm}
      onSubmit={onSubmit}
      fieldsState={state.fieldsState}
      fieldsStateArgs={state.fieldsStateArgs}
      formData={state.currentForm}
      pageData={state.pageData}
    />
  );
};

PagePwRequest.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PagePwRequest;
