/* eslint-disable react/prop-types */
import React from 'react';
import ReferralLink from './ReferralLink';
import messages from '../../../messages';

const RegisterLink = ({username, prefix}) => (
  <ReferralLink
    link="/user/signup/"
    username={username}
    prefix={prefix || messages.get('user.registerLink.prefix')}
    linkText={messages.get('user.registerLink.linkText')}
    suffix={messages.get('user.registerLink.suffix')}
  />
);

export default RegisterLink;
