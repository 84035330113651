import memoizeOne from 'memoize-one';
import {
  nativeLang //, dictSettings
} from '../../setup';
import { unicodeHelper } from '../../lib/util';
import UAC from '../../UAC';
import { locUserStore } from '../../lib/BrowserStore';
import { getDictList } from '../../Contexts';
import { Entry } from './Results';
import { PREVIEW_MODE } from '../../controller/devel';

import {QUERY_STATUSES} from './enums';

import {
  TypeEntry,
  TypeDict,
  TypeDictList,
  TypeDictOption,
  TypeStrTlds,
  TypeLangPair,
  TypeTldSrcTrg,
  // TypeInitialValues,
  TypeDictState,
  TypeDictAccess,
  TypeDictWithAccess
} from '../../types/Dict';

import {
  TypeUserData
} from '../../types/User';

import { getUserData } from '../../Contexts';



// const initialValues: TypeInitialValues = dictSettings.initialValues as TypeInitialValues;

export const sortDictsByWeight = (a:TypeDictOption, b:TypeDictOption) => {
  const aX = a.weight || 0;
  const bX = b.weight || 0;

  return aX === bX ? 0 : (
    aX > bX ? 1 : -1
  );
};

export const getForeignLang = ({src, trg}:TypeTldSrcTrg): TypeStrTlds => {
  return src === nativeLang ? trg : src;
};



export const genIdLangPairId = (src:TypeStrTlds, trg:TypeStrTlds) => `${src}-${trg}`;

const rxDictIdMatch = /(?:\w\w-)(.*)$/i;
export const getDictIdSuffix = (dictId: string):string | null =>
  ((dictId + '').match(rxDictIdMatch) || [])[1] || null
;

export const normalizeSourceText = (text: string) => {
  return unicodeHelper.dedupeSpaces(text);
};

export const isSubmittableState = (
  state: TypeDictState,
  words: string[],
  _userData:TypeUserData = getUserData()
) => {
  const r = state.lastQuery.status === QUERY_STATUSES.error || !!(
    (state.curInputText || words.length) && (
      state.lastQuery.text !== state.curInputText ||
      state.lastQuery.dict !== state.curDictId ||
      // state.lastQuery.words?.slice(0).sort().join(',') !== words?.slice().sort().join(',') ||
      JSON.stringify(_userData) !== JSON.stringify(state.lastQuery.userData)
    )
  );

  // PREVIEW_MODE && console.warn('submittable', {
  //   'state.lastQuery.text': state.lastQuery.text,
  //   'state.curInputText': state.curInputText,
  //   'state.lastQuery.dict': state.lastQuery.dict,
  //   'state.curDictId': state.curDictId,
  //   words,
  //   "return": r
  // });
  return r;
};

export const getDict = (
  dictId: string,
  _dictList: TypeDictList = getDictList()
) => {
  if (!_dictList || _dictList.length === 0) {
    console.log('getDict: dictList not yet ready to return dict with this id: ', dictId);
  }
  return _dictList.find(d => d.id === dictId);
};


export const getDictsWithAccesses = (
  _dictList: TypeDictList = getDictList(),
  _user_dict_access?: TypeDictAccess
) => {
  const r : TypeDictWithAccess[] = _dictList.map(
    d => ({...d, ...UAC.getDictAccess(d, _user_dict_access)})
  );
  return r;
};

export const sortLangPairsAbc = (a:TypeLangPair, b:TypeLangPair) =>
  a.foreign_desc && b.foreign_desc ? a.foreign_desc.localeCompare(b.foreign_desc) : 0
;

export const getLangPairs = memoizeOne((
  _dictList: TypeDictList = getDictList(),
  _userData?: TypeUserData,
  _detailed = true
):TypeLangPair[] => {

  if (!Array.isArray(_dictList)) {
    return [{value: '', text: 'Betöltés...'}];
  }
  // const srcDstPairs: Record<string, TypeLangPair> = {};
  const srcDstPairs: {[key: string]: TypeLangPair} = {};

  for (let i = 0; i < _dictList.length; i++) {
    const dict = _dictList[i];
    const id = genIdLangPairId(dict.src, dict.trg);
    if (srcDstPairs[id]) { continue; }
    const foreign = getForeignLang({src: dict.src, trg: dict.trg});
    const foreignAccess = UAC.isAnAccessibleForeignLang(foreign, _userData?.user_dict_access);

    const dicts = _detailed ? getDictsWithAccesses(
      _dictList.filter(({src, trg}) => (src === dict.src && trg === dict.trg)),
      _userData?.user_dict_access
    ) : null;

    const langPair: TypeLangPair = {
      access: _detailed ? !!dicts?.find(da => da.access) : false,
      dicts,
      foreignAccess,
      src: dict.src,
      trg: dict.trg,
      src_desc: dict.src_desc,
      trg_desc: dict.trg_desc,
      foreign,
      foreign_desc: dict.src === nativeLang ? dict.trg_desc : dict.src_desc,
      unidirectional: dict.unidirectional,
      text: `${dict.src_desc} - ${dict.trg_desc}`,
      value: id,
      id
    };
    srcDstPairs[id] = langPair;
  }

  // console.log('####', srcDstPairs);
  const langPairs = Object.values(srcDstPairs).sort(sortLangPairsAbc);

  return langPairs;
});


export const getLangDirOption = (dict: TypeDict): TypeDictOption => {
  const {src, trg_desc, src_desc, id, shortname, alternate, name} = dict;

  const foreign_desc = src === nativeLang ? trg_desc : src_desc;

  const {access, expiry} = UAC.getDictAccess(dict);

  const option:TypeDictOption = {
    ...dict,
    access,
    expiry,
    alternate: alternate.split(/,\s+|,/),
    foreign: dict.foreignLang,
    foreign_desc,
    value: id,
    text: shortname || id,
    desc: name.hu
  };
  return option;
};


export const getDictsOptionsByLangDir = (
  src: TypeStrTlds,
  trg: TypeStrTlds,
  _dictList?: TypeDictList
):TypeDictOption[] => {

  if (!Array.isArray(_dictList)) {
    return [{value: '', text: 'Betöltés...', desc: ''}];
  }

  const options = _dictList.filter(d => d.src === src && d.trg === trg).map(
    d => getLangDirOption(d)
  );
  // console.log('----', {userData, options, log});
  return options.sort(sortDictsByWeight);
};


export const getAnAccessibleDictOption = (
  desiredDict: string,
  src: TypeStrTlds,
  trg: TypeStrTlds,
  _dictList: TypeDictList = getDictList()
): [boolean, TypeDictOption | null, string] => {

  const dicts = getDictsOptionsByLangDir(src, trg, _dictList);
  const curDict = dicts.find(d => d.id === desiredDict);

  if (curDict) {
    return [true, curDict, 'exactMatch'];
  }
  const idSuffix = getDictIdSuffix(desiredDict);

  const dictBySuffixMatch = dicts.find(
    d => (d.idsuffix === idSuffix
    ) || (
      idSuffix && d.alternate?.indexOf && d.alternate.indexOf(idSuffix) > -1
    ) || (
      !d.alternate &&
      PREVIEW_MODE && console.warn('nincs ilyen szótár', d, idSuffix, d.alternate)
    )
  );

  if (dictBySuffixMatch) {
    return [true, dictBySuffixMatch, 'bySuffix'];
  }

  if (dicts[0]) {
    return [true, dicts[0], 'byFirstUsable'];
  }

  return [
    false,
    null,
    'notMatch'
  ];
};


export const entryList = (function() {
  const lsProp = "myEntryList";
  let initalized = false;
  let entryList: TypeEntry[] = [];

  const init = () => {
    if (initalized) { return; }
    entryList = locUserStore.get(lsProp, [], true) as TypeEntry[];
    initalized = true;
  };

  const cloneEntry = (entry: TypeEntry): TypeEntry => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {_results, cachedHtml, ...restEntry} = entry;
    return {
      ...restEntry
    };
  };

  const updateStore = () => {
    locUserStore.set(lsProp, entryList as never, true);
  };

  return {
    add(entry: TypeEntry) {
      init();
      const prevEntry = this.get(entry.fid);
      if (!prevEntry) {
        entryList.push(cloneEntry(entry));
        updateStore();
      }
    },
    remove(entry: TypeEntry) {
      init();
      const prevEntry = this.get(entry.fid);
      if (prevEntry) {
        entryList.splice(entryList.indexOf(prevEntry), 1);
        updateStore();
      }
    },
    removeByFid(fid: string) {
      init();
      const prevEntry = this.get(fid);
      if (prevEntry) {
        this.remove(prevEntry);
      }
    },
    get(fid: string) {
      init();
      return entryList.find(e => e.fid === fid);
    },
    getAll() {
      init();
      return entryList.slice();
    },
    getAllAsInstances() {
      const all = this.getAll();
      return all.map(entry => new Entry({ fid: entry.fid, entry: entry.data}, null));
    },
    toggle(
      entry: TypeEntry,
      toggle: boolean,
      fn: { (value: boolean): void; }
    ) {
      init();
      void (toggle ? this.add(entry) : this.remove(entry));

      if (typeof fn === 'function') {
        fn(toggle);
      }
    }
  };
})();
