import { getForeignLang } from "./base/dictionary/dictHelpers";
import { getUserDictAccess, getDictList } from "./Contexts";
import { TypeDict, TypeStrTlds, TypeDictList, TypeDictAccess, TypeDictAccessLang } from "./types/Dict";
import { TypeAccessState } from "./types/Subscription";
import {dictSettings} from "./setup";

const initialValues = dictSettings.initialValues;

const sortFreeAtFirst = (a: TypeDict, b: TypeDict) => {
  return a.free === b.free ? 0 : a.free < b.free ? 1 : -1;
};


export const UAC = {
  getAnAccessibleDict(
    src: TypeStrTlds,
    trg: TypeStrTlds,
    desiredDictId?: string,
    desiredOnly? : boolean,
    _dictList: TypeDictList = getDictList(),
    _user_dict_access: TypeDictAccess = getUserDictAccess()
  ): TypeDict | null {

    const uaLang = this.getUACLangIfExists(src, trg, _user_dict_access);

    if (_dictList && uaLang && src && trg) {
      const accessibleDictIds = [...(uaLang.freedicts || []), ...(uaLang.dicts || [])];
      const altDicts = _dictList.filter(
        (d) => d.src === src && d.trg === trg && accessibleDictIds.indexOf(d.id) > -1
      );
      const desired = desiredDictId ? altDicts.find(d => d.id === desiredDictId) : null;

      return desired
        ? desired
        : (desiredOnly
          ? null
          : altDicts.sort(sortFreeAtFirst)[0] || null
        );
    }
    else {
      // eslint-disable-next-line prefer-rest-params
      console.warn('getAnAccessibleDict: some props is missing', arguments);
    }
    return null;
  },

  getAccessibleInitialDict(): TypeDict | null {
    const initialDict = this.getAnAccessibleDict(
      initialValues.src,
      initialValues.trg,
      initialValues.dictId,
      true
    );

    if (!initialDict) {
      console.warn('FATAL ERROR: getAccessibleInitialDict(): initial dict not found with these conditions', initialValues);
    }

    return initialDict || null;
  },

  getUACLangIfExists(
    src: TypeStrTlds,
    trg: TypeStrTlds,
    _user_dict_access:TypeDictAccess = getUserDictAccess()
  ): TypeDictAccessLang | null {
    const foreign = getForeignLang({src, trg}) as TypeStrTlds;
    const dictAccessLang = _user_dict_access ? _user_dict_access[foreign] : null;
    return dictAccessLang;
  },

  getDictAccessById(
    dictId: string,
    _dictList: TypeDictList = getDictList()
  ) {
    const dict = _dictList.find(d => d.id === dictId);
    return dict ? this.getDictAccess(dict) : null;
  },

  getDictAccess(
    dict: TypeDict,
    _user_dict_access :TypeDictAccess = getUserDictAccess()
  ): TypeAccessState {
    const udaLang = _user_dict_access && _user_dict_access[dict.foreignLang];
    const access:boolean = !!udaLang && (
      udaLang.dicts.indexOf(dict.id) > -1 ||
      udaLang.freedicts.indexOf(dict.id) > -1
    );
    const expiry = access ? udaLang.expiry[dict.id] : null;
    return {
      access,
      expiry
    };
  },

  // getLangPairAccess(
  //   dict: TypeDict,
  //   src: TypeStrTlds,
  //   trg: TypeStrTlds,
  //   _user_dict_access :TypeDictAccess = getUserDictAccess()
  // ) {
  //   const foreign = getForeignLang({src, trg}) as TypeStrTlds;
  //   const udaLang = _user_dict_access && _user_dict_access[foreign];
  //   console.log(dict)
  //   const access:boolean = !!udaLang && (
  //     udaLang.dicts.indexOf(dict.id) > -1 ||
  //     udaLang.freedicts.indexOf(dict.id) > -1
  //   );
  //   const expiry = access ? udaLang.expiry : null;

  //   return {
  //     access,
  //     expiry
  //   };
  // },

  isAnAccessibleForeignLang(
    foreign: TypeStrTlds,
    _user_dict_access:TypeDictAccess = getUserDictAccess()
  ):boolean {
    return !!(_user_dict_access && _user_dict_access[foreign] && (
      _user_dict_access[foreign].dicts.length > 0 ||
      _user_dict_access[foreign].freedicts.length > 0
    ));
  },

  hasAccessToDictById(
    dictId: string,
    _dictList: TypeDictList = getDictList()
  ) {
    const dict = _dictList.find(d => d.id === dictId);
    if (dict) {
      return this.hasAccessToDict(dict);
    }
    else {
      console.warn('hasAccessToDictById(): dictId not found', dictId);
    }
    return false;
  },

  hasAccessToDict(
    dict: TypeDict,
    _user_dict_access:TypeDictAccess = getUserDictAccess()
  ) {
    const udaLang = _user_dict_access ? _user_dict_access[dict.foreignLang] : null;
    if (!udaLang) {
      console.warn(`hasAccessToDict(): user_dict_access['${dict.foreignLang}'] not found`);
      return false;
    }
    const access = (
      udaLang.dicts.indexOf(dict.id) > -1 ||
      udaLang.freedicts.indexOf(dict.id) > -1
    );
    // console.log(dict.id, access, {
    //   dict, udaLang
    // });

    return access;
  }
};

export default UAC;
