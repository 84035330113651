import React from 'react';
import './LoadingBounce.scss';
import messages from '../../messages';

const LoadingBounce = () => (
  <div className="loading-spinner" aria-label={messages.get('layout.QrPreloader.bounceText')}>
    <div className="londing-spinner__bounce1"></div>
    <div className="londing-spinner__bounce2"></div>
  </div>
);
export default LoadingBounce;
