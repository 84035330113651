import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Hyperlink from './hyperlink';
import faIconMapper from '../lib/faIconMapper';
import TooltipWrapper, {proptypePlacement} from './misc/TooltipWrapper';
import genUuid from '../lib/strings/genUuid';



const tpl = ({iconLeft, iconRight, text, children, TextWrpTag}) => (
  <>
    {(iconLeft ? <i className={'icon-left ' + faIconMapper(iconLeft)}></i> : null)}
    {TextWrpTag ? <TextWrpTag>{text || children}</TextWrpTag> : (text || children)}
    {(iconRight ? <i className={'icon-right ' + faIconMapper(iconRight)}></i> : null)}
  </>
);

const ContentLink = React.forwardRef(({
  method,
  preferredMethod,
  id,
  className,
  baseClass = "cmp-ContentLink",
  passedData = {},
  children,
  innerWrap,
  textWrap,
  tooltip = null,
  data = {},
  // index,
  // region,
  _parentId,
  _item,
  // items,
  // kbd,
  ...otherProps
}, ref) => {
  const {
    text,
    iconLeft,
    iconRight,
    tooltip: tooltipData,
    id: dataId,
    target,
    href,
    rel,
    formButton = null
  } = data || {};

  const tooltipProp = tooltipData || tooltip || null;

  const {className: attrClassName, title: attrTitle, ...attrs} = data.attrs || {};
  const InnerWrpTag = innerWrap ? innerWrap : null;
  const TextWrpTag = textWrap ? textWrap : null;
  const Content = tpl({iconLeft, iconRight, text, children, TextWrpTag});

  const tabIndex = href || otherProps.onClick || attrs.onClick ? 0 : void 0;

  const cmpAttrs = {
    tabIndex,
    className: classNames(className, attrClassName, formButton ? baseClass : null),
    id: id || dataId,
    ...otherProps,
    ...attrs
  };

  if (tooltipProp && !cmpAttrs.id) {
    console.warn(
      'ContentLink: tooltip prop is set, but id is not set. Tooltip will not work properly.',
      cmpAttrs
    );
    cmpAttrs.id = 'ContentLink--' + genUuid();
  }


  if (!formButton) {
    Object.assign(cmpAttrs, {
      baseClass: baseClass,
      passedData: passedData,
      title: tooltipProp === true ? null : attrTitle,
      method,
      preferredMethod,
      href,
      target,
      rel
    });
  }
  else if (formButton === "submit") {
    cmpAttrs.type = "submit";
  }
  else if (formButton === "reset") {
    cmpAttrs.type = "reset";
  }
  else if (formButton !== "button") {
    console.warn('ContentLink: unsupported formButton type: ', formButton);
  }
  const tooltipText = typeof tooltipProp === "string" ? tooltipProp : (
    tooltipProp ? tooltipProp?.text || attrTitle : null
  );

  if (tooltipText && tooltipText === cmpAttrs.title) {
    delete cmpAttrs.title;
  }


  const Tag = formButton ? 'button' : Hyperlink;

  const LinkJsx = (
    <Tag
      ref={ref}
      {...cmpAttrs}
    >
      {InnerWrpTag ? (
        <InnerWrpTag>{Content}</InnerWrpTag>
      ) : (
        Content
      )}
    </Tag>
  );

  return tooltipText ? (
    <TooltipWrapper
      id={`tooltip-${cmpAttrs.id}`}
      title={tooltipText}
      placement={tooltipProp?.placement || void 0}
      autoHide={tooltipProp?.autoHide || void 0}
      className={tooltipProp?.className || void 0}
      overlayProps={tooltipProp?.overlayProps || void 0}
      tooltipProps={tooltipProp?.tooltipProps || void 0}
      trigger={tooltipProp?.trigger || void 0}
    >{LinkJsx}</TooltipWrapper>) : LinkJsx;
});


export const propTypeTooltip = PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.shape({
  text: PropTypes.string,
  placement: proptypePlacement,
  autoHide: PropTypes.number,
  className: PropTypes.string,
  overlayProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  trigger: PropTypes.arrayOf(PropTypes.string)
})]);



ContentLink.propTypes = {
  method: PropTypes.string,
  preferredMethod: PropTypes.string,

  _item: PropTypes.shape({
    getItemData: PropTypes.func
  }),

  _parentId: PropTypes.string,

  id: PropTypes.string,
  className: PropTypes.string,
  region: PropTypes.string,
  kbd: PropTypes.string,
  tooltip: propTypeTooltip,
  baseClass: PropTypes.string,
  children: PropTypes.node,
  index: PropTypes.node,
  items: PropTypes.array,
  passedData: PropTypes.object,
  // @inner inner wrapper, eg: span
  innerWrap: PropTypes.node,
  textWrap: PropTypes.node,
  data: PropTypes.shape({
    // @formButton: render as form button. Note: href not supported
    formButton: PropTypes.oneOf(['submit', 'reset', 'button']),
    // @text link szövege
    text: PropTypes.node,
    // @iconLeft: gomb szövege előtt megjelenő icon
    iconLeft: PropTypes.string,
    // @iconRight: gomb szövege után megjelenő icon
    iconRight: PropTypes.string,
    // @target: nem kötelező, értéke lehet pl: _blank
    target: PropTypes.string,
    // @href: relative vagy absolute hivatkozás vagy null
    href: PropTypes.string,
    // @rel: nem kötelező, de ha nincs megadva és href értéke külső hivatkozás,
    //        a nofollow noopener automatikusan hozzáadásra kerül kliensen.
    rel: PropTypes.string,
    // @attrs: attribútumok
    attrs: PropTypes.object,
    // @tooltip: tooltip szövege vagy true, ha a data.title-ből kell
    tooltip: propTypeTooltip
  })
};

export default ContentLink;
