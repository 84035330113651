
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import ContentLink from "../../components/contentLink";
import TooltipWrapper from '../../components/misc/TooltipWrapper';
import messages from '../../messages';
import grammarHelper from '../../lib/grammarHelper';
import LoaderSpinner from '../../components/misc/LoaderSpinner';
import {keyCodes} from '../../lib/util';

const LanguageChooseDict = ({dictId, userData, dictList, dicts, baseClass, setDict, onKeyDown}) => {
  const _onSelect = useCallback((value) => {
    const dict = dicts.find((d) => d.id === value);
    if (dict?.access) {
      if (typeof setDict === 'function') setDict(value);
    }
  }, [dicts, setDict]);

  const _onKeyDown = useCallback((e) => {
    if (typeof onKeyDown === 'function') onKeyDown(e);
    if (e.which === keyCodes.ENTER || e.which === keyCodes.SPACE) {
      if (!e?.target?.classList?.contains('disabled')) {
        e.target.click();
      }
      e.preventDefault();
    }
  }, [onKeyDown]);

  return (
    dictList ? (
      <Dropdown
        className={`${baseClass}__dict_wrp`}
        // alignRight={true}
        focusFirstItemOnShow={true}
        onSelect={_onSelect}
        onKeyDown={_onKeyDown}
      >
        <Dropdown.Toggle
          tabIndex={0}
          as={ContentLink}
          id={'choose-dict-sel'}
          className={`${baseClass}__dict active`}
          accessKey={"s"}
        >
          {dictList && dictList.find((d) => d.id === dictId)?.shortname}
        </Dropdown.Toggle>

        <Dropdown.Menu
          role="menu"
          popperConfig={{
            strategy: 'fixed',
            placement: 'bottom'
          }}
          flip={true}
        >
          {/* <Dropdown.Header>Grimm Szótár:</Dropdown.Header> */}
          {dicts && dicts.map((item, i) => {
            const title = item.access ? null : messages.get(
              userData.loggedin ? 'access.dictRequiresSubscription' : 'access.dictRequiresLogin',
              {dict: item.text, article: grammarHelper.article.hu(item.text, true)}
            );
            return (
              <TooltipWrapper
                id={`dict-select-${item.value}-${i}`}
                key={i}
                title={title}
              >
                <Dropdown.Item
                  key={i}
                  aria-label={`${item.text}: ${title}`}
                  data-val={item.value}
                  className={!item.access ? 'disabled' : ''}
                  active={dictId === item.value}
                  eventKey={item.value}
                >
                  {item.text}
                </Dropdown.Item>
              </TooltipWrapper>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    ) : <LoaderSpinner className={`${baseClass}__dicts-preload`} style={"inline-loading"} />
  );
};


LanguageChooseDict.propTypes = {
  baseClass: PropTypes.string,
  dictId: PropTypes.string,
  dictList: PropTypes.array,
  dicts: PropTypes.array,
  onKeyDown: PropTypes.func,
  setDict: PropTypes.func,
  userData: PropTypes.shape({
    loggedin: PropTypes.bool
  })
};

export default LanguageChooseDict;
