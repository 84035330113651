import { debounce, getWindowWidth } from "./lib/util";
import el_trigger from "./lib/dom/el_trigger";

export const getTopHeaderHeight = (() => {
  let cached = 0;
  return () => (
    cached = cached || document?.getElementById('r-header-top')?.offsetHeight || 0
  );
})();

export const getNavHeaderHeight = (() => {
  let cached = 0;
  return () => (
    cached = cached || document?.getElementById('r-header-nav')?.offsetHeight || 0
  );
})();


export const getHeadersHeight = (sticky) => (sticky ? 0 : getTopHeaderHeight()) + getNavHeaderHeight();


export const winResizeEmitter = function(appEventEmitter, repeatAfter = 1000) {
  let repeatTimer = null;
  if (typeof window === "undefined") {
    return { off: () => {}, emit: () => {}};
  }

  const listener = debounce((e) => {
    const width = getWindowWidth();
    appEventEmitter.emit('window.resize.debounced', width, e);
    if (e && !e?.detail?.customEvent) {
      if (repeatTimer) { clearTimeout(repeatTimer); }

      repeatTimer = setTimeout(() => {
        el_trigger(window, 'resize', {triggered: true});
        repeatTimer = null;
      }, repeatAfter);

    }
  }, 100);

  window.addEventListener('resize', listener);

  return {
    emit: listener,
    off: () => window.removeEventListener('resize', listener)
  };
};
