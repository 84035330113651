// import arrayGroupBy from "../../../lib/arrays/arrayGroupBy";

export default [
  [
    '{grimm_api_url}',
    {
      alias: 'papercode-checkcode',
      flags: ['private'],
      defaultRequestData: {
        model: "papercode",
        method: 'checkcode'
      },
      requiredRequestData: {
        params: {
          code: { type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'papercode-activate',
      flags: ['private'],
      defaultRequestData: {
        model: "papercode",
        method: 'activate'
      },
      requiredRequestData: {
        params: {
          code: { type: "string" }
        }
      },
      publicPath: null
    }
  ],


  [
    '{grimm_api_url}',
    {
      alias: 'papercode-checklegacycode',
      flags: ['private'],
      defaultRequestData: {
        model: "papercode",
        method: 'checklegacycode'
      },
      requiredRequestData: {
        params: {
          code: { type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'papercode-activatelegacy',
      flags: ['private'],
      defaultRequestData: {
        model: "papercode",
        method: 'activatelegacy'
      },
      requiredRequestData: {
        params: {
          code: { type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'papercode-getpayment',
      flags: ['private'],
      defaultRequestData: {
        model: "papercode",
        method: 'getpayment'
      },
      requiredRequestData: {
        params: {
          trid: { type: "string" } // papreCode
        }
      },
      publicPath: null
    }
  ]

];
