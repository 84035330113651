/* eslint-disable no-use-before-define */

import React, {useRef, useReducer, useEffect, useCallback, useContext} from 'react';

import PropTypes from 'prop-types';
import AuthRelatedPage from "./AuthRelatedPage";
import { useIsMounted } from '../../hooks';
import { parseParams } from '../../lib/util';
import { UserContext } from '../../Contexts';

import globals from '../../controller/globals';


// import WithGrammarArticle from './components/WithGrammarArticle';
import {ResendPwVerify} from './components/ResendEmail';

import * as schemes from './data/schemes';
import { formDataExtend } from './data/formDataHelper';
import grammarHelper from '../../lib/grammarHelper';

import messages from '../../messages';
import { userCheckCode } from './userHelper';
import ForgotPwLink from './components/ForgotPwLink';

function reducer(state, {type, payload}) {
  switch (type) {
    case 'page':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}
// primaryBtnSave
const formData = {
  formId: 'password-verify-change',
  title: messages.get('user.PagePwReset.title'),
  primaryBtn: (getEventData) => {
    const {userData} = getEventData();
    return {
      text: messages.get(
        userData.loggedin
          ? 'user.PagePwReset.primaryBtnSave'
          : 'user.PagePwReset.primaryBtnSaveAndLogin'
      )
    };
  },
  secondaryBtn: (getEventData) => {
    const {pageData} = getEventData();
    const {sentToEmail} = (pageData || {});
    const history = globals.get('history');

    return {
      action: !sentToEmail ? null : () => {
        history.push('/user/forgot-password', {username: sentToEmail});
      }
    };
  },
  warn: (getEventData) => {
    const {sentToEmail} = getEventData().pageData;
    return <>
      {messages.get('user.PagePwReset.warn')}
      &nbsp; <ResendPwVerify email={sentToEmail} />
    </>;
  },
  desc: (getEventData) => {
    const {
      sentToEmail = messages.get('user.PagePwReset.given')
    } = getEventData().pageData;

    return messages.get('user.PagePwReset.desc', {
      article: grammarHelper.article.hu(sentToEmail, false),
      email: sentToEmail
    });
    // return <>
    //   <WithGrammarArticle email={sentToEmail || 'megadott'} /> címre <b>küldött megerősítő kód</b> és az új jelszavad megadásával, beléphetsz a rendszerbe.
    // </>;
  },
  fieldsStates: {
    apiError: {
      error: (getEventData, {msg}) => msg
    },
    apiSuccess: {
      success: messages.get('user.PagePwReset.pwsetSuccess')
    },
    invalid_code: {
      warn: messages.get('user.PagePwReset.codeNotValid'),
      desc: (getEventData) => {
        const {
          sentToEmail = messages.get('user.PagePwReset.given')
        } = getEventData().pageData;

        return <>
          <ForgotPwLink prefix={messages.get('user.PagePwReset.descInvalid', {
            article: grammarHelper.article.hu(sentToEmail, false),
            email: sentToEmail
          })} username={sentToEmail} />
        </>;

        // return <>
        //   <WithGrammarArticle email={sentToEmail || 'megadott'} /> címre <b>küldött megerősítő kód</b> megadásával beléphetsz a rendszerbe.
        // </>;
      },
      primaryBtn: {
        disabled: true
      }
    }
  },
  fields: [
    {
      name: "verification-code",
      label: messages.get('user.fields.verificationCode'),
      autoComplete: "one-time-code",
      maxLength: 5,
      placeholder: messages.get('user.fields.verificationCodePlaceholder'),
      schema: schemes.verification_code,
      helperText: null,
      fieldStates: {
        invalid_code: {
          errorMsg: messages.get('inputScheme.invalidCode')
        },
        sessionMatch: {

        },
        sessionNotMatch: {

        }
      }
    },
    {
      name: "new-password",
      label: messages.get('user.fields.newPassword'),
      autoComplete: "new-password",
      type: 'password',
      schema: schemes.password,
      helperText: messages.get('user.fields.newPasswordHelper')
    },
    {
      name: "verify-password",
      label: messages.get('user.fields.newPasswordRepeat'),
      autoComplete: "new-password",
      type: 'password',
      schema: schemes.password_verify,
      helperText: null
    }
  ]
};



const PagePwReset = ({appPub, history, location, match, ...props}) => {
  const {verifyKey} = match.params;
  const paramSentTo = location?.state?.sentto || parseParams(location.search).sentto || '';
  const sentToEmail = schemes.email.validate(paramSentTo) ? '' : paramSentTo.toLowerCase();
  const refForm = useRef();
  const userData = useContext(UserContext);

  const {api, toastErrorMsg, toastSuccessMsg} = appPub;
  const isMounted = useIsMounted();

  const setFieldsState = useCallback((fieldsState, fieldsStateArgs = null) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch({ type: 'page', payload: { fieldsState, fieldsStateArgs } });
  }, []);

  useEffect(() => {
    if (verifyKey && refForm?.current) {
      // refForm.current.querySelector('.trs-user__submit')?.click();
    }
  }, [verifyKey]);


  const onCodeChange = useCallback((e, {
    value, schema, fieldState, setFieldState, ...rest}
  ) => {

    const code = value + ''.trim();
    if (code && !schema.validate(value)) {
      userCheckCode(code, 'PASSWORD', {
        isMounted,
        api,
        toastErrorMsg
      }, ({
        fieldsState,
        fieldsStateArgs,
        fieldState,
        fieldStateArgs
      }) => {
        setFieldsState(fieldsState, fieldsStateArgs);
        setFieldState(fieldState, fieldStateArgs);
      });
    }
    else {
      setFieldsState(null, null);
      setFieldState(null, null);
    }
  }, [api, isMounted, setFieldsState, toastErrorMsg]);


  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    fieldsState: null,
    fieldsStateArgs: null,
    pageData: {
      sentToEmail
    },
    currentForm: (
      formDataExtend(formData, {
        fields: [
          {
            name: 'verification-code',
            onChange: onCodeChange,
            onBlur: onCodeChange,
            onInit: onCodeChange,
            defaultValue: verifyKey || ''
          }
        ]
      })
    )
  });


  const onSubmit = useCallback((formData) => {
    if (!state.loading) {

      dispatch({ type: 'page', payload: { loading: true } });
      const pwcode = formData['verification-code'].trim().toUpperCase();

      api.getEndpointByAlias('user-changepass').fetch({
        requestData: {
          params: {
            pwcode,
            password: formData['new-password'],
            password2: formData['verify-password']
          }
        }
      }, (status, data) => api.fetchResponseHelper({
        status,
        data,
        isMounted: () => isMounted(),
        onAny: () => dispatch({ type: 'page', payload: { loading: false } }),
        onSuccess: () => {
          const msg = messages.get('user.PagePwReset.pwsetSuccess');
          toastSuccessMsg(msg, 'user-pw-reset');
          setFieldsState('apiSuccess', msg);
          if (data.loggedin) {
            const notify = userData.loggedin ? false : true;
            appPub.login(data.user, true, notify);
          }
        },
        onErrorMsg: (msg) => {
          toastErrorMsg(msg, 'user-pw-reset');
          setFieldsState('apiError', [{msg}]);
        }
      }), true);
    }
  }, [api, appPub, isMounted, setFieldsState, state, toastErrorMsg, toastSuccessMsg]);



  // console.log('APP PROPS', props);
  return (
    <AuthRelatedPage
      appPub={appPub}
      // appProps={props}
      loading={state.loading}
      refForm={refForm}
      onSubmit={onSubmit}
      fieldsState={state.fieldsState}
      fieldsStateArgs={state.fieldsStateArgs}
      formData={state.currentForm}
      pageData={state.pageData}
    />
  );
};

PagePwReset.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PagePwReset;
