import {unicodeHelper, konsole, isNumeric} from '../../lib/util';

import {getDeepestChildElements} from '../../lib/dom/getDeepestChildElements';
import {ttsSpeakButtonAttachToEntryDOM} from '../qrcode/TtsSpeakButton';
import {getDict, normalizeSourceText} from '../dictionary/dictHelpers';
import { nativeLang, ttsVoiceLangTLD, selectorsToTts } from '../../setup';
import {fixFlatStructure, fixPhrasesWithBullet} from './data/result.lib';
import DEV_MODE, {DEV_TIMING} from "../../controller/devel";
import ttsMap, {htmlSrHidden} from '../../ttsMap';
import messages from '../../messages';


const dwExtSepaLabel = messages.get('resultEntry.dwExtSepaLabel');
const rxRawReplace = [
  // Példák, szókapcsolatok
  [/\s+?\|\|\|\|/gm, (
    `<span role="heading" aria-level="4" title="${dwExtSepaLabel}" class="dw-ext-sepa">` +
      '<span class="sr-hidden">|||</span>' +
      `<b class="sr-only">${dwExtSepaLabel}:</b>` +
    '</span>'
  )
  ],
  [/<span\s+style=\\?"display:\s?block;?\\?"\s?\/>/gim, '<hr class="style-block" />'],
  [/<span>\s<span class="collorth">/gm, '<span><span class="collorth">'],
  [/<\/span>\s?;/gim, '</span><span class="semicolon">;</span>']
];

const rxApostrophes = /[’']/g;

const fixApostrophes = (str) => {
  return str.replace(rxApostrophes, "'");
};

const withNoStencePunctuation = (str) => {
  return str.replace(/([.?!])\s*$/g, '');
};

const normalizeLc = (str) => {
  return normalizeSourceText((str + '')).toLowerCase();
};


export const cloneApiResultQueries = (queries) => (
  (queries || []).map(({entries, searched_word}) => ({
    entries: (entries || []).map((entry) => ({...entry, _cloned: true})),
    searched_word,
    _cloned: true
  }))
);

export const cloneApiResult = (apuResult) => (
  (apuResult || []).slice(0).map(({dict_id, queries}) => ({
    dict_id,
    _cloned: true,
    queries: cloneApiResultQueries(queries)
  }))
);



export class Entry {
  constructor(entry, _results) {
    this.fid = entry._fid;
    this.sid = entry._sid;
    this.data = entry;
    this.dict = null;
    this.justRemoved = false;
    this._results = _results;
    this.cachedHtml = {
      htmlA11y: null,
      htmlPrint: null
    };
    this.frag = null;
    this.orthvar = null;
    this.homo = null;
    this.highlightable = null;
    this.headWords = null;
    this.headWordsNorm = null;
    this.nativeLang = nativeLang === this.data.src ? this.data.src : this.data.trg;
    this.foreignLang = nativeLang === this.data.src ? this.data.trg : this.data.src;
    this.foreignTld = ttsVoiceLangTLD[this.foreignLang];
    this.htmlLang = this.foreignLang;
  }

  clone(extend) {
    const cloned = new Entry({...this.data}, this._results);
    if (extend && typeof extend === "object") {
      for (const key in extend) {
        if (Object.hasOwnProperty.call(extend, key)) {
          cloned[key] = extend[key];
        }
      }
    }
    return cloned;
  }

  getSearchWords() {
    return this?._results?.getSearchWordsBySid(this.sid);
  }

  getHeadWord() {
    return this.data.headword_text;
  }



  _getHeadWords(all = true, norm = false) {
    const headwords = [];
    const orthvar = this.getOrthvar();
    if (all || this.data.headword_text !== orthvar) {
      headwords.push(
        norm
          ? fixApostrophes(this.data.headword_text).toLowerCase()
          : this.data.headword_text
      );
    }
    if (this.data._otherHeadwords instanceof Set) {
      this.data._otherHeadwords.forEach((ohw) => {
        if (ohw !== this.data.headword_text && ohw !== orthvar) {
          headwords.push(
            norm ? fixApostrophes(ohw).toLowerCase() : ohw
          );
        }
      });
    }
    headwords.sort((a, b) => b.length - a.length);
    return headwords;
  }

  getHighlightable() {
    if (this.highlightable) return this.highlightable;

    this.highlightable = this._getHeadWords(false);
    return this.highlightable;
  }

  getHeadWords() {
    if (this.headWords) return this.headWords;

    this.headWords = this._getHeadWords(true);
    return this.headWords;
  }

  getHeadWordsNorm() {
    if (this.headWordsNorm) return this.headWordsNorm;
    this.headWordsNorm = this._getHeadWords(true, true);
    return this.headWordsNorm;
  }

  getSearchedWord() {
    return this.data.searchedWord;
  }

  _getFragment() {
    if (this.frag === null) {
      let html = this.data.html;
      this.frag = document.createElement('div');

      for (let i = 0; i < rxRawReplace.length; i++) {
        const [rx, str] = rxRawReplace[i];
        html = html.replace(rx, str);
      }
      // console.log('html', {html});
      this.frag.innerHTML = html;
    }
    return this.frag;
  }

  // _setFragment(frag) {
  //   this.frag = frag;
  // }

  _clearFragment() {
    this.frag = null;
  }

  _extractText(frag, selector, selRemovables) {
    const el = frag.querySelector(selector)?.cloneNode(true) || null;

    if (el && selRemovables) {
      (el.querySelectorAll(selRemovables) || [])
        .forEach(child => { child.parentNode.removeChild(child); })
      ;
    }
    return el?.innerText || null;
  }

  _extractOrthvar(_frag) {
    if (this.orthvar === null) {
      this.orthvar = this._extractText(_frag || this._getFragment(), '.orthvar', '.irreg');
    }
    return this.orthvar;
  }

  _extractHomo(_frag) {
    if (this.homo === null) {
      this.homo = this._extractText(_frag || this._getFragment(), '.homo');
    }

    if (this.homo && isNumeric(this.homo)) { this.homo -= 0; }

    return this.homo;
  }

  _extendHtmlWithTtsHighlights(elTtsCont, highlightableRx) {
    getDeepestChildElements(elTtsCont).forEach(childEl => {
      let childHTML = childEl.innerHTML;

      // add "mark" tags to highlight headings collected from duplicates
      if (highlightableRx.length) {
        for (let i = 0; i < highlightableRx.length; i++) {
          const newHtml = childHTML.replace(
            highlightableRx[i],
            '<mark class="hl">$1</mark>'
          );
          if (newHtml !== childHTML) {
            childHTML = newHtml;
            break;
          }
        }
      }
      childEl.innerHTML = childHTML;
    });
  }

  _extendHtmlWithTTs(frag) {
    const highlightableRx = this.getHighlightable().map(w => (
      new RegExp(`(${w.replace(rxApostrophes, "[’`']")})`, 'gi')
    ));

    const selectorToTtts = (nativeLang === this.data.src
      ? selectorsToTts.nativeSrc
      : selectorsToTts.foreignSrc
    );

    const elemsToTTS = [...frag.querySelectorAll(selectorToTtts)].filter(elem => {
      if (elem.querySelectorAll(selectorToTtts).length) {
        // olyan esetek amikor <span class="tr"-ben van egy child ugyanezen class-al
        // ne jelenjen meg a TTS btn csak az (utolsó) child-nál.
        return false;
      }
      return true;
    });

    while (elemsToTTS.length) {
      const el = elemsToTTS.pop();
      const elTtsCont = ttsSpeakButtonAttachToEntryDOM({el, lang: this.foreignLang});
      this._extendHtmlWithTtsHighlights(elTtsCont, highlightableRx);
    }
  }

  _extendHtml(accessibleStyle = true) {
    // const frag = document.createDocumentFragment();
    // console.log(this)
    DEV_TIMING && console.time('extendHtml');
    let frag = this._getFragment();

    this.orthvar = this._extractOrthvar(frag);
    this.homo = this._extractHomo(frag);

    this._extendHtmlWithTTs(frag);

    if (accessibleStyle) {
      frag = this.extendWithHeadings(frag);
      frag = fixFlatStructure(frag) || frag;
      frag = fixPhrasesWithBullet(frag);
      frag = this.extendWithA11y(frag);

    }

    let finalHtml = frag.innerHTML;

    if (accessibleStyle) {
      finalHtml = ttsMap.replacer(finalHtml, this.nativeLang, true);
      finalHtml = ttsMap.replacer(finalHtml, this.foreignLang, true);
    }


    this._clearFragment();

    DEV_TIMING && console.timeEnd('extendHtml');
    return finalHtml;
  }

  extendWithHeadings(frag) {
    const elOrthvar = frag.querySelector('.orthvar');
    if (elOrthvar) {
      const elH3 = document.createElement('h3');
      elH3.className = 'dw-orthvar';
      elOrthvar.parentNode.insertBefore(elH3, elOrthvar);
      const elHomo = frag.querySelector('.homo');
      if (elHomo) {
        elH3.className += ' dw-orthvar-with-homo';
        elH3.appendChild(elHomo);
      }
      elH3.appendChild(elOrthvar);

    }
    return frag;
  }

  extendWithA11y(frag) {

    (frag.querySelectorAll('.form') || []).forEach(el => {
      el.setAttribute('lang', this.htmlLang);
    });

    (frag.querySelectorAll('.pos') || []).forEach(el => {
      let c = el?.innerText.trim();
      const pos = c.replace(/[<>]/, '');
      c = c.replace(/^</g, htmlSrHidden('&lt;'));
      c = c.replace(/>$/g, htmlSrHidden('&gt;'));
      el.innerHTML = ' ' + c;
      // szófaj
      el.setAttribute('aria-label', messages.get('resultEntry.posAriaLabel', {pos}));

    });
    (frag.querySelectorAll('.egorth') || []).forEach(el => {
      if (!el.parentNode.className) {
        el.parentNode.className = 'dw-egorth-wrp';
      }
    });
    // jelentés szám
    (frag.querySelectorAll('.sensenum') || []).forEach(el => {
      el.setAttribute('aria-description', messages.get('resultEntry.sensenumAriaLabel'));
    });
    (frag.querySelectorAll('.irreg') || []).forEach(el => {
      el.setAttribute('aria-hidden', 'true');
      // rendhagyó ige
      el.setAttribute('title', messages.get('resultEntry.irregTitle'));
    });

    (frag.querySelectorAll('.egbullet, .phrasebullet, .pron') || []).forEach(el => {
      el.setAttribute('aria-hidden', 'true');
    });


    (frag.querySelectorAll('.sensenum') || []).forEach(el => {
      if (el.parentNode && !el.parentNode.classList.contains('entry')) {
        el.parentNode?.classList.add('dw-senses-parent');
        el.parentNode.setAttribute('role', 'listitem');
        if (el.parentNode.parentNode) {
          el.parentNode.parentNode.classList.add('dw-senses-wrapper');
          el.parentNode.parentNode.setAttribute('role', 'list');
        }
      }
    });
    (frag.querySelectorAll('.dw-ext-sepa') || []).forEach(el => {
      el?.parentNode?.classList.add('dw-phrases-parent');
    });
    return frag;
  }

  getId() {
    return this.data.id;
  }

  getDict() {
    if (this.dict) {
      return this.dict;
    }
    return (
      this.dict = getDict(this.data._dict)
    );
  }

  getCahcedHtml(accessibleStyle) {
    const key = accessibleStyle ? 'htmlA11y' : 'htmlPrint';
    return this.cachedHtml[key] || null;
  }

  setCahcedHtml(accessibleStyle, html) {
    const key = accessibleStyle ? 'htmlA11y' : 'htmlPrint';
    return (this.cachedHtml[key] = html || null);
  }

  getHtml(accessibleStyle) {
    if (!this.getCahcedHtml(accessibleStyle)) {
      if (this.getCahcedHtml(!accessibleStyle)) { // if the other view is cached, clear the fragment
        this._clearFragment();
      }
      this.setCahcedHtml(accessibleStyle, this._extendHtml(accessibleStyle));
    }
    return this.getCahcedHtml(accessibleStyle);
  }

  getOrthvar() {
    return this.orthvar || this._extractOrthvar();
  }

  getHomo() {
    return this.homo || this._extractHomo();
  }

  isExpr() {
    return this.data.expr === true;
  }

  isDupe() {
    return this.data._duplicate || false;
  }

  isMergedEntry() {
    return this.data._mergedEntry ? true : false;
  }

  destroy() {
    for (const prop in this) {
      if (Object.hasOwnProperty.call(this, prop)) {
        delete this[prop];
      }
    }
  }
}


class Results {
  constructor(results, {text, fullWord, dict, words, wordsLimited, wordsOverLimit, wordsWithDupes}) {
    this.results = cloneApiResultQueries(results);
    this.dict = dict;
    this.entries = [];
    this.entryById = {};
    this.entryBySid = {};
    this.rawEntryBySid = {};
    this.fullWord = fullWord; // the full word that was searched as first word
    this.issues = {
      sameSidDifferentContent: [],
      anyEntryWithSameSid: [],
      variousSidSameContent: []
    };

    this.queryFullText = text.toLowerCase();
    this.queryFullTextNorm = fixApostrophes(this.queryFullText);

    this.queryWords = words;
    // do not modify, represents the original answer from the server
    this.raw = {
      fullWord,
      results: this.results.slice(0),
      wordsLimited: wordsLimited.slice(0),
      wordsOverLimit: wordsOverLimit.slice(0),
      wordsWithDupes: wordsWithDupes.slice(0),
      searchedWords: this.results.map(q => q.searched_word)
    };
    this.queryWordsLimited = wordsLimited;
    this.queryWordsOverLimit = wordsOverLimit;
    this.queryWordsOverLimitLc = wordsOverLimit.map(w => normalizeLc(w));
    this.queryWordsWithDupes = wordsWithDupes;


    this.fullTextResult = this.results.find(r => {
      const sw = (r.searched_word + '').toLowerCase();
      const swNorm = fixApostrophes(sw);
      // const sw = (r.searched_word + '');
      // console.log('full text result', sw, this.queryFullText)
      return sw === this.queryFullText || swNorm === this.queryFullTextNorm;
    });

    this.sidsBySearchWord = {};
    this.searchWordBySid = {};

    this.wordsSortAbc = unicodeHelper.sortAbc(words.slice(0));
    this.wordsSortLen = unicodeHelper.sortByLength(words.slice(0));

    this.searchedWords = this.raw.searchedWords.slice(0);
    this.searchedWordsLc = this.searchedWords.map(w => (w + '').toLowerCase());



    if (this.hasFullTextResult()) {
      this._mergeFullTextResultWithOthers();
    }

    this.initialWord = this.hasFullTextResult()
      ? this.fullTextResult.searched_word
      : this.searchedWords[0]
    ;

    this.wordsToMatch = this.hasFullTextResult()
      ? [this.fullTextResult.searched_word, ...this.wordsSortLen]
      : this.wordsSortLen
    ;

    this.matchReg = unicodeHelper.getWordMatchReg(this.wordsToMatch);

    this.initialWordIndex = 0;
    // console.log('____', this);
    this.init();
  }

  init() {
    if (this.initialized) return;
    this.initialized = true;

    const idProp = 'id';

    for (let i = 0; i < this.results.length; i++) {
      const {entries, searched_word} = this.results[i];
      entries.sort((a, b) => a.expr === b.expr ? 0 : a.expr ? 1 : -1);


      for (let x = 0; x < entries.length; x++) {
        const entry = entries[x];
        const id = entry[idProp];
        // const fid = `${id}_${entry.headword_id}`;
        const fid = `${id}_${entry.headword_id}`;
        // const sid = `${id}_${searched_word}`;
        const sid = `${entry.expr ? '_expr' : '_headword'}_${id}_[${searched_word}]`;
        this.extendEntry(entry, {sid, fid, searched_word});

        if (!this.rawEntryBySid[sid]) this.rawEntryBySid[sid] = [];

        this.collectIssueDuplications(entry, {searched_word});

        this.rawEntryBySid[sid].push(entry);
        this.collectSearchWord(searched_word, sid);


        if (this.getentryBySid(sid)) { // already exists
          this.collectIssueSameSidWithDifferentContents(entry, {searched_word});
          continue;
        }

        // create instance
        this._createEntryInstance(entry);
      }
    }


    this.logIssuesIfAny();
  }

  // removeWords(words) {
  //   this.queryWords = this.queryWords.filter(w => !words.includes(w));
  //   this.queryWordsLimited = this.queryWords.filter(w => !words.includes(w));
  //   this.queryWordsOverLimit = this.queryWords.filter(w => !words.includes(w));
  //   this.queryWordsWithDupes = this.queryWords.filter(w => !words.includes(w));
  // }

  _createEntryInstance(entry) {
    // create instance
    if (this.entryBySid[entry._sid]) {
    // if (this.entryById[entry.id]) {
      konsole.error('already exists', entry._sid, entry);
      return;
    }
    const newEntry = new Entry(entry, this);
    // this.entryById[entry.id] = newEntry;
    this.entryBySid[entry._sid] = newEntry;
    this.entries.push(newEntry);
  }

  extendEntry(entry, {fid, searched_word, sid}) {
    entry._dict = this.dict;
    entry._fid = fid;
    entry._sid = sid;
    entry._searched_word = searched_word;
    this.extendEntryWithSearchWord(entry, searched_word);
  }

  extendEntryWithSearchWord(entry, searched_word) {
    if (!Array.isArray(entry._searched_words)) {
      entry._searched_words = [];
    }
    if (entry._searched_words.indexOf(searched_word) === -1) {
      entry._searched_words.push(searched_word);
    }
  }

  logIssuesIfAny() {
    if (
      this.issues.anyEntryWithSameSid.length ||
      this.issues.sameSidDifferentContent.length ||
      this.issues.variousSidSameContent.length
    ) {
      DEV_MODE && konsole.warn('Result issues found', this.issues);
    }
  }

  collectSearchWord(searched_word, sid) {
    if (!this.searchWordBySid[sid]) {
      this.searchWordBySid[sid] = [];
    }
    if (this.searchWordBySid[sid].indexOf(searched_word) === -1) {
      this.searchWordBySid[sid].push(searched_word);
    }


    if (!this.sidsBySearchWord[searched_word]) {
      this.sidsBySearchWord[searched_word] = [];
    }
    if (this.sidsBySearchWord[searched_word].indexOf(sid) === -1) {
      this.sidsBySearchWord[searched_word].push(sid);
    }
  }

  collectIssueSameSidWithDifferentContents(entry, {searched_word}) {
    const stored = this.getentryBySid(entry._sid);
    if (stored && stored.data.html !== entry.html) {
      this.issues.sameSidDifferentContent.push([
        searched_word, stored.data, entry
      ]);
      // DEV_MODE && konsole.warn(
      //   'unexpected error: entry found with the same sid but different content.', {diff: {
      //     sotredEntry: stored.data.html,
      //     newEntry: entry.html
      //   }}
      // );
    }
  }

  collectIssueDuplications(entry, {searched_word}) {
    // const id = entry.id;
    // const fid = entry._fid;
    const sid = entry._sid;
    // const expr = entry.expr;
    // expr items with the same id
    const anyEntryWithSameSid = this.rawEntryBySid[sid].find(e =>
      entry !== e && e._sid === sid &&
      e._searched_word === searched_word
    );

    if ((anyEntryWithSameSid)) {
      entry._duplicate = true;

      // console.warn('DUPLICATE', {
      //   entry,
      //   anyEntryWithSameSid,
      //   "this.rawEntryBySid": this.rawEntryBySid[sid]
      // });

      if (anyEntryWithSameSid._otherHeadwords instanceof Set === false) {
        anyEntryWithSameSid._otherHeadwords = new Set([]);
      }
      anyEntryWithSameSid._otherHeadwords.add(entry.headword_text);
      this.extendEntryWithSearchWord(anyEntryWithSameSid, searched_word);

      this.issues.anyEntryWithSameSid.push(
        [searched_word, anyEntryWithSameSid, entry]
      );
      return true;
    }


    const entryAnotherSidByTheSameHtml = this.rawEntryBySid[sid].find(e =>
      entry !== e && sid !== e._sid && e.html === entry.html &&
      e._searched_word === searched_word
    );

    if ((entryAnotherSidByTheSameHtml) && entryAnotherSidByTheSameHtml.sid !== sid) {
      entry._duplicate = true;

      if (entryAnotherSidByTheSameHtml._otherHeadwords instanceof Set === false) {
        entryAnotherSidByTheSameHtml._otherHeadwords = new Set([]);
      }
      entryAnotherSidByTheSameHtml._otherHeadwords.add(entry.headword_text);

      this.issues.variousSidSameContent.push(
        [searched_word, entryAnotherSidByTheSameHtml, entry]
      );
      return true;
    }

    return false;
  }


  _mergeFullTextResultWithOthers() {
    this.fullTextResult.orig_entries = this.fullTextResult.entries.slice(0);

    for (let i = 0; i < this.results.length; i++) {
      if (this.fullTextResult === this.results[i]) {
        continue;
      }
      const {entries, searched_word} = this.results[i];
      const sw = searched_word.toLowerCase();
      const swNorm = fixApostrophes(sw);

      // console.log('_mergeFullTextResultWithOthers', {sw, searched_word, queryFullText: this.queryFullText});

      if (sw !== this.queryFullText && this.queryFullTextNorm !== swNorm) {
        // DEV_MODE && console.log('merge', {sw, searched_word, queryFullText: this.queryFullText});
        while (entries.length) {
          const entry = {
            ...entries.pop()
          };

          entry._mergedEntry = true;
          entry._orig_searched_word = entry.searched_word;
          entry.searched_word = this.queryFullText;

          entry._expr_original = entry.expr;
          entry.expr = true;
          this.extendEntryWithSearchWord(entry, searched_word);

          this.fullTextResult.entries.push(entry);

        }
        this.removeWordFromQuery(sw);
      }
    }
  }


  isActiveWordMatchOrthvar(entry, activeWord) {
    const orthvar = entry.getOrthvar();
    return (
      fixApostrophes(orthvar.toLowerCase()) ===
      fixApostrophes(activeWord.toLowerCase())
    );
  }

  isFullTextRelatedToAnyHeadword(entry, strict = false) {
    // ha teljes keresett szó szerepel a találat headword-jében.
    if (this.fullTextResult) {
      const highlightable = entry.getHeadWords();
      return !!(
        highlightable?.find(w => {
          const wNorm = fixApostrophes(w).toLowerCase();
          return strict
            ? withNoStencePunctuation(wNorm) === withNoStencePunctuation(this.queryFullTextNorm)
            : wNorm.indexOf(this.queryFullTextNorm) > -1
          ;
        })
      );
    }
    return false;
  }

  isFullTextRelatedToOrthvar(entry) {
    const orthvar = entry.getOrthvar();

    // ha teljes keresett szó szerepel az entry orthvar-ban vagy fordítva
    if (this.fullTextResult) {
      const orthvarNorm = fixApostrophes(orthvar).toLowerCase();

      return (
        orthvarNorm.indexOf(this.queryFullTextNorm) > -1 ||
        this.queryFullTextNorm.indexOf(orthvarNorm) > -1
      );
    }
    return false;
  }

  isOrthvarRelatedTOAnyHeadword(entry) {
    const hws = entry.getHeadWordsNorm();
    const orthvarNorm = fixApostrophes(entry.getOrthvar()).toLowerCase();
    return hws.indexOf(orthvarNorm) > -1;
  }

  removeWordFromQuery(word) {
    const fn = w => w.toLowerCase() !== word.toLowerCase();
    if (this.queryWords) this.queryWords = this.queryWords.filter(fn);
    if (this.queryWordsLimited) this.queryWordsLimited = this.queryWordsLimited.filter(fn);
    if (this.queryWordsOverLimit) this.queryWordsOverLimit = this.queryWordsOverLimit.filter(fn);
    if (this.queryWordsWithDupe) this.queryWordsWithDupes = this.queryWordsWithDupes.filter(fn);
    if (this.searchedWords) this.searchedWords = this.searchedWords.filter(fn);
    this.searchedWordsLc = this.searchedWords.map(w => w.toLowerCase());
    this.queryWordsOverLimitLc = this.queryWordsOverLimit.map(w => normalizeLc(w));
    // console.log('removeWordFromQuery', {word, queryWords: this.queryWords, searchedWords: this.searchedWords});
  }

  isValidWordIndex(word, index) {
    const valid = this.queryWordsWithDupes.filter(w => w === word)[index];
    return !!valid;
  }

  getLastWordIndex(word) {
    // DEV_MODE && console.log('getLastWordIndex', {
    //   word,
    //   queryWordsWithDupes: this.queryWordsWithDupes,
    //   index: this.queryWordsWithDupes.filter(w => w === word).lastIndexOf(word)
    // });

    return this.queryWordsWithDupes.filter(w => w === word).lastIndexOf(word);
  }

  hasFullTextResult() {
    return this.fullTextResult && this.fullTextResult.entries.length;
  }

  getEntriesById(id) {
    return this.entries.filter(e => e.getId() === id);
  }

  getentryBySid(sid) {
    return this.entryBySid[sid];
  }

  getSearchWordsBySid(sid) {
    return this.searchWordBySid[sid];
  }

  getEntriesBySearchWord(activeWord, withDupes = false, accessible, expr = false) {
    let entries = (this.sidsBySearchWord[activeWord] || [])
      .map(sid => this.getentryBySid(sid))
    ;

    if (entries.length && !withDupes) {
      entries = entries.filter(entry => !entry.isDupe());
    }

    if (typeof expr === 'boolean') {
      entries = entries.filter(entry => {
        const exprOverride = (
          this.isActiveWordMatchOrthvar(entry, activeWord) ||
          this.isFullTextRelatedToAnyHeadword(entry, true)
        );

        const exprMatch = entry.data.expr === expr;

        if (exprOverride) {
          // return !exprMatch;
        }
        return exprMatch;
      });
    }



    const entriesToSort = entries.map((entry, index) => {
      let weight = 0;
      const sortDetails = [];

      if (entry.data.expr && entry.data._mergedEntry) {
        weight -= Math.pow(16, 14);
        sortDetails.push('merged_entry_0__' + weight);
      }

      if (this.isActiveWordMatchOrthvar(entry, activeWord)) {
        weight += Math.pow(16, 14);
        sortDetails.push('isActiveWordMatchOrthvar_1__' + weight);
      }
      else if (this.isFullTextRelatedToAnyHeadword(entry)) {
        weight += Math.pow(16, 14);
        sortDetails.push('isFullTextRelatedToAnyHeadword_1__' + weight);
      }

      if (this.isOrthvarRelatedTOAnyHeadword(entry)) {
        weight += Math.pow(16, 13);
        sortDetails.push('isOrthvarRelatedTOAnyHeadword_2__' + weight);
      }

      if (this.isFullTextRelatedToAnyHeadword(entry, true)) {
        weight += Math.pow(16, 11);
        sortDetails.push('isFullTextRelatedToAnyHeadword_3__' + weight);
      }
      if (this.isFullTextRelatedToOrthvar(entry)) {
        weight += Math.pow(16, 10);
        sortDetails.push('isFullTextRelatedToOrthvar_4__' + weight);
      }


      // ha az aktív szóban szerepel az orthvar
      // if (activeWord.indexOf(orthvar) > -1) {
      //   weight += 1000;
      // }
      entry._sortDetails = sortDetails;
      return {entry, index, weight};
    });


    entriesToSort.sort((a, b) => {
      const weightA = a.weight;
      const weightB = b.weight;
      const orthvarA = a.entry.getOrthvar();
      const orthvarB = b.entry.getOrthvar();
      const homoA = a.entry.getHomo() || 0;
      const homoB = b.entry.getHomo() || 0;

      if (weightA !== weightB) {
        return weightA > weightB ? -1 : 1;
      }

      if (orthvarA && orthvarB && orthvarA !== orthvarB) {
        return orthvarA < orthvarB ? -1 : 1;
      }

      if (homoA && homoB && homoA !== homoB) {
        return homoA < homoB ? -1 : 1;
      }

      return a.index < b.index ? -1 : 1;
    });

    return entriesToSort.map(item => item.entry);
  }

  wordExists(word) {
    return this.searchedWordsLc.indexOf(normalizeLc(word)) > -1;
  }

  destroy() {
    delete this.frag;
    while (this?.entries?.length) {
      this.entries.pop().destroy();
      for (const prop in this) {
        if (Object.hasOwnProperty.call(this, prop)) {
          delete this[prop];
        }
      }
    }
  }
}

export default Results;
