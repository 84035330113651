/* eslint-disable react/prop-types */
import React from 'react';

import PageSignIn from '../PageSignIn';
import PageLogIn from '../PageLogIn';
import PagePwRequest from '../PagePwRequest';
import PagePwReset from '../PagePwReset';
import PageReg from '../PageReg';
import PageRegActivate from '../PageRegActivate';
import PageProfile from '../PageProfile';
import PageLogout from '../PageLogout';
import StaticPageMustLogin from '../../../StaticPageMustLogin';
import StaticPageMustLogout from '../../../StaticPageMustLogout';
// import StaticPageLoader from '../../../StaticPageLoader';

import { Route, Redirect } from 'react-router-dom';
import messages from '../../../messages';


// import PrivateRoute from '../../PrivateRoute';
import { meta } from '../../../lib/util';

const getUserRoutes = (pageProps) => {
  const isLoggedIn = pageProps.appPub.isLoggedIn;

  return [
    <Route path="/user/signin" key={'signin'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.signin'));
      return isLoggedIn()
        // ? <Redirect to="/" />
        ? <StaticPageMustLogout { ...{...props, ...pageProps} } />
        : <PageSignIn { ...{...props, ...pageProps} } />;
    }} />,

    <Route path="/user/login" key={'login'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.login'));
      return isLoggedIn()
        ? <StaticPageMustLogout { ...{...props, ...pageProps} } />
        : <PageLogIn { ...{...props, ...pageProps} } />;
    }} />,

    <Route path="/user/signup" key={'signup'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.signup'));
      return isLoggedIn()
        ? <StaticPageMustLogout { ...{...props, ...pageProps} } />
        : <PageReg { ...{...props, ...pageProps} } />;
    }} />,

    <Route path="/user/activate/:verifyKey?" key={'activate'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.activate'));
      return <PageRegActivate { ...{...props, ...pageProps} } />;
      // return isLoggedIn()
      //   ? <StaticPageMustLogout { ...{...props, ...pageProps} } />
      //   : <PageRegActivate { ...{...props, ...pageProps} } />;
    }} />,

    <Route path="/user/forgot-password" key={'pwReset'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.forgot'));
      return <PagePwRequest { ...{...props, ...pageProps} } />;
      // return isLoggedIn()
      //   ? <StaticPageMustLogout { ...{...props, ...pageProps} } />
      //   : <PagePwRequest { ...{...props, ...pageProps} } />;
    }} />,

    <Route path="/user/changepass/:verifyKey?" key={'pwActivate'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.changepass'));
      return <PagePwReset { ...{...props, ...pageProps} } />;
    }} />,

    <Route path="/user/profile" key={'userProfile'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.profile'));
      return isLoggedIn()
        ? <PageProfile { ...{...props, ...pageProps} } />
        : <StaticPageMustLogin { ...{...props, ...pageProps} } />
      ;
    }} />,

    <Route path="/user/logout" key={'userProfile'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.logout'));
      return <PageLogout { ...{...props, ...pageProps} } />;
    }} />
  ];
};



export default getUserRoutes;
