/* eslint-disable react/prop-types */
import React from 'react';
import ContentParagraph from '../../components/contentParagraph';
import messages from '../../messages';

const QrPreloader = React.memo(({loading, error, errormsg}) => (
  <>
    {loading ? (
      <ContentParagraph id={`static_qr_loading`} data={{
        inlineHtml: messages.get('components.Preloader.loading')
      }} />
    ) : null}
    {error ? (
      <ContentParagraph id={`static_qr_error`} data={{
        theme: 'alert',
        inlineHtml: (`
          <strong>${messages.get('components.Preloader.error')}</strong>
          ${errormsg}
        `)
      }} />
    ) : null}
  </>
));

QrPreloader.displayName = 'QrPreloader';

export default QrPreloader;
