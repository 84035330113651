
class ScrollPos {

  constructor(callback) {
    var that = this;
    this.callback = typeof callback === 'function' ? callback : () => {};
    this.waitForTimeFrame = false;
    this.prevScrollPos = { y: window.pageYOffset, x: window.pageXOffset };
    this.curScrollPos = { y: window.pageYOffset, x: window.pageXOffset };
    var frame = () => this.updateScroll();

    window.addEventListener('scroll', function(e) {
      if (!that.waitForTimeFrame) {
        window.requestAnimationFrame(frame);
        that.waitForTimeFrame = true;
      }
    });
  }

  updateScroll() {
    this.curScrollPos.y = window.pageYOffset;
    this.curScrollPos.x = window.pageXOffset;
    if (typeof this.callback === 'function') {
      this.callback(this.prevScrollPos, this.curScrollPos);
    }
    this.waitForTimeFrame = false;
    this.prevScrollPos.y = this.curScrollPos.y;
    this.prevScrollPos.x = this.curScrollPos.x;
  }
}

export default ScrollPos;
