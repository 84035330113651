import PropTypes from "prop-types";
import React, {useState, useCallback, useEffect} from 'react';
import { specialCharsByLang } from '../../../../setup';
import SpecCharsBlock from './SpecCharsBlock';
import SpecCharsInline from './SpecCharsInline';

import "./SpecChars.scss";

const isVariableCase = (chars, upperCase) => {
  const charsAllStr = chars.flatMap((char) => {
    if (Array.isArray(char)) {
      return char[0];
    }
    return char;
  }).join('');

  return charsAllStr.toUpperCase() !== charsAllStr.toLowerCase();
};


const SpecChars = ({
  lang = 'hu',
  baseClass,
  id,
  onSelect,
  onVisibilityChange,
  visible = false,
  mode = "block",
  hintCaretPosition = false,
  disabled
}) => {
  const classSpace = baseClass + '__specchars';
  const chars = specialCharsByLang[lang];
  const [upperCase, setUpperCase] = useState(false);
  const [shiftPressed, setShiftPressed] = useState(false);
  const caseSwitchNeeded = isVariableCase(chars);
  // const [menuOpen, setMenuOpen] = useState(false);

  const onCaseToggle = useCallback(
    () => setUpperCase(!upperCase),
    [upperCase]
  );

  // const onMenuToggle = useCallback((isOpen) => {
  //   if (menuOpen !== isOpen) setMenuOpen(isOpen);
  // }, [menuOpen]);

  useEffect(() => {
    const onKeyUp = (e) => { if (!e.shiftKey && shiftPressed) setShiftPressed(false); };
    const onKeyDown = (e) => { if (e.shiftKey && !shiftPressed) setShiftPressed(true); };
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [shiftPressed]);

  if (!chars || chars.length === 0) return null;

  const upperCaseActvie = shiftPressed || upperCase;

  const propsToChild = {
    classSpace,
    onSelect,
    id,
    lang,
    visible,
    onVisibilityChange,
    upperCaseActvie,
    onCaseToggle,
    mode,
    hintCaretPosition,
    chars,
    disabled,
    caseSwitchNeeded
  };

  return (
    mode === "inline" ? (
      <SpecCharsInline {...propsToChild} />
    ) : (
      <SpecCharsBlock {...propsToChild} />
    )
  );
};

SpecChars.propTypes = {
  id: PropTypes.string.isRequired,
  lang: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  visible: PropTypes.bool,
  hintCaretPosition: PropTypes.bool,
  onVisibilityChange: PropTypes.func,
  mode: PropTypes.oneOf(['inline', 'block']),
  baseClass: PropTypes.string.isRequired
};

export default SpecChars;
