type queueCallback = (queuedData: unknown) => void;
export class QueueItem {
  queuedData: unknown;

  callback: queueCallback;

  private _queue: Queue;

  constructor(
    _queue: Queue,
    queuedData: unknown,
    callback: queueCallback
  ) {
    this._queue = _queue;
    this.queuedData = queuedData;
    this.callback = callback;
  }

  finish() {
    this.callback(this.queuedData);
    this.destroy();
    this._queue.remove(this);
  }

  destroy() {
    delete this.queuedData;
    delete this.callback;
  }
}

export class Queue {
  queue: QueueItem[];

  name: string;

  constructor(name: string) {
    this.queue = [];
    this.name = name;
  }

  finishAll() {
    while (this.queue.length) {
      this.queue[0].finish();
    }
  }

  add(data: unknown, callback: queueCallback) {
    this.queue.push(
      new QueueItem(this, data, callback)
    );
  }

  remove(queue: QueueItem) {
    const index = this.queue.indexOf(queue);
    if (index > -1) {
      this.queue.splice(index, 1);
    }
  }

  flush() {
    this.queue.forEach(q => q.destroy());
    this.queue = [];
  }
}


export default Queue;
