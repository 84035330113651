/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-danger-with-children */
import React from 'react';
import deepEqual from 'fast-deep-equal';
import PropTypes from 'prop-types';
import {DEV_MODE} from '../controller/devel';
import cloneEvent from '../lib/dom/cloneEvent';

// import * as rangy from '@notjosh/rangy-selectionsaverestore';
import { filterHtml } from './filterHtml';
import SaveAndRestoreSelection from './vendor/saveAndRestoreSelection';
// import { htmlFilterRangy } from './filterHtml';
const htmlFilterRangy = (html: string) => html;

const debug = false && DEV_MODE;

const sanitizeSetup = {
  debug,
  // allowedTags: ['br', 'span', 'div'],
  presetName: 'contentEditableSanitizer',
  allowedTags: ['br', 'div'],
  wildcardSelectors: ['.tr-hlw'],
  allowedAttrs: ['class', 'data-tr-wordindex', 'data-tr-word', 'tabindex', 'role']
};
const sanitize = (html: string) => filterHtml(html, sanitizeSetup);

const rx_br_only = /^(\s+)?<br(\s+)?\/?>(\s+)?$/i; // sometimes the empty field contains a br
const rx_line_breaks = /^(\r\n|\n|\r)$/;

function normalizeHtml(str: string): string {
  str = str ? sanitize(str.replace(/&nbsp;|\u202F|\u00A0/g, ' ')).replace(rx_br_only, '') : '';
  return str.search(rx_line_breaks) > -1 ? '' : str; // avoid first line break
}

/**
 * A simple component for an html element with editable contents.
 */
export default class ContentEditable extends React.Component<Props> {
  focused: boolean;

  mouseStillDown: boolean;

  mightGetFocusByClicking: boolean;

  rangyCurrentSelection: unknown;

  lastHtml: string;

  // eslint-disable-next-line no-undef
  el: React.RefObject<HTMLElement>;

  saveAndRestoreSelection: SaveAndRestoreSelection;

  last: {
    keyDownEvent: React.KeyboardEvent<any> | null;
  };

  constructor(props) {
    super(props);
    // window._ce = this;
    this.focused = false;
    this.mouseStillDown = false;
    this.mightGetFocusByClicking = false;
    this.rangyCurrentSelection = null;
    this.lastHtml = normalizeHtml(this.props.html);
    this.el = typeof this.props.innerRef === 'function'
      ? { current: null }
      // eslint-disable-next-line no-undef
      : React.createRef<HTMLElement>()
    ;
    this.saveAndRestoreSelection = new SaveAndRestoreSelection(() => this.getEl());
    this.last = {
      keyDownEvent: null
    };
  }

  getEl = () => (
    this.props.innerRef && typeof this.props.innerRef !== 'function'
      ? this.props.innerRef
      : this.el
  ).current;

  emitChange = (origEvt: React.SyntheticEvent<any> | null) => {
    const el = this.getEl();
    if (!el) return;
    if (origEvt) {
      // this.rangySaveSelection('emitChange', origEvt);
    }
    // this.updateCaret();

    const rawHtml = el.innerHTML;
    const html = normalizeHtml(rawHtml);
    // const text = el.innerText;
    const text = el.textContent;

    if (this.props.onChange && html !== this.lastHtml) {
      // const cleanHtml = htmlFilterRangy(html);
      const cleanHtml = html; // TODO: ide át kell emelni az összes input filtert ami a SorceArea-ban van
      const data = { html, cleanHtml, rawHtml, text };
      const eventToMerge = origEvt || new window.CustomEvent('change', {
        bubbles: false,
        cancelable: false,
        detail: { customEvent: true, customData: data }
      });
      const evt = Object.assign({}, eventToMerge, {
        target: { value: cleanHtml, ...data }
      });
      this.props.onChange(evt, html, text, cleanHtml);
    }
    this.lastHtml = html;

    if (rawHtml !== html) {
      el.innerHTML = html;
    }
  };


  // onBeforeInputNative = (e) => {
  //   if (e.inputType === 'insertCompositionText' && e.data.search(/^\w+\n$/) > -1) {
  //     console.log('android gboard onBeforeInput NATIVE ENTER ', e);
  //   }
  // };

  onBeforeInput = (e) => {
    // ANDROID GBOARD BUG FIX:
    // Gboard on Android sends a onBeforeInput event with a latest word + newline character
    // when the user presses the enter key on the keyboard,
    // but it also sends a keyDown event without key or keyCode.
    const lastKeyDownEvent = this.last.keyDownEvent;

    if (
      lastKeyDownEvent &&
      e.nativeEvent?.type === 'textInput' &&
      (lastKeyDownEvent.timeStamp - e.timeStamp) < 100 &&
      lastKeyDownEvent.key === "Unidentified" &&
      (lastKeyDownEvent.keyCode === 229 || lastKeyDownEvent.which === 229) &&
      e.data.search(/^\w+\n$/) > -1
    ) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();

      const overridedEventProps = {
        keyCode: 13,
        key: 'Enter',
        which: 13,
        charCode: 0,
        code: 'gboard_enter'
      };

      const clonedEvent = cloneEvent(lastKeyDownEvent.nativeEvent, overridedEventProps);
      this.onKeyDown(clonedEvent);

      DEV_MODE && console.log('android gboard enter fix ', {
        e, clonedEvent, lastKeyDownEvent
      });
    }
  };

  // eslint-disable-next-line no-undef
  onKeyDown = (e: React.KeyboardEvent<any>) => {
    this.last.keyDownEvent = e;
    if (typeof this.props.onKeyDown === 'function') {
      this.props.onKeyDown(e);
    }
  };

  onKeyUp = (e: React.KeyboardEvent<any>) => {
    if (typeof this.props.onKeyUp === 'function') {
      this.props.onKeyUp(e);
    }
  };

  onMouseDown = (origEvt: React.MouseEvent<any>) => {
    debug && console.log('onMouseDown:');
    this.mouseStillDown = true;
    if (typeof this.props.onMouseDown === 'function') {
      this.props.onMouseDown(origEvt);
    }
  };

  onMouseUp = (origEvt: React.MouseEvent<any>) => {
    debug && console.log('onMouseUp:');

    if (this.mouseStillDown) {
      this.mouseStillDown = false;
      this.mightGetFocusByClicking = origEvt.target === this.getEl();
    }
    if (typeof this.props.onMouseUp === 'function') {
      this.props.onMouseUp(origEvt);
    }
  };

  onClick = (origEvt: React.MouseEvent<any>) => {
    debug && console.log('onClick:');
    this.mightGetFocusByClicking = false;
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(origEvt);
    }
  };

  onBlur = (origEvt: React.FocusEvent<any>) => {
    debug && console.log('onBlur:');

    this.focused = false;

    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(origEvt);
    }
  };

  onFocus = (origEvt: React.SyntheticEvent<any>) => {
    debug && console.log('onFocus:');
    this.focused = true;
    this.restoreSelection(true);
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(origEvt as React.FocusEvent<any>);
    }

  };

  // eslint-disable-next-line no-undef
  onFocusIn = (e: FocusEvent) => {
    debug && console.log('onFocusIn:');

    if (typeof this.props.onFocusIn === 'function') {
      this.props.onFocusIn(e);
    }
  };

  // eslint-disable-next-line no-undef
  onFocusOut = (e: FocusEvent) => {
    debug && console.log('onFocusOut:');

    if (typeof this.props.onFocusOut === 'function') {
      this.props.onFocusOut(e);
    }
  };

  onSelect = (origEvt: React.SyntheticEvent<any>) => {
    debug && console.log('onSelect:');
    // this.rangySaveSelection('By onSelect');
    // this.updateCaret();
    this.saveSelection();
    if (typeof this.props.onSelect === 'function') {
      this.props.onSelect(origEvt);
    }
  };

  saveSelection() {
    this.saveAndRestoreSelection.save();
  }

  getSelection() {
    return this.saveAndRestoreSelection.getSelection();
  }

  restoreSelection(putCaretAtEndIfItFails) {
    const restored = this.saveAndRestoreSelection.restore();
    if (!restored && putCaretAtEndIfItFails) {
      this.saveAndRestoreSelection.setToEnd();
    }
  }

  componentDidMount() {
    const el = this.getEl();
    if (el && this.props.onScroll) {
      // eslint-disable-next-line no-undef
      el.addEventListener('scroll', this.props.onScroll as UIEventHandler, true);
      el.addEventListener('focusout', this.onFocusOut);
      el.addEventListener('focusin', this.onFocusIn);
      // el.addEventListener('beforeinput', this.onBeforeInputNative);
    }
  }

  componentWillUnmount() {
    const el = this.getEl();
    if (el && this.props.onScroll) {
      el.removeEventListener('scroll', this.props.onScroll as UIEventHandler, true);
      el.removeEventListener('focusout', this.onFocusOut);
      el.removeEventListener('focusin', this.onFocusIn);
      // el.removeEventListener('beforeinput', this.onBeforeInputNative);

    }
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    const { props } = this;
    const el = this.getEl();

    // We need not rerender if the change of props simply reflects the user's edits.
    // Rerendering in this case would make the cursor/caret jump

    // Rerender if there is no element yet... (somehow?)
    if (!el) return true;
    // ...or if html really changed... (programmatically, not by user edit)
    // debug && console.log('SAVE SELECTION BEFORE UPDATE')
    // this.rangySaveSelection('shouldComponentUpdate');

    const normCurHtml = normalizeHtml(el.innerHTML);
    const normNextHtml = normalizeHtml(nextProps.html);

    const noRangyCurHtml = htmlFilterRangy(normCurHtml);
    const norRangyNextHtml = htmlFilterRangy(normNextHtml);
    let reason = 'none';
    if (
      (props.onChange !== nextProps.onChange && (reason = "onChange")) ||
      (props.onScroll !== nextProps.onScroll && (reason = "onScroll")) ||
      (props.onFocusOut !== nextProps.onFocusOut && (reason = "onFocusOut")) ||
      (props.onFocusIn !== nextProps.onFocusIn && (reason = "onFocusIn")) ||
      (props.onBlur !== nextProps.onBlur && (reason = "onBlur")) ||
      (props.onClick !== nextProps.onClick && (reason = "onClick")) ||
      (props.onDoubleClick !== nextProps.onDoubleClick && (reason = "onDoubleClick")) ||
      (props.onMouseDown !== nextProps.onMouseDown && (reason = "onMouseDown")) ||
      (props.onMouseUp !== nextProps.onMouseUp && (reason = "onMouseUp")) ||
      (props.onKeyUp !== nextProps.onKeyUp && (reason = "onKeyUp")) ||
      (props.onKeyDown !== nextProps.onKeyDown && (reason = "onKeyDown")) ||
      (props.onPaste !== nextProps.onPaste && (reason = "onPaste")) ||
      (props.disabled !== nextProps.disabled && (reason = "disabled")) ||
      (props.tagName !== nextProps.tagName && (reason = "tagName")) ||
      (props.className !== nextProps.className && (reason = "className")) ||
      (props.style !== nextProps.style && (reason = "style")) ||
      (props.lang !== nextProps.lang && (reason = "lang")) ||
      (props.innerRef !== nextProps.innerRef && (reason = "innerRef")) ||
      (props['aria-multiline'] !== nextProps['aria-multiline'] && (reason = "aria-multiline"))
    ) {
      DEV_MODE && console.log('editable rerendering by prop change:', reason);
      if (this.getEl() === document.activeElement) {
        DEV_MODE && console.log('editable rerendering IN FOCUS by prop change:', reason);
        this.saveSelection();
      }
    }

    if (noRangyCurHtml !== norRangyNextHtml) {
      // this.lastHtml = html;
      debug && console.log('- - shouldComponentUpdate rerender nextProps.html !== html');
      // this.rangySaveSelection('shouldComponentUpdate');
      return true;
    }
    else {
      debug && console.log(
        '- - shouldComponentUpdate cancel save selection before update, html nextProps.html === html',
        {
          reason,
          nextProps,
          props,
          normNextHtml,
          norRangyNextHtml,
          normCurHtml,
          noRangyCurHtml
        }
      );

      if (normCurHtml !== noRangyCurHtml || normNextHtml !== norRangyNextHtml) {
        // this.rangyRestoreSelection('shouldComponentUpdate no change');
      }
    }

    // Handle additional properties
    return props.disabled !== nextProps.disabled ||
      props['aria-multiline'] !== nextProps['aria-multiline'] ||
      props.tagName !== nextProps.tagName ||
      props.className !== nextProps.className ||
      props.innerRef !== nextProps.innerRef ||
      props.placeholder !== nextProps.placeholder ||
      !deepEqual(props.style, nextProps.style);
  }

  componentDidUpdate() {
    const el = this.getEl();
    if (!el) return;

    // Perhaps React (whose VDOM gets outdated because we often prevent
    // rerendering) did not update the DOM. So we update it manually now.
    // normalizeHtml: nem kell, mert az eredetit adja és kapja vissza value-ként
    const propHTML = htmlFilterRangy(this.props.html);
    const curHtmlClean = htmlFilterRangy(el.innerHTML);
    // Todo: ki kell szedni minden range html-t mielőtt össehasonlítjuk

    if (propHTML !== curHtmlClean) {
      debug && console.log('- - OVERWRITE innertHTML BY PROP', {
        innerHTML: el.innerHTML,
        curHtmlClean,
        htmlProp: propHTML
      });
      el.innerHTML = propHTML;
      // this.rangyRestoreSelection('componentDidUpdate');
    }
    this.lastHtml = propHTML;
    // replaceCaret(el);
    if (this.focused) {
      this.restoreSelection(false);
    }

    // debug && console.log('Todo: Olcsó replaceCaret, cserélni kell')
  }

  // rangySaveSelection(log) {
  //   try {
  //     if (this.rangyCurrentSelection) {
  //       this.rangyRemoveMarkers('by rangySaveSelection');
  //     }
  //     this.rangyCurrentSelection = rangy.saveSelection();
  //   }
  //   catch (error) {
  //     console.warn(error);
  //   }

  //   debug && console.log('- - call rangySaveSelection(); by', log, this.rangyCurrentSelection);
  // }


  // rangyRestoreSelection(log) {
  //   if (!this.focused) return console.log('- - rangyRestoreSelection() skipped, not focused');

  //   if (this.rangyCurrentSelection) {
  //     rangy.restoreSelection(this.rangyCurrentSelection, true);
  //     this.rangyCurrentSelection = null;
  //     debug && console.log('- - call restoreSelection(); by', log, this.rangyCurrentSelection);
  //   }
  //   else {
  //     debug && console.log('- - call NO restoreSelection(); (no current) by', log, this.rangyCurrentSelection);
  //   }
  // }

  // rangyRemoveMarkers(log) {
  //   if (this.rangyCurrentSelection) {
  //     rangy.removeMarkers(this.rangyCurrentSelection);
  //     this.rangyCurrentSelection = null;
  //     debug && console.log('- - call removeMarkers(); by', log, this.rangyCurrentSelection);
  //   }
  //   else {
  //     debug && console.log('- - call No removeMarkers(); no selection by', log, this.rangyCurrentSelection);
  //   }
  // }



  render() {
    debug && console.log('RENDER EDITABLE');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tagName, html, innerRef, onFocusIn, onFocusOut, ...props } = this.props;
    const __html = normalizeHtml(html); // || '<br>';
    return React.createElement(
      tagName || 'div',
      {
        ...props,
        ref: typeof innerRef === 'function' ? (current: HTMLElement) => {
          innerRef(current);
          this.el.current = current;
        } : innerRef || this.el,
        lang: this.props.lang,
        onInput: this.emitChange,
        onClick: this.onClick,
        onDoubleClick: this.props.onDoubleClick,
        onMouseDown: this.onMouseDown,
        onTouchStart: this.onMouseDown,
        onMouseUp: this.onMouseUp,
        onTouchEnd: this.onMouseUp,
        onBeforeInput: this.onBeforeInput,
        onBlur: this.onBlur,
        onFocus: this.onFocus,
        onSelect: this.onSelect,
        onKeyUp: this.onKeyUp,
        onKeyDown: this.onKeyDown,
        onPaste: this.props.onPaste,
        contentEditable: !this.props.disabled,
        spellCheck: "false",
        dangerouslySetInnerHTML: { __html }
      },
      this.props.children);
  }

  static propTypes = {
    html: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onScroll: PropTypes.func,
    onFocusOut: PropTypes.func,
    onFocusIn: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onPaste: PropTypes.func,
    disabled: PropTypes.bool,
    tagName: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    lang: PropTypes.string,
    innerRef: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func
    ])
  };
}

// eslint-disable-next-line no-undef
export type ContentEditableEvent = React.SyntheticEvent<any, Event> & { target: { value: string } } | CustomEvent;
type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R;
type DivProps = Modify<JSX.IntrinsicElements["div"], {
    onChange: (
      (event: ContentEditableEvent, html: string, text: string, cleanHtml: string) => void
    )
  }>;

export interface Props extends DivProps {
  html: string,
  disabled?: boolean,
  tagName?: string,
  className?: string,
  style?: Object,
  innerRef?: React.RefObject<HTMLElement> | Function,
}
