
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import classNames from 'classnames';
import { logoutHelper } from './user/userHelper';
import globals from '../controller/globals';
import { UserContext } from '../Contexts';
import ContentDropDown from '../components/contentDropDown';
import messages from '../messages';

const openLink = (url) => {
  const history = globals.get('history');
  history.push(url, {});
};

const menuItems = [
  {
    eventKey: 'profile',
    content: messages.get('userMenu.profile'),
    icon: 'user-circle',
    onSelect() {
      openLink('/user/profile');
    }
  },
  {
    eventKey: 'subscriptions',
    content: messages.get('userMenu.subscriptions'),
    icon: 'cart-plus',
    onSelect() {
      openLink('/subscription');
    }
  },
  {
    eventKey: 'papercode',
    content: messages.get('userMenu.papercode'),
    icon: 'barcode',
    onSelect() {
      openLink('/papercode');
    }
  },
  {
    eventKey: 'transactions',
    content: messages.get('userMenu.transactions'),
    icon: 'receipt',
    onSelect() {
      openLink('/transactions');
    }
  },
  {
    eventKey: 'logout',
    content: messages.get('userMenu.logout'),
    icon: 'sign-out-alt',
    onSelect() {
      logoutHelper();
    }
  }
].filter(item => item.hidden !== false);

const UserMenu = React.memo(({
  children,
  refContainer,
  onChange
}) => {
  const appPub = globals.get('appPub');

  const onVisibilityChange = useCallback((visible) => {
    appPub.setHtmlClasses({ userMenu: visible });
  }, [appPub]);

  return (
    <UserContext.Consumer>{({loggedin, email}) => (
      <ContentDropDown
        className={classNames("nav-user", loggedin ? 'active-user' : null)}
        contentLabel={email}
        id={'user-menu'}
        icon="user-circle"
        onVisibilityChange={onVisibilityChange}
        onSelect={onChange}
        content={children}
        ariaListLabel={messages.get('userMenu.listAriaLabel')}
        items={menuItems}
        buttonTitle={
          loggedin
            ? messages.get('userMenu.togglerAriaLabel')
            : messages.get('userMenu.loginAriaLabel')
        }
        refContainer={refContainer}
        href={loggedin ? null : "/user/signin"}
      />
    )}</UserContext.Consumer>
  );
});

UserMenu.displayName = 'UserMenu';

UserMenu.propTypes = {
  onChange: PropTypes.func,
  refContainer: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default UserMenu;
