
import React, {useRef, useReducer, useEffect, useContext, useCallback} from 'react';
import PropTypes from 'prop-types';
import { nativeLang } from '../../setup';
import { getDictsOptionsByLangDir, getAnAccessibleDictOption } from './dictHelpers';

import { UserContext, DictListContext } from '../../Contexts';
import LanguageChooseDict from './LanguageChooseDict';
import ContentDropDown from '../../components/contentDropDown';
import {getLangPairs, genIdLangPairId} from './dictHelpers';
import UAC from '../../UAC';
import grammarHelper from '../../lib/grammarHelper';
import messages from '../../messages';

import LanguageSwitcherButtons from './LanguageSwitcherButtons';

const baseClass = "language-switcher";

function reducer(state, {type, payload}) {
  switch (type) {
    case 'langPairs': {
      const {langPairs} = payload;
      return {
        ...state,
        langPairs
      };
    }
    case 'langData': {
      const {items, dicts} = payload;
      return {
        ...state,
        items,
        dicts
      };
    }
    case 'timerSwitchClick':
      return {...state, [type]: payload};
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}


const LanguageSwitcher = React.memo(({
  src,
  trg,
  dictId,
  onKeyDown,
  onChange
}) => {
  const refContainer = useRef(null);
  const [state, dispatch] = useReducer(reducer, {
    items: null,
    langPairs: null,
    dicts: null,
    timerSwitchClick: null
  });

  const dictList = useContext(DictListContext);
  const userData = useContext(UserContext);
  const curId = genIdLangPairId(src, trg);

  useEffect(() => {
    const langPairs = getLangPairs(dictList, userData);
    dispatch({
      type: 'langPairs',
      payload: { langPairs }
    });
  }, [dictList, userData]);


  useEffect(() => { // UPDATE
    const filtered = !state.langPairs ? null : state.langPairs.filter(
      lp => (src === nativeLang
        ? (lp.src === nativeLang)
        : (lp.trg === nativeLang)
      ));

    const items = filtered && filtered.map((lp) => ({
      user: {...userData},
      'userData.loggedin': userData.loggedin,
      'userData.lp': lp,
      eventKey: lp.value,
      disabled: !lp.access,
      title: lp.access ? null : messages.get(
        userData.loggedin ? 'access.langRequiresSubscription' : 'access.langRequiresLogin',
        {lang: lp.foreign_desc, article: grammarHelper.article.hu(lp.foreign_desc, true)}),
      ariaLabel: `${lp.src_desc} - ${lp.trg_desc}`,
      content: <>
        <span className="src">{lp.src_desc}</span>

        {lp.access
          ? (lp.unidirectional ? (
            <i className="ico fas fa-long-arrow-alt-right"></i>
          ) : (
            <i className="ico fas fa-arrows-alt-h"></i>
          ))
          : (
            <i className="ico fas fa-info-circle"></i>
          )}

        <span className="trg">{lp.trg_desc}</span>

      </>
    }));

    const dicts = dictList
      ? getDictsOptionsByLangDir(src, trg, dictList)
      : null
    ;
    // console.warn('DISPATCH ITEMS', {items, dicts, dictList, userData});
    dispatch({
      type: 'langData',
      payload: {items, dicts }
    });

  }, [dictList, userData, trg, src, dictId, state.langPairs]);

  useEffect(() => {
    return () => {
      if (state.timerSwitchClick) {
        clearTimeout(state.timerSwitchClick);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const setLang = useCallback((id) => {
    const {src, trg} = state.langPairs.find(lp => lp.id === id);
    onChange({dictId, src, trg, dictList });
  }, [dictId, dictList, onChange, state.langPairs]);


  const setDict = useCallback((dictId) => {
    const dict = dictList.find(d => d.id === dictId);
    if (!dict) {
      console.warn('Dict not found', dictId);
      return;
    }

    if (!UAC.hasAccessToDict(dict)) {
      console.warn('Dict has no access', dictId);
      return;
    }

    onChange({
      dictId,
      src: src,
      trg: trg,
      dictList
    });
  }, [onChange, src, trg, dictList]);


  if (dictList) {
    const [valid, dict] = getAnAccessibleDictOption(dictId, src, trg);
    if (!valid) {
      if (!dict) {
        throw new Error('Unable restore initial dict.');
      }
      setDict(dict.id);
      console.warn('Initalize invalid dict state.');
    }
  }
  return (
    <div className={`${baseClass}`} ref={refContainer}>
      {/* nyelv választó */}
      <ContentDropDown
        className="lang-select"
        id={'lang-select'}
        activeKey={curId}
        refContainer={refContainer}
        items={state.items}
        onChange={setLang}
        propOverClass="language-switcher--langpairs"
        content= {!dictList ? <strong>Betöltés...</strong> : null}
      >{({refToTarget, onClickOrKeyDown, show, visible}) =>
          <LanguageSwitcherButtons baseClass={baseClass}
            {...{dictId, src, trg, dictList, dispatch, onKeyDown, onChange, refToTarget, onClickOrKeyDown, show, visible}}
          />
        }</ContentDropDown>

      {/* Szótár választó */}
      <LanguageChooseDict
        dictId={dictId}
        dictList={dictList}
        dicts={state.dicts}
        baseClass={baseClass}
        setDict={setDict}
        onKeyDown={onKeyDown}
        userData={userData}
      />
    </div>
  );
});

LanguageSwitcher.propTypes = {
  src: PropTypes.string.isRequired,
  trg: PropTypes.string.isRequired,
  dictList: PropTypes.array,
  dictId: PropTypes.string.isRequired,
  srcText: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func
};

LanguageSwitcher.displayName = 'LanguageSwitcher';

export default LanguageSwitcher;
