import Api from './Api';
import {apiUrl, ttsServiceUrl} from '../setup.js';
import apiMethodsDictionary from '../base/dictionary/data/apiMethods';
import apiMethodsUser from '../base/user/data/apiMethods';
import apiMethodsSubscription from '../base/subscription/data/apiMethods';
import apiMethodsPaperCode from '../base/paperCode/data/apiMethods';
import globals from '../controller/globals';

const api = new Api({
  id: 'ci-api',
  sessIdPropName: 'sessionid',
  sessIsAuthName: 'session_auth',
  sessionInitializer: 'user-isloggedin',
  tokens: {
    tts_service_url: ttsServiceUrl,
    grimm_api_url: apiUrl
  },
  endpointList: [ // defaults
    ...apiMethodsDictionary,
    ...apiMethodsUser,
    ...apiMethodsSubscription,
    ...apiMethodsPaperCode,
    [
      '{grimm_api_url}',
      {
        alias: 'qrpage-wordlist',
        flags: ['private'],
        defaultRequestData: {
          method: 'wordlist',
          model: "qrpage"
        },
        requiredRequestData: {
          params: {
            "qrcodeid": {type: "string" }
          }
        },
        publicPath: null
      }
    ]
  ]
});

api.requestEmitter.on("*", (eventName, request) => {
  const appPub = globals.get('appPub');
  const loginObserver = appPub.getLoginObserver();
  if (request.forced_logout) {
    loginObserver.checkLogin();
  }
});

export default api;
