
import React from 'react';
import PropTypes from 'prop-types';
import ContentButton from '../../components/contentButton';
import ContentButtons from '../../components/contentButtons';
import GrimmBrand from '../branding/GrimmBrand';
import messages from '../../messages';

import LoaderSpinner from '../../components/misc/LoaderSpinner';


const LayoutUnavailable = ({
  headline,
  actionHref,
  actionText,
  children,
  enableFrontPageLink,
  baseClass = "layout-unavailable",
  appPub
}) => {

  const loggedIn = appPub.isLoggedIn();

  return (
    <section className={`${baseClass} page-unavailable`}>
      <div className={`page-unavailable__content page-width`}>

        <GrimmBrand />

        {loggedIn === null ? (
          <LoaderSpinner variant='light' style="inline-loading" className="page-unavailable-loader" />
        ) : (
          <>
            <h1>{headline}</h1>

            {children
              ? <div className="page-unavailable__child">{children}</div>
              : null
            }
            <ContentButtons>
              {actionHref && actionText ? (
                <ContentButton
                  tabIndex={0}
                  id={`btn-action-${baseClass}`}
                  data={{
                    link: { href: actionHref },
                    theme: "grimm-ghost-orange"
                  }}
                >{actionText}</ContentButton>
              ) : null}

              {enableFrontPageLink ? (
                <ContentButton
                  tabIndex={0}
                  id={`btn-to-front-${baseClass}`}
                  data={{
                    link: { href: '/' },
                    theme: "grimm-ghost"
                  }}
                >{messages.get('components.LayoutUnavailable.btnToFront')}</ContentButton>
              ) : null}
            </ContentButtons>
          </>
        )}

      </div>
    </section>
  );
};

LayoutUnavailable.propTypes = {
  headline: PropTypes.node.isRequired,
  children: PropTypes.node,
  enableFrontPageLink: PropTypes.bool,
  baseClass: PropTypes.string.isRequired,
  actionHref: PropTypes.string,
  actionText: PropTypes.node,
  appPub: PropTypes.object.isRequired
};

export default LayoutUnavailable;
