// import setup from '../setup.js';

import {DEV_MODE} from '../controller/devel';
import {parseParams, getLocation, konsole} from '../lib/util';
import { locStore } from '../lib/BrowserStore';
import {glindow} from '../controller/globals';
import Endpoint from './ApiEndpoint';
import EventEmitter from '../lib/EventEmitter';
import deepmerge from "deepmerge";
import Queue from '../lib/Queue';
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

// https://www.npmjs.com/package/abortcontroller-polyfill


const removeUrlHash = url => (url + '').replace(/#.*$/, '');
// eslint-disable-next-line no-unused-vars

import {ENUM_fetchStatus} from './enums';

const fetch = glindow.fetch;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const AbortController = glindow.AbortController || (() => {});
const FormData = glindow.FormData;
const File = glindow.File;


class Api {
  static ENUM = ENUM_fetchStatus;

  static SESSID_TOKEN = '___sessId___';

  constructor({
    id,
    tokens,
    sessionInitializer,
    sessIdPropName,
    sessIsAuthName,
    endpointList = []
  }) {
    this.instanceIndex = this._static.instances++;
    this.id = id || `api-id-${this.instanceIndex}`;
    this.dataEmitter = new EventEmitter('apiDataEmitter');
    this.requestEmitter = new EventEmitter('apiRequestEmitter');
    this.endpoints = [];
    this.sessionInitializer = sessionInitializer || null;
    this.state = {
      sessionReady: this.sessionInitializer ? false : true
    };
    this.sessIdPropName = sessIdPropName || 'sessionid';
    this.sessAuthName = sessIsAuthName || 'session_auth';
    this.tokens = {...(tokens || {})};
    this.init(endpointList);
    this.sumOfRequests = 0;
    this.trustedReqParams = {};
    this.store = {};
    this.cache = [];
    this.cacheLength = 100;
    this.lsPropSessId = `${this.id}-sessId`;
    this.sessionReadyQueue = new Queue('sessionReadyQueue');
  }

  setState(newState) {
    const oldState = this.state;
    this.state = { ...oldState, ...newState };
    this.onStateChange(newState, oldState);
  }

  onStateChange(newState, oldState) {
    if (this.sessionInitializer &&
      newState.sessionReady !== oldState.sessionReady &&
      newState.sessionReady
    ) {
      this.sessionReadyQueue.finishAll();
    }
  }

  init(endpointList) {
    endpointList.forEach(endpointSetup => this.addEndpoint(endpointSetup));
  }

  getStoreItem(prop) {
    return this.store[prop];
  }

  clearPendingRequests() {
    const prog = this.endpoints.filter(endpoint => (
      !endpoint.hasFlag('private') &&
      endpoint.hasStatus(ENUM_fetchStatus.loading)
    ));
    if (prog && prog.length && DEV_MODE) {
      konsole.log('fetch clearPendingRequests', prog.map(
        ep => `${ep.publicPath}: ${ep.getState('status')}`
      ));
    }
    prog.forEach(endpoint => endpoint.abortFetch('clearPendingRequests'));
  }

  getPublicPaths() {
    return this.endpoints
      .filter(endpoint => !endpoint.hasFlag('private'))
      .map(endpoint => endpoint.publicPath)
    ;
  }

  updateSotre(endpoint, data) {
    if (!data || !endpoint || !endpoint.storeData) { return; }
    for (const dataProp in endpoint.storeData) {
      if (
        Object.hasOwnProperty.call(endpoint.storeData, dataProp) &&
        data[dataProp]
      ) {
        const {as, transform, transformOverwriteData} = endpoint.storeData[dataProp];
        const value = (
          typeof transform === "function" ? transform(data[dataProp], data) : data[dataProp]
        );
        const propName = as || dataProp;
        this.store[propName] = value;
        if (transformOverwriteData) {
          data[dataProp] = value;
        }
        this.dataEmitter.emit(propName, value);
      }
    }
  }

  extendPath(endpoint, {restData}) {
    const {defaultRestData} = endpoint;
    const allRestData = {
      ...(this.tokens || {}),
      ...(defaultRestData || {}),
      ...(restData || {})
    };
    let {path} = endpoint;

    for (const prop in allRestData) {
      if (Object.hasOwnProperty.call(allRestData, prop)) {
        path = path.replace(`{${prop}}`, allRestData[prop]);
      }
    }

    return this.addAliasParam(removeUrlHash(path), endpoint.alias);
  }

  addAliasParam(url, alias) {
    const lastPath = url.split('/').reverse()[0];
    const hasParam = lastPath.search(/[?&]/) > -1;
    const param = `alias=${alias}`;
    if (!lastPath) {
      return url + '?' + param;
    }
    else if (hasParam) {
      return url + '&' + param;
    }
    else {
      return url + '/?' + param;
    }
  }

  isInvalidProp(value, validation) { // todo
    const {type, maxLenth} = validation;
    // eslint-disable-next-line valid-typeof
    if (type && typeof value !== type) {
      return 'value is not type of ' + type;
    }
    if (maxLenth && type === "string" && value.length > maxLenth) {
      return 'value is longer than ' + maxLenth;
    }
    return false;
  }

  validateRequestType(type = "N/A", data = {}, requiredData = {}) {
    const ret = {
      type, data, requiredData, invalid: false, reason: null, message: null
    };

    for (const prop in requiredData) {
      if (Object.hasOwnProperty.call(data, prop)) {
        const validation = requiredData[prop];
        const value = data[prop];
        const invalid = this.isInvalidProp(value, validation);
        if (invalid) {
          ret.invalid = true;
          ret.reason = {
            error: `Invalid ${type} property [${prop}]`,
            value,
            validation,
            message: invalid
          };
          return ret;
        }
      }
    }
    return ret;
  }

  validateRequest({restData, requestData, formData}, endpoint) {
    const {requiredRestData, requiredRequestData, requiredFormData} = endpoint;
    const validations = [
      this.validateRequestType('restData', restData, requiredRestData),
      this.validateRequestType('requestData', requestData, requiredRequestData),
      this.validateRequestType('requestData', formData, requiredFormData)
    ];
    return validations;
  }

  genCacheChecksum(path, fetchObj) {
    const {body, headers, credentials, mode, method /*, signal */} = fetchObj;
    let _body = body;

    if (body instanceof FormData) {
      _body = [...body].reduce((obj, [prop, val]) => {
        if (val instanceof File) {
          const {lastModified, name, size, type} = val;
          obj[prop] = {lastModified, name, size, type};
        }
        else {
          obj[prop] = val;
        }
        return obj;
      }, {});
    }

    return JSON.stringify({
      body: _body,
      headers,
      [this.sessIdPropName]: this.getSessId(),
      credentials,
      mode,
      method,
      path
    }, null, 2);
  }

  getCached(checksum) {
    const cached = this.cache.find(c => c.checksum === checksum) || null;

    if (cached) {
      // renew cache validity
      this.cache.unshift(
        ...this.cache.splice(this.cache.indexOf(cached), 1)
      );
    }

    return cached;
  }

  setCached(checksum, response) {
    if (!this.getCached(checksum)) {
      const now = new Date();

      this.cache.unshift({
        checksum,
        response: {
          ...response,
          _resp: {
            _cached: { ts: now - 0, at: now + '' },
            ...response._resp
          }
        }
      });
      if (this.cache.length > this.cacheLength) {
        this.cache.pop();
      }
    }
  }

  fetchBuildRequest({
    encodeURI,
    requestData = {},
    formData = [],
    restData = {},
    location = getLocation(),
    headers = {},
    requestType = "REST", // todo
    callback,
    beforeFetch
  }, endpoint) {

    const request = {
      encodeURI,
      restData: {...restData || {}},
      requestData: {...requestData || {}}
    };
    // encode restparams
    if (restData && typeof restData === "object") {
      for (const prop in restData) {
        if (Object.hasOwnProperty.call(restData, prop)) {
          restData[prop] = encodeURIComponent(restData[prop]);
        }
      }
    }

    if (location?.search) {
      // const urlParams = parseParams(location.search, parseNum);
      const urlParams = parseParams(location.search);
      // todo trustedReqParams
      requestData = {
        ...urlParams,
        ...request.requestData
      };
    }

    const extendedPath = this.extendPath(endpoint, { restData });
    const method = endpoint.method || 'POST';
    let body;

    if (method.search(/GET|HEAD/i) === -1) {
      const json = JSON.stringify(
        deepmerge.all([
          {
            'cookieLevel': 'default',
            'language': 'hu',
            [this.sessIdPropName]: Api.SESSID_TOKEN,
            ...(requestData || {})
          },
          (endpoint.defaultRequestData || {}),
          (requestData || {})
        ], {
          arrayMerge: overwriteMerge,
          clone: false
        })
      );


      if (endpoint.multipartFormData) { // cahce not yet supported
        body = new FormData();
        body.append('json', json);

        if (formData && formData.length) {
          formData.forEach(([name, value, filename], i) => {
            body.append((name || i) + '', value, filename);
          });
        }
      }
      else {
        body = json;
      }
    }

    const _headers = { ...headers };

    if (!endpoint.multipartFormData) {
      _headers['Content-Type'] = 'application/json';
    }
    else if (_headers['Content-Type']) {
      delete _headers['Content-Type'];
    }

    const fetchObj = {
      credentials: 'omit',
      method,
      mode: 'cors',
      body,
      headers: _headers
    };


    return {
      encodeURI,
      callback,
      request,
      beforeFetch,
      requestType,
      extendedPath,
      fetchObj,
      checksum: this.genCacheChecksum(extendedPath, fetchObj)
      // checksum: endpoint.multipartFormData ? null : this.genCacheChecksum(extendedPath, fetchObj)
    };
  }



  fetch(setup, endpoint, clearCache, callback) {
    const {requestData, restData, formData} = setup.request;

    const buildedRequest = setup.checksum ? setup : this.fetchBuildRequest(setup, endpoint);
    const validations = this.validateRequest({requestData, restData, formData}, endpoint);
    const invalidated = validations.filter(v => v.invalid);

    if (invalidated && invalidated.length) {
      DEV_MODE && console.warn(
        'Several request properties are missing or not valid.\n -',
        invalidated.map(v => v.reason.error + ': ' + v.reason.message).join('\n - '),
        {
          requestData,
          restData,
          formData,
          setup,
          invalidated
        }
      );
      callback(this._prepareFetch(buildedRequest, endpoint, clearCache, invalidated));
      return false;
    }

    if (!this.state.sessionReady && this.sessionInitializer !== endpoint.alias) {
      // wait for finishing endpoint that referenced by sessionInitializer option.
      this.sessionReadyQueue.add([buildedRequest, endpoint, clearCache], (queuedData) => {
        callback(this._prepareFetch(...queuedData));
      });
    }
    else {
      callback(this._prepareFetch(buildedRequest, endpoint, clearCache));
    }

    return buildedRequest;
  }

  _prepareFetch(buildedRequest, endpoint, clearCache, invalidated) {
    const {
      // encodeURI,
      callback,
      // request,
      beforeFetch,
      // extendedPath,
      // fetchObj = {},
      checksum = null
    } = buildedRequest || {};

    const abortController = new AbortController();
    const signal = abortController?.signal || (void 0);
    const returnValues = {
      abortController,
      checksum,
      cached: false,
      fetchStatus: ENUM_fetchStatus.pending
    };
    buildedRequest.fetchObj.signal = signal;

    if (invalidated) { // invalid request
      returnValues.cached = false;
      returnValues.fetchStatus = ENUM_fetchStatus.invalid;
      const error = new Error('invalid request');

      this._fetchError(error, buildedRequest, endpoint, {
        invalid: true,
        invalidated,
        ok: false,
        buildedRequest,
        endpoint,
        status: 400,
        statusText: "Bad Request",
        path: endpoint.path,
        rawData: error,
        redirected: null,
        reqId: null,
        type: null,
        url: null
      });

      return returnValues;
    }

    const cached = clearCache && checksum ? false : this.getCached(checksum);

    if (cached && endpoint.apiCache) {
      if (typeof callback === "function") {
        callback(cached.response._resp.ok, cached.response, 'return cached');
        returnValues.cached = true;
        return returnValues;
      }
    }



    if (typeof beforeFetch === "function") {
      beforeFetch(() => this._beginFetch(buildedRequest, endpoint));
    }
    else this._beginFetch(buildedRequest, endpoint);

    return returnValues;
  }

  _beginFetch(buildedRequest, endpoint) {
    const {
      extendedPath,
      fetchObj,
      // beforeFetch,
      // callback,
      checksum,
      encodeURI,
      request,
      requestType
    } = buildedRequest;


    let _resp = {};

    if (fetchObj.method.search(/GET|HEAD/i) === -1) { // update sessionId
      if (endpoint.multipartFormData && fetchObj.body instanceof FormData) {
        fetchObj.body.set('json',
          fetchObj.body.get('json').replace(Api.SESSID_TOKEN, this.getSessId())
        );
      }
      else {
        fetchObj.body = fetchObj.body.replace(Api.SESSID_TOKEN, this.getSessId());
      }
    }

    return fetch(extendedPath, fetchObj).then(response => {
      const {ok, status, statusText, headers, url, type, redirected} = response;
      const contentType = headers?.get("content-type") || 'application/json';
      // Maybe we get sessionid as a header value
      const sessId = headers && headers.get && headers.get(this.sessIdPropName);
      const sessAuth = headers && headers.get && headers.get(this.sessAuthName);
      if (typeof sessId === "string") {
        this.updateSessId(sessId);
      }
      if (typeof sessAuth !== "undefined" && sessAuth !== null) {
        this.updateSessAuth(sessAuth);
      }

      if (sessId && !this.state.sessionReady &&
        this.sessionInitializer &&
        this.sessionInitializer === endpoint.alias
      ) {
        this.setState({sessionReady: true });
      }
      this.sumOfRequests++;

      _resp = {
        buildedRequest: {
          extendedPath,
          fetchObj,
          // beforeFetch,
          // callback,
          checksum,
          encodeURI,
          request,
          requestType
        },
        endpoint,
        ok,
        status,
        statusText,
        // encodeURI,
        // request,
        // checksum,
        path: endpoint.path,
        redirected,
        url,
        type,
        reqId: this.sumOfRequests
      };

      // return ((contentType.search(/json/i) > -1 ? response.json() : {}) || {}); // todo: fix needed on serverside
      return ((contentType.search(/json/i) > -1 ? response.json() : {}) || {}); // todo: fix needed on serverside
    }).then((data) => {
      return this._fetchSuccess(data, buildedRequest, endpoint, _resp);
    }).catch(error => {
      return this._fetchError(error, buildedRequest, endpoint, _resp);
    });
  }


  _fetchSuccess(data, buildedRequest, endpoint, _respRef) {
    const _resp = _respRef || {};
    const {callback, checksum} = buildedRequest;

    _resp.rawData = {...data};

    // otherwise we get sessionid as a parameter.
    const sessId = data[this.sessIdPropName];
    const sessAuth = data[this.sessAuthName];

    if (typeof sessId === "string") {
      this.updateSessId(sessId);
    }
    if (typeof sessAuth !== "undefined" && sessAuth !== null) {
      this.updateSessAuth(sessAuth);
    }
    if (sessId && !this.state.sessionReady &&
      this.sessionInitializer && this.sessionInitializer === endpoint.alias
    ) {
      this.setState({sessionReady: true });
    }
    this.updateSotre(endpoint, data);

    const response = { _resp, ...data};

    if (typeof callback === "function") {
      callback(response._resp.ok, response, 'then beofre catch');
    }
    if (endpoint.apiCache) {
      this.setCached(checksum, response);
    }
    this.requestEmitter.emit([endpoint.alias, '*'], response);

    return _resp;
  }

  _fetchError(error, buildedRequest, endpoint, _respRef) {
    const _resp = _respRef || {};
    _resp._buildedRequest = buildedRequest;
    _resp._endpoint = endpoint;
    const {callback} = buildedRequest;

    _resp.rawData = error;

    if (error?.message) {
      if (error.message.search(/network|fetch/i) > -1) {
        _resp.statusText = "Hálózati hiba lépett fel";
      }
      else if (error.message.search(/unexpected/i) > -1) {
        _resp.statusText = "Hiba lépett fel a kiszolgálóval való kommunikáció során";
      }
      else if (error.message.search(/invalid request/i) > -1) {
        _resp.statusText = "A küldött adatok validációja sikertelen volt.";
      }
      else {
        _resp.statusText = "Nem várt hiba lépett fel";
      }
      _resp.status = error.message;
    }

    typeof callback === "function" && callback(false, {
      _resp,
      error: error || null
    }, 'catchError');

    if (error.name === "AbortError") {
      return _resp;
    }

    this.logError(endpoint.path, error);

    return _resp;
  }

  matchSessId(sessId) {
    return this.getSessId() === sessId;
  }

  getSessId() {
    return locStore.get(this.lsPropSessId, '0') || '0';
  }


  updateSessId(sessId) {
    if (this._lastSessId !== sessId) {
      DEV_MODE && console.log('updateSessId', sessId);
      this.requestEmitter.emit('sessIdChange', sessId);

      locStore.set(this.lsPropSessId, (
        this._lastSessId = sessId || '0'
      ));
    }
  }

  updateSessAuth(sessAuth) {
    if (this._lastSessAuth !== sessAuth) {
      DEV_MODE && console.log('updateSessAuth', sessAuth);
      this.requestEmitter.emit('sessAuthChange', sessAuth);
      this._lastSessAuth = sessAuth || false;
    }
  }

  clearSession() {
    // console.log('clear session');
    // locStore.set(this.lsPropSessId, '0');
    // this.setState({sessionReady: false });
  }

  addEndpoint(endpointSetup) {
    const [path, setup] = Array.isArray(endpointSetup) ? endpointSetup : [endpointSetup, {}];
    const endpoint = new Endpoint(
      path,
      setup,
      this,
      this.endpoints.length
    );
    this.endpoints.push(endpoint);
    return endpoint;
  }

  fetchAll() {
    this.endpoints.forEach(endpoint => endpoint.fetch());
  }

  getEndpointByPublicPath(p) {
    const endpoint = this.endpoints.find(endpoint => endpoint.publicPath === p);
    return endpoint || null;
  }

  getOrAddEndpointByPublicPath(p) {
    let endpoint = this.getEndpointByPublicPath(p);
    if (endpoint instanceof Endpoint !== true) {
      endpoint = this.addEndpoint(
        [`content${p}`, {
          flags: ['autoadded'],
          publicPath: p
        }]
      );
    }
    return endpoint;
  }

  getEndpoint(p) {
    const endpoint = this.endpoints.find(endpoint => endpoint.path === p);
    return endpoint || null;
  }

  getEndpointByAlias(alias) {
    const endpoint = this.endpoints.find(endpoint => endpoint.alias === alias);
    if (!endpoint) {
      throw new Error('Endpoint does not exist ' + alias);
    }
    return endpoint;
  }

  allowParams(params) {
    // for debug
    if (params && typeof params === "object") {
      for (const prop in params) {
        if (Object.hasOwnProperty.call(params, prop)) {
          const param = params[prop];
          if (!this.trustedReqParams[param]) {
            this.trustedReqParams[param] = [];
          }
          if (this.trustedReqParams[param].indexOf(prop) === -1) {
            this.trustedReqParams[param].push(prop);
          }
        }
      }
    }
  }

  logError(path, error) {
    konsole.error(`Endpoint Error [${path}]`, error);
  }
}

Api.prototype._static = {
  instances: 0
};
Api.prototype.ENUM = ENUM_fetchStatus;


const extractErrorMsg = (data, forceString) => {
  const err = data?.errormsg || data?._resp?.statusText || 'Nem várt hiba történt';
  return forceString & Array.isArray(err) ? err.join(', ') : err;
};


const fetchResponseHelper = ({status, data, onAny, onSuccess, onErrorMsg, isMounted}) => {
  if (typeof isMounted === "function" && isMounted() === false) {
    DEV_MODE && console.log('fetchResponseHelper: unmounted callback');
    return;
  }


  if (ENUM_fetchStatus.success === status) {
    if (!data?.error) {
      onAny && onAny(true, data);
      onSuccess && onSuccess(data);
    }
    else {
      onAny && onAny(false, data);
      onErrorMsg && onErrorMsg(data?.errormsg || 'Nem várt hiba történt :(');
    }
  }
  else if (ENUM_fetchStatus.aborted !== status) {
    onAny && onAny(false, data);
    onErrorMsg && onErrorMsg(extractErrorMsg(data));
  }
};


Api.prototype.ENUM = ENUM_fetchStatus;

Api.prototype.fetchResponseHelper = fetchResponseHelper;
Api.fetchResponseHelper = fetchResponseHelper;
Api.prototype.extractErrorMsg = extractErrorMsg;
Api.extractErrorMsg = extractErrorMsg;

export default Api;

export {
  fetchResponseHelper,
  Api,
  Endpoint
};
