/* eslint-disable react/prop-types */
import React from 'react';

import StaticPageMustLogin from '../../../StaticPageMustLogin';
import ModuleLoader from '../../../components/ModuleLoader';

import { Route } from 'react-router-dom';
import { meta } from '../../../lib/util';

const lazyImports = {
  subscription: () => React.lazy(() => import('../../../base/transactions/')),
  paperCode: () => React.lazy(() => import('../../../base/paperCode/'))
};


const getTransactionRoutes = (pageProps) => {
  const isLoggedIn = pageProps.appPub.isLoggedIn;

  return [
    <Route path={[
      "/transactions"
    ]} key={'view-transactions'} exact render={(props) => {
      meta.setTitle('Tranzakciók');
      return isLoggedIn()
        ? <ModuleLoader moduleImportFn={lazyImports.subscription}
          { ...{...pageProps, ...props} }
        />
        : <StaticPageMustLogin { ...{...pageProps, ...props} } />;
    }} />
  ];
};

export default getTransactionRoutes;
