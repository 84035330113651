export default [

  [
    '{grimm_api_url}',
    {
      alias: 'user-check_login',
      flags: ['private'],
      defaultRequestData: {
        method: 'check_login',
        model: "user"
      },
      requiredRequestData: {
        params: {
          "email": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'user-login',
      flags: ['private'],
      defaultRequestData: {
        method: 'login',
        model: "user"
      },
      requiredRequestData: {
        params: {
          "email": {type: "string" },
          "password": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'user-registeruser',
      flags: ['private'],
      defaultRequestData: {
        method: 'registeruser',
        model: "user"
      },
      requiredRequestData: {
        params: {
          "email": {type: "string" },
          "password": {type: "string" },
          "password2": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'user-activateuser',
      flags: ['private'],
      defaultRequestData: {
        method: 'activateuser',
        model: "user"
      },
      requiredRequestData: {
        params: {
          "code": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'user-validatecode',
      flags: ['private'],
      defaultRequestData: {
        method: 'validatecode',
        model: "user"
      },
      requiredRequestData: {
        params: {
          "action": {type: "string" }, // ACTIVATE|PASSWORD
          "code": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'user-changepass',
      flags: ['private'],
      defaultRequestData: {
        method: 'changepass',
        model: "user"
      },
      requiredRequestData: {
        params: {
          "pwcode": {type: "string" },
          "password": {type: "string" },
          "password2": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'user-reminduser',
      flags: ['private'],
      defaultRequestData: {
        method: 'reminduser',
        model: "user"
      },
      requiredRequestData: {
        params: {
          "email": {type: "string" },
          "action": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      apiCache: false,
      hideLog: true,
      alias: 'user-isloggedin',
      flags: ['private'],
      defaultRequestData: {
        method: 'isloggedin',
        model: "user"
      },
      requiredRequestData: {
        params: {}
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      apiCache: false,
      hideLog: true,
      alias: 'user-logout',
      flags: ['private'],
      defaultRequestData: {
        method: 'logout',
        model: "user"
      },
      requiredRequestData: {
        params: {}
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      apiCache: false,
      hideLog: true,
      alias: 'user-deleteuser',
      flags: ['private'],
      defaultRequestData: {
        method: 'deleteuser',
        model: "user"
      },
      requiredRequestData: {
        params: {
          "email": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'userdata-partnerdata',
      flags: ['private'],
      defaultRequestData: {
        method: 'partnerdata',
        model: "userdata"
      },
      requiredRequestData: {
        params: { // TODO: frissíteni, változni fognak a prop nevek
          "name": {type: "string" },
          "post_code": {type: "string" },
          "city": {type: "string" },
          "address": {type: "string" },
          "email": {type: "string" },
          "taxcode": {type: "string" }
        }
      },
      publicPath: null
    }
  ]
];
