import {useCallback} from 'react';
import {filterEditableHtml} from '../../../lib/filterHtml';
import {normalizeSourceText} from '../dictHelpers';

const useOnOcrResult = ({dispatch, isMounted}) => {
  return useCallback(({text, lang}) => {
    // onSourceLangChange(lang);
    isMounted && dispatch({type: 'ocrRead',
      payload: {
        src: lang,
        html: text,
        cleanHtml: filterEditableHtml(text),
        ocrText: text,
        text: normalizeSourceText(text)
      }}
    );
  }, [dispatch, isMounted]);
};

export default useOnOcrResult;


// const onOcrResult = useCallback(({text, lang}) => {
//   // onSourceLangChange(lang);
//   isMounted && dispatch({type: 'ocrRead',
//     payload: {
//       src: lang,
//       html: text,
//       cleanHtml: filterEditableHtml(text),
//       ocrText: text,
//       text: normalizeSourceText(text)
//     }}
//   );
// }, [isMounted]);
