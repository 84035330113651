import React, { useReducer, useCallback, useRef } from "react";
import PropTypes from 'prop-types';
import {glindow} from "../../../controller/globals";
import OcrToolCrop from "./OcrToolCrop";
import OcrToolModal from "./OcrToolModal";
import messages from "../../../messages";
import {useIsMounted} from '../../../hooks';


const FileReader = glindow.FileReader;
const Image = glindow.Image;

function reducer(state, {type, payload}) {
  switch (type) {
    case 'ocrTool':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}



function downloadUrl(href) {
  if (!href) { return; }
  const anchor = document.createElement('a');
  anchor.download = 'cropPreview.png';
  anchor.href = href;
  anchor.click();
}

const ifValidFile = (reader, callback) => {
  if (reader instanceof FileReader !== true) {
    throw new Error('reader is not an instance of FileReader.');
  }
  if (reader.readyState === FileReader.EMPTY) {
    callback(false, messages.get('ocrTool.errorFileIsEmpty'));
  }
  const elImg = new Image();
  elImg.addEventListener('load', () => callback(true, elImg));
  elImg.addEventListener('error', (...all) => callback(false, elImg, all));
  elImg.src = reader.result;
};


const OcrTool = ({appPub, baseClass, srcLang, onOcrResult, refFileInput}) => {

  const [state, dispatch] = useReducer(reducer, {
    src: null,
    dimensions: null,
    modalShow: false
  });

  const {api, toastErrorMsg} = appPub;

  const isMounted = useIsMounted();

  const refForm = useRef();
  const refOcrCrop = useRef();

  const onHide = useCallback(() => {
    refForm.current.reset();
    if (state.loading) {
      api.getEndpointByAlias('freedict-ocrupload').abortFetch('OcrTool', true);
    }

    dispatch({
      type: 'ocrTool',
      payload: {
        loading: false,
        modalShow: false,
        src: null
      }
    });

  }, [api, state.loading]);

  const onUpload = useCallback((lang, testOnly) => {
    const ocrCrop = refOcrCrop.current;
    if (!ocrCrop) { return; }

    const {
      croppedImageBlob, croppedImageUrl // , croppedFile, dimensions
    } = ocrCrop.getData();

    if (!croppedImageBlob) {
      toastErrorMsg(messages.get('ocrTool.errorNoSelection'), 'ocr-upload-noblob');
      ocrCrop.selectAll();
      return;
    }

    if (testOnly) {
      downloadUrl(croppedImageUrl);
      return;
    }

    dispatch({
      type: 'ocrTool',
      payload: {
        loading: true
      }
    });

    api.getEndpointByAlias('freedict-ocrupload').fetch({
      formData: [['image', croppedImageBlob, croppedImageBlob.name]],
      requestData: {
        params: { srclang: lang }
      }
    }, (status, data) => api.fetchResponseHelper({
      status,
      data,
      isMounted: () => isMounted(),
      onAny: () => dispatch({ type: 'ocrTool', payload: { loading: false } }),
      onSuccess: () => {
        const text = (data.ocrText || '').trim();
        if (!text) {
          toastErrorMsg(messages.get('ocrTool.errorNoTextDetected'), 'ocr-upload-text');
          return;
        }
        onOcrResult({lang, text, data});
        onHide();
      },
      onErrorMsg: (msg) => toastErrorMsg(msg, 'ocr-upload-error')
    }), false);
  }, [api, isMounted, onHide, onOcrResult, toastErrorMsg]);


  const onReaderOrImageError = useCallback(() => {
    onHide();
    toastErrorMsg(messages.get('ocrTool.errorOnFileRead'), 'ocr-upload-fileread');
  }, [onHide, toastErrorMsg]);


  const onSelectFile = useCallback((e) => {
    const file = (e.target?.files || [])[0] || null;
    if (!file) { return; }

    const reader = new FileReader();

    reader.addEventListener("load", () => ifValidFile(reader, (success, elImg) => {
      if (success) {
        const {naturalWidth, naturalHeight, width, height} = elImg;
        dispatch({
          type: 'ocrTool',
          payload: {
            dimensions: { naturalWidth, naturalHeight, width, height },
            src: reader.result,
            modalShow: true
          }
        });
      }
      else {
        onHide();
        toastErrorMsg(messages.get('ocrTool.errorInvalidImage'), 'ocr-upload-imgerror');
      }
    }));

    reader.addEventListener("onerror", onReaderOrImageError);
    reader.addEventListener("onabort", onHide);
    reader.readAsDataURL(file);
  }, [onHide, onReaderOrImageError, toastErrorMsg]);

  return (
    <div className={`${baseClass}__ocr-wrp`}>
      <form ref={refForm}>
        <input
          ref={refFileInput}
          accept="image/jpeg, image/png"
          type="file"
          id="ocr-file-input"
          onChange={onSelectFile}
        />
      </form>
      {!state.src ? null : (
        <OcrToolModal
          className={`${baseClass}__ocr-modal`}
          show={state.modalShow}
          onHide={onHide}
          onUpload={onUpload}
          loading={state.loading}
          srcLang={srcLang}
        >
          <OcrToolCrop
            ref={refOcrCrop}
            className={`${baseClass}__ocr-crop`}
            src={state.src}
            loading={state.loading}
            dimensions={state.dimensions}
            onError={onReaderOrImageError}
          />
        </OcrToolModal>
      )}
    </div>
  );
};

OcrTool.propTypes = {
  appPub: PropTypes.object.isRequired,
  baseClass: PropTypes.string.isRequired,
  onOcrResult: PropTypes.func.isRequired,
  srcLang: PropTypes.string.isRequired,
  refFileInput: PropTypes.object
};

export default OcrTool;
