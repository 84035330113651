import React from 'react';
import PropTypes from 'prop-types';
import ModuleLoader from './components/ModuleLoader';

const StaticPageLoader = ({moduleImportFn, children, ...moduleProps}) => {
  return (
    <div className="static-page static-page--loader">
      {typeof moduleImportFn === "function" ? (
        <ModuleLoader
          moduleImportFn={moduleImportFn}
          moduleProps={moduleProps}
        />
      ) : null}
      {children || null}
    </div>
  );
};

StaticPageLoader.propTypes = {
  location: PropTypes.object,
  appPub: PropTypes.object,
  moduleImportFn: PropTypes.func,
  children: PropTypes.node
};

export default StaticPageLoader;
