// @ts-nocheck
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useRef, useEffect} from 'react';
import classnames from 'classnames';
import useIsMounted from '../hooks';
import ContentButton from '../components/contentButton';
import { UserContext } from '../Contexts';
import UserMenu from './UserMenu';
import PageHeaderMenu from './PageHeaderMenu';
import GrimmBrand from './branding/GrimmBrand';
import trapFocus from '../lib/dom/trapFocus';
import globals from '../controller/globals';
import { amedia } from '../setup';
import arrayRandPick from '../lib/arrays/arrayRandPick';
import messages from '../messages';

const defaultMenuState = false;

const AMedia = React.memo(() => {
  const src = amedia ? arrayRandPick(amedia['468x120']) : null;
  return !src ? null : (
    <div className="a-media">
      <iframe
        src={src}
        width="468"
        height="120"
        frameBorder="0"
        scrolling="no"
        allowFullScreen={true}>
      </iframe>
    </div>
  );
});
AMedia.displayName = 'AMedia';

const PageHeaderForMemo = ({
  noSticky = false,
  children = null,
  narrow = false,
  pageTitle = null,
  amedia = null,
  isMobileView = false,
  primaryButton = null
}) => {
  const appPub = globals.get('appPub');
  const [open, setOpen] = useState(defaultMenuState);
  const isMounted = useIsMounted();
  const refPageHeader = useRef();
  const refToggler = useRef();
  const refHeadernavigations = useRef();

  useEffect(() => {
    appPub.setHtmlClasses({ showMenu: open });
  }, [appPub, open]);

  const onToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onClose = useCallback((e) => {
    if (e.target?.matches('a') && (
      e.type === 'click' || (
        e.code === "space" ||
        e.code === "enter" ||
        e.which === 32 ||
        e.which === 13
      )
    )) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    let closeOnEscape;
    let unTrap = null;

    if (open) {
      if (refPageHeader.current) {
        unTrap = trapFocus(refPageHeader.current, refToggler.current);
      }
      closeOnEscape = (e) => {
        if (e.code === "Escape" || e.which === 27) {
          setOpen(false);
          appPub.setHtmlClasses({ showMenu: false });
        }
      };
      document.addEventListener('keydown', closeOnEscape);
    }

    return () => {
      if (typeof unTrap === "function") {
        unTrap();
      }
      if (typeof closeOnEscape === "function") {
        document.removeEventListener('keydown', closeOnEscape);
      }
    };
  }, [open, isMounted, appPub]);

  const rxHeaderBtn = primaryButton ? <ContentButton data={{theme: "grimm-ghost", ...primaryButton }} /> : null;

  return (
    <header
      ref={refPageHeader}
      aria-label={messages.get('layout.Header.ariaLabel')}
      className={
        classnames(
          "page-header",
          open ? 'main-nav-open' : null,
          noSticky ? 'disallow-sticky' : 'allow-sticky'
        )
      }
    >
      <UserContext.Consumer>{({loggedin}) => (
        <>
          <div className={`page-header-in page-width${narrow ? '-narrow' : ''}`}>
            {isMobileView ? (
              <div className="page-header-mobile-buttons">
                {rxHeaderBtn}
              </div>
            ) : null}
            <div className="page-header-branding">
              <div className="page-header-brand">
                <GrimmBrand linkedToFront={!open} />
              </div>
              {pageTitle && <div className='page-header-pageTitle'>{pageTitle}</div>}
              <div className='page-header-child'>{children}</div>
            </div>

            <div className="page-header-navigations" ref={refHeadernavigations}>
              <UserMenu
                refContainer={refHeadernavigations}
              />
              <nav id="main-nav" className="main-navigation" role="navigation">
                <button
                  className={classnames('main-navigation__toggle', open ? 'open' : null)}
                  aria-label={
                    messages.get(open
                      ? 'layout.PageHeader.togglerArialabelOpen'
                      : 'layout.PageHeader.togglerArialabelClose'
                    )
                  }
                  aria-haspopup="true"
                  aria-controls="main-navigation"
                  aria-pressed={open}
                  aria-expanded={open}
                  onClick={onToggle}
                  tabIndex={0}
                  ref={refToggler}
                  id="toggle-mobilmenu"
                >
                  <div><span></span></div>
                </button>
                <div className="main-navigation__content">
                  <h1 className="main-navigation__brand">
                    <GrimmBrand linkedToFront={false} />
                  </h1>
                  <PageHeaderMenu loggedin={loggedin} onClose={onClose} />
                </div>
              </nav>
            </div>
          </div>
          {(!isMobileView) ? (
            <div className={`page-header-layaout-adapted page-width${narrow ? '-narrow' : ''}`}>
              <div className="page-header-layout-left">
                {rxHeaderBtn}
              </div>
              <div className="page-header-layout-right">
                {amedia ? (
                  <div className="page-header-layout-right-amedia">
                    <AMedia />
                  </div>
                ) : null}
              </div>
            </div>

          ) : null}
        </>

      )}
      </UserContext.Consumer>
    </header>
  );
};

/** @type {React.FC} */
const PageHeader = React.memo(PageHeaderForMemo);

PageHeader.displayName = 'PageHeader';

export default PageHeader;
