import React, {} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
// import SelectionTTS from './SelectionTTS';
import ContentEditable from '../../lib/react-contenteditable';
import debounceAnimationFrame from "debounce-animation-frame";

import { throttle } from '../../lib/util';
import el_liveBind from "../../lib/dom/el_liveBind";
import StyleWriter from "../../lib/dom/StyleWriter";
import Results from './Results';
import messages from "../../messages";
import { normalizeSourceText } from "./dictHelpers";
import LineBreakMode from "./components/LineBreakMode";
import SpecChars from "./components/SpecChars/SpecChars";
import { filterEditableHtml } from "../../lib/filterHtml";
import DEV_MODE from "../../controller/devel";

import AreaIcon from "./components/AreaIcon";
import OcrIcon from "./components/OcrIcon";
import WordCount from "./components/WordCount";
// import DoubleClickAbsorber from "./components/DoubleClickAbsorber";

import './TrsSourceArea_.scss';


const debug = true && DEV_MODE;
const baseClass = "tr-source-area";


const hlwHoverCss = (word) => (`
  .tr-source-area .tr-hlw[data-tr-word="${word}"] {
    color: #1d1346;
    background-image: linear-gradient(#495df8, #495df8);
  }
`);


class TrsSourceArea extends React.Component {
  constructor(props) {
    super(props);
    this._static.instances++;
    this.mounted = false;
    this.instanceId = `${baseClass}_${this._static.instances}`;

    this.refContentEditableEl = props.innerRef ? props.innerRef : React.createRef();
    this.refContentEditable = React.createRef();
    this.refSourceArea = React.createRef();
    this.refEditingEnd = React.createRef();
    this.refEditingStart = React.createRef();
    this.timerIdFocus = null;

    this.styleWriter = new StyleWriter(this.instanceId);

    this.state = {
      inFocusSustained: false,
      inFocus: false,
      scrolled: false,
      scrollEnd: false,
      scrollable: false
    };

    this.queueAfterDidUpdate = [];


    this.unmountable = {
      offWordClick: null,
      offWordMouseover: null,
      offWordMouseout: null
    };
  }

  getId() {
    return this.props.id || this.instanceId;
  }

  clearFocusSustained() {
    if (this.timerIdFocus) {
      clearTimeout(this.timerIdFocus);
      this.timerIdFocus = null;
    }
  }

  setFocusSustained() {
    this.clearFocusSustained();
    this.timerIdFocus = setTimeout(() => {
      if (this.mounted && !this.state.inFocus) {
        this.setState({inFocusSustained: false});
      }
    }, 250);
  }

  setInFocusOn() {
    if (!this.state.inFocus || !this.state.inFocusSustained) {
      this.clearFocusSustained();
      this.setState({
        inFocusSustained: true,
        inFocus: true
      });
    }
  }

  setInFocusOff() {
    if (this.state.inFocus) {
      this.setState({inFocus: false});
      this.setFocusSustained();
    }
  }

  getContentEditable() {
    return this.refContentEditable.current || null;
  }

  updateScroll(_el) {
    const el = _el || this.refContentEditableEl.current;
    if (!el) { return; }
    const newState = {
      scrollable: el.scrollHeight > el.clientHeight,
      scrolled: el.scrollTop > 10,
      scrollEnd: el.scrollTop >= el.scrollHeight - el.clientHeight - 5
    };
    if (this.state.scrolled !== newState.scrolled ||
      this.state.scrollable !== newState.scrollable ||
      this.state.scrollEnd !== newState.scrollEnd
    ) {
      this.setState(newState);
    }
  }


  componentDidMount() {
    this.mounted = true;
    this.props.appPub.eventEmitter.on('window.resize.debounced', this.handleWindowResize);

    if (this.refSourceArea?.current) {
      this.unmountable.offWordClick = el_liveBind(
        this.refSourceArea.current, 'mousedown touch', '.tr-hlw', this.handleWordClick
      );
      this.unmountable.offWordMouseover = el_liveBind(
        this.refSourceArea.current, 'mouseover', '.tr-hlw', this.handleWordHover
      );
      this.unmountable.offWordMouseout = el_liveBind(
        this.refSourceArea.current, 'mouseout', '.tr-hlw', this.handleWordHover
      );
      this.updateScroll();
    }
    else {
      // throw new Error('this.refSourceArea.current is not defined');
      console.warn('this.refSourceArea.current is not defined');
    }

  }

  componentWillUnmount() {
    this.mounted = false;

    this.props.appPub.eventEmitter.off('window.resize.debounced', this.handleWindowResize);

    if (this.styleWriter) {
      this.styleWriter.destroy();
      this.styleWriter = null;
    }
    if (this.unmountable.offWordClick) {
      this.unmountable.offWordClick();
      this.unmountable.offWordClick = null;
    }
    if (this.unmountable.offWordMouseover) {
      this.unmountable.offWordMouseover();
      this.unmountable.offWordMouseover = null;
    }
    if (this.unmountable.offWordMouseout) {
      this.unmountable.offWordMouseout();
      this.unmountable.offWordMouseout = null;
    }
  }



  shouldComponentUpdate(nextProps, nextState) {
    let changed = "";
    if (
      (nextState.inFocus !== this.state.inFocus && (changed = 'focus')) ||
      (nextState.inFocusSustained !== this.state.inFocusSustained && (changed = 'focusSustained')) ||
      (nextState.scrolled !== this.state.scrolled && (changed = 'scrolled')) ||
      (nextState.scrollable !== this.state.scrollable && (changed = 'scrollable')) ||
      (nextState.scrollEnd !== this.state.scrollEnd && (changed = 'scrollEnd')) ||
      (nextProps.specCharsVisible !== this.props.specCharsVisible && (changed = 'specCharsVisible')) ||
      (nextProps.placeholder !== this.props.placeholder && (changed = 'placeholder')) ||
      (nextProps.numOfWordsNotUnique !== this.props.numOfWordsNotUnique && (changed = 'numOfWordsNotUnique')) ||
      (nextProps.numOfUniqueWords !== this.props.numOfUniqueWords && (changed = 'numOfUniqueWords')) ||
      (nextProps.numOfMaxWords !== this.props.numOfMaxWords && (changed = 'numOfMaxWords')) ||
      (nextProps.hasWhiteSpace !== this.props.hasWhiteSpace && (changed = 'hasWhiteSpace')) ||
      (nextProps.expanded !== this.props.expanded && (changed = 'expanded')) ||
      (nextProps.error !== this.props.error && (changed = 'error')) ||
      (nextProps.innerRef !== this.props.innerRef && (changed = 'innerRef')) ||
      (nextProps.onSpecCharsVisibilityChange !== this.props.onSpecCharsVisibilityChange && (changed = 'onSpecCharsVisibilityChange')) ||
      (nextProps.onEditingEndBlur !== this.props.onEditingEndBlur && (changed = 'onEditingEndBlur')) ||
      (nextProps.onKeyDown !== this.props.onKeyDown && (changed = 'onKeyDown')) ||
      (nextProps.onKeyUp !== this.props.onKeyUp && (changed = 'onKeyUp')) ||
      (nextProps.onChange !== this.props.onChange && (changed = 'onChange')) ||
      (nextProps.onReset !== this.props.onReset && (changed = 'onReset')) ||
      (nextProps.onScroll !== this.props.onScroll && (changed = 'onScroll')) ||
      (nextProps.onBlur !== this.props.onBlur && (changed = 'onBlur')) ||
      (nextProps.onBlurLate !== this.props.onBlurLate && (changed = 'onBlurLate')) ||
      (nextProps.onOcrClick !== this.props.onOcrClick && (changed = 'onOcrClick')) ||
      (nextProps.onWordSelect !== this.props.onWordSelect && (changed = 'onWordSelect')) ||
      (nextProps.enterKeyModeChange !== this.props.enterKeyModeChange && (changed = 'enterKeyModeChange')) ||
      (nextProps.a11ymultiline !== this.props.a11ymultiline && (changed = 'a11ymultiline')) ||
      (nextProps.className !== this.props.className && (changed = 'className')) ||
      (nextProps.id !== this.props.id && (changed = 'id')) ||
      (nextProps.sourceLang !== this.props.sourceLang && (changed = 'sourceLang')) ||
      (nextProps.value !== this.props.value && (changed = 'value')) ||
      (nextProps.showPreview !== this.props.showPreview && (changed = 'showPreview')) ||
      (nextProps.isEmpty !== this.props.isEmpty && (changed = 'isEmpty')) ||
      (nextProps.submittable !== this.props.submittable && (changed = 'submittable')) ||
      (nextProps.loading !== this.props.loading && (changed = 'loading')) ||
      (nextProps.resultInstance !== this.props.resultInstance && (changed = 'resultInstance')) ||
      (nextProps.dictId !== this.props.dictId && (changed = 'dictId')) ||
      (nextProps.activeWord !== this.props.activeWord && (changed = 'activeWord')) ||
      // eslint-disable-next-line no-unused-vars
      (nextProps.activeWordIndex !== this.props.activeWordIndex && (changed = 'activeWordIndex'))
    ) {

      debug && console.log('TrSourceArea update TRIGGER BY', changed, {
        nextProps,
        nextState,
        prevProps: {...this.props},
        prevState: {...this.state}
      });

      return true;
    }
    else {
      // console.log('no change')
    }
    return false;
  }

  componentDidUpdate(/* prevProps, prevState */) {
    while (this.queueAfterDidUpdate.length && this.mounted) {
      const fn = this.queueAfterDidUpdate.shift();
      if (typeof fn === "function") fn();
    }
  }

  afterDidUpdate(fn) {
    this.queueAfterDidUpdate.push(fn);
  }

  focusEditable() {
    this.refContentEditableEl.current?.focus();
  }

  paste(text, focus) {
    // console.log('insert spec char PASTE', text);
    const contentEditable = this.getContentEditable();
    if (!contentEditable) return;
    if (focus && this.refContentEditableEl.current !== document.activeElement) {
      this.focusEditable();
      contentEditable.saveSelection();
    }

    if (document.queryCommandSupported('insertText')) {
      // document.execCommand('insertText', false, text);
      return document.execCommand('insertHTML', false, text);
    }
    else {
      return document.execCommand('paste', false, text);
    }
  }

  hasSelection() {
    const contentEditable = this.getContentEditable();
    if (contentEditable && contentEditable.getSelection()) {
      return true;
    }
    return false;
  }

  isPreviewable(rawHtml = this.props.value) {
    return !!(
      !this.isEditingInFocus() &&
      this.props.showPreview &&
      !this.state.inFocusSustained &&
      // this.props.resultInstance &&
      rawHtml.trim()
    );
  }

  isInEditingMode() {
    return (
      this.state.inFocusSustained || this.props.submittable || this.isEditingInFocus()
    );
  }

  isEditingInFocus() {
    return this.refEditingEnd?.current === document.activeElement;
  }

  renderEditableHtml(previewMode = this.isPreviewable()) {
    const rawHtml = this.props.value;
    // console.warn(rawHtml);
    if (previewMode) {
      const filteredHtml = filterEditableHtml(rawHtml, {debug});
      const result = this.props.resultInstance;
      const numOfMatchByWord = {};

      // console.log("RENDER TEXT", {
      //   searchedWords: result.searchedWords,
      //   filteredHtml,
      //   matchReg: result.matchReg
      // });
      // const _matched = filteredHtml.match(result.matchReg);
      // console.log("RENDER _matched", _matched);

      if (!result) return filteredHtml;

      const newHtml = filteredHtml.replace(
        result.matchReg,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (fullMatch, punctLeft, word, _offset, _fullHtml) => {
          const lc_word = normalizeSourceText(word.toLowerCase());
          if (!numOfMatchByWord[lc_word]) { numOfMatchByWord[lc_word] = 0; }
          const index = numOfMatchByWord[lc_word]++;
          const activeWord = normalizeSourceText(this.props.activeWord.toLowerCase());
          const isActive = activeWord === lc_word;

          // console.log("RENDER lc_word", {lc_word, word}, result.searchedWords.indexOf(lc_word));
          // console.log("RENDER fullMatch", {
          //   fullMatch,
          //   punctLeft,
          //   word,
          //   _offset,
          //   _fullHtml,
          //   lc_word,
          //   "result.searchedWordsLc": result.searchedWordsLc
          // });
          const searchedWordX = result.searchedWordsLc.indexOf(lc_word);

          if (searchedWordX > -1) {
            const searchedWord = result.searchedWords[searchedWordX];
            const className = classNames(
              'tr-hlw',
              'tr-hlw--' + index,
              isActive && this.props.activeWordIndex === index ? 'active-index' : null,
              isActive ? 'active-word' : null
            );
            return (
              `${punctLeft}<span
                tabindex="-1"
                role="link"
                data-tr-word="${searchedWord}"
                data-tr-wordindex="${index}"
                class="${className}"
              >${word}</span>`
            );
          }
          if (result.queryWordsOverLimitLc.indexOf(lc_word) > -1) {
            return (
              `${punctLeft}<span class="tr-hlo">${word}</span>`
            );
          }
          return punctLeft + word;
        }
      );
      // console.log('SHOW PREVIEW', {
      //   rawHtml,
      //   filteredHtml,
      //   newHtml,
      //   result: this.props.result
      // });
      return newHtml;
    }
    else {
      return filterEditableHtml(this.props.value, {debug});
      // return this.props.value;
    }
  }

  triggerOnBlurLate(e) {
    if (typeof this.props.onBlurLate === "function") {
      this.afterDidUpdate(() => {
        window.requestAnimationFrame(() => {
          if (this.mounted) {
            // check again in case it was changed in the meantime
            if (typeof this.props.onBlurLate === "function") {
              this.props.onBlurLate(e, {
                editingEndInFocus: this.isEditingInFocus()
              });
            }
          }
        });
      });
    }
  }

  handlePaste = (e) => {
    e.preventDefault();
    let text = '';
    if (e.clipboardData || e.originalEvent.clipboardData) {
      text = (e.originalEvent || e).clipboardData.getData('text/plain');
    }
    else if (window.clipboardData) {
      text = window.clipboardData.getData('Text');
    }
    text = normalizeSourceText(filterEditableHtml(text.replace(/\n\s*\n/g, '<br />'), {
      allowedTags: ['br'],
      allowedAttrs: [],
      debug
    }));

    this.paste(text);
  };

  handleKeyDown = (e) => {
    // console.log("android gboardhandleKeyDown", e);
    if (typeof this.props.onKeyDown === "function") {
      this.props.onKeyDown(e);
    }
  };

  handleKeyUp = (e) => {
    // console.log("android gboardhandleKeyUp", e);
    if (typeof this.props.onKeyUp === "function") {
      this.props.onKeyUp(e);
    }
  };


  handleScroll = throttle(
    debounceAnimationFrame((e) => void this.updateScroll(e?.target)),
    50
  );

  handleChange = (e, html, text, cleanHtml) => {
    if (typeof this.props.onChange === "function") {
      this.props.onChange(e, {html, text, cleanHtml});
    }
    this.afterDidUpdate(this.handleScroll);
  };

  handleBlur = (e) => {
    this.setInFocusOff();
    if (typeof this.props.onBlur === "function") {
      this.props.onBlur(e);
    }
    this.triggerOnBlurLate(e);
  };



  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleFocus = (e) => void 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleFocusIn = (e) => void this.setInFocusOn();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleFocusOut = (e) => void 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleInsertSpecChar = (char, _e) => {
    // e.preventDefault();
    // if (this.refContentEditableEl.current !== document.activeElement) {
    //   this.focusEditable();
    // }
    const contentEditable = this.getContentEditable();
    if (!contentEditable) return;
    // contentEditable.insertToSelection(char);
    this.paste(char, true);
  };

  handleWindowResize = () => void this.updateScroll();

  handleWordClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // e.stopImmediatePropagation();
    const word = e.target.getAttribute('data-tr-word');
    const index = e.target.getAttribute('data-tr-wordindex') - 0;
    if (typeof this.props.onWordSelect === "function") {
      this.props.onWordSelect(e, word, index);
    }
  };

  handleWordHover = (e) => {
    const word = e.target.getAttribute('data-tr-word');
    this.styleWriter.write(
      e.type === "mouseover" ? hlwHoverCss(word) : ''
    );
  };

  // handleDoubleClick = (e) => this._onDoubleClick(e);

  handleReset = (e) => {
    if (!this.props.showPreview) {
      this.focusEditable();
    }
    if (typeof this.props.onReset === "function") {
      this.props.onReset(e);
    }
  };

  handleEditingStartClick = (e) => {
    e.stopPropagation();
    this.focusEditable();
  };

  handleEditingEndClick = (e) => {
    e.stopPropagation();
    const inEditing = this.isInEditingMode();
    if (inEditing) {
      this.props.onSubmit();
    }
    this.refEditingStart.current?.focus();
    this.forceUpdate();
  };

  handleEditingEndBlur = (e) => {
    if (typeof this.props.onEditingEndBlur === "function") {
      this.props.onEditingEndBlur(e);
    }
  };


  // handleFocusWithin = () => {
  //   console.log('handleFocusWithin');
  // };

  // handleBlurWithin = () => {
  //   console.log('handleBlurWithin');
  // };

  // handleMouseUp(e) {
  //   console.log('CACTCH click onMouseUp ', e.detail, e);
  // }

  // handleMouseDown(e) {
  //   console.log('CACTCH click onMouseDown ', e.detail, e);
  // }

  render() {
    const previewMode = this.isPreviewable();
    const html = this.renderEditableHtml(previewMode);
    const showWordCount = this.props.hasWhiteSpace || this.props.numOfWordsNotUnique > 1;
    const expanded = this.props.expanded;
    // const showOcr = this.props.isEmpty || previewMode;
    const inEditing = this.isInEditingMode();


    return (
      <>
        <div ref={this.refSourceArea}
          tabIndex={-1}
          onFocus={this.handleFocusWithin}
          onBlur={this.handleBlurWithin}
          className={classNames(
            expanded ? 'expanded' : null,
            baseClass,
            this.props.className,
            inEditing ? 'in-editing' : null,
            this.state.scrolled ? 'scrolled' : null,
            this.state.scrollable ? 'scrollable' : null,
            this.state.scrollEnd ? 'scrollend' : null,
            this.props.resultInstance ? 'has-result' : null,
            // this.props.numOfUniqueWords >= 2 ? 'has-multiple-unique-word' : null,
            this.props.error >= 2 ? 'has-error' : null
          )}
        >
          {/* {this.props.resultInstance || this.props.error ? ( */}

          {/* {showOcr ? (
            <OcrIcon pos="top" baseClass={baseClass} onClick={this.props.onOcrClick} tabIndex={expanded ? -1 : 0} />
          ) : null} */}
          <OcrIcon
            pos="bot"
            id="trs-icon-ocr-bot"
            baseClass={baseClass}
            tooltip={{placement: 'bottom-end'}}
            onClick={this.props.onOcrClick}
            tabIndex={inEditing ? -1 : -0}
          />

          {/* <span style={{position: 'absolute'}}>
            {inEditing ? 'inEditing' : 'not inEditing'}
          </span> */}
          {this.props.value || this.props.error ? (
            <AreaIcon
              className={`${baseClass}__reset`}
              id="trs-icon-reset"
              onClick={this.handleReset}
              tabIndex={this.state.inFocusSustained ? 0 : -1}
              tooltip={{placement: 'top-end'}}
              accessKey="q"
              icon="eraser"
              title={messages.get('TrsSourceArea.resetTitle')}
            />
          ) : null}

          <ContentEditable
            id={this.getId() + '_ContentEditable'}
            placeholder={this.props.placeholder}
            accessKey={"i"}
            role="textbox"
            aria-required="true"
            lang={this.props.sourceLang}
            aria-label={this.props.placeholder}
            aria-multiline={this.props.a11ymultiline ? "true" : "false"}
            ref={this.refContentEditable}
            innerRef={this.refContentEditableEl}
            html={html || ''} // innerHTML of the editable div
            disabled={false} // use true to disable editing
            onChange={this.handleChange} // handle innerHTML change
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onFocusIn={this.handleFocusIn}
            onFocusOut={this.handleFocusOut}
            onDoubleClick={this.handleDoubleClick}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            onPaste={this.handlePaste}
            onScroll={this.handleScroll || this.props.onScroll}
            tagName='pre' // Use a custom HTML tag (uses a div by default)
          />

          <AreaIcon
            className={`${baseClass}__editing-start`}
            id="trs-icon-editing-start"
            ref={this.refEditingStart}
            onClick={this.handleEditingStartClick}
            tooltip={{placement: 'top-start', autoHide: 1500}}
            tabIndex={inEditing ? -1 : 0}
            icon={'pen'} // pen-square | check
            title={messages.get('TrsSourceArea.editStart')}
          />

          <AreaIcon
            className={`${baseClass}__editing-end`}
            id="trs-icon-editing-end"
            ref={this.refEditingEnd}
            onClick={this.handleEditingEndClick}
            onBlur={this.handleEditingEndBlur}
            tooltip={{placement: 'top-start', autoHide: 1500}}
            tabIndex={inEditing ? 0 : -1}
            icon={this.props.submittable ? 'search' : 'check'} // pen-square | check
            title={messages.get(
              this.props.submittable ? 'TrsSourceArea.editSubmit' : 'TrsSourceArea.editEnd'
            )}
          />

          <WordCount
            baseClass={baseClass}
            active={showWordCount}
            numOfUniqueWords={this.props.numOfUniqueWords}
            numOfMaxWords={this.props.numOfMaxWords}
          />

          {/* <DoubleClickAbsorber baseClass={baseClass} active={previewMode} /> */}

        </div>
        <div className={baseClass + '__bottom'}>
          <SpecChars
            disabled={!this.state.inFocus}
            id={this.getId() + '_SpecChars'}
            mode="inline"
            hintCaretPosition={!this.hasSelection()}
            visible={this.props.specCharsVisible}
            onSelect={this.handleInsertSpecChar}
            onVisibilityChange={this.props.onSpecCharsVisibilityChange}
            baseClass={baseClass}
            lang={this.props.sourceLang}
          />
          <LineBreakMode
            id={this.getId() + '_lineBreakMode'}
            baseClass={baseClass}
            onSelect={this.props.enterKeyModeChange}
            wordsLen={this.props.numOfWordsNotUnique}
          ></LineBreakMode>
        </div>
      </>
    );
  }
}

TrsSourceArea.prototype._static = {
  instances: 0
};

TrsSourceArea.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  appPub: PropTypes.object.isRequired,
  sourceLang: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  showPreview: PropTypes.bool,
  a11ymultiline: PropTypes.bool,
  resultInstance: PropTypes.instanceOf(Results),
  loading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  submittable: PropTypes.bool,
  numOfWordsNotUnique: PropTypes.number,
  numOfUniqueWords: PropTypes.number,
  numOfMaxWords: PropTypes.number,
  expanded: PropTypes.bool,
  hasWhiteSpace: PropTypes.bool,
  dictId: PropTypes.string,
  error: PropTypes.bool,
  activeWord: PropTypes.string,
  activeWordIndex: PropTypes.number,
  innerRef: PropTypes.object,
  specCharsVisible: PropTypes.bool,
  onSpecCharsVisibilityChange: PropTypes.func,
  onEditingEndBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  onScroll: PropTypes.func,
  onBlur: PropTypes.func,
  onBlurLate: PropTypes.func,
  onOcrClick: PropTypes.func,
  onWordSelect: PropTypes.func,
  enterKeyModeChange: PropTypes.func,
  className: PropTypes.string
};



export default TrsSourceArea;
