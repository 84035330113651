
import PropTypes from "prop-types";
import React from 'react';
import ContentButton from '../../../components/contentButton';
import {firstBool} from '../../../lib/util';
import LoaderSpinner from "../../../components/misc/LoaderSpinner";

const FormButton = React.forwardRef(({
  defaults,
  extend,
  theme = "lightgrey",
  loading,
  disabled,
  isSubmit,
  className,
  id,
  defaultText = 'Tovább'
}, ref) => {

  const setup = {
    ...(defaults || {}),
    ...(extend || {})
  };

  // console.log(className, {defaults, extend, theme, isSubmit});
  const _disabled = firstBool(
    typeof loading === "boolean" && loading ? true : null,
    disabled,
    setup.disabled,
    false
  );

  return (
    (!setup.hidden && (isSubmit || setup.action)) ? <ContentButton
      ref={ref}
      tabIndex={0}
      className={className}
      id={id}
      onClick={setup.action || null}
      data={{
        type: isSubmit ? 'submit' : 'link',
        theme,
        disabled: _disabled
      }}
    >{setup?.text || defaultText}
      {loading
        ? <LoaderSpinner style={"inline-loading"} size="sm" variant={"light"} />
        : null
      }
    </ContentButton> : null
  );
});

FormButton.propTypes = {
  className: PropTypes.string,
  defaultText: PropTypes.string,
  defaults: PropTypes.object,
  extend: PropTypes.object,
  id: PropTypes.string,
  isSubmit: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  theme: PropTypes.string
};


export default FormButton;
