import PropTypes from "prop-types";
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import messages from "../../../../messages";
import classnames from "classnames";

const SpecCharsBlock = ({
  classSpace, onSelect, upperCaseActvie, onCaseToggle, chars, mode, disabled, visible
}) => {
  return (
    //  drop="down"
    <Dropdown
      className={
        classnames(
          classSpace,
          `${classSpace}--${mode}`,
          `${classSpace}--${visible ? 'visible' : 'hidden'}`,
          disabled ? 'disabled' : null
        )
      }
      // onToggle={onMenuToggle}
      disabled={disabled}
      drop="left" onSelect={onSelect}
      title={messages.get('TrsSourceArea.specialChars.title')}
    >
      <Dropdown.Toggle id="dropdown-specialChars" aria-label={messages.get('TrsSourceArea.specialChars.title')}>
        <i className="icon-left fas fa-keyboard"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu> {/* align="right" */}
        <Dropdown.Header>{messages.get('TrsSourceArea.specialChars.title')}
          <button
            disabled={disabled}
            className={classnames(
              classSpace + '__case',
              upperCaseActvie ? 'active' : null
            )}
            aria-pressed={upperCaseActvie}
            onClick={onCaseToggle}
            aria-label={upperCaseActvie
              ? messages.get('TrsSourceArea.specialChars.toLowerCase')
              : messages.get('TrsSourceArea.specialChars.toUpperCase')
            }
            title={upperCaseActvie
              ? messages.get('TrsSourceArea.specialChars.toLowerCase')
              : messages.get('TrsSourceArea.specialChars.toUpperCase')
            }
          >{upperCaseActvie ? 'ABC' : 'abc'}</button>
        </Dropdown.Header>
        {chars.map((c, i) => {
          const char = upperCaseActvie ? c.toUpperCase() : c;
          return (
            <Dropdown.Item
              disabled={disabled}
              className={classnames(classSpace + '__item')}
              eventKey={char}
              tabIndex="0" as={"button"} key={i}
            >{char}</Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};


SpecCharsBlock.propTypes = {
  chars: PropTypes.string,
  mode: PropTypes.string,
  classSpace: PropTypes.string,
  onCaseToggle: PropTypes.func,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  caseSwitchNeeded: PropTypes.bool,
  upperCaseActvie: PropTypes.bool
};

export default SpecCharsBlock;
