import React, {} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Preloader from './Preloader';
// import ContentParagraph from '../../components/contentParagraph';
// import {messages} from '../../messages';
import UserForm from '../form/Form';
import Layout from '../Layout';
import {LAYOUT_STATES} from './data/enums';
import { propTypeFormData } from '../form/data/proptypes';
import messages from '../../messages';
import "./_user.scss";


const headerButton = {
  href: "/",
  theme: "grimm-ghost",
  text: messages.get('user.form.backToHome')
};

const AuthRelatedPage = ({
  appPub,
  formData,
  onSubmit,
  fieldsState,
  loading,
  fieldsStateArgs,
  pageData,
  refForm,
  children
}) => {
  const baseClass = 'trs-user';
  const layoutState = LAYOUT_STATES.INITIAL;


  return (
    <Layout
      appPub={appPub}
      className={classNames(
        baseClass,
        loading ? 'child-loading' : null,
        `layout-state-${layoutState}`,
        'white-right'
      )}
      headerPrimaryButton={headerButton}
      contentLeft={(
        null
      )}
      contentRight={(
        <UserForm
          baseClass={baseClass}
          pageData={pageData}
          refForm={refForm}
          onSubmit={onSubmit}
          formData={formData}
          fieldsState={fieldsState}
          fieldsStateArgs={fieldsStateArgs}
        >
          {children}
        </UserForm>
      )}
    >
    </Layout>
  );
};

AuthRelatedPage.propTypes = {
  refForm: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  formData: propTypeFormData.isRequired,
  pageData: PropTypes.object,
  loading: PropTypes.bool,
  fieldsState: PropTypes.string,
  fieldsStateArgs: PropTypes.any,
  children: PropTypes.node,
  appPub: PropTypes.object.isRequired
};

export default AuthRelatedPage;
