import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const ContentButtons = ({
  _parentId,
  id,
  type,
  region,
  className,
  passedData = {},
  children,
  baseClass = "cmp-ContentButtons",
  items = [],
  data = {}
}) => {
  const {className: attrClassName, ...attrs} = data.attrs || {};

  return (
    <div
      data-id={id}
      id={id}
      className={classNames(baseClass, className, attrClassName)}
      {...attrs}
    >
      {children || null}
    </div>
  );
};



ContentButtons.propTypes = {
  _item: PropTypes.shape({
    getItemData: PropTypes.func
  }),

  _parentId: PropTypes.string,

  id: PropTypes.string,
  className: PropTypes.string,
  baseClass: PropTypes.string,
  type: PropTypes.string,
  region: PropTypes.string,
  passedData: PropTypes.object,
  children: PropTypes.node,
  data: PropTypes.shape({
    // @attrs: attribútumok
    attrs: PropTypes.object
  }),
  items: PropTypes.array

};

export default ContentButtons;
