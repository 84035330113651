// @ts-nocheck
/* eslint-disable react/prop-types */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import GrimmDragon from './branding/GrimmDragon';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import classNames from 'classnames';
import { getWindowWidth } from '../lib/util';
import { mobileBreakPoint } from '../setup';

const baseClass = "layout";

const DesktopLayout = ({
  contentLeft,
  contentLeftRole,
  contentRight,
  contentRightRole,
  contentHeaderChild,
  headerPrimaryButton,
  pageTitle,
  amedia,
  children
}) => (
  <>
    <PageHeader
      amedia={amedia}
      primaryButton={headerPrimaryButton}
      pageTitle={pageTitle}
    >
      {contentHeaderChild}
    </PageHeader>
    <div className={classNames(baseClass + '__bg', 'page-width')}>
      {/* <div className={classNames(baseClass + '__bg-in')}> */}
      <div className={classNames(baseClass + '__bg__side-l')}>
      </div>
      <div className={classNames(baseClass + '__bg__side-r')}>
        <div className={classNames(baseClass + '__bg__dragon-mask-l')}>
          <GrimmDragon color="#000" />
        </div>
        <div className={classNames(baseClass + '__bg__dragon-mask-r')}>
          <GrimmDragon color="#fff" />
        </div>
        {/* </div> */}
      </div>
    </div>

    <main className={`${baseClass}__content`}>
      <div className={`${baseClass}__content__left page-width`}>
        <div role={contentLeftRole} className={`${baseClass}__content__side-left`}>
          <div className={`${baseClass}__content__side-left-content`}>
            {contentLeft}
          </div>
        </div>
      </div>

      <div className={`${baseClass}__content__right page-width`}>
        <div role={contentRightRole} className={`${baseClass}__content__side-right`}>
          <div className={`${baseClass}__content__side-right-content`}>
            {contentRight}
          </div>
        </div>
      </div>
      <div className={`${baseClass}__content__side-bottom`}>
        <div className={`${baseClass}__content__side-bottom-content`}>
          {children}
        </div>
      </div>
    </main>
  </>
);

DesktopLayout.displayName = 'DesktopLayout';



const MobileLayout = ({
  contentTop,
  contentBot,
  children,
  headerPrimaryButton,
  contentHeaderChild,
  pageTitle
}) => (
  <>
    <main className={`${baseClass}__content`}>
      <div className={`${baseClass}__content__mobile-top page-width`}>
        <PageHeader
          pageTitle={pageTitle}
          primaryButton={headerPrimaryButton}
          isMobileView={true}
        >
          {contentHeaderChild}
        </PageHeader>
        {contentTop}
      </div>

      <div className={`${baseClass}__content__mobile-bot page-width`}>
        {contentBot}
        {children}
      </div>
    </main>
  </>
);

MobileLayout.displayName = 'MobileLayout';

const LayoutForMemo = ({
  amedia = false,
  mobileReverseSides = false,
  contentLeft = null,
  contentLeftRole = null,
  contentRight = null,
  pageTitle = null,
  contentRightRole = null,
  headerPrimaryButton = null,
  contentHeaderChild = null,
  className,
  children = null,
  appPub
}) => {

  const [clientWidth, setClientWidth] = useState(getWindowWidth());

  useEffect(() => {
    const onResize = (_e, width) => setClientWidth(width);
    appPub.eventEmitter.on('window.resize.debounced', onResize);
    return () => {
      appPub.eventEmitter.off('window.resize.debounced', onResize);
    };
  }, [appPub.eventEmitter]);

  const isMobile = clientWidth <= mobileBreakPoint;

  return (
    <div className={
      classNames(
        className,
        baseClass,
        isMobile ? 'mobile-view' : 'desktop-view',
        mobileReverseSides ? 'mobile-reversed' : 'mobile-not-reversed'
      )}>
      {isMobile ? (
        <MobileLayout
          pageTitle={pageTitle}
          contentHeaderChild={contentHeaderChild}
          headerPrimaryButton={headerPrimaryButton}
          contentTop={mobileReverseSides ? contentRight : contentLeft}
          contentTopRole={mobileReverseSides ? contentRightRole : contentLeftRole}
          contentBot={mobileReverseSides ? contentLeft : contentRight}
          contentBotRole={mobileReverseSides ? contentLeftRole : contentRightRole}
        >
          {children}
        </MobileLayout>
      ) : (
        <DesktopLayout
          pageTitle={pageTitle}
          headerPrimaryButton={headerPrimaryButton}
          contentHeaderChild={contentHeaderChild}
          contentLeft={contentLeft}
          contentLeftRole={contentLeftRole}
          contentRight={contentRight}
          contentRightRole={contentRightRole}
          amedia={amedia}
        >
          {children}
        </DesktopLayout>
      )
      }

      <PageFooter appPub={appPub} />
    </div>
  );
};

const Layout = React.memo(LayoutForMemo);

Layout.displayName = 'Layout';


LayoutForMemo.propTypes = {
  appPub: PropTypes.object,
  pageTitle: PropTypes.string,
  twoSideMode: PropTypes.bool,
  mobileReverseSides: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  contentHeaderChild: PropTypes.node,
  headerPrimaryButton: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }),
  contentLeft: PropTypes.node,
  contentRight: PropTypes.node
};

export default Layout;
