
import { debounce } from '../../lib/util';
import globals from '../../controller/globals';
import {PREVIEW_MODE} from '../../controller/devel';

export const userCheckMail = debounce((raw_email, { api, isMounted, toastErrorMsg }, fn) => {
  const email = (raw_email + '').trim().toLowerCase();

  api.getEndpointByAlias('user-check_login').fetch(
    { requestData: { params: { email } } },
    (status, data) => {
      const { /* email_resgistered_oldsystem, */ regstatus } = data || {};
      if (!isMounted()) return;
      let res = {
        bothState: null,
        bothStateArgs: null
      };

      if (status === api.ENUM.aborted) {
        return res;
      }
      else if (status !== api.ENUM.success) {
        const msg = api.extractErrorMsg(data, true);
        toastErrorMsg(msg, 'user-check_login_api_error');
        res = {
          bothState: 'apiError',
          bothStateArgs: [{msg: msg}]
        };
      }
      else if (data.error) {
        res = {
          bothState: 'emailNotValid',
          bothStateArgs: [{msg: api.extractErrorMsg(data, true) }]
        };
      }
      else {
        switch (regstatus) {
          case "EMAIL_NOT_AVAILABLE":
            res = {bothState: 'emailRegistrationDisabled'};
            break;
          case "EMAIL_REGISTRATION_ALLOWED":
            res = {bothState: 'emailNotRegistered'};
            break;
          case "EMAIL_REGISTERED_NOT_ACTIVATED":
            res = {bothState: 'emailRegisteredButNotActivated'};
            break;
          case "EMAIL_REGISTERED_ACTIVATED":
            res = {bothState: 'emailRegistered'};
            break;
          default:
            console.warn('Unexpected e-mail status', regstatus);
            break;
        }
      }

      fn({
        email,
        fieldsState: res.fieldsState || res.bothState || null,
        fieldsStateArgs: res.fieldsStateArgs || res.bothStateArgs || null,
        fieldState: res.fieldState || res.bothState || null,
        fieldStateArgs: res.fieldStateArgs || res.bothStateArgs || null
      });
    }, true);
}, 200);



export const userCheckCode = debounce((
  raw_code,
  action = 'ACTIVATE', // || PASSWORD
  { api, isMounted, toastErrorMsg },
  fn
) => {
  const code = (raw_code + '').trim().toUpperCase();

  api.getEndpointByAlias('user-validatecode').fetch(
    {
      requestData: {
        params: { code, action }
      }
    },
    (status, data) => {
      const { sessionMatch } = data || {};
      if (!isMounted()) return;
      let res = {
        bothState: null,
        bothStateArgs: null
      };

      if (status === api.ENUM.aborted) {
        return res;
      }
      else if (status !== api.ENUM.success) {
        const msg = api.extractErrorMsg(data, true);
        toastErrorMsg(msg, 'user-validatecode_api_error');
        res = {
          bothState: 'apiError',
          bothStateArgs: [{msg: msg}]
        };
      }
      else if (data.error) {
        res = {
          bothState: !data.valid ? 'invalid_code' : 'apiError',
          bothStateArgs: [{msg: api.extractErrorMsg(data, true) }]
        };
      }
      else {
        res = {bothState: sessionMatch ? 'sessionMatch' : 'sessionNotMatch'};
      }

      fn({
        code,
        fieldsState: res.fieldsState || res.bothState || null,
        fieldsStateArgs: res.fieldsStateArgs || res.bothStateArgs || null,
        fieldState: res.fieldState || res.bothState || null,
        fieldStateArgs: res.fieldStateArgs || res.bothStateArgs || null
      });
    }, true);
}, 200);


export const logoutHelper = ({notify = true, redirect = true} = {}, callback) => {
  const appPub = globals.get('appPub');
  const {api} = appPub;

  api.getEndpointByAlias('user-logout').fetch(
    { requestData: { params: { } } },
    (status, data) => {

      if (status !== api.ENUM.success) {
        const msg = api.extractErrorMsg(data);
        appPub.toastErrorMsg(msg, 'user-logout');
        callback(false, null);
        return;
      }
      if (data.error) {
        callback(false, data);
        if (notify) {
          appPub.toastErrorMsg(data.errormsg, 'user-logout');
        }
        else {
          PREVIEW_MODE && console.warn('user-logout', data.errormsg);
        }
      }
      else {
        if (typeof callback === 'function') {
          callback(true, data);
        }
        appPub.logout(data.user, redirect, notify);
      }
    }, true);
};
