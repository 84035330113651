import { PREVIEW_MODE } from "../../../controller/devel";
import classNames from "classnames";
import messages from "../../../messages";


const crDiv = ({tagName = 'div', className, innerHTML}) => {
  const _el = document.createElement(tagName);
  if (className) _el.className = className;
  if (innerHTML) _el.innerHTML = innerHTML;
  return _el;
};

const crSenseParent = (className) => crDiv({
  className: classNames(
    'dw-sense-parent-from-flat',
    className ? 'dw-parent-' + className : null
  )
});

// egbullet wrapper
// egbullet vagy egorth (de csak első alkalommal) új szülőt nyit.
let fixFlatSenseParentI = 0;
const fixFlatSenseParent = (elSenseParent) => {
  elSenseParent.classList.add('dw-sense-parent-from-flat--' + fixFlatSenseParentI++);
  try {
    [...elSenseParent.childNodes].reduce((prev, node) => {
      if (node.nodeType === 1 && (
        (node.classList.contains('egorth') && !prev.elEgWrp) ||
        node.classList.contains('egbullet')
      )) {
        prev.elEgWrp = crDiv({
          className: 'dw-eg-wrp',
          tagName: 'p',
          innerHTML: !prev.elEgWrp ? '<span class="egbullet"> ∗</span>' : null
        });
        elSenseParent.insertBefore(prev.elEgWrp, node);
      }
      if (prev.elEgWrp) {
        prev.elEgWrp.appendChild(node);
      }
      return prev;
    }, {
      elEgWrp: null
    });
  }
  catch (error) {
    PREVIEW_MODE && console.warn('fixFlatSenseParent failed', {error, elSenseParent});
  }

  return elSenseParent;
};

// azoknál a szótáraknál, ahol a szócikkekbben a jelentések és a kifejezések egyszinten vannak
// ömlesztve egymás után, szülőkre szeparálja őket
// új jelentés szülőt "sensenum" class-ű elem nyit meg
// egy jelentés szülőt bezárhat egy br vagy egy kifejezés, vagy a szócikk vége vagy pventry
// egy kifejezés szülőt phrasebullet nyit meg
// egy kifejezés szülőt bezárhat egy br vagy egy új szócikk kezdete vagy pventry
// a kifejezés szülők és a jelentés szülők wrapperbe kerülnek

const fixFlatEntry = (elEntry, className = "phraseme", headline = "") => {
  if (!elEntry || elEntry.nodeType !== 1) return;
  [...elEntry.childNodes].reduce((prev, node, index, nodes) => {
    if (node.nodeType === 1 && node.classList.contains('sensenum')) {
      if (!prev.elSenses) {
        prev.elSenses = 0;
        prev.elSenses = crDiv({
          className: 'dw-senses dw-senses--phraseme'
        });
        elEntry.insertBefore(prev.elSenses, node);
      }
      if (prev.elSenseParent) fixFlatSenseParent(prev.elSenseParent);
      prev.elSenseParent = crSenseParent(className);
      prev.elSenses.appendChild(prev.elSenseParent);
      prev.elPhrases = null;
      prev.elPhraseParent = null;
    }
    if (prev.elSenseParent) {
      if (node.nodeType === 1 && (
        node.tagName === 'BR' || node.classList.contains('phrasebullet') || node.classList.contains('pvblock')
      )) {
        if (prev.elSenseParent) fixFlatSenseParent(prev.elSenseParent);
        prev.elSenseParent = null;
      }
      else {
        prev.elSenseParent.appendChild(node);
      }
    }

    if (node.nodeType === 1 && node.classList.contains('phrasebullet')) {
      if (!prev.elPhrases) {
        prev.elPhrases = crDiv({
          className: 'dw-phrases dw-phrases--' + className,
          innerHTML: headline ? `<h4 class="dw-phrases__headline">${headline}</h4>` : ''
        });
        elEntry.insertBefore(prev.elPhrases, node);
      }
      prev.elPhraseParent = crSenseParent(className);
      prev.elPhrases.appendChild(prev.elPhraseParent);
      prev.elSenses = null;
      if (prev.elSenseParent) fixFlatSenseParent(prev.elSenseParent);
      prev.elSenseParent = null;
    }
    if (prev.elPhraseParent) {
      if (node.nodeType === 1 && (
        node.tagName === 'BR' || node.classList.contains('sensenum') || node.classList.contains('pvblock')
      )) {
        prev.elPhraseParent = null;
      }
      else {
        prev.elPhraseParent.appendChild(node);
      }
    }

    // others
    if (index === nodes.length - 1 && prev.elSenseParent) {
      fixFlatSenseParent(prev.elSenseParent);
    }
    if (node.nodeType === 1 && node.classList.contains('pvblock')) {
      try {
        // Frazális igék
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        node.querySelectorAll('.pventry').forEach(fixFlatEntryPhrasal);
      }
      catch (error) {
        PREVIEW_MODE && console.warn('fixFlatEntry.recursion.call', {error});
      }
    }
    return prev;
  }, {
    elSenses: null,
    elSenseParent: null,
    elPhrases: null,
    elPhraseParent: null
  });
  return elEntry;
};

const fixFlatEntryPhrasal = (elEntry) => fixFlatEntry(
  elEntry,
  'phrasal-verb',
  messages.get('resultEntry.pventryLabel')
);

const fixFlatStructure = (frag, clone = true, entrySelector = '.GrimmDict > .entry') => {
  if (frag.querySelector('.GrimmDict > .entry > .sensenum') !== null) { // isFlatStructure
    try {
      const fragCloned = clone ? frag.cloneNode(true) : frag;
      const elEntry = entrySelector ? fragCloned.querySelector(entrySelector) : fragCloned;
      fixFlatEntry(elEntry);
      fragCloned.firstElementChild.classList.add('dw-fixed-flat-structure');
      return fragCloned;
    }
    catch (error) {
      PREVIEW_MODE && console.warn('fixFlatStructure.fixFlatEntry failed', {error, frag});
    }
  }
  else if (frag.querySelector('.GrimmDict > .entry > .egorth') !== null) {
    try {
      const fragCloned = clone ? frag.cloneNode(true) : frag;
      const elEntry = entrySelector ? fragCloned.querySelector(entrySelector) : fragCloned;
      elEntry.replaceWith(fixFlatSenseParent(elEntry));
      fragCloned.firstElementChild.classList.add('dw-fixed-flat-structure');
      return fragCloned;
    }
    catch (error) {
      PREVIEW_MODE && console.warn('fixFlatStructure.fixFlatSenseParent failed', {error, frag});
    }
  }
  return frag;
};

const groupPhraseBulletAndHisNextSibling = (el) => {
  if (!el || !el.parentNode || el.parentNode.classList.contains('entry')) {
    return;
  }
  el.parentNode.classList.add('dw-bullet-parent');
  el.parentNode.setAttribute('role', 'list');

  const nextNode = el.nextSibling;
  const nextEl = el.nextElementSibling;
  if (nextNode !== null || nextEl !== null) {
    const elGrp = document.createElement('span');
    elGrp.classList.add('dw-bullet-grp');
    elGrp.setAttribute('role', 'listitem');
    el.parentNode.insertBefore(elGrp, el);
    if (nextNode !== null) {
      elGrp.appendChild(nextNode);
    }
    if (nextEl !== null && nextNode !== nextEl) {
      elGrp.appendChild(nextEl);
    }
    if (elGrp.firstChild) {
      elGrp.insertBefore(el, elGrp.firstChild);
    }
    else {
      elGrp.appendChild(el);
    }
  }
};

const fixPhrasesWithBullet = (frag) => {
  const elsBullet = frag?.querySelectorAll('.phrasebullet');
  elsBullet?.forEach(groupPhraseBulletAndHisNextSibling);
  return frag;
};

export {
  fixPhrasesWithBullet,
  fixFlatStructure
};
