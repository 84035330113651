export const formDataExtend = (step, {fields, ...rest}) => {
  const re = { ...step, ...rest, fields: [...step.fields] };

  if (fields && fields.length) {

    for (let i = 0; i < re.fields.length; i++) {
      const field = re.fields[i];

      for (let x = 0; x < fields.length; x++) {
        const extenderField = fields[x];
        if (extenderField.name === field.name) {
          re.fields.splice(i, 1, { ...field, ...extenderField });
          break;
        }
      }
    }
  }
  return re;
};
