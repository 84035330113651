import React from 'react';
import ContentParagraph from '../../../components/contentParagraph';
import {messages} from '../../../messages';
import PropTypes from 'prop-types';


const MaxWordNotify = React.memo(({numOfUniqueWords, numOfMaxWords, hasResult}) => (
  numOfUniqueWords > numOfMaxWords ? (
    <ContentParagraph data={{
      theme: 'warning-slim'
    }}>{
        hasResult
          ? messages.get('tr.noteNummOfwordsExceeded', {numOfMaxWords})
          : messages.get('tr.noteNumOfwordsWillExceed', {numOfMaxWords})
      }
    </ContentParagraph>
  ) : null
));

MaxWordNotify.propTypes = {
  numOfUniqueWords: PropTypes.number,
  numOfMaxWords: PropTypes.number,
  hasResult: PropTypes.bool
};

MaxWordNotify.displayName = 'MaxWordNotify';

export default MaxWordNotify;
