import PropTypes from "prop-types";
import React, {useCallback, useRef, useState, useEffect} from 'react';
import messages from "../../../../messages";
import classnames from "classnames";
import TooltipWrapper from '../../../../components/misc/TooltipWrapper';
import SpecCharsInlineButton from "./SpecCharsInlineButton";
import {keyCodes} from "../../../../lib/util";

const charToUpperCase = (char) => {
  if (Array.isArray(char)) {
    return [char[0].toUpperCase(), char[1]];
  }
  return char.toUpperCase();
};

const SpecCharsInline = ({
  classSpace,
  onSelect,
  upperCaseActvie,
  onCaseToggle,
  hintCaretPosition = false,
  id,
  chars,
  lang,
  mode,
  disabled,
  caseSwitchNeeded,
  onVisibilityChange,
  visible
}) => {
  const refToggler = useRef(null);
  const refFirstChar = useRef(null);
  const refLastChar = useRef(null);
  const refShiftKey = useRef(null);
  const refLastFocusedChar = useRef(null);
  const [focusFirstChar, setFocusToFirstItem] = useState(false);
  useEffect(() => {
    if (focusFirstChar && visible) {
      setFocusToFirstItem(false);
      refFirstChar.current?.focus();
    }
  }, [focusFirstChar, visible]);

  const onTogglerClickOrKeyDown = useCallback(e => {
    if (e.type === "keydown" && (e.which === keyCodes.ESC && visible)) {
      onVisibilityChange(e, false);
      refToggler.current?.focus();
      e.preventDefault();
    }
    if (e.type === "keydown" && (e.which === keyCodes.DOWN && visible)) {
      refFirstChar.current?.focus();
      e.preventDefault();
    }
    if (e.type === "keydown" && (e.which === keyCodes.UP && visible)) {
      refLastChar.current?.focus();
      e.preventDefault();
    }
    if (e.type !== "keydown" || (e.type === "keydown" && (
      (e.which === keyCodes.ENTER || e.which === keyCodes.SPACE) ||
      (e.which === keyCodes.DOWN && !visible)
    )
    )) {
      e.preventDefault();
      if (typeof onVisibilityChange === 'function') {
        onVisibilityChange(e);
      }
      if (e.type === "keydown") {
        setFocusToFirstItem(true);
      }
    }
  }, [onVisibilityChange, visible]);


  const onButtonClickOrKeyDown = useCallback((e) => {
    const char = e.target.getAttribute('data-char');
    if (e.type === "keydown" && (
      (e.which === keyCodes.ESC && visible) ||
      (e.which === keyCodes.TAB && !e.shiftKey && e.target === refLastChar.current)
    )) {
      onVisibilityChange(e, false);
      refToggler.current?.focus();
      e.preventDefault();
    }
    if (e.type === "keydown" && (
      (e.which === keyCodes.UP && e.target === refFirstChar.current) ||
      (e.which === keyCodes.DOWN && e.target === refLastChar.current)
    )) {
      refToggler.current?.focus();
      e.preventDefault();
    }
    if (e.type === "keydown" && (e.which === keyCodes.DOWN || e.which === keyCodes.RIGHT)) {
      e.preventDefault();
      e.target.nextElementSibling?.focus();
    }
    if (e.type === "keydown" && (e.which === keyCodes.UP || e.which === keyCodes.LEFT)) {
      e.preventDefault();
      e.target.previousElementSibling?.focus();
    }

    if (e.type !== "keydown" || (e.type === "keydown" && (
      e.which === keyCodes.ENTER || e.which === keyCodes.SPACE)
    )) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (typeof onSelect === 'function') {
        onSelect(char, e);
      }
    }
  }, [onSelect, onVisibilityChange, visible]);


  const onButtonFocus = useCallback((e) => {
    refLastFocusedChar.current = e.target;
  }, []);


  const tooltip = visible
    ? messages.get('TrsSourceArea.specialChars.close')
    : messages.get('TrsSourceArea.specialChars.title')
  ;
  const menuId = id + '-menu';
  const buttonId = id + '-toggler';

  return (
    <div
      className={
        classnames(
          classSpace,
          `${classSpace}--${mode}`,
          chars.length > 24 ? `${classSpace}--manychar` : null,
          `${classSpace}--lang-${lang}`,
          `${classSpace}--${visible ? 'visible' : 'hidden'}`,
          disabled ? 'disabled' : null
        )
      }
    >
      <div className={`${classSpace}__header`}>
        <TooltipWrapper
          id={`dict-specialChars-keyboard`}
          placement={"top-start"}
          title={tooltip}
        >
          <button
            className={`${classSpace}__keyboard`}
            onClick={onTogglerClickOrKeyDown}
            onKeyDown={onTogglerClickOrKeyDown}
            ref={refToggler}
            id={buttonId}
            aria-controls={menuId}
            aria-pressed={visible ? 'true' : 'false'}
            // title={visible ? messages.get('TrsSourceArea.specialChars.close') : null}
            aria-haspopup="menu"
            aria-label={
              visible
                ? messages.get('TrsSourceArea.specialChars.titleClose')
                : messages.get('TrsSourceArea.specialChars.titleOpen')
            }
          >
            <i className="icon-left fas fa-keyboard"></i>
            <i className="icon-left close-x fas fa-times-circle"></i>
            {/* <i className="icon-left close-x fas fa-window-close"></i> */}
            {/* <i className={`icon-left fas fa-${visible ? 'window-close' : 'keyboard'}`}></i> */}
          </button>
        </TooltipWrapper>

        {caseSwitchNeeded ? (
          <TooltipWrapper
            id={`dict-specialChars-capslock`}
            placement="bottom"
            title={upperCaseActvie
              ? messages.get('TrsSourceArea.specialChars.toLowerCase')
              : messages.get('TrsSourceArea.specialChars.toUpperCase')
            }
          >
            <button
              className={classnames(
                classSpace + '__case',
                upperCaseActvie ? 'active' : null
              )}
              aria-controls={menuId}
              aria-pressed={upperCaseActvie}
              onClick={onCaseToggle}
              ref={refShiftKey}
              aria-label={upperCaseActvie
                ? messages.get('TrsSourceArea.specialChars.toLowerCase')
                : messages.get('TrsSourceArea.specialChars.toUpperCase')
              }
            >
              {upperCaseActvie ? 'ABC' : 'abc'}
            </button>
          </TooltipWrapper>
        ) : null}

      </div>
      <TooltipWrapper
        id={`dict-specialChars-body`}
        placement="bottom"
        title={
          disabled ? (
            hintCaretPosition
              ? messages.get('TrsSourceArea.specialChars.noFocus')
              : ''
          ) : null
        }
      >
        <div
          className={`${classSpace}__body`}
          id={menuId}
          role="menu"
          aria-label={messages.get('TrsSourceArea.specialChars.menu')}
          aria-orientation="horizontal"
        >
          {chars.map((c, i) => {
            const char = upperCaseActvie ? charToUpperCase(c) : c;
            return (
              <SpecCharsInlineButton
                key={i}
                classSpace={classSpace}
                disabled={disabled}
                ref={i === 0 ? refFirstChar : (i === chars.length - 1 ? refLastChar : null)}
                char={char}
                ariadescribedby={hintCaretPosition ? `dict-specialChars-body` : null}
                ariadisabled={hintCaretPosition && disabled}
                onFocus={onButtonFocus}
                onClick={onButtonClickOrKeyDown}
                onKeyDown={onButtonClickOrKeyDown}
              />
            );
          })}
        </div>
      </TooltipWrapper>

    </div>
  );
};

SpecCharsInline.propTypes = {
  chars: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])),
  id: PropTypes.string,
  mode: PropTypes.string,
  lang: PropTypes.string,
  classSpace: PropTypes.string,
  onCaseToggle: PropTypes.func,
  onSelect: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  hintCaretPosition: PropTypes.bool,
  caseSwitchNeeded: PropTypes.bool,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  upperCaseActvie: PropTypes.bool
};

export default SpecCharsInline;
