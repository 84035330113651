const el_liveBind = function el_liveBind(
  element: HTMLElement,
  event: string, descendentSelector: string,
  callback: Function,
  evtOpts?: boolean | AddEventListenerOptions
) {
  // console.log('BIND', {
  //   element, event, descendentSelector, callback, evtOpts
  // })
  const eventNames = event.trim().split(/\s+/);
  evtOpts = typeof evtOpts !== "undefined" ? evtOpts : { passive: false };

  function listener(e: Event) {
    const target = e.target as HTMLTextAreaElement
    const elem = descendentSelector ? target.closest(descendentSelector) : element;
    if (elem && typeof callback === 'function') {
      callback.call(elem, e, elem, unbind);
    }
  }

  function unbind(e: Event) {
    for (let i = 0; i < eventNames.length; i++) {
      element.removeEventListener(eventNames[i], listener);
    }
  }

  for (let i = 0; i < eventNames.length; i++) {
    element.addEventListener(eventNames[i], listener, evtOpts);
  }

  return unbind;
};

export default el_liveBind;
