import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentLink from './contentLink';
import faIconMapper from '../lib/faIconMapper';
// import globals from '../controller/globals';

const ContentHeadline = ({
  _parentId,
  id,
  type,
  passedData = {},
  baseClass = "cmp-ContentHeadline",
  className,
  children,

  region,
  items,
  data
}) => {
  const {
    text,
    link,
    level,
    seoLevel,
    prefix,
    suffix,
    iconLeft,
    iconRight
  } = data || {};

  const {className: attrClassName, ...attrs} = data?.attrs || {};
  const tagLevel = seoLevel || level || 2;
  const Tag = tagLevel === 0 ? 'div' : `h${tagLevel}`;

  return (
    <Tag
      className={classNames(baseClass, `hl-${level || seoLevel || 2}`, className, attrClassName)}
      id={id || null}
      data-id={id || null}
      data-seolevel={seoLevel}
      data-level={level}
      {...attrs}>
      {prefix || null}
      {(link && (link.href || link.iconLeft || link.iconRight || iconLeft || iconRight)) ? (
        <ContentLink
          id={id ? id + '__link' : null}
          className={baseClass + '__link'}
          passedData={passedData}
          data={{
            iconLeft,
            iconRight,
            ...(link || {}),
            text
          }}
        >{text || children}</ContentLink>
      ) : (
        <>
          {(iconLeft ? <i className={'icon-left ' + faIconMapper(iconLeft)}></i> : null)}
          {text || children}
          {(iconRight ? <i className={'icon-right ' + faIconMapper(iconRight)}></i> : null)}
        </>
      )}
      {suffix || null}
    </Tag>
  );
};



ContentHeadline.propTypes = {
  _item: PropTypes.shape({
    getItemData: PropTypes.func
  }),
  _parentId: PropTypes.string,

  id: PropTypes.string,
  type: PropTypes.string,
  region: PropTypes.string,
  baseClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  passedData: PropTypes.object,
  data: PropTypes.shape({
    // @text: csak szöveget tartalmazhat,
    text: PropTypes.node,
    // @level: H1, H2, H3, stb méretű vizuális megjelenés
    level: PropTypes.number,
    // @seoLevel: H1, h2, H3 stb tag a html-ben. SeoLevel === 0: div lesz a htmlben h(n) tag helyett
    seoLevel: PropTypes.number,
    // @link: amennyiben a cím linekelve van, a contentLink komponens data paraméterei adhatóak át
    link: PropTypes.object,
    // @iconLeft HL bal oldalán megjelenő ikon font neve
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    // @attrs: elem attribútumok, (href, target felülírja a fent megadottat)
    attrs: PropTypes.object
  }).isRequired,
  items: PropTypes.array
};

export default ContentHeadline;
