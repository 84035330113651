import { ttsMaxLength, nativeLang } from "../../../setup";

export default [
  [
    '{tts_service_url}/voices',
    {
      method: 'GET',
      alias: 'tts-voices',
      flags: ['private', 'preload'],
      storeData: {
        ttsVoices: {
          as: 'ttsVoices'
        }
      },
      publicPath: null
    }
  ],

  [
    '{tts_service_url}/tts/{voice}/{text}',
    {
      method: 'GET',
      alias: 'tts-text-to-voice',
      flags: ['private'],
      dependency: ['tts-voices'],
      requiredRestData: {
        "voice": {type: "string", oneOf: 'ttsVoices.name'},
        "text": {type: "string", maxLenth: ttsMaxLength }
      },
      publicPath: null
    }
  ],

  [
    '{tts_service_url}/tts/',
    {
      method: 'POST',
      alias: 'tts-text-to-voice-post',
      flags: ['private'],
      dependency: ['tts-voices'],
      requiredRequestData: {
        "voice": {type: "string", oneOf: 'ttsVoices.name'},
        "text": {type: "string", maxLenth: ttsMaxLength }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'freedict-dict',
      flags: ['private'],
      defaultRequestData: {
        method: 'dict',
        model: "freedict",
        params: {
          xml: "0",
          html: "1",
          // fmt: "1", // css 2 = részletes json
          entry: "1",
          //  0: csak a címszavak a tartalom nélkül
          //  1: címszavak tartalommal együtt (default)
          compact: "0",
          //  1: példák helyén "[...]" lesz, tehát nincs példa
          //  0: mutassa a példákat
          nosub: "1",
          //  0: mutassa a példákat külön találatonkként
          //  1: ne tegye ezt
          expr: "1", // 0 volt, 2022.10.26 - issue gastro olasz magyar - fondo - eltér a régi felülettől
          //  0: csak szavakban (default)
          //  1: szavakban és kifejezésekben
          //  2: csak kifejezésekben)

          subentry: "0"
          // - show examples as different results (useful with entry=1)
          // régiben volt:

        }
      },
      requiredRequestData: {
        params: {
          "words": {type: "array" },
          "dict": {type: "array", oneOf: 'dictList.id' }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'freedict-dictlist',
      flags: ['private', 'preload'],
      storeData: {
        dictlist: {
          as: 'dictList',
          transform: (v) => (typeof v === "object" ? Object.values(v) : v).filter((d) => {
            if (d.weight) { d.weight -= 0; }

            if (d.featured) {
              d.featured = d.featured === "1" || d.featured === true;
            }
            if (d.free) {
              d.free = d.free === '1' || d.free === true;
            }
            if (d.usable) {
              d.usable = d.usable === '1' || d.usable === true;
            }
            if (d.unidirectional) {
              d.unidirectional = d.unidirectional === '1' || d.unidirectional === true;
            }

            d.foreignLang = d.src === nativeLang ? d.trg : d.src;
            d.foreignDesc = d.src === nativeLang ? d.trg_desc : d.src_desc;

            return d.usable === "1" || d.usable === true;
            // return d.id.indexOf("-tan") > -1;
          }),
          transformOverwriteData: true
        }
      },
      defaultRequestData: {
        method: 'dictlist',
        // method: 'dictlistdb',
        model: "freedict"
        // model: "listdicts"
      },
      requiredRequestData: {},
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      multipartFormData: true,
      alias: 'freedict-ocrupload',
      flags: ['private'],
      defaultRequestData: {
        method: 'ocrupload',
        model: "freedict"
      },
      requiredFormData: {
        image: {instanceof: "Blob" }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      multipartFormData: true,
      alias: 'freedict-ocrupload-base64',
      flags: ['private'],
      defaultRequestData: {
        method: 'ocrupload',
        model: "freedict"
      },
      requiredRequestData: {
        params: {
          "image": {instanceof: "DataUrl" }
        }
      },
      publicPath: null
    }
  ]
];
