import React from 'react';
import ContentButton from '../../../components/contentButton';
import {messages} from '../../../messages';
import PropTypes from 'prop-types';
import {sourceAreaSetup} from '../../../setup';


const FormSubmit = React.memo(({baseClass, submittable}) => (
  <div className={`${baseClass}__submit-wrp`}>
    <ContentButton
      tabIndex={0}
      className={`${baseClass}__submit`}
      id="trs-dict-submit"
      data={{
        type: 'submit',
        attrs: {
          accessKey: "k",
          title: submittable
            ? (
              messages.get('tr.submittable') +
              (sourceAreaSetup.enterKeyMode ? '' : ' (CTRL+ENTER)')
            )
            : messages.get('tr.warnNotSubmittable')
        },
        disabled: !submittable,
        theme: "grimm-orange-big"
      }}
    >Szótáraz</ContentButton>
  </div>
));

FormSubmit.displayName = 'FormSubmit';


FormSubmit.propTypes = {
  baseClass: PropTypes.string.isRequired,
  submittable: PropTypes.bool.isRequired
};

export default FormSubmit;
