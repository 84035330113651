import { glindow } from "./globals";

const BroadcastChannel = glindow.BroadcastChannel;
const channels = {};

const onMessageError = (event) => {
  console.warn('Broadcast message error', event);
};

const broadcastChannels = {
  _newChannel(channelId) {
    const channel = new BroadcastChannel(channelId);
    channel.addEventListener('messageerror', onMessageError);
    return channel;
  },
  _getChannel(channelId) {
    if (BroadcastChannel) {
      return channels[channelId] || this._newChannel(channelId);
    }
    return null;
  },

  get(channelId) {
    return this._getChannel(channelId);
  },

  close(channelId) {
    const channel = this._getChannel(channelId);
    if (channel) {
      channel.removeEventListener('messageerror', onMessageError);
      channel.close();
      delete channels[channelId];
    }

  },
  postMessage(channelId, msg) {
    const channel = this._getChannel(channelId);
    if (channel) {
      channel.postMessage(msg);
    }
  },
  onMessage(channelId, callback) {
    const channel = this._getChannel(channelId);
    if (channel) {
      channel.addEventListener('message', callback);
    }
  },
  offMessage(channelId, callback) {
    const channel = this._getChannel(channelId);
    if (channel) {
      channel.removeEventListener('message', callback);
    }
  }
};



export default broadcastChannels;
