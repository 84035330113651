/* eslint-disable no-use-before-define */

import React, {useRef, useReducer, useState, useContext} from 'react';

import PropTypes from 'prop-types';
import AuthRelatedPage from "./AuthRelatedPage";
import { UserContext } from '../../Contexts';
import ContentButton from '../../components/contentButton';
import ContentButtons from '../../components/contentButtons';
import * as schemes from './data/schemes';
import { formDataExtend } from './data/formDataHelper';
import messages from '../../messages';
import DeleteUserModal from './components/DeleteUserModal';

function reducer(state, {type, payload}) {
  switch (type) {
    case 'page':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}

const formData = {
  formId: 'user-profile',
  title: messages.get('user.PageProfile.title'),
  primaryBtn: {text: 'Tovább', disabled: true, hidden: true },
  desc: (
    <>
    </>
  ),
  warn: null,

  fields: [
    {
      name: "username",
      label: messages.get('user.fields.username'),
      autoComplete: "username",
      type: 'email',
      schema: schemes.username,
      readOnly: true,
      helperText: null
    }
  ]
};


const PagePwRequest = ({appPub, history, location, ...props}) => {
  const refForm = useRef();
  const {email} = appPub.getLogin();
  const userData = useContext(UserContext);

  // const setFieldsState = useCallback((fieldsState, fieldsStateArgs = null) => {
  //   setState({ ...state, fieldsState, fieldsStateArgs });
  // });

  const [state, dispatch] = useReducer(reducer, {
    fieldsState: null,
    fieldsStateArgs: null,
    loading: false,
    pageData: {
    },
    currentForm: (
      formDataExtend(formData, {
        fields: [
          {
            name: 'username',
            defaultValue: email
          }
        ]
      })
    )
  });


  const onSubmit = (e) => {
    // console.log('submit');
  };

  const [showUserDelete, setShowUserDelete] = useState(false);

  // console.log('APP PROPS', props);
  return (
    <>
      <AuthRelatedPage
        appPub={appPub}
        // appProps={props}
        loading={state.loading}
        refForm={refForm}
        onSubmit={onSubmit}
        fieldsState={state.fieldsState}
        fieldsStateArgs={state.fieldsStateArgs}
        formData={state.currentForm}
        pageData={state.pageData}
      >
        <DeleteUserModal
          appPub={appPub}
          history={history}
          show={showUserDelete}
          onHide={() => setShowUserDelete(false)}
        />
        <ContentButtons>
          <ContentButton
            tabIndex={0}
            id="trs-user-reset-pw"
            data={{
              href: `/user/forgot-password/?username=${userData.email}`,
              theme: "inline-link"
            }}
          >{messages.get('user.PageProfile.passwordReset')}</ContentButton>

          <ContentButton
            tabIndex={0}
            onClick={() => setShowUserDelete(true)}
            id="trs-user-delete-user"
            data={{
              theme: "inline-link"
            }}
          >{messages.get('user.PageProfile.deleteUserProfileBtn')}</ContentButton>
        </ContentButtons>

      </AuthRelatedPage>
    </>
  );
};

PagePwRequest.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PagePwRequest;
