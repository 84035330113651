import React, {useReducer, useCallback, useContext} from 'react';
import ContentHeadline from '../../components/contentHeadline';
import ContentParagraph from '../../components/contentParagraph';
import Form from 'react-bootstrap/Form';
import ContentInput from '../../components/contentInput';
import { useForm } from 'react-hook-form';
import { nopeResolver } from '@hookform/resolvers/nope';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Nope from 'nope-validator';
import { propTypeFormData } from './data/proptypes';
import { callDeepProp } from '../../lib/objects/deepOps';
import { objectFromPairs } from '../../lib/objects/objectFromPairs';
import FormButton from '../user/components/FormButton';
import messages from '../../messages';
import { UserContext } from '../../Contexts';
import "./_form.scss";

function reducer(state, {type, payload}) {
  switch (type) {
    case 'formState':
      return { ...state, ...payload };
    case 'statesOfFields': {
      const {name, fieldState, fieldStateArg} = payload;
      return {
        ...state,
        statesOfFields: {
          ...(state.statesOfFields || {}),
          [name]: {fieldState, fieldStateArg}
        }
      };
    }
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}

const FormContent = ({
  baseClass,
  onSubmit,
  formData,
  pageData,
  fieldsState,
  loading,
  fieldsStateArgs,
  children,
  refForm
}) => {
  const userData = useContext(UserContext);

  const [formState, dispatch] = useReducer(reducer, {
    showPw: false,
    statesOfFields: {}
  });


  const baseClassForm = `${baseClass}__form`;
  const classSpace = "form-content";

  const schema = Nope.object().shape(
    objectFromPairs(formData.fields.map(f => [f.name, f.schema]))
  );

  const useFormInstance = useForm({ // https://react-hook-form.com/api/useform
    resolver: nopeResolver(schema)
  });

  const getEventData = useCallback(() => ({
    useFormInstance,
    fieldsState,
    fieldsStateArgs,
    statesOfFields: formState.statesOfFields,
    formData,
    userData,
    pageData,
    schema
  }), [
    fieldsState,
    fieldsStateArgs,
    formData,
    pageData,
    schema,
    formState.statesOfFields,
    useFormInstance,
    userData
  ]);

  const themeVar = (node) => {
    if (typeof node === "function") {
      return node(getEventData);
      // return node({state, useFormInstance, formData});
    }
    return node;
  };


  const getFieldsStateVar = (prop, defValue, defArgs) => {
    const _defValue = themeVar(defValue);

    return themeVar(callDeepProp({
      obj: formData.fieldsStates,
      deepProp: `${fieldsState}.${prop}`,
      args: [getEventData, ...(fieldsStateArgs || [])],
      defValue: _defValue,
      defArgs
    }));
  };

  const onFieldStateChange = useCallback((name, fieldState, fieldStateArg) => {
    dispatch({
      type: 'statesOfFields',
      payload: { name, fieldState, fieldStateArg }
    });
  }, []);

  const setState = useCallback((state) => dispatch({
    type: 'formState',
    payload: state || {}
  }), []);


  const primaryBtn = getFieldsStateVar('primaryBtn', formData.primaryBtn);
  const secondaryBtn = getFieldsStateVar('secondaryBtn', formData.secondaryBtn);

  const formDataWarn = getFieldsStateVar('warn', formData.warn);
  const formDataDesc = getFieldsStateVar('desc', formData.desc);
  const formDataError = getFieldsStateVar('error', formData.error);
  const formDataSuccess = getFieldsStateVar('success', formData.success);
  const formDataTitle = getFieldsStateVar('title', formData.title);

  const formSubmitWrapper = useCallback(function(...args) {
    if (!loading && primaryBtn.disabled && !primaryBtn.allowSubmit) { return; }

    if (typeof onSubmit === "function") {
      onSubmit.apply(this, args);
    }
  }, [loading, onSubmit, primaryBtn.allowSubmit, primaryBtn.disabled]);

  // console.log('FORM RENDER')
  return (
    <Form
      noValidate ref={refForm}
      className={
        classNames(baseClassForm, `form-${formData.formId}`, 'form-content')
      }
      onSubmit={useFormInstance.handleSubmit(formSubmitWrapper)}
    >

      {formDataWarn && <ContentParagraph
        className={`${classSpace}__formwarn`}
        data={{
          theme: 'warning',
          level: 3
        }}
      >{formDataWarn}</ContentParagraph>}

      {formDataTitle && <ContentHeadline
        data={{
          level: 2,
          seoLevel: 1
        }}
        className={`${classSpace}__formhead`}
      >{formDataTitle}</ContentHeadline>}

      {formDataDesc && <ContentParagraph
        data={{
          theme: 'regular',
          level: 1
        }}
        className={`${classSpace}__formdesc`}
      >{formDataDesc}</ContentParagraph>}

      <div className={`${classSpace}__fields`}>

        {formData.fields.map((field, i) => (
          <ContentInput
            key={i}
            pageData={pageData}
            statesOfFields={formState.statesOfFields}
            onFieldStateChange={onFieldStateChange}
            parentState={formState}
            setParentState={setState}
            id={`${formData.formId}__${field.name}`}
            useFormInstance={useFormInstance}
            type={field.type || 'text'}
            {...field}
          />
        ))}
      </div>
      {formDataError && <ContentParagraph
        className={`${classSpace}__formerror`}
        data={{
          theme: 'alert',
          level: 3
        }}
      >{formDataError}</ContentParagraph>}

      {formDataSuccess && <ContentParagraph
        className={`${classSpace}__formsuccess`}
        data={{
          theme: 'success',
          level: 3
        }}
      >{formDataSuccess}</ContentParagraph>}

      <div className="form-item form-submit-item">
        <FormButton
          defaults={themeVar(formData.secondaryBtn)}
          extend={secondaryBtn}
          theme="lightgrey"
          isSubmit={false}
          className={`${classSpace}__back`}
          id="form-content-btm-back"
          defaultText={messages.get('user.form.defSecondaryBtn')}
        />
        <FormButton
          loading={loading}
          defaults={themeVar(formData.primaryBtn)}
          extend={primaryBtn}
          theme="grimm-orange-def"
          isSubmit={true}
          className={`${classSpace}__submit`}
          id="form-content-btn-submit"
          defaultText={messages.get('user.form.defPrimaryBtn')}
        />

      </div>
      {children}
    </Form>
  );
};


FormContent.propTypes = {
  baseClass: PropTypes.string,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  formData: propTypeFormData,
  pageData: PropTypes.object,
  refForm: PropTypes.object,
  fieldsState: PropTypes.string,
  fieldsStateArgs: PropTypes.any,
  children: PropTypes.node
};

export default FormContent;
