import {getEnterKeyMode} from "../components/LineBreakMode";
import {sourceAreaSetup, lsPropNames, dictSettings} from '../../../setup';
import { sesStore } from '../../../lib/BrowserStore';
import {LAYOUT_STATES} from '../enums';
import {getUserData} from '../../../Contexts';

const initialValues = dictSettings.initialValues;
const {enterKeyModeFewWordsLen} = sourceAreaSetup;

const useInitialDictState = () => {
  const userData = getUserData();

  return {
    forcedSubmits: 0,
    currentUserData: userData,
    trResult: null,
    lastOcrText: null,
    lastQuery: {
      src: null,
      trg: null,
      status: null,
      text: null,
      dict: null,
      words: null,
      userData: null,
      wordsWithDupes: null,
      wordsLimited: null,
      wordsOverLimit: null
    },
    enterKeyMode: getEnterKeyMode(enterKeyModeFewWordsLen),
    curInputCleanHtml: sesStore.get(lsPropNames.getSess('curInputHtml'), initialValues.inputHtml),
    curInputHtml: sesStore.get(lsPropNames.getSess('curInputHtml'), initialValues.inputHtml),
    curInputText: sesStore.get(lsPropNames.getSess('curInputText'), initialValues.inputText),
    curPreviewHtml: null,
    curWord: null,
    curWordIndex: null,

    curSrc: sesStore.get(lsPropNames.getSess('curSrc'), initialValues.src),
    curTrg: sesStore.get(lsPropNames.getSess('curTrg'), initialValues.trg),
    curDictId: sesStore.get(lsPropNames.getSess('curDictId'), initialValues.dictId),

    loadingQuery: false,
    ready: false,
    error: null,
    layoutState: LAYOUT_STATES.INITIAL,
    loading: false,
    errormsg: null
  };
};

export default useInitialDictState;
