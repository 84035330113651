import React from 'react';
// import ContentLink from "../components/contentLink";

const PageFooter = (props) => {
  return (
    <section className="page-footer">
      <div className="page-footer-in page-width">
        <nav className="footer-navigation">

        </nav>
      </div>
    </section>
  );
};
export default PageFooter;
