// eslint-disable-next-line react/prop-types
import React, {useCallback, useState, useContext, useEffect} from "react";

import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SplitButton from 'react-bootstrap/SplitButton';
import Dropdown from 'react-bootstrap/Dropdown';
import {tldMap, nativeLang} from '../../../setup';
import unicode_helper from "../../../lib/unicode_helper";
import LoadingBounce from "../../../components/misc/LoadingBounce";
import classNames from "classnames";
import {DEV_MODE} from "../../../controller/devel";
import { UserContext, DictListContext} from '../../../Contexts';
import {getLangPairs} from '../dictHelpers';

import messages from "../../../messages";

const debug = false && DEV_MODE;

function OcrToolModal({onHide, onUpload, children, loading, srcLang, ...rest}) {

  // const languages = Object.values(tldMap).sort();
  // const restLangs = {...tldMap};
  // delete restLangs[srcLang];
  const [upLang, setUpLang] = useState(srcLang);
  const [langs, setLangs] = useState([]);
  const dictList = useContext(DictListContext);
  const userData = useContext(UserContext);

  useEffect(() => {
    const langs = [];
    const uniqueLangs = {};
    const langPairsWithUa = getLangPairs(dictList)
      .filter(lp => lp.access)
    ;

    for (let i = 0; i < langPairsWithUa.length; i++) {
      const lp = langPairsWithUa[i];
      if (!uniqueLangs[lp.src]) {
        uniqueLangs[lp.src] = lp.src_desc;
        langs.push({
          value: lp.src,
          text: lp.src_desc
        });
      }
    }

    setLangs(unicode_helper.sortAbc(langs, nativeLang, 'text'));
  }, [dictList, userData]);

  const onSelectLang = useCallback((lang) => {
    setUpLang(lang);
    onUpload(lang);
  }, [setUpLang, onUpload]);

  const onUploadClick = useCallback(() => {
    onUpload(upLang);
  }, [onUpload, upLang]);

  return (
    <Modal
      onHide={onHide}
      {...rest}
      className={classNames(loading ? 'ocr-loading' : '')}
      size="md"
      scrollable
      aria-labelledby="ocr-modal-crop"
      centered
      autoFocus
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="h6" id="ocr-modal-crop">
          {messages.get('ocrTool.selectAreaToCrop')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="posrel">
        {children}
        {loading ? (
          <LoadingBounce />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Mégse</Button>
        {debug ? (
          <Button variant="primary" onClick={() => onUpload(srcLang, true)}>teszt</Button>
        ) : null}
        <SplitButton
          onClick={onUploadClick}
          id={`ocr-uploud-button`}
          className="ocr-langs-dropdow"
          disabled={loading}
          drop={"down"}
          variant="primary"
          title={messages.get('ocrTool.scannAsLang', { lang: tldMap[upLang]})}
        >
          <Dropdown.Header>{messages.get('ocrTool.currentLang')}</Dropdown.Header>
          {langs && langs.map(({value, text}, i) => {
            // console.log(valuesByLanguages)
            return (
              <Dropdown.Item active={value === upLang} onSelect={onSelectLang} key={i} eventKey={value}>{text}</Dropdown.Item>
            );
          })}
        </SplitButton>
      </Modal.Footer>
    </Modal>
  );
}

OcrToolModal.propTypes = {
  loading: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  srcLang: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default OcrToolModal;
