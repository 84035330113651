
import React, {useCallback, useEffect, useRef, useReducer} from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import {messages} from '../messages';
import classNames from 'classnames';
import ContentButton from './contentButton';
import { callDeepProp } from '../lib/objects/deepOps';
import {isCssSelectorSupported} from "../lib/util";
import useIsMounted from '../hooks';

const getTypeClasses = (type, compact) => {
  switch (type) {
    case 'checkbox':
      return [
        'form-checkbox-item',
        'form-checkbox'
      ];
    default:
      return [
        classNames(
          'form-item-text-related',
          compact ? 'compact-item ' : 'not-compact',
          `form-item-${type}`
        ),
        'form-text form-text-related form-' + type
      ];
  }
};

const matchAutoFillOnElement = (el) => {
  if (isCssSelectorSupported(":autofill")) {
    return el.matches(":autofill");
  }
  else if (isCssSelectorSupported(":-webkit-autofill")) {
    return el.matches(":-webkit-autofill");
  }
  return false;
};


function reducer(state, {type, payload}) {
  switch (type) {
    case 'inputState':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}


const ContenTextInput = ({
  id,
  label,
  children,
  useFormInstance,
  name,
  parentState,
  setParentState,
  errorMessage,
  helperText,
  compact = true,
  autoComplete,
  defaultValue,
  onFieldStateChange,
  type,
  onBlur,
  onFocus,
  onChange,
  onInit,
  hidden,
  fieldStates = {},
  statesOfFields = {},
  pageData,
  schema,
  readOnly,
  ...rest
}) => {

  const isMounted = useIsMounted();

  const [wrapperClass, fieldClass] = getTypeClasses(type, !!compact);

  const errMsg = (
    useFormInstance.formState.errors && useFormInstance.formState.errors[name]?.message
  ) || errorMessage;


  const [inputState, dispatch] = useReducer(reducer, {
    fieldState: null,
    fieldStateArgs: null,
    focused: false,
    filled: !!defaultValue
  });

  const {
    onBlur: formOnBlur,
    onFocus: formOnFocus,
    onChange: formOnChange,
    defaultValue: formDefaultValue,
    ref: formRef,
    name: formName,
    ...formRegisterRest
  } = useFormInstance.register(name);

  const refInput = useRef();

  const fwRefInput = (ref) => {
    refInput.current = ref;
    formRef(ref);
  };


  const setFieldState = useCallback((fieldState, fieldStateArgs) => {
    if ((fieldState === null || fieldStates[fieldState])) {
      if (typeof onFieldStateChange === 'function') {
        onFieldStateChange(formName, fieldState, fieldStateArgs);
      }
      if (inputState.fieldState !== fieldState) {
        dispatch({
          type: 'inputState',
          payload: {fieldState, fieldStateArgs}
        });
      }
    }
    else if (fieldState !== null) {
      console.warn('fieldState not found', fieldState, fieldStates);
    }
  }, [fieldStates, formName, inputState, onFieldStateChange]);


  const updateInput = useCallback((el, focused = inputState.focused, caller) => {
    const filled = !!el.value || matchAutoFillOnElement(el);
    if (filled !== inputState.filled || focused !== inputState.focused) {
      dispatch({
        type: 'inputState',
        payload: {filled, focused}
      });
    }
  }, [inputState]);

  const getEventData = useCallback(() => ({
    refInput,
    value: refInput?.current?.value,
    setFieldState,
    fieldState: inputState.fieldState,
    statesOfFields,
    pageData,
    useFormInstance,
    // inputState,
    // setInputState,
    fieldStates,
    schema
  }), [fieldStates, inputState.fieldState, pageData, schema, setFieldState, statesOfFields, useFormInstance]);

  const getFieldStateVar = useCallback((prop, defValue, defArgs) => {
    return callDeepProp({
      obj: fieldStates,
      deepProp: `${inputState.fieldState}.${prop}`,
      args: [getEventData, ...(inputState.fieldStateArgs || [])],
      defValue,
      defArgs
    });
  }, [fieldStates, getEventData, inputState.fieldState, inputState.fieldStateArgs]);

  const _onBlur = useCallback(e => {
    updateInput(e.target, false, 'onBlur');
    formOnBlur && formOnBlur(e);
    onBlur && onBlur(e, getEventData());
  }, [formOnBlur, getEventData, onBlur, updateInput]);

  const _onFocus = useCallback(e => {
    updateInput(e.target, true, 'onFocus');
    formOnFocus && formOnFocus(e);
    onFocus && onFocus(e, getEventData());
  }, [formOnFocus, getEventData, onFocus, updateInput]);

  const _onChange = useCallback(e => {
    updateInput(e.target, false, 'onChange');
    formOnChange && formOnChange(e);
    onChange && onChange(e, getEventData());
  }, [formOnChange, getEventData, onChange, updateInput]);

  const errorMsg = (messages.getStrict(`inputScheme.${errMsg}`) ?? errMsg) ||
    getFieldStateVar('errorMsg')
  ;

  const _helperText = getFieldStateVar('helperText', helperText);

  // console.log('formDefaultValue || defaultValue', formDefaultValue, defaultValue, rest);

  useEffect(() => {
    updateInput(refInput.current, false, 'onInit');
    onInit && onInit(null, getEventData());

    setTimeout(() => isMounted() && updateInput(refInput.current, false, 'timer'), 100);
    setTimeout(() => isMounted() && updateInput(refInput.current, false, 'timer'), 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Group controlId={id} data-filled={inputState.filled}
      className={classNames(
        "form-item",
        hidden ? 'hidden' : null,
        wrapperClass,
        `field-${formName}-wrapper`,
        errorMsg ? 'err' : null,
        inputState.focused ? 'focusin' : 'not-focusin',
        inputState.filled ? 'filled' : 'not-filled',
      )}
    >
      {type === "checkbox" ? (
        <Form.Check
          name={formName}
          className={classNames(
            fieldClass,
            `field-${formName}`,
            getFieldStateVar('className')
          )}
          label={getFieldStateVar('label', label)}
          onChange={_onChange}
          isInvalid={!!errorMsg}
          defaultChecked={formDefaultValue || defaultValue}
          autoComplete={getFieldStateVar('autoComplete', autoComplete || name)}
          feedback={errorMsg}
          readOnly={readOnly || hidden}
          {...formRegisterRest}
          {...rest}
          ref={fwRefInput}
        />
      ) : (
        <>
          {type === "password" ? (
            <ContentButton data={{
              attrs: {
                className: 'toggle-pw-visibility',
                title: "Jelszó mutatása"
              },
              theme: "icon-dark",
              link: { iconLeft: parentState.showPw ? "fas fa-eye" : "fas fa-eye-slash" },
              text: 'Jelszó mutatása'
            }} onClick={
              () => { setParentState({ showPw: !parentState.showPw}); }
            } />
          ) : null}
          <Form.Label>{label}</Form.Label>
          <Form.Control
            name={formName}
            // bsPrefix="form-text"
            className={classNames(
              fieldClass,
              `field-${formName}`,
              getFieldStateVar('className')
            )}
            type={type === "password" ? (parentState.showPw ? 'text' : type) : type}
            defaultValue={formDefaultValue || defaultValue}
            autoComplete={getFieldStateVar('autoComplete', autoComplete || name)}
            onBlur={_onBlur}
            onFocus={_onFocus}
            onChange={_onChange}
            isInvalid={!!errorMsg}
            readOnly={readOnly || hidden}
            {...formRegisterRest}
            {...rest}
            ref={fwRefInput}
          />
          <Form.Control.Feedback type="invalid">
            {errorMsg}
          </Form.Control.Feedback>
        </>
      )}


      {_helperText && <Form.Text className="helper-text" muted id={id + '__helperText'}>{_helperText}</Form.Text>}

      {children}
    </Form.Group>
  );
};

ContenTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  parentState: PropTypes.object.isRequired,
  setParentState: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  useFormInstance: PropTypes.shape({
    register: PropTypes.func.isRequired, // {register} = useForm()
    formState: PropTypes.object.isRequired
  }),
  pageData: PropTypes.object,
  statesOfFields: PropTypes.object,
  fieldStates: PropTypes.object,
  onFieldStateChange: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onInit: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  schema: PropTypes.object.isRequired,
  // https://react-bootstrap-v4.netlify.app/components/forms/#form-control-props
  as: PropTypes.string,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  custom: PropTypes.bool,
  readOnly: PropTypes.bool,
  isValid: PropTypes.bool,
  plaintext: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  autoComplete: PropTypes.string,
  htmlSize: PropTypes.number
};

/*

<ContentInput
id="userName"
label=""
formRegister={register}
name=""
errors={errors}
helperText=""
autoComplete=""
/>

*/

export default ContenTextInput;
