import React from 'react';
import PropTypes from 'prop-types';
import LayoutUnavailable from './base/layout/LayoutUnavailable';

import messages from './messages';
const baseClass = "PageMustLogout";

const PageMustLogout = ({appPub, location, ...props}) => {
  const target = encodeURIComponent(
    location.pathname + location.search + location.hash
  );
  return <LayoutUnavailable
    enableFrontPageLink={true}
    headline={messages.get('PageMustLogout.headline')}
    actionHref={`/user/logout?target=${target}`}
    actionText={messages.get('PageMustLogout.action')}
    baseClass={baseClass}
    appPub={appPub}
  />;
};

PageMustLogout.propTypes = {
  location: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PageMustLogout;
