const rx_endpointToPubPath = /^\/?content\//;
import {ENUM_fetchStatus} from './enums';
import {DEV_MODE, DEV_TIMING} from '../controller/devel';


class Endpoint {
  static ENUM = ENUM_fetchStatus;

  constructor(
    path,
    {
      method = 'POST',
      hideLog = false,
      apiCache = true,
      autoFetch = false,
      multipartFormData = false,
      delay = 1,
      apiType = "REST",
      alias = null,
      publicPath = null,
      storeData,
      defaultRequestData = {},
      defaultRestData = {},
      requiredRestData = {}, // todo
      requiredRequestData = {}, // todo
      requiredFormData = {}, // todo
      flags = []
    },
    __parent,
    i
  ) {
    this.__api = __parent;
    this.flags = [...(flags || [])];
    this.alias = alias || path;
    this.hideLog = hideLog;
    this.publicPath = publicPath || path.replace(rx_endpointToPubPath, '');
    this.defaultRequestData = {...(defaultRequestData || {})};
    this.defaultRestData = {...(defaultRestData || {})};
    this.requiredRestData = {...(requiredRestData || {})};
    this.requiredRequestData = {...(requiredRequestData || {})};
    this.requiredFormData = {...(requiredFormData || {})};
    this.apiCache = apiCache || false;
    this.multipartFormData = multipartFormData || false;
    this.index = i;
    this.instanceId = this._static.instanceI++;
    this.id = `epid[${this.instanceId}][${this.alias}]`;
    this.method = method || "POST";
    this.apiType = apiType || "REST";
    this.storeData = storeData || null; // for Api
    this.numOfRequest = 0;
    this.abortController = null;
    this.onChangeFn = null;
    this.fetchQueue = [];
    this.state = {
      status: ENUM_fetchStatus.initial,
      requestTime: 0,
      data: null
    };
    this.path = path;

    if (autoFetch || this.hasFlag('preload')) {
      setTimeout(() => this.fetch(), delay);
    }
  }

  hasFlag(flag) {
    return this.flags.indexOf(flag) > -1;
  }

  hasStatus(status) {
    return this.state.status === status;
  }

  getStatus() {
    return this.state.status;
  }

  getRequestTime() {
    return this.state.requestTime
      ? (Math.round((this.state.requestTime / 1000) * 100) / 100).toFixed(2) + 's'
      : 0
    ;
  }

  setState(newState, fn) {
    const oldState = this.state;
    this.state = {
      ...this.state,
      ...newState
    };
    if (typeof fn === "function") {
      fn();
    }

    if (oldState.status !== this.state.status) {
      // console.log('##status change', this.alias, {
      //   from: oldState.status,
      //   to: this.state.status
      // })
      if (typeof this.onChangeFn === "function") {
        this.onChangeFn.call(null, this.state.status, oldState.status);
      }
    }
  }

  getState(prop) {
    return this.state[prop];
  }

  setOnChange(fn) {
    if (typeof fn === "function") {
      this.onChangeFn = fn;
    }
    else {
      console.warn("Endpoint.setOnChange: arg0 is not function");
    }
  }

  callFetchQueue(status) {
    while (this.fetchQueue.length) {
      const cb = this.fetchQueue.shift();
      if (typeof cb === "function") {
        cb(status || this.state.status, this.state.data);
      }
    }
  }

  clearFetchQueue() {
    this.fetchQueue.splice(0, this.fetchQueue.length);
  }


  resetFetch() {
    if (this.hasStatus(ENUM_fetchStatus.loading)) {
      this.abortFetch('resetFetch', true);
    }

    this.setState({
      status: ENUM_fetchStatus.initial,
      data: null,
      requestTime: 0
    });
  }

  abortFetch(caller, bypassStatusChange) {
    if (!bypassStatusChange) {
      this.setState({ status: ENUM_fetchStatus.aborted });
    }

    if (this.abortController?.abort) {
      this.abortController.abort();
      this.callFetchQueue(ENUM_fetchStatus.aborted);
    }
    else if (DEV_MODE) {
      console.warn('abortFetch: no abort controller', this.abortController?.abort, caller);
    }
  }

  _fetchStart(afterStateUpdate) {
    if (DEV_MODE) {
      // console.info('Fetching started', this.path);
      if (DEV_TIMING) { console.time(this.id); }
    }
    this.startedAt = (new Date()) - 0;

    this.setState({
      status: ENUM_fetchStatus.loading,
      requestTime: 0
    }, afterStateUpdate);
  }

  _fetchEnd(success, data, _debug) {
    if (DEV_MODE) {
      if (DEV_TIMING) { console.timeEnd(this.id); }
    }
    const newState = {
      loadig: false,
      requestTime: (new Date()) - this.startedAt,
      data
    };
    const isAbortError = data?.error?.name === "AbortError" || data?.error?.code === 20;

    if (isAbortError) {
      if (DEV_MODE) {
        console.log(`%c Note: abortError omitted:`, 'color: purple; font-size: 18px', data);
      }
    }
    else {
      newState.status = success ? ENUM_fetchStatus.success : ENUM_fetchStatus.error;
    }

    this.setState(newState, () => {
      if (success) {
        if (DEV_MODE && !data._resp._cached && !this.hideLog) {
          console.log(
            `%c FETCH SUCCESS: ${this.id}} | ${this.getRequestTime()}ms`,
            'color: green; font-size: 18px;', data
          );
        }
        this.callFetchQueue(ENUM_fetchStatus.success);
      }
      else if (!isAbortError) { // ANY OTHER ERROR (NOT ABORT)
        if (DEV_MODE && !data._resp._cached) {
          console.log(
            `%c FETCH ERROR: ${this.id}} | ${this.getRequestTime()}ms`,
            'color: red; font-size: 18px;', data
          );
        }
        this.callFetchQueue(ENUM_fetchStatus.error);
      }
    });
  }


  fetch(
    {
      encodeURI = true,
      requestData,
      restData,
      formData,
      onChange,
      ...rest
    } = {},
    callback,
    clearCache
  ) {
    if (typeof onChange === "function") { this.setOnChange(onChange); }

    const request = this.__api.fetchBuildRequest({
      requestType: this.apiType,
      encodeURI,
      requestData,
      restData,
      formData,
      ...rest,
      callback: (success, data, _debug) => {
        this._fetchEnd(success, data, _debug);
      },
      beforeFetch: (cb) => this._fetchStart(cb)
    }, this);

    // cached exactly the same request data
    const cached = request?.checksum ? this.__api.getCached(request.checksum) : null;

    if ((clearCache || !cached) && (
      this.hasStatus(ENUM_fetchStatus.success) ||
      this.hasStatus(ENUM_fetchStatus.error) ||
      this.hasStatus(ENUM_fetchStatus.loading) // will be aborted
    )) {
      this.resetFetch(); // note: this immediately changes the status value to "initial"
    }

    if (cached) {
      this.setState({
        data: cached.response,
        requestTime: 0
      });
    }

    this.fetchQueue.push(callback);

    switch (this.state.status) {
      case ENUM_fetchStatus.aborted:
      case ENUM_fetchStatus.initial: { // start fetch
        this.numOfRequest++;
        this.__api.fetch(request, this, clearCache, ({abortController} = {}) => {
          this.abortController = abortController;
        });
        break; // start fetching
      }

      case ENUM_fetchStatus.loading:
        return; // wait for loading

      case ENUM_fetchStatus.invalid:
      case ENUM_fetchStatus.error:
      case ENUM_fetchStatus.success: {
        this.callFetchQueue(this.state.status); // return cached answer
        return;
      }
      default:
        console.warn('unexpected status');
        break;
    }
  }
}


Endpoint.prototype._static = {
  instanceI: 0
};



export default Endpoint;
