import React, { useState} from 'react';
import { useIsMounted } from '../../../hooks';
import PropTypes from 'prop-types';

import {email as schema_email} from '../data/schemes';

import LoaderSpinner from '../../../components/misc/LoaderSpinner';
import ContentButton from '../../../components/contentButton';
import globals from '../../../controller/globals';
import { userSetup } from '../../../setup';
import messages from '../../../messages';

const minWaitMs = userSetup.verifyMailMinWaitMs;

export const ResendEmail = ({email, method}) => {

  const appPub = globals.get('appPub');
  const {api, toastErrorMsg, toastSuccessMsg, toastWarnMsg} = appPub;
  const isRegActivate = method === "ACTIVATE";
  const appStateProp = isRegActivate ? 'user.regActivate' : 'user.pwRequest';
  const isMounted = useIsMounted();

  const [state, setState] = useState({
    // lastSentAt: 0,
    // lastSentTo: null,
    loading: false
  });

  const onClick = (e) => {
    const now = (new Date()) - 0;
    e.preventDefault();

    if (!state.loading) {
      // const {lastSentAt, lastSentTo} = state;
      const {lastSentAt, lastSentTo} = appPub.getStateDeep(appStateProp) || 0;


      if (lastSentAt && lastSentTo === email && now - lastSentAt < minWaitMs) {
        const timeLeft = Math.round((minWaitMs - (now - lastSentAt)) / 1000);
        toastWarnMsg(messages.get(
          isRegActivate
            ? 'user.resendEmail.regVerifyCodeAlreadySent'
            : 'user.resendEmail.pwVerifyCodeAlreadySent'
          , {timeLeft}
        ), `verifyCodeAlreadySent-${method}`);
        return;
      }

      setState({...state, loading: true});

      api.getEndpointByAlias('user-reminduser').fetch({
        requestData: {
          params: {
            email: email.toLowerCase(),
            action: method
          }
        }
      }, (status, data) => api.fetchResponseHelper({
        status,
        data,
        isMounted: () => isMounted(),
        // onAny: () => setState({ ...state, loading: false }),
        onSuccess: () => {
          setState({
            ...state,
            lastSentAt: (new Date()) - 0,
            lastSentTo: email,
            loading: false
          });
          appPub.setStateDeep(appStateProp, {
            ...appPub.getStateDeep(appStateProp),
            lastSentAt: (new Date()) - 0,
            lastSentTo: email
          });

          toastSuccessMsg(
            messages.get(
              isRegActivate
                ? 'user.resendEmail.regVerifyCodeJustSent'
                : 'user.resendEmail.pwVerifyCodeJustSent'
            ),
            `verifyCodeJustSent-${method}`
          );
        },
        onErrorMsg: (msg) => {
          toastErrorMsg(msg, 'user-reminduser-error');
          setState({ ...state, loading: false });
        }
      }), true);
    }
  };

  if (!email || schema_email.validate(email)) {
    return null;
  }
  return (
    <ContentButton
      onClick={onClick}
      data={{
        text: messages.get(
          state.loading ? 'user.resendEmail.loading' : 'user.resendEmail.text'
        ),
        theme: 'inline-link'
      }}
    >
      {state.loading && <LoaderSpinner style='inline-loading' size={'sm'} />}
    </ContentButton>
  );

};


ResendEmail.propTypes = {
  email: PropTypes.string.isRequired,
  method: PropTypes.oneOf(['ACTIVATE', 'PASSWORD']).isRequired
};

// eslint-disable-next-line react/prop-types
export const ResendRegVerify = ({email}) => {
  return <ResendEmail email={email} method={'ACTIVATE'} />;
};
// eslint-disable-next-line react/prop-types
export const ResendPwVerify = ({email}) => {
  return <ResendEmail email={email} method={'PASSWORD'} />;
};


export default ResendEmail;
