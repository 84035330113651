import {
  ttsVoiceLangTLD,
  ttsServiceUrl,
  ttsVoiceFilter
} from '../setup';
import {PREVIEW_MODE} from '../controller/devel';
import api from '../api/api.instance';
import {initialState} from "../components/misc/AudioPlayer";


const initialButtonState = (state) => ({
  ...initialState,
  ...(state || {})
});


const filterText = async(rawText, lang, {filterPreset}) => {
  let text = rawText;
  const ttsFilterSetup = ttsVoiceFilter[lang];

  if (!ttsFilterSetup) return text;

  if (!Array.isArray(filterPreset)) {
    filterPreset = ['default'];
  }
  else if (filterPreset.length === 0 || filterPreset.indexOf('default') > -1) {
    filterPreset.unshift('default');
  }

  if (ttsFilterSetup.presets) {
    for (let i = 0; i < filterPreset.length; i++) {
      const preset = filterPreset[i];
      if (typeof ttsFilterSetup.presets[preset] === "function") {
        text = await ttsFilterSetup.presets[preset](text);
      }
    }
  }

  if (rawText !== text) {
    PREVIEW_MODE && console.warn(
      'filterText TEXT replaced from\n[', rawText, ']to:\n[', text, ']'
    );
  }
  return text;
};


const ttsSpeakDetermineVoice = ({gender, ttsVoices, lang}) => {
  // const gender = "MALE";
  const ssmlGender = gender || "FEMALE";
  const wavenet = true;
  const filteredByLang = ttsVoices.filter((d) => (
    d.languageCodes[0].indexOf(ttsVoiceLangTLD[lang]) > -1
  ));

  let filteredByQuality = filteredByLang.filter(d => d.name.indexOf(wavenet ? "-Wavenet-" : "-Standard-") > -1);
  if (filteredByQuality.length === 0) {
    filteredByQuality = filteredByLang;
  }

  let filteredByGender = filteredByQuality.filter((d) => d.ssmlGender === ssmlGender);
  if (filteredByGender.length === 0) {
    filteredByGender = filteredByQuality;
  }

  const voice = (
    filteredByGender.find((d) => d.name.slice(-1) === "A") ||
      filteredByGender[0] ||
      { name: "en-GB-Standard-A" }
  ).name;

  PREVIEW_MODE && console.log('speak by lang', {
    filteredByQuality,
    filteredByGender,
    voice,
    "ttsVoiceLangTLD[lang]": ttsVoiceLangTLD[lang],
    lang,
    ssmlGender,
    ttsVoices
  });

  return voice;
};



async function ttsSpeak(refTTSAudio, {
  directVoice,
  lang = "en",
  gender,
  text = "",
  filterPreset = [],
  callback,
  state,
  ttsButtonConnector,
  ...rest
}) {
  state = initialButtonState(state);

  if (!refTTSAudio.current || !text || !text.trim()) {
    callback && callback({
      ...state,
      currentEvent: "ttsSpeak.noText",
      loading: false,
      error: refTTSAudio.current ? "noText" : "noAudioElement",
      errorObj: {text, refTTSAudio: refTTSAudio.current}
    });
    return;
  }

  if (lang === "sp") { // quickfix for bad tld
    lang = "es";
  }

  const ttsText = await filterText(text.trim(), lang, {filterPreset});


  if (!ttsText) {
    callback && callback({
      ...state,
      currentEvent: "ttsSpeak.noTextAfterFilter",
      loading: false,
      error: "noText",
      errorObj: {ttsText}

    });
    return;
  }

  const player = refTTSAudio.current;
  const ttsVoices = api.getStoreItem('ttsVoices');

  if (!ttsVoices) {
    callback && callback({
      ...state,
      currentEvent: "ttsSpeak.noVoicesWaitForLoading",
      loading: true,
      error: null,
      errorObj: null
    });

    const timerId = setTimeout(() => {
      callback && callback({
        ...state,
        currentEvent: "ttsSpeak.noVoicesTimeout",
        loading: false,
        error: 'Hiba a felolvasó szolgáltatás betöltésekor: időtúllépés',
        errorObj: null
      });
    }, 1000 * 30);

    api.dataEmitter.once(['ttsVoices'], (prop, payload) => {
      // ttsSpeak({lang, text, filterPreset});
      if (timerId) clearTimeout(timerId);
      callback && callback({
        ...state,
        currentEvent: "ttsSpeak.beforeRecursion",
        loading: true,
        error: false,
        errorObj: null
      });

      ttsSpeak({
        directVoice,
        lang,
        gender,
        text,
        filterPreset,
        callback,
        state,
        ...rest
      });
    });

    return;
  }

  const voice = directVoice || ttsSpeakDetermineVoice({gender, ttsVoices, lang}) || 'en-GB-Standard-A';

  PREVIEW_MODE && console.log('tts-text-to-voice before fetch', {voice, ttsText});

  callback && callback({
    ...state,
    currentEvent: "ttsSpeak.beforeFetch",
    loading: true,
    error: null,
    errorObj: null
  });

  api.getEndpointByAlias('tts-text-to-voice').fetch({
    encodeURI: true,
    restData: {
      voice,
      text: ttsText
    }
  }, (ok, resp) => {
    if (!ok || !resp.file) {
      PREVIEW_MODE && console.error('tts-text-to-voice response error', resp);
      callback && callback({
        ...state,
        currentEvent: "ttsSpeak.fetchError",
        loading: false,
        error: 'Hiba a felolvasó szolgáltatás betöltésekor: ' + api.extractErrorMsg(resp),
        errorObj: resp
      });
      return;
    }
    callback && callback({
      ...state,
      currentEvent: "ttsSpeak.fetchSuccess",
      loading: false,
      error: false,
      errorObj: null
    });
    PREVIEW_MODE && console.log('tts-text-to-voice after fetch', {voice, ttsText, resp});
    player.setTtsButtonConnector(ttsButtonConnector);
    player.setSrc(ttsServiceUrl + '/' + resp.file, ttsText, true);
  }, false);
}

export default ttsSpeak;
