import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import messages from '../../messages';

const LoaderSpinner = ({
  className,
  loadingText = messages.get('layout.QrPreloader.loading'),
  variant = "primary",
  animation = "border",
  size,
  style = "api-loading"
}) => (
  <div className={classNames(className, style)}>
    <Spinner animation={animation} role="status" size={size} variant={variant}>
      <span className="sr-only">{loadingText}</span>
    </Spinner>
  </div>
);

LoaderSpinner.propTypes = {
  // https://react-bootstrap.github.io/components/spinners/
  className: PropTypes.string,
  loadingText: PropTypes.node,
  animation: PropTypes.oneOf(['border', 'grow']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']),
  style: PropTypes.oneOf(['api-loading', 'inline-loading'])
};

export default LoaderSpinner;
