import PropTypes from "prop-types";
import React, {useCallback, useState} from 'react';
import { sourceAreaSetup, lsPropNames } from '../../../setup';
import Dropdown from 'react-bootstrap/Dropdown';
import messages from "../../../messages";
import classNames from "classnames";
import { locStore } from '../../../lib/BrowserStore';
import ContentIconItem from '../../../components/contentIconItem';
// import TooltipWrapper from '../../../components/misc/TooltipWrapper';
import { keyCodes } from '../../../lib/util';
import {ENTER_KEY_MODES} from '../enums';

import "./LineBreakMode.scss";
const {enterKeyMode, enterKeyModeFewWordsLen} = sourceAreaSetup;

const optionValues = Object.values(ENTER_KEY_MODES);
const optionList = optionValues.map(value => ({
  value,
  label: messages.get(`TrsSourceArea.lineBreakMode.options.${value}.label`),
  desc: messages.get(`TrsSourceArea.lineBreakMode.options.${value}.desc`, {
    fewWordsLen: enterKeyModeFewWordsLen
  })
}));

const getEnterKeyMode = () => {
  const mode = locStore.get(lsPropNames.getLocal('enterMode'), enterKeyMode);
  return optionValues.includes(mode) ? mode : enterKeyMode;
};

// eslint-disable-next-line react/prop-types
const IconToggle = React.forwardRef(({ classSpace, className, children, onClick, enterMode, wordsLen = 0, ...args}, ref) => {
  const onKeyDown = useCallback((e) => {
    if (e.type === "keydown" && (
      e.which === keyCodes.SPACE ||
      e.which === keyCodes.ENTER
    )) {
      e.preventDefault();
      e.target.click();
    }
  }, []);

  const descCurrent = (
    messages.get('TrsSourceArea.lineBreakMode.title') + ': ' +
    messages.get(`TrsSourceArea.lineBreakMode.options.${enterMode}.label##PLAINTEXT##`)
  );

  const enterSubmits = enterMode === 'submitAlways' || (
    enterMode === 'submitFewWords' &&
    wordsLen <= enterKeyModeFewWordsLen
  );

  return (
    <ContentIconItem
      className={classNames(
        `${classSpace}__trigger`,
        `${classSpace}__trigger--${enterSubmits ? 'enter-submits' : 'enter-break-line'}`,
        className
      )}
      title={
        messages.get('TrsSourceArea.lineBreakMode.title')
      }
      tooltip={{
        placement: 'top-end'
      }}
      ref={ref}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...args}
      data={{
        attrs: { 'aria-label': descCurrent },
        icon: (
          enterSubmits ? 'keyboard-return' : 'wrap-text'
        )
        // formButton: 'button'
      }}
    >
      {children}
    </ContentIconItem>
  );
});

const LineBreakMode = ({baseClass, onSelect, wordsLen = 0}) => {
  const classSpace = baseClass + '__lineBreakMode';
  const mode = enterKeyMode ? 'enter' : 'shift-enter';
  const [enterMode, setEnterMode] = useState(
    getEnterKeyMode()
  );

  const _onSelect = useCallback((eventKey, e) => {
    setEnterMode(eventKey);
    locStore.set(lsPropNames.getLocal('enterMode'), eventKey);
    if (typeof onSelect === 'function') {
      onSelect(eventKey, e);
    }
  }, [onSelect]);

  return (
    <Dropdown
      className={
        classNames(
          classSpace,
          `${classSpace}--${mode}`
        )
      }
      // onToggle={onMenuToggle}
      drop="down"
      onSelect={_onSelect}
    >
      <Dropdown.Toggle
        classSpace={classSpace}
        id="dropdown-LineBreakMode"
        aria-label={messages.get('TrsSourceArea.specialChars.title')}
        wordsLen={wordsLen}
        enterMode={enterMode}
        as={IconToggle}
      >
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="right" flip={true}
      > {/* align="right" */}
        <Dropdown.Header>{messages.get('TrsSourceArea.lineBreakMode.optionListHeader') + ':'}</Dropdown.Header>
        {optionList.map(({value, label, desc}, i) => {
          return (
            <Dropdown.Item
              className={classNames(classSpace + '__item')}
              eventKey={value}
              active={value === enterMode}
              tabIndex="0" as={"button"} key={i}
            >
              <strong className={classNames(classSpace + '__item__label')}>{label}</strong>
              <small className={classNames(classSpace + '__item__desc')}>{desc}</small>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

LineBreakMode.propTypes = {
  enterKeyMode: PropTypes.bool,
  wordsLen: PropTypes.number,
  onSelect: PropTypes.func,
  baseClass: PropTypes.string.isRequired
};

export {
  getEnterKeyMode,
  LineBreakMode
};

export default LineBreakMode;
