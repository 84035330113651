import React from "react";
import PropTypes from 'prop-types';

import {TtsButtonConnector} from '../../base/qrcode/TtsSpeakButton';

const initialState = {
  currentEvent: null,
  playing: false,
  canPlay: false,
  canPlayThrough: false,
  aborted: false,
  error: null,
  errorObj: null,
  loading: false,
  title: null
};

const AudioPlayer = class audioPlayer extends React.PureComponent {

  constructor(props) {
    super(props);
    this.audioEl = React.createRef();
    this.listenTracker = 0;
    this.ttsButtonConnector = null;
    this.state = {
      ...initialState,
      controlledSrc: this.props.defaultSrc
    };
  }

  onError = (e) => {
    this.props.onError?.(e);
    this.setState({
      currentEvent: 'onError',
      loading: false,
      error: true,
      errorObj: e
    });
  };

  onCanPlay = (e) => {
    this.props.onCanPlay?.(e);
    this.setState({
      currentEvent: 'onCanPlay',
      loading: false,
      canplay: true
    });
  };

  onCanPlayThrough = (e) => {
    this.props.onCanPlayThrough?.(e);
    this.setState({
      currentEvent: 'onCanPlayThrough',
      loading: false,
      canplay: true,
      canPlayThrough: true
    });
  };

  onPlay = (e) => {
    this.setListenTrack();
    this.props.onPlay?.(e);
    this.setState({
      currentEvent: 'onPlay',
      playing: true,
      paused: false,
      loading: false
    });
  };

  onAbort = (e) => {
    this.clearListenTrack();
    this.props.onAbort?.(e);
    this.setState({
      currentEvent: 'onAbort',
      playing: false,
      loading: false
    });
  };

  onEnded = (e) => {
    this.clearListenTrack();
    this.props.onEnded?.(e);
    this.setState({
      currentEvent: 'onEnded',
      playing: false,
      paused: false
    });
    this.audioEl.current.currentTime = 0;
  };

  onPause = (e) => {
    this.clearListenTrack();
    this.props.onPause?.(e);
    this.setState({
      currentEvent: 'onPause',
      playing: false,
      paused: true
    });
  };

  onSeeked = (e) => {
    this.props.onSeeked?.(e);
  };

  onLoadedMetadata = (e) => {
    this.props.onLoadedMetadata?.(e);
  };

  setListenTrack() {
    if (!this.listenTracker) {
      const listenInterval = this.props.listenInterval;
      this.listenTracker = window.setInterval(() => {
        this.audioEl.current && this.props.onListen?.(this.audioEl.current.currentTime);
      }, listenInterval);
    }
  }

  getState() {
    return {...this.state};
  }

  getSrc() {
    return this.audioEl.current?.src;
  }

  abort() {
    if (this.state.playing) {
      this.stop();
    }
  }

  updateConnector() {
    if (this.ttsButtonConnector instanceof TtsButtonConnector) {
      if (this.ttsButtonConnector.garbage) {
        this.ttsButtonConnector = null;
      }
      else {
        this.ttsButtonConnector.stateChange({...this.state});
      }
    }
  }

  setTtsButtonConnector(connector = null) {
    if (
      this.ttsButtonConnector instanceof TtsButtonConnector &&
     (connector !== this.ttsButtonConnector)
    ) {
      if (!this.ttsButtonConnector.garbage) {
        this.ttsButtonConnector.unconnect();
      }
      if (connector === null) this.ttsButtonConnector = null;
    }

    if (connector instanceof TtsButtonConnector) {
      this.ttsButtonConnector = connector;
      if (connector !== this.ttsButtonConnector) {
        connector.connect(this);
      }
    }
  }

  setSrc(src, title = null, callbackOrPlay = null) {
    this.abort();

    const cb = typeof callbackOrPlay === 'function'
      ? callbackOrPlay
      : () => callbackOrPlay === true && this.play(true)
    ;
    if (src === this.getSrc()) {
      cb();
      return;
    }
    this.setState({
      ...initialState,
      currentEvent: 'setSrc',
      controlledSrc: src,
      loading: true,
      title
    }, cb);
  }

  play(beginning = false) {
    if (!this.state.canPlay && !this.state.canPlayThrough && !this.state.loading) {
      this.audioEl.current?.load();
    }
    if (beginning && (this.state.canPlay || this.state.canPlayThrough)) {
      this.audioEl.current.currentTime = 0;
    }
    this.audioEl.current?.play();
  }

  pause() {
    this.audioEl.current?.pause();
  }

  stop() {
    if (this.audioEl.current) {
      this.audioEl.current.currentTime = 0;
      this.audioEl.current.pause();
      this.audioEl.current.currentTime = 0;
    }
  }

  clearListenTrack() {
    if (this.listenTracker) {
      clearInterval(this.listenTracker);
      delete this.listenTracker;
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const audio = this.audioEl.current;

    if (!audio) return;

    if (
      (this.props.src !== prevProps.src ||
      this.state.controlledSrc !== prevState.controlledSrc) &&
      this.state.loading !== true
    ) {
      audio.load();
      this.setState({
        loading: true
      }, () => this.updateConnector());
    }
    else { // any other state change
      this.updateConnector();
    }
  }

  componentDidMount() {
    const audio = this.audioEl.current;

    if (!audio) return;

    audio.addEventListener('error', this.onError);

    // When enough of the file has downloaded to start playing
    audio.addEventListener('canplay', this.onCanPlay);

    // When enough of the file has downloaded to play the entire file
    audio.addEventListener('canplaythrough', this.onCanPlayThrough);

    // When audio play starts
    audio.addEventListener('play', this.onPlay);

    // When unloading the audio player (switching to another src)
    audio.addEventListener('abort', this.onAbort);

    // When the file has finished playing to the end
    audio.addEventListener('ended', this.onEnded);

    // When the user pauses playback
    audio.addEventListener('pause', this.onPause);

    // When the user drags the time indicator to a new time
    audio.addEventListener('seeked', this.onSeeked);

    audio.addEventListener('loadedmetadata', this.onLoadedMetadata);

    audio.addEventListener('volumechange', this.onVolumeChanged);
  }

  // Remove all event listeners
  componentWillUnmount() {
    const audio = this.audioEl.current;
    if (this.ttsButtonConnector) {
      this.ttsButtonConnector.unconnect();
    }

    if (!audio) return;

    audio.removeEventListener('error', this.onError);

    // When enough of the file has downloaded to start playing
    audio.removeEventListener('canplay', this.onCanPlay);

    // When enough of the file has downloaded to play the entire file
    audio.removeEventListener('canplaythrough', this.onCanPlayThrough);

    // When audio play starts
    audio.removeEventListener('play', this.onPlay);

    // When unloading the audio player (switching to another src)
    audio.removeEventListener('abort', this.onAbort);

    // When the file has finished playing to the end
    audio.removeEventListener('ended', this.onEnded);

    // When the user pauses playback
    audio.removeEventListener('pause', this.onPause);

    // When the user drags the time indicator to a new time
    audio.removeEventListener('seeked', this.onSeeked);

    audio.removeEventListener('loadedmetadata', this.onLoadedMetadata);

    audio.removeEventListener('volumechange', this.onVolumeChanged);
  }

  render() {
    const conditionalProps = {};
    if (this.props.controlsList) {
      conditionalProps.controlsList = this.props.controlsList;
    }
    // Set lockscreen / process audio title on devices
    const title = (this.props.title ? this.props.title : this.state.title) || null;

    return (
      <div id="tts-audio-player">
        <audio
          ref={this.audioEl}
          src={this.props.src || this.state.controlledSrc}
          autoPlay={this.props.autoPlay}
          className={this.props.className}
          controls={!(this.props.controls === false)}
          crossOrigin={this.props.crossOrigin}
          id={this.props.id}
          loop={this.props.loop}
          muted={this.props.muted}
          preload={this.props.preload}
          style={this.props.style}
          title={title}
          hidden={this.props.hidden}
          {...conditionalProps}
        >
          {
            this.props.children ||
            <p>Your browser does not support the <code>audio</code> element.</p>
          }
        </audio>
      </div>
    );
  }
};

AudioPlayer.propTypes = {
  autoPlay: PropTypes.bool,
  hidden: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string,
  controls: PropTypes.bool,
  controlsList: PropTypes.string,
  crossOrigin: PropTypes.string,
  id: PropTypes.string,
  listenInterval: PropTypes.number,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  onAbort: PropTypes.func,
  onCanPlay: PropTypes.func,
  onCanPlayThrough: PropTypes.func,
  onEnded: PropTypes.func,
  onError: PropTypes.func,
  onListen: PropTypes.func,
  onLoadedMetadata: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onSeeked: PropTypes.func,
  onVolumeChanged: PropTypes.func,
  preload: PropTypes.oneOf(['', 'none', 'metadata', 'auto']),
  defaultSrc: PropTypes.string, // Not required b/c can use <source>
  src: PropTypes.string, // Not required b/c can use <source>
  style: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string
};

export {
  AudioPlayer,
  initialState
};

export default AudioPlayer;
