/* eslint-disable react/prop-types */
import React from 'react';

import StaticPageMustLogin from '../../../StaticPageMustLogin';
import ModuleLoader from '../../../components/ModuleLoader';

import { Route } from 'react-router-dom';
import { meta } from '../../../lib/util';
import { pubFeature } from '../../../setup';
import messages from '../../../messages';

const lazyImports = {
  subscription: () => React.lazy(() => import('../../../base/entryList/'))
};


const getEntryListRoutes = (pageProps) => {
  const isLoggedIn = pageProps.appPub.isLoggedIn;
  return pubFeature.szotarnaplo ? [
    <Route path={[
      "/entrylog"
    ]} key={'view-entryList'} exact render={(props) => {
      meta.setTitle(
        messages.get('meta.pageTitle.logEntry')
      );
      return isLoggedIn()
        ? <ModuleLoader moduleImportFn={lazyImports.subscription}
          { ...{...pageProps, ...props} }
        />
        : <StaticPageMustLogin { ...{...pageProps, ...props} } />;
    }} />

  ] : null;
};

export default getEntryListRoutes;
