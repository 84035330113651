// import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
// "node_modules/react-app-polyfill/node_modules/core-js/stable/index.js"
import 'react-app-polyfill/stable';
import 'element-closest-polyfill';
// import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'abortcontroller-polyfill';
import './lib/ie11-polyfills';

// import 'core-js/es6/promise';
// import 'core-js/es6/set';
// import 'core-js/es6/map';


import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate, render} from 'react-dom';

const renderer = process.env.BUILD_TARGET === 'server' ? hydrate : render;

import Dwcc from "./lib/dwcc/";

const dwcc = new Dwcc({
  lang: 'hu',
  autoload: false,
  editConsentSelector: '.show-cookie-settings'
});
dwcc.showIfNotConsent();


// console.log(process.env.BUILD_TYPE);

renderer(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
