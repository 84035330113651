import {useCallback} from 'react';
import {normalizeSourceText} from '../dictHelpers';
import {unicodeHelper} from '../../../lib/util';
import {getEnterKeyMode} from "../components/LineBreakMode";
import {sourceAreaSetup} from '../../../setup';
import insertContentToFocusedEditable from '../../../lib/dom/insertContentToFocusedEditable';
const {enterKeyModeFewWordsLen} = sourceAreaSetup;
import {ENTER_KEY_MODES} from "../enums";

const useOnSourceKeyDown = ({
  refSource, onSubmit, isMounted,
  curDictId, curSrc, curTrg
}) => {
  return useCallback((e) => {
    const isEnter = (e.keyCode === 13 || e.key === "enter");
    if (!isEnter || !refSource?.current) return;

    const text = normalizeSourceText(refSource.current.textContent);
    const wordsWithDupes = unicodeHelper.getWords(text.toLowerCase(), false);
    const isModifierDown = e.ctrlKey || e.metaKey || e.shiftKey;
    const enterKeyMode = getEnterKeyMode();
    const doSubmit =
      (enterKeyMode === ENTER_KEY_MODES.breakLine && isModifierDown) || // submit only when modifier is pressed.
      (enterKeyMode === ENTER_KEY_MODES.submitAlways && !isModifierDown) || // submit when not modifier is pressed.
      // submit when not modifier is pressed or only few words are entered.
      (
        enterKeyMode === ENTER_KEY_MODES.submitFewWords && (
          (wordsWithDupes.length <= enterKeyModeFewWordsLen && !isModifierDown) ||
          (wordsWithDupes.length > enterKeyModeFewWordsLen && isModifierDown)
        )
      )
    ;

    e.preventDefault();

    if (doSubmit) { // submit the form
      if (!text || !refSource.current) { return; }
      // refSource.current.blur();
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          isMounted() && onSubmit();
          // Order is important here. Blur must be called after onSubmit, otherwise
          // trigger autosubmit on blur.
          refSource.current.blur();
        }, 10);
      });
    }
    else { // otherwise inserts linebrake
      if (e.code !== 'gboard_enter') {
        insertContentToFocusedEditable('\n');
      }
      else {
        window.requestAnimationFrame(() => {
          insertContentToFocusedEditable('\n');
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refSource, curDictId, curSrc, curTrg, isMounted, onSubmit]);
};

export default useOnSourceKeyDown;



// const onSourceKeyDown = useCallback((e) => {
//   const isEnter = (e.keyCode === 13 || e.key === "enter");
//   if (!isEnter || !refSource?.current) return;

//   const text = normalizeSourceText(refSource.current.textContent);
//   const wordsWithDupes = unicodeHelper.getWords(text.toLowerCase(), false);
//   const isModifierDown = e.ctrlKey || e.metaKey || e.shiftKey;
//   const enterKeyMode = getEnterKeyMode();
//   const doSubmit =
//     (enterKeyMode === ENTER_KEY_MODES.breakLine && isModifierDown) || // submit only when modifier is pressed.
//     (enterKeyMode === ENTER_KEY_MODES.submitAlways && !isModifierDown) || // submit when not modifier is pressed.
//     // submit when not modifier is pressed or only few words are entered.
//     (
//       enterKeyMode === ENTER_KEY_MODES.submitFewWords &&
//       wordsWithDupes.length <= enterKeyModeFewWordsLen &&
//       !isModifierDown
//     )
//   ;

//   if (doSubmit) { // submit the form
//     e.preventDefault();
//     if (!text || !refSource.current) { return; }

//     refSource.current.blur();
//     window.requestAnimationFrame(() => {
//       // eslint-disable-next-line @typescript-eslint/no-use-before-define
//       setTimeout(() => isMounted() && onSubmit(), 10);
//     });
//   }
//   else { // otherwise inserts linebrake
//     e.preventDefault();
//     insertContentToFocusedEditable('\n');
//   }
//   // eslint-disable-next-line @typescript-eslint/no-use-before-define, react-hooks/exhaustive-deps
// }, [state.curDictId, state.curSrc, state.curTrg, isMounted, onSubmit]);
