import React from 'react';
import globals from './controller/globals';
import { TypeDictList, TypeDictAccess } from './types/Dict';
import { TypeUserData } from './types/User';

export const getUserData = ():TypeUserData => globals.get('appPub')?.getUserData() || {};
export const getUserDictAccess = ():TypeDictAccess => getUserData()?.user_dict_access;
export const getDictList = ():TypeDictList => globals.get('appPub')?.getDictList();

// https://github.com/mjancarik/consume-multiple-contexts
// https://github.com/VeprUA/react-multi-provider#readme

export const UserContext = React.createContext({
  loggedin: false
});
UserContext.displayName = 'UserContext';


export const DictListContext = React.createContext([] as TypeDictList);
DictListContext.displayName = 'DictListContext';
