// import arrayGroupBy from "../../../lib/arrays/arrayGroupBy";

export default [
  [
    '{grimm_api_url}',
    {
      alias: 'subscribe-packages',
      flags: ['private'],
      defaultRequestData: {
        method: 'packages',
        model: "subscribe"
      },
      requiredRequestData: {
        params: {
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'basket-clean',
      flags: ['private'],
      defaultRequestData: {
        method: 'clean',
        model: "basket"
      },
      requiredRequestData: {
        params: {
          "packageId": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'basket-getcodeinfo',
      flags: ['private'],
      defaultRequestData: {
        method: 'getcodeinfo',
        model: "basket"
      },
      requiredRequestData: {
        params: {
          "fullcode": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'basket-updatebasket',
      flags: ['private'],
      defaultRequestData: {
        method: 'updatebasket',
        model: "basket"
      },
      requiredRequestData: {
        params: {
          // "items":[{"id":1, days: 31},{"id":4, days: 31}],
          // "coupons":[{"code":"ENCP-0000"}]
          "items": {type: "array" },
          "coupons": {type: "array" }
        }
      },
      publicPath: null
    }
  ],


  [
    '{grimm_api_url}',
    {
      alias: 'basket-getcontent',
      flags: ['private'],
      defaultRequestData: {
        method: 'getcontent',
        model: "basket"
      },
      requiredRequestData: {
        params: {
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'check-basketcoupon',
      flags: ['private'],
      defaultRequestData: {
        method: 'basketcoupon',
        model: "check"
      },
      requiredRequestData: {
        params: {
          code: {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'check-packagecode',
      flags: ['private'],
      defaultRequestData: {
        method: 'packagecode',
        model: "check"
      },
      requiredRequestData: {
        params: {
          code: {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'subscribe-subscriptions',
      flags: ['private'],
      defaultRequestData: {
        method: 'subscriptions',
        model: "subscribe"
      },
      requiredRequestData: {
        params: {

        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'basket-getlasttransaction',
      flags: ['private'],
      defaultRequestData: {
        method: 'getlasttransaction',
        model: "basket"
      },
      requiredRequestData: {
        params: {
        }
      },
      publicPath: null
    }
  ],

  [ // ez fizetés
    '{grimm_api_url}',
    {
      alias: 'basket-geturl',
      flags: ['private'],
      defaultRequestData: {
        method: 'geturl',
        model: "basket",
        params: {
          customer_Country: "Magyarország"
        }
      },
      requiredRequestData: {
        params: { // TODO: frissíteni, változni fognak a prop nevek
          "customer_Name": {type: "string" },
          "customer_ZipCode": {type: "string" },
          "customer_Country": {type: "string" },
          "customer_City": {type: "string" },
          "customer_Address": {type: "string" },
          "customer_TaxNumber": {type: "string" }
        }
      },
      publicPath: null
    }
  ],

  [
    '{grimm_api_url}',
    {
      alias: 'basket-getpayment',
      flags: ['private'],
      defaultRequestData: {
        method: 'getpayment',
        model: "basket"
      },
      requiredRequestData: {
        params: {
          code: {type: "string" }
        }
      },
      publicPath: null
    }
  ]
];
