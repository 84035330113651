import Nope from 'nope-validator';
// import messages from '../../../messages';
export const email = Nope.string().email('invalidEmail');


export const username = Nope.string()
  .test(value => email.validate(value.trim()))
  .atMost(255)
  .required('requiredField');

export const password = Nope.string()
  .greaterThan(7, 'tooShortPassword')
  .required('requiredField');

export const password_verify = Nope.string()
  .greaterThan(7, 'tooShortPassword')
  .oneOf([Nope.ref("new-password")], 'passwordNotMatch')
  .required('requiredField');

export const verification_code = Nope.string()
  .greaterThan(4, 'tooShort')
  .required('requiredField');

export const terms = Nope.boolean()
  .true('termsUnchecked')
  .required('requiredField');
