

(function() { // query selector all :scope support

  if (typeof Element === "undefined" || typeof document === "undefined") {
    return;
  }

  try {
    document.querySelector(':scope > body');
  }
  catch (error) {
    (function(Prot) {
      var scope = /:scope(?![\w-])/gi;
      var querySelectorWithScope = polyfill(Prot.querySelector);

      Prot.querySelector = function querySelector(selectors) {
        return querySelectorWithScope.apply(this, arguments);
      };

      var querySelectorAllWithScope = polyfill(Prot.querySelectorAll);

      Prot.querySelectorAll = function querySelectorAll(selectors) {
        return querySelectorAllWithScope.apply(this, arguments);
      };

      if (Prot.matches) {
        var matchesWithScope = polyfill(Prot.matches);

        Prot.matches = function matches(selectors) {
          return matchesWithScope.apply(this, arguments);
        };
      }

      if (Prot.closest) {
        var closestWithScope = polyfill(Prot.closest);

        Prot.closest = function closest(selectors) {
          return closestWithScope.apply(this, arguments);
        };
      }

      function polyfill(qsa) {
        return function(selectors) {
          var hasScope = selectors && scope.test(selectors);
          if (hasScope) {
            var attr = 'q' + Math.floor(Math.random() * 9000000) + 1000000;
            arguments[0] = selectors.replace(scope, '[' + attr + ']');
            this.setAttribute(attr, '');
            var elementOrNodeList = qsa.apply(this, arguments);
            this.removeAttribute(attr);
            return elementOrNodeList;
          }
          else {
            return qsa.apply(this, arguments);
          }
        };
      }
    })(Element.prototype);
  }
})();


// eslint-disable-next-line no-undef
if (typeof NodeList !== "undefined" && !NodeList.prototype.forEach) {
  // eslint-disable-next-line no-undef
  NodeList.prototype.forEach = Array.prototype.forEach;
}



if (typeof FileReader !== "undefined" && !FileReader.prototype.readAsBinaryString) {
  FileReader.prototype.readAsBinaryString = function(fileData) {
    var binary = "";
    var pt = this;
    var reader = new FileReader();
    reader.onload = function(e) {
      var bytes = new Uint8Array(reader.result);
      var length = bytes.byteLength;
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      // pt.result  - readonly so assign content to another property
      pt.content = binary;
      pt.onload(); // thanks to @Denis comment
    };
    reader.readAsArrayBuffer(fileData);
  };
}
