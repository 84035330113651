/* eslint-disable react/prop-types */
import React from 'react';
import ReferralLink from './ReferralLink';
import messages from '../../../messages';

const ActivateLink = ({username}) => (
  <ReferralLink
    link="/user/activate/"
    paramName="sentto"
    username={username}
    prefix={messages.get('user.activateLink.prefix')}
    linkText={messages.get('user.activateLink.linkText')}
    suffix={messages.get('user.activateLink.suffix')}
  />
);

export default ActivateLink;
