import {useEffect, useRef} from 'react';
import { DEV_MODE } from '../../../controller/devel';
// import {useIsJustMounted} from '../../../hooks';

// not used
const useOnDictOrLangChange = (callback, {curSrc, curTrg, curDictId, userData, isJustMounted}) => {
  const refCallback = useRef();

  useEffect(() => {
    refCallback.current = callback || (() => console.warn('useOnDictOrLangChange: callback is not defined'));
  }, [callback]);

  useEffect(() => {
    if (!isJustMounted) {
      DEV_MODE && console.log('useOnDictOrLangChange', {curSrc, curTrg, curDictId, userData, isJustMounted});
      refCallback.current();
    }
    else {
      DEV_MODE && console.log('useOnDictOrLangChange: NOT MOUNTED', {curSrc, curTrg, curDictId, userData, isJustMounted});
    }
  }, [curDictId, curSrc, curTrg, userData, isJustMounted]);
};

export default useOnDictOrLangChange;



export const useOnForcedSubmit = (callback, {forcedSubmits, isJustMounted}) => {
  const refCallback = useRef();

  useEffect(() => {
    refCallback.current = callback || (() => console.warn('useOnForcedSubmit: callback is not defined'));
  }, [callback]);

  useEffect(() => {
    if (!isJustMounted) {
      DEV_MODE && console.log('useOnForcedSubmit', {forcedSubmits, isJustMounted});
      refCallback.current();
    }
    else {
      DEV_MODE && console.log('useOnForcedSubmit: NOT MOUNTED', {forcedSubmits, isJustMounted});
    }
  }, [forcedSubmits, isJustMounted]);
};


// useEffect(() => {
//   if (!isJustMounted) { onSubmit(); }
// // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [state.curDictId, state.curSrc, state.curTrg, userData]); // must not included: isJustMounted
