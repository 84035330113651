import {DEV_MODE} from '../../controller/devel';

const selectionApiSupported = !!(window.getSelection && document.createRange);

// example:
// const savedSel = saveSelection(document.getElementById('myDiv'));
// restoreSelection(document.getElementById('myDiv'), savedSel);


const debug = false && DEV_MODE;

function createCaretPlacer(beginningOrEnd) {
  return function(el) {
    if (document.activeElement !== el) {
      el.focus();
    }
    if (typeof window.getSelection !== "undefined" &&
      typeof document.createRange !== "undefined") {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(beginningOrEnd);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
    else if (typeof document.body.createTextRange !== "undefined") {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(beginningOrEnd);
      textRange.select();
    }
  };
}

const placeCaretToStart = createCaretPlacer(true);
const placeCaretToEnd = createCaretPlacer(false);


function saveSelection(containerEl) {
  const doc = containerEl.ownerDocument, win = doc.defaultView || doc.parentWindow;

  if (selectionApiSupported) {
    const sel = win.getSelection();
    debug && console.warn('sel', sel?.anchorNode);
    if (!sel || sel.rangeCount <= 0) return null;
    const range = sel.getRangeAt(0);

    if (sel.anchorNode === containerEl ||
      containerEl.contains(sel.anchorNode)
      // sel.focusNode === containerEl ||
      // Range.commonAncestorContainer === containerEl ||

    ) {
      debug && console.warn('sel ok', sel.anchorNode, range.commonAncestorContainer);
    }
    else {
      debug && console.warn('sel not ok', sel.anchorNode, range.commonAncestorContainer);
    }
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(containerEl);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    const start = preSelectionRange.toString().length;

    return {
      start,
      end: start + range.toString().length
    };
  }
  else if (document.selection) {
    const selectedTextRange = doc.selection.createRange();
    const preSelectionTextRange = doc.body.createTextRange();
    preSelectionTextRange.moveToElementText(containerEl);
    preSelectionTextRange.setEndPoint("EndToStart", selectedTextRange);
    const start = preSelectionTextRange.text.length;

    return {
      start,
      end: start + selectedTextRange.text.length
    };
  }
  return null;
}

function restoreSelection(containerEl, savedSel) {
  const doc = containerEl.ownerDocument, win = doc.defaultView || doc.parentWindow;

  if (selectionApiSupported) {
    let charIndex = 0;
    const range = doc.createRange();
    range.setStart(containerEl, 0);
    range.collapse(true);
    const nodeStack = [containerEl];
    let node;
    let foundStart = false;
    let stop = false;

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        const nextCharIndex = charIndex + node.length;
        if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
          range.setStart(node, savedSel.start - charIndex);
          foundStart = true;
        }
        if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
          range.setEnd(node, savedSel.end - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      }
      else {
        let i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    const sel = win.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    debug && console.warn('sel restored', sel?.anchorNode);
  }
  else if (document.selection) {
    const textRange = doc.body.createTextRange();
    textRange.moveToElementText(containerEl);
    textRange.collapse(true);
    textRange.moveEnd("character", savedSel.end);
    textRange.moveStart("character", savedSel.start);
    textRange.select();
  }

  return null;
}

class SaveAndRestoreSelection {
  constructor(el) {
    this.el = null;
    this.saved = null;
    this.disabled = false;
    if (el) { this.setEl(el); }
  }

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }

  setEl(el) {
    // eslint-disable-next-line no-undef
    if (typeof el !== 'string' && typeof el !== 'function' && !(el instanceof HTMLElement)) {
      throw new Error('SaveAndRestoreSelection.setEl: el must be a string or a function or an HTMLElement');
    }
    this.el = el;
  }

  getEl() {
    if (typeof this.el === 'function') {
      return this.el.call(null);
    }
    else if (typeof this.el === 'string') {
      return document.querySelector(this.el);
    }
    return this.el;
  }

  getSelection() {
    return this.saved;
  }

  clear() {
    this.saved = null;
  }

  save() {
    if (this.disabled) { return; }
    this.saved = saveSelection(this.getEl());
    debug && console.log('SaveAndRestoreSelection.save', this.saved);
  }

  restore() {
    if (this.disabled) { return; }
    if (this.saved) {
      restoreSelection(this.getEl(), this.saved);
      debug && console.log('SaveAndRestoreSelection.restore', this.saved);
      // this.saved = null;
      return true;
    }
    return false;
  }

  setToEnd() {
    if (this.disabled) { return; }
    debug && console.log('SaveAndRestoreSelection.setToEnd');
    placeCaretToEnd(this.getEl());
  }

  setToStart() {
    if (this.disabled) { return; }
    debug && console.log('SaveAndRestoreSelection.setToStart');
    placeCaretToStart(this.getEl());
  }
}


export {
  saveSelection,
  restoreSelection,
  SaveAndRestoreSelection
};

export default SaveAndRestoreSelection;
