import broadcastChannels from "../controller/broadcastChannels";
import {DEV_MODE, PREVIEW_MODE} from '../controller/devel';

const LOG = DEV_MODE || PREVIEW_MODE;

export class LoginObserver {
  constructor({api, intervalMs = 60 * 1000}, onChange) {
    this.api = api;
    this.id = this._shared.instanceIndex++;
    this.channelId = `LoginObserver-${this.id}`;
    this._timerId = null;
    this.intervalMs = intervalMs;
    this._onChange = (notify) => {
      if (typeof onChange === 'function') {
        onChange({...this.state}, notify);
      }
    };
    this.state = {
      loggedin: null,
      user: null,
      checksum: null
    };

    this.api.requestEmitter.on('sessAuthChange', (alias, sessAuth) => {
      if (this.state.loggedin !== null && this.state.loggedin !== sessAuth) {
        LOG && console.warn('LoginObserver: sessAuthChange -> checkLogin()', this.state.loggedin, sessAuth);
        this.checkLogin();
      }
    });

    broadcastChannels.onMessage(this.channelId, ({data}) => {
      const {loggedin, user, sessId} = data || {};
      if (typeof sessId === 'string' && !this.api.matchSessId(sessId)) {
        this.api.updateSessId(sessId);
      }
      if (typeof loggedin === 'boolean' && typeof user === 'object') {
        this._setState({loggedin, user}, {triggerChange: true, notify: true});
        // console.log('broadcastChannels: login', loggedin, user, sessId);
      }
    });

    this.api.requestEmitter.on('user-logout', (/* alias, data */) => { // TODO: -> not used
      // this.api.clearSession();
      this.checkLogin();
    });
  }

  _getChecksum(user) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {user_dict_access, user_access, ...userRest} = user;
    return user && typeof user === 'object'
      ? JSON.stringify(userRest)
      : null
    ;
  }

  _shouldStateChange({loggedin, user, checksum: _checksum}) {
    const checksum = _checksum || this._getChecksum(user);
    const changed = (
      (this.state.checksum !== checksum && "checksum") ||
      (this.state.loggedin !== loggedin && "loggedin")
    );
    return changed;
  }

  _setState(
    {loggedin, user, checksum: _checksum},
    {broadcast = false, triggerChange = false, notify = false}
  ) {
    const checksum = _checksum || this._getChecksum(user);

    if (
      this._shouldStateChange({loggedin, user, checksum})
    ) {
      this.state = { loggedin, checksum, user };
      if (broadcast) {
        broadcastChannels.postMessage(this.channelId, {
          loggedin,
          user,
          sessId: this.api.getSessId()
        });

      }
      if (triggerChange) {
        this._onChange(notify);
      }
    }
  }

  setState({loggedin, user}) {
    this._setState({loggedin: !!loggedin, user}, {broadcast: true});
  }

  start() {
    this.checkLogin();
    this._timerI = setInterval(() => this.checkLogin(), this.intervalMs);
  }

  stop() {
    clearInterval(this._timerId);
  }

  checkLogin(fn) {
    this.api.getEndpointByAlias('user-isloggedin').fetch({
      requestData: {
        params: {}
      }
    }, (status, data) => {
      if (status === this.api.ENUM.success) {
        const {loggedin, user, forced_logout} = data;
        this._setState({loggedin, user}, {
          forcedLogout: forced_logout, // TODO: -> api.instances
          triggerChange: true,
          broadcast: true
        });
        if (typeof fn === 'function') {
          fn(loggedin);
        }
      }
    }, true);
  }
}

LoginObserver.prototype._shared = {
  instanceIndex: 0
};


export default LoginObserver;
