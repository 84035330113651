import toast from 'react-hot-toast';
import ttsSpeak from './lib/ttsSpeak';
import objectMatch from './lib/objects/objectMatch';
import messages from './messages';

import { getDeepProp, setDeepProp } from './lib/objects/deepOps';

import {
  lsPropNames,
  toastOptions
} from './setup';

import {
  isExternalUrl
} from './lib/util.js';


const initAppPub = (app) => {
  const appPub = {
    api: app.api,
    ttsSpeak: (...args) => {
      ttsSpeak(app.refTTSAudio, ...args);
    },
    toast,
    toastOnce: (() => {
      const toastIds = {};
      return (id, msg, options) => {
        if (!id) {
          toast(msg, options);
          return;
        }
        if (toastIds[id]) {
          toast.dismiss(toastIds[id]);
        }
        return (toastIds[id] = toast(msg, options));
      };
    })(),
    toastMsg: (
      msg,
      toastId = null,
      { type = 'BLANK', position = "top-center", ...rest} = {}
    ) => {
      const TYPE = toastOptions.enums[type || 'BLANK'] || 'BLANK';
      const options = {
        type: TYPE,
        ...(toastOptions[TYPE] || {}),
        position: position || 'top-center',
        ...rest
      };
      appPub.toastOnce(toastId, Array.isArray(msg) ? msg.join(' \n') : msg, options);
    },
    toastErrorMsg: (msg, toastId) => appPub.toastMsg(msg, toastId, {type: 'ERROR'}),
    toastSuccessMsg: (msg, toastId) => appPub.toastMsg(msg, toastId, {type: 'SUCCESS'}),
    toastWarnMsg: (msg, toastId) => appPub.toastMsg(msg, toastId, {type: 'WARNING'}),

    refTTSAudio: app.refTTSAudio,
    locationData: app.locationData,
    eventEmitter: app.eventEmitter,
    scrollTo: (...args) => app.scrollTo(...args),
    setHtmlClasses: s => {
      app.setHtmlClasses(s);
    },
    setStateDeep: (prop, val, fn) => app._setState({
      ...setDeepProp(app.state, prop, val)
    }, fn),
    getStateDeep: (prop) => getDeepProp(app.state, prop),
    get: (prop) => {
      return app.state[prop];
    },
    set: (state, fn) => {
      app._setState({
        ...app.state,
        ...state
      }, fn);
    },

    getDictList: () => app.state.dictList || [],
    getUserData: () => app.state.user.data || {},
    getLogin: () => appPub.getStateDeep('user.data'),
    isLoggedIn: () => appPub.getStateDeep('user.data.loggedin'),
    updateuUserDictAccess: (user_dict_access, mutate = "auto") => {
      if (!user_dict_access) {
        console.warn('user_dict_access not passed');
        return;
      }

      const doMutate = mutate === "auto"
        ? (!objectMatch(app.state.user.data.user_dict_access, user_dict_access))
        : mutate === "mutate"
      ;
      // console.log('mutate', doMutate);

      if (doMutate) {
        appPub.setStateDeep('user.data', {
          ...app.state.user.data, user_dict_access
        });
      }
      else {
        appPub.setStateDeep('user.data.user_dict_access', user_dict_access);
      }
    },
    login: (user, redirect, notify) => {

      app.loginObserver.setState({ loggedin: true, user: user });
      appPub.setStateDeep('user.data', {
        loggedin: true,
        ...user
      }, () => {
        if (redirect) {
          app.props?.history?.push(
            typeof redirect === 'string' && !isExternalUrl(redirect)
              ? redirect
              : '/',
            {}
          );
        }
        if (notify) {
          const msg = messages.get('user.login.loginSuccess');
          // console.log('---login---', {user, redirect, notify, msg, mounted: app._mounted});
          appPub.toastSuccessMsg(msg, 'user-login');
        }
      });
    },
    logout: (anonymUser, redirect, notify) => {
      if (appPub.isLoggedIn() === false) {
        lsPropNames.clear();
      }

      app.loginObserver.setState({ loggedin: false, user: anonymUser });
      appPub.setStateDeep('user.data', {
        loggedin: false,
        ...(anonymUser || {})
      }, () => {
        if (redirect) {
          app.props?.history?.push(
            typeof redirect === 'string' && !isExternalUrl(redirect)
              ? redirect
              : '/',
            {}
          );
        }

        if (notify) {
          const msg = messages.get('user.logout.logoutSuccess');
          // console.log('---logout---', {anonymUser, redirect, notify, msg, mounted: app._mounted});
          appPub.toastSuccessMsg(msg, 'user-logout');
        }
      });
    },
    getLoginObserver: () => app.loginObserver
  };

  return appPub;
};


export default initAppPub;
