
import React, { PureComponent } from "react";
import debounceAnimationFrame from "debounce-animation-frame";
import ReactCrop from "react-image-crop";
import PropTypes from 'prop-types';
import {glindow} from "../../../controller/globals";
import "react-image-crop/dist/ReactCrop.css";
import DEV_MODE from "../../../controller/devel";
import messages from "../../../messages";

const File = glindow.File;



class OcrToolCrop extends PureComponent {
  constructor(props) {
    super(props);

    const dims = props.dimensions;
    const widthPrc = dims.width > dims.height ? (dims.height / dims.width) * 100 : 100;
    const heightPrc = dims.height > dims.width ? (dims.width / dims.height) * 100 : 100;
    const xPrc = widthPrc === 100 ? 0 : (100 - widthPrc) / 2;
    const yPrc = heightPrc === 100 ? 0 : (100 - heightPrc) / 2;

    this.state = {
      setup: {
        minWidth: dims ? Math.min(dims.width, 100) : 100,
        minHeight: dims ? Math.min(dims.height, 100) : 100
      },
      prcCrop: {
        unit: "%",
        // aspect: 1,
        width: widthPrc - 2,
        height: heightPrc - 2,
        x: xPrc + 1,
        y: yPrc + 1
      },
      croppedFile: null,
      croppedImageBlob: null,
      croppedImageUrl: null
    };
  }

  imgSetup = {
    mimeType: "image/jpeg",
    quality: 0.9
  };

  selectAll(callback) {
    if (!this.imageRef) return;

    this.makeClientCrop({
      aspect: void 0,
      x: 0,
      y: 0,
      unit: "px",
      height: this.imageRef.height,
      width: this.imageRef.width
    });

    this.setState({
      prcCrop: {
        aspect: void 0,
        x: 0,
        y: 0,
        unit: "%",
        height: 100,
        width: 100
      }
    }, callback);

  }

  onImageLoaded = (image) => {
    this.imageRef = image;
    // return false;
  };

  onCropComplete = (pxCrop) => {
    this.makeClientCrop(pxCrop);
  };

  onCropChange = debounceAnimationFrame((pxCrop, prcCrop) => {
    this.setState({ pxCrop, prcCrop });
    // console.log('CHANGE', prcCrop);
  });

  getData = () => {
    return {
      dimensions: this.props.dimensions,
      ...this.state
    };
  };

  async makeClientCrop(pxCrop) {
    if (this.imageRef && pxCrop.width && pxCrop.height) {
      const {croppedImageUrl, croppedImageBlob, croppedFile} = await this.getCroppedImg(
        this.imageRef,
        pxCrop,
        "newOcrFile.jpg"
      );
      this.setState({ croppedImageUrl, croppedImageBlob, croppedFile });
    }
    else {
      this.setState({ croppedImageUrl: null, croppedImageBlob: null, croppedFile: null });
    }
  }

  getCroppedImg(image, pxCrop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio || 1;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const desiredWidth = pxCrop.width * pixelRatio * scaleX;
    const desiredHeight = pxCrop.height * pixelRatio * scaleY;

    const mpxCur = desiredWidth + desiredHeight;
    const mpxMax = 1024 * 4; // ~4 mpx
    const reduceRatio = mpxCur > mpxMax ? (mpxMax / mpxCur) : 1;

    canvas.width = desiredWidth * reduceRatio;
    canvas.height = desiredHeight * reduceRatio;

    ctx.setTransform(
      1 * pixelRatio * reduceRatio,
      0, 0,
      1 * pixelRatio * reduceRatio,
      0, 0
    );
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      pxCrop.x * scaleX,
      pxCrop.y * scaleY,
      pxCrop.width * scaleX,
      pxCrop.height * scaleY,
      0,
      0,
      pxCrop.width * scaleX,
      pxCrop.height * scaleY
    );

    // console.log({
    //   reduceRatio,
    //   desiredWidth,
    //   desiredHeight,
    //   reducedWidth: canvas.width,
    //   reducedHight: canvas.height,
    //   mpxCur: desiredWidth * desiredHeight,
    //   mpxRed: canvas.width * canvas.height
    // });

    return new Promise((resolve, reject) => {
      // toDataURL
      canvas.toBlob((blob) => {
        if (!blob) {
          // reject(new Error('Canvas is empty'));
          DEV_MODE && console.warn("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        const croppedFile = new File([blob], fileName, { type: this.imgSetup.mimeType });

        resolve({croppedImageUrl: this.fileUrl, croppedImageBlob: blob, croppedFile});
      }, this.imgSetup.mimeType, this.imgSetup.quality);
    });
  }

  render() {
    const { prcCrop, croppedImageUrl } = this.state;
    const { src } = this.props;
    // console.log(croppedImageUrl,src)
    // console.log(crop, croppedImageUrl, src, this.imageRef );
    return (
      <div className={this.props.className}>
        {src && (
          <ReactCrop
            // minWidth={this.state.setup.minWidth}
            // minHeight={this.state.setup.minHeight}
            src={src}
            imageAlt={messages.get('ocrTool.altText')}
            crop={prcCrop}
            disabled={this.props.loading}
            // keepSelection={true}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onImageError={this.props.onError}
            onChange={this.onCropChange}
            // onDragStart(event) (optional)
            // onDragEnd(event) (optional)
          />
        )}
        {croppedImageUrl && DEV_MODE && (
          <img className={this.props.className + '__preview'} alt="Crop" src={croppedImageUrl} />
        )}
      </div>
    );
  }
}



OcrToolCrop.propTypes = {
  src: PropTypes.string,
  loading: PropTypes.bool,
  dimensions: PropTypes.shape({
    naturalWidth: PropTypes.number,
    naturalHeight: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number
  }),
  className: PropTypes.string,
  onError: PropTypes.func

};

export default OcrToolCrop;
