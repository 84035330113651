import { sesStore, locStore, locUserStore } from "./lib/BrowserStore";
import { notEmpty, removeCombiningChars } from "./lib/util";
import { PREVIEW_MODE } from "./controller/devel";
import {ENTER_KEY_MODES} from "./base/dictionary/enums";

if (typeof process === 'undefined') {
  window.process = {};
}

export const apiUrl = process?.env?.RAZZLE_ONLINESZOTAR === "on"
  ? process.env.RAZZLE_API_URL_ONLINESZOTAR // TODO: remove after ONLINE SZOTAR finished
  : process?.env?.RAZZLE_DEV2 === "on"
    ? process?.env?.RAZZLE_API_URL_DEV2
    : process.env.RAZZLE_API_URL
;

PREVIEW_MODE && console.warn('RAZZLE apiUrl', apiUrl);

export const ttsServiceUrl = process.env.RAZZLE_TTS_SERVICE_URL;

export const ttsVoiceFilter = { // depecrated
  de: { // TODO: lehet, hogy inkább szótáranként kellene
    presets: {
      default: (function() {
        const charMap = {
          '¦': '',
          '*': '',
          '̣ạ̈': 'ä',
          'ạ': 'a',
          'ẹ': 'e',
          'ọ': 'o',
          'ụ': 'u',
          'ị': 'i'
        };

        return async(text) => {
          const newText = await removeCombiningChars(text, charMap);
          // if (text !== newText) {
          //   console.warn('ttsVoiceFilter: text chnaged', text, '-> ', newText);
          // }
          return newText;
        };
      })()
      // orthvar: (text) => {
      //   return text.replace(/\S\([^()\s]+\)\S/g, '');
      // }
    }
  }
};



export const selectorsToTts = {
  foreignSrc: [
    '.fixform',
    '.orthvar',
    '.phorth',
    '.egorth',
    '.refl',
    '.collorth',
    '.compl_src'
  ].join(', '),
  nativeSrc: [
    '.senseid + span', // TODO: nem biztos, hogy jó, Polyák Adri kérése kéziszótár magyar-angol esetén
    '.tr_tan',
    '.tr',
    '.egtr',
    '.colltr',
    '.phtr'
  ].join(', '),
  exclude: [
    '.sr-only',
    '.gend',
    '.slot',
    '.plur',
    '.gend', // TODO: nem működik: olasz magyar - ingyenes "miniszterelnök"
    '.irreg'
  ].join(', ')
};

export const replacementsMapByDict = {
  regional: {
    selector: '.inline',
    items: {
      "AmE": "American English (amerikai angol)",
      "BrE": "British English (brit angol)"
    }
  },
  style: {
    selector: '.info',
    items: {
      "biz": "bizalmas hangvételû, kötetlen, nem hivatalos",
      "hiv": "hivatalos",
      "iron": "ironikus",
      "pej": "elítélõ, pejoratív",
      "szl": "szleng",
      "tréf": "tréfás",
      "vál": "választékos nyelvhasználat",
      "vulg": "durva, vulgáris"
    }
  },
  pos: {
    selector: '.pos',
    items: {
      "ált névm": "általános névmás",
      "birt névm": "birtokos névmás",
      "C": "countable noun (megszámlálható főnév)",
      "csak hátravetve": "posztponált helyzetben",
      "det": "determiner (determináns)",
      "elölj": "elöljárószó",
      "előtag": "előtag",
      "esz": "egyes szám",
      "fn": "főnév",
      "hsz": "határozószó",
      "htl névelő": "határozatlan névelő",
      "ht névelő": "határozott névelő",
      "ige": "ige",
      "isz": "indulatszó",
      "kérd": "kérdőnévelő/kérdés",
      "kölcsönös névm": "kölcsönös névmás",
      "ksz": "kötőnévelőszó",
      "mn": "melléknév",
      "modális segédige": "modális segédige",
      "módsz": "módosítószó",
      "msz": "mondatszó",
      "mut névm": "mutató névmás",
      "neg": "negative form (tagadó alak)",
      "névm": "névmás",
      "nu": "névutó",
      "összet": "összetétel",
      "pl": "plural (többes szám)",
      "pp": "past participle (múlt idejû melléknévi igenév)",
      "pres part": "present participle (jelen idejû melléknévi igenév)",
      "pt": "past simple (egyszerû múlt)",
      "segédige": "segédige",
      "sg": "something (valami)",
      "sing": "singular (egyes szám)",
      "sorszn": "sorszámnév",
      "sy": "somebody (valaki)",
      "swhere": "somewhere (valahol)",
      "szem névm": "személyes névmás",
      "szn": "számnév",
      "törtszn": "törtszámnév",
      "tőnévelőszn": "tőnévelőszámnév",
      "tsz": "többes szám",
      "U": "uncountable noun (megszámlálhatatlan főnév)",
      "utótag": "utótag",
      "vh névm": "visszaható névmás",
      "von hsz": "vonatkozó határozószó",
      "von ksz": "vonatkozó kötõszó",
      "von névm": "vonatkozó névmás"
    }
  },
  prof: {
    selector: '.info',
    items: {
      "áll": "állattan",
      "asztrol": "asztrológia",
      "bibl": "bibliai",
      "biol": "biológia",
      "el": "elektronika",
      "ép": "építészet/építõipar",
      "fényk": "fényképezés",
      "film": "film",
      "fiz": "fizika",
      "földr": "földrajz",
      "gk": "gépkocsi",
      "hajó": "hajózás",
      "infor": "informatika",
      "irod": "szépirodalom",
      "isk": "iskola",
      "ját": "játékok",
      "jog": "jogtudomány",
      "kat": "katonaság",
      "kém": "kémia",
      "körny": "környezetvédelem",
      "közl": "közlekedés",
      "mat": "matematika",
      "mezõg": "mezõgazdaság",
      "mûsz": "mûszaki",
      "növ": "növénytan",
      "nyelvt": "nyelvtudomány, nyelvtan",
      "okt": "oktatás(ügy), iskolai élet, nevelés",
      "orv": "orvostudomány",
      "pol": "politika",
      "pszich": "pszichológia",
      "rep": "repülés",
      "sp": "sport",
      "szính": "színház",
      "távk": "távközlés",
      "tört": "történelem, történettudomány",
      "vall": "vallás",
      "vill": "villamosság",
      "zene": "zenemûvészet"
    }
  },
  others: {
    selector: '.info',
    items: {
      "ált": "általában",
      "átv": "átvitt értelemben",
      "kb.": "körülbelül",
      "pl.": "például",
      "rendsz.": "rendszerint",
      "ritk": "ritka",
      "röv": "rövidítés",
      "stb.": "és a többi",
      "ua.": "ugyanaz",
      "v.": "vagy",
      "/": "vagylagosság jele",
      "=": "ugyanaz, mint",
      "→": "lásd ott, lásd még"
    }
  },
  lava: {
    symbol: {
      "▶": "többszava ige",
      "♦": "címszóval alkotott idiómák vagy kifejezések",
      "‖": "szókapcsolatok",
      "≠": "összetéveszthető álbarátok (hamis barátok) figyelmeztető jele.",
      "i": "összetéveszthető álbarátok (hamis barátok) figyelmeztető jele.",
      "[!]": "forrásnyelvi eredeti és a fordítás közötti stiláris különbözőséget jelöl"
      // "{…}": " bármely értelemszerûen betehető szó vagy mondat",
      // "/": "választási lehetőségre utal",
      // " / ": "több szó között áll fenn választási lehetőség"
    },
    abbrv: {
      "afr": "afrikai használat",
      "am": "amerikai használat",
      "ausz": "ausztráliai használat",
      "br": "brit használat",
      "e.sz.": "egyes szám",
      "elölj": "elöljáró",
      "fnév": "főnév",
      "hat.szó": "határozószó",
      "ind": "indiai használat",
      "ír": "írországi használat",
      "kan": "kanadai használat",
      "kb.": "körülbelül",
      "mnév": "melléknév",
      "nem megszáml.": "nem megszámlálható főnév",
      "pl.": "például",
      "r.szerint": "rendszerint",
      "skót": "skóciai használat",
      "sz.": "személy",
      "sznév": "számnév",
      "t.sz.": "többes szám",
      "tbsz": "többes száma",
      "újz": "új-zélandi használat",
      "v": "vagy",
      "vhol": "valahol",
      "vhová": "valahová",
      "vki": "valaki",
      "vmely": "valamely",
      "vmerre": "valamerre",
      "vmi": "valami"
    }
  }
};
export const entryReplacementsMap = {
  en: [
    [/smb\/smth|sy\/sg/gim, 'somebody or something'],
    [/smth\/smb|sg\/sy/gim, 'something or somebody'],
    [/(\w)(\/)(\w)/gim, '$1 | $3'],
    // // (the)
    // [/(?:|^\s)smth|sg(?:\W|$)/gim, ' something'],
    // [/(?:|^\s)smb|sy(?:\W|$)/gim, ' somebody'],
    // [/([>(\s/]|^)(smth|sg)([)\s</]|$)/gim, 'something', true],
    // [/([>(\s/]|^)(smb|sy)([)\s</]|$)/gim, 'somebody', true],
    ["swehere", 'somewhere', true],
    ["smth", 'something', true],
    ["smb", 'somebody', true],
    ["sy's", "somebody's", true],
    ["sy’s", "somebody's", true],
    ["sg", 'something', true],
    ["sy", 'somebody', true]
  ],
  hu: [
    ["vmivé/vmilyenné", ' valamivé vagy valamilyenné', true],
    ["vmilyen helyzetbe", ' valamilyen helyzetbe', true],
    ["vmilyennek", ' valamilyennek', true],
    ["vmennyi", ' valamennyi', true],
    ["vkit/vmit", ' valakit vagy valamit', true],
    ["vmit/vkit", ' valamit vagy valakit', true],
    ["vmeddig", ' valameddig', true],
    ["vminek", ' valaminek', true],
    ["vkinek", ' valakinek', true],
    ["vmihez", ' valamihez', true],
    ["vhova", ' valahova', true],
    ["vkivel", ' valakivel', true],
    ["vmivel", ' valamivel', true],
    ["vmilyen", ' valamilyen', true],
    ["vamikor", ' valamikor', true],
    ["vmikor", ' valamikor', true],
    ["vkire", ' valakire', true],
    ["vmije", ' valamije', true],
    ["vmire", ' valamire', true],
    ["vahol", ' valahol', true],
    ["vhol", ' valahol', true],
    ["vmit", ' valamit', true],
    ["vmin", ' valamin', true],
    ["vmi", ' valami', true],
    ["vkit", ' valakit', true],
    ["vkin", ' valakin', true],
    ["vki", ' valaki', true]
  ],
  de: [
    // ["verspäten sichA", 'sich verspäten', true],
    // ["abschürfen sichD", 'sich abschürfen', true],
    ["sichA", ' sich', true], // nem kell feltételes [ad]?!
    ["sichD", ' sich', true], // nem kell feltételes [ad]?!
    ["etwA", ' etwas', true],
    ["etwD", ' etwas', true],
    ["jmdn", ' jemandem', true],
    ["etw", ' etwas', true],
    ["jd", ' jemand', true],
    ["jm", ' jemandem', true],
    ["jn", ' jemanden', true],
    ["js", ' jemandes', true]
    // ["SZÓCIKK/sich", 'sich SZÓCIKK', true],
    // TODO: tesztelni: ingyenes német magyar: leben -> etwas nem jó
    // TODO: tesztelni: ingyenes német magyar: bereiten -> reiten* <-- csillag benne marad
    // A sárgával jelölt résznél a dran||kommen kiejtésében a két | jel miatt nagyobb szünet van a kiejtésben, mint a különírt dran sein esetében – tehát ahogyan a zárójelek, a || jelek is megakasztják a kiejtést.
    // TODO: angol magyar kézi: pick -> pị¦cken így jelenik meg

  ],
  fr: [
    ["qn", " quelqu'un", true],
    ["qc", " quelque chose", true],
    ["qqpart", " quelque part", true]
  ],
  it: [
    ["qn", " qualcuno", true],
    ["qc", " qualcosa", true]
  ],
  ru: [
    ["кто-н.", " кто-нибудь", true],
    ["кого-н.", " кого-нибудь", true],
    ["кому-н.", " кому-нибудь", true],
    ["кем-н.", " кем-нибудь", true],
    ["в ком-н.", " в ком-нибудь", true],
    ["на ком-н.", " на ком-нибудь", true],
    ["о ком-н.", " о ком-нибудь", true],
    ["что-н.", " что-нибудь", true],
    ["чего-н.", " чего-нибудь", true],
    ["чему-н.", " чему-нибудь", true],
    ["чем-н.", " чем-нибудь", true],
    ["в чём-н.", " в чём-нибудь", true],
    ["на чём-н.", " на чём-нибудь", true],
    ["о чём-н.", " о чём-нибудь", true],
    ["чьи-н.", " чьи-нибудь", true],
    ["чью-н.", " чью-нибудь", true]
  ],
  es: [
    ["Vd.", " Usted", true]
  ]
};

export const ttsMaxLength = 254;
export const keepSession = true;
export const previewMode = process?.env?.RAZZLE_PREVIEW === "1";
export const initPageTitle = process?.env?.RAZZLE_INITIAL_PAGE_TITLE;
export const numOfMaxWords = 50;
export const submitButtonEnabled = false;

export const href_pdfTerms = process.env.PUBLIC_PATH + 'docs/grimm_oszr_aszf.pdf';
export const href_pdfPrivacy = process.env.PUBLIC_PATH + 'docs/adatkezelesi_tajekoztato.pdf';

export const mobileBreakPoint = 959;

export const sourceAreaSetup = {
  enterKeyMode: ENTER_KEY_MODES.submitFewWords, // submitFewWords || 'submitAlways' || 'breakLine'
  enterKeyModeFewWordsLen: 4
};

export const subscriptionSetup = {
  maxExpiryByDays: 900 // szerver oldalon is módosítani kell
};

export const pubFeature = {
  szotarnaplo: false
};



export const amedia = null;
// export const amedia = {
//   '468x120': [
//     'https://bmw.digitalwave.hu/2022_Q1/i4/B468x120/GLASS_V1_NL/pub/',
//     'https://bmw.digitalwave.hu/2021_Q4/ixi4/B468x120/IXFEATURE_BUDMOT_NL/pub/',
//     'https://bmw.digitalwave.hu/2021_Q3/BPS_PremSel/B468x120/BUDMOT_NL/pub/'
//   ],
//   '160x600': [
//     'https://bmw.digitalwave.hu/2021_Q3/BPS_PremSel/B160x600/MAGISZ/pub/'
//   ]
// };

export const dictSettings = {
  initialValues: {
    src: 'en',
    trg: 'hu',
    dictId: 'eh-kis',
    inputHtml: '',
    inputText: ''
  }
};

export const specialCharsByLang = {
  "en": [['ʼ', 'aposztróf'], ['&', 'És jel']],
  "de": "äöüß".split(''),
  "hu": "áéíöőóüűú".split(''),
  "fr": "àâäèéêëîïőœùûüÿç".split(''),
  "nl": "".split(''),
  // "nl": "àáâäèéêëìíîïòóőöùúûüƒ".split(''),
  "pt": "àáâãéêíóőőnévelőúüç".split(''),
  "it": "àèéìòóù".split(''),
  "es": "áéíóúüñ¿¡".split(''),
  "ru": "абвгдеёжзийклмнопрстуфхцчшщъыьэюя".split('')
};

export const ttsVoiceLangTLD = {
  en: 'en-GB',
  hu: 'hu-HU',
  de: 'de-DE',
  fr: 'fr-FR',
  it: 'it-IT',
  ru: 'ru-RU',
  es: 'es-ES',
  nl: 'nl-NL'
};


export const nativeLang = 'hu';

export const tldMap = {
  de: 'Német',
  en: 'Angol',
  fr: 'Francia',
  hu: 'Magyar',
  it: 'Olasz',
  nl: 'Holland',
  ru: 'Orosz',
  es: 'Spanyol'
};


// const rxDictIdMatch = /(?:\w\w-)(.*)$/i;


// note: soap requests should to be proxied using soapProxy.js (_proxy/startSoapProxy.cmd)
export const routes = {
  // location change esetén pusztán param változás miatt nem kerül újratöltésre a page
  paramIndependent: [
    'foo-param-bar'
  ],
  isParamIndependentPath(pathname) {
    return !!this.paramIndependent.find(path => pathname.indexOf(path) > -1);
  }
};

/** @type {import('scroll-js').ScrollToCustomOptions} */
export const scrolljsToOptions = {
  duration: 600,
  easing: 'ease-in-out'
};
export const stickyBlockOffset = 85;

export const userSetup = {
  verifyMailMinWaitMs: 60 * 1000
};



export const lsPropNames = (function() {
  const sessProps = {
    curInputHtml: 'trSourceHtml',
    curInputText: 'trSourceText',
    curSrc: 'trSource',
    curTrg: 'trTarget',
    curDictId: 'trDict',
    ttsText: 'ttstest_text',
    ttsVoice: 'ttstest_voice',
    entryViewA11y: 'entryViewA11y'
  };

  const localProps = {
    enterMode: 'trEnterMode'
  };

  const pub = {
    _clear(store, props) {
      // return console.warn('lsPropNames.clear() is temporary disabled for locUserStore');
      for (const p in props) {
        if (Object.hasOwnProperty.call(props, p)) {
          store.remove(props[p]);
        }
      }
    },
    _get: (props, prop) => {
      if (!props[prop]) {
        console.warn('getLsPropName() prop does not exist', prop);
        return prop;
      }
      return props[prop];
    },
    clear() {
      locUserStore.clear();
      this._clear(sesStore, sessProps);
      this._clear(locStore, localProps);
    },
    getSess(prop) {
      return this._get(sessProps, prop);
    },
    getLocal(prop) {
      return this._get(localProps, prop);
    }
  };

  // @ts-ignore
  window.addEventListener('error', () => {
    pub.clear();
    console.info('lsStore clear');
  });

  return pub;

})();


export const toastOptions = (function() {
  const enums = {
    BLANK: "blank",
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning"
  };

  return {
    enums,
    [enums.WARNING]: {
      type: enums.BLANK,
      duration: 2000,
      // style: {

      // },
      icon: '⚠️'
    }
  };
})();


const isExperimentalMode = () => notEmpty(
  locStore.get('experimental-mode')
);

if (window) {
  // @ts-ignore
  window.enableExperimentalMode = () => {
    locStore.set('experimental-mode', '1');
    // @ts-ignore
    window.location.reload(true);
  };
  // @ts-ignore
  window.disableExperimentalMode = () => {
    locStore.set('experimental-mode', null);
    // @ts-ignore
    window.location.reload(true);
  };
}



export default {
  dictSettings,
  toastOptions,
  lsPropNames,
  userSetup,
  ttsVoiceLangTLD,
  nativeLang,
  numOfMaxWords,
  isExperimentalMode,
  // getDictTypeSetupById,
  // getDictOrderIndexById,
  // dictTypesSetup,
  tldMap,
  scrolljsToOptions,
  stickyBlockOffset,
  initPageTitle,
  apiUrl,
  ttsServiceUrl,
  ttsMaxLength,
  keepSession,
  previewMode
};
