import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// https://github.com/renditions/react-img
import getSizes from '@renditions/get-sizes';
import getSrcset, { sortRenditions } from '@renditions/get-srcset';
import {DEV_MODE} from '../controller/devel';
import {previewMode} from '../setup';
// const placeholder = process.env.PUBLIC_PATH + `img/placeholder_16_9.jpg`;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// );
const allowedSizes = {
  width: [
    16, 24, 32, 64, 128, 256,
    360, 426, 480, 640, 768, 800, 848, 854,
    960, 1024, 1050, 1280, 1366, 1540, 1600, 1920, 2048, 2560, 2880, 3200, 3840
  ],
  height: [
    16, 24, 32, 64, 128, 256,
    144, 240, 360, 432, 450, 480, 540, 576, 720,
    768, 900, 1080, 1152, 1440, 1620, 1800, 2160, 2304
  ]
};

const defGetSrc = (filename, rendition) => {
  if ((DEV_MODE || previewMode) && rendition.width &&
    allowedSizes.width.indexOf(rendition.width) === -1
  ) {
    console.warn('Invalid image size', rendition.width, filename);
  }
  return `${filename}/w/${rendition.width}`;
};

const sortBreakpoints = b => {
  b.forEach(r => {
    r.numMinWidth = (r.mediaMinWidth || 0) - 0;
  });
  b.sort((a, b) => a.numMinWidth - b.numMinWidth);
  return b;
};

const getSizesFix = (size, breakpoints) => {
  let sizes = getSizes({ size, breakpoints });
  if (sizes.substr(-1) === ',') {
    sizes = sizes.slice(0, -1);
  }
  return sizes;
};

const Img = ({
  src,
  getSrc = defGetSrc,
  renditions,
  baseClass = "cmp-Img",
  className,
  size = null,
  breakpoints,
  autoSortRenditions = true,
  autoSortBreakpoints = true,
  imgRef = null,
  ...rest
}) => {
  // console.time('img.render()');
  // create a copy of renditions array for in-place transforms
  const renditionsConfig = renditions ? renditions.slice(0) : [];
  const breakpointsConfig = breakpoints ? breakpoints.slice(0) : [];


  if (renditions && renditions.length) {
    if (autoSortRenditions) {
      sortRenditions(renditionsConfig);
    }
    renditionsConfig.forEach(rendition => {
      rendition.src = getSrc(src, rendition);
    });
  }
  if (breakpointsConfig && breakpointsConfig.length) {
    if (autoSortBreakpoints) {
      sortBreakpoints(breakpointsConfig);
    }
  }
  // console.timeEnd('img.render()');
  return (
    <img
      ref={imgRef}
      className={classNames(baseClass, className)}
      src={renditionsConfig.length ? renditionsConfig[0].src : src}
      srcSet={renditions && renditions.length ? getSrcset(renditionsConfig) : null}
      sizes={breakpoints && breakpoints.length ? getSizesFix(size, breakpointsConfig) : size}
      {...rest}
    />
  );
};

// https://github.com/renditions/react-img

Img.propTypes = {
  className: PropTypes.string,
  imgRef: PropTypes.any,
  baseClass: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.string,
  breakpoints: PropTypes.arrayOf(PropTypes.shape({
    mediaMinWidth: PropTypes.string,
    size: PropTypes.string
  })),
  getSrc: PropTypes.func,
  renditions: PropTypes.arrayOf(PropTypes.shape({
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired
  })),
  autoSortRenditions: PropTypes.bool,
  autoSortBreakpoints: PropTypes.bool
};

export default Img;
