export const getDeepestChildElements = (function() {

  const getDeepestChildsElement = (el: Element, childs = []) => {
    const childElements = el.children;

    for (const childEl of childElements) {
      if (childEl.childElementCount === 0) {
        childs.push(childEl);
      }
      else {
        getDeepestChildsElement(childEl, childs);
      }
    }
    return childs;
  };

  return (el: Element) => {
    if (!el || el.nodeType !== 1) {
      console.error('getDeepestChildElements: el is not an Element', el);
      return [];
    }
    if (el.childElementCount === 0) {
      return [el];
    }
    return getDeepestChildsElement(el, []);
  };
})();

export default getDeepestChildElements;
