/* eslint-disable no-use-before-define */

import React, {useContext, useEffect} from 'react';

import PropTypes from 'prop-types';
import { UserContext } from '../../Contexts';
import { logoutHelper } from './userHelper';
import LayoutUnavailable from '../layout/LayoutUnavailable';
import LoaderSpinner from '../../components/misc/LoaderSpinner';
import { useIsMounted } from '../../hooks';
import { parseParams, isExternalUrl } from '../../lib/util';
import messages from '../../messages';


const PageLogOut = ({appPub, history, location}) => {
  const userData = useContext(UserContext);
  const isMounted = useIsMounted();
  const params = parseParams(location.search);
  const paramTarget = decodeURIComponent(location?.state?.target || params.target || '');
  const redirectUrl = !isExternalUrl(paramTarget) ? paramTarget : '/';


  useEffect(() => {
    const timer = setTimeout(() => {
      if (userData.loggedin) {
        logoutHelper({notify: true, redirect: false}, () => {
          if (isMounted) { history.replace(redirectUrl); }
        });
      }
      else if (isMounted && userData.loggedin !== null) {
        history.replace(redirectUrl);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [history, userData, isMounted, redirectUrl]);

  return (
    <LayoutUnavailable
      headline={messages.get('user.PageLogout.title')}
      baseClass="user-logout"
      appPub={appPub}
    >
      <LoaderSpinner style={"inline-loading"} />
    </LayoutUnavailable>
  );
};

PageLogOut.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PageLogOut;
