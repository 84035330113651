
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ContentModal = ({
  id,
  show,
  header,
  children,
  closeText = "Mégse",
  actionText = "OK",
  actionVariant = "primary",
  onHide,
  onAction,
  ...props
}) => {
  return (
    <Modal
      {...props}
      size="lg"
      onHide={onHide}
      show={show}
      aria-labelledby={id}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={id}>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>{closeText}</Button>
        <Button variant={actionVariant} onClick={onAction}>{actionText}</Button>
      </Modal.Footer>
    </Modal>
  );
};

ContentModal.propTypes = {
  id: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node,
  closeText: PropTypes.string,
  actionText: PropTypes.string,
  actionVariant: PropTypes.oneOf([
    'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link',
    'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'
  ]),
  onAction: PropTypes.func,
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default ContentModal;
