/* eslint-disable no-unused-vars */
export const enum LAYOUT_STATES {
  INITIAL = 'initial',
  SUBMITTED = 'submitted'
}

export const enum QUERY_STATUSES {
  success = 'success',
  pending = 'pending',
  error = 'error'
}
export const enum ENTER_KEY_MODES {
  submitFewWords = 'submitFewWords',
  submitAlways = 'submitAlways',
  breakLine = 'breakLine'
}
