
import React, {useReducer, useCallback, useRef, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Preloader from './Preloader';

import LanguageSwitcher from './LanguageSwitcher';
import {unicodeHelper} from '../../lib/util';
// import { sesStore } from '../../lib/BrowserStore';
import {numOfMaxWords, submitButtonEnabled, sourceAreaSetup} from '../../setup.js';
import {useIsMounted} from '../../hooks';
import {useIsJustMounted} from '../../hooks';
import {messages} from '../../messages';

import Layout from '../Layout';
import TrsSourceArea from './TrsSourceArea';

import FormSubmit from './components/FormSubmit';
import ResultEntries from './components/ResultEntries';
import MaxWordNotify from './components/MaxWordNotify';

import reducer from './reducer';
import OcrTool from './OcrTool/OcrTool';
import {normalizeSourceText, isSubmittableState} from './dictHelpers';
// import UAC from '../../UAC';
import {LAYOUT_STATES} from './enums';
import { UserContext, DictListContext} from '../../Contexts';
// import {filterEditableHtml} from '../../lib/filterHtml';
import {getEnterKeyMode} from "./components/LineBreakMode";
import useOnDictValidation from "./hooks/useOnDictValidation";
import useOnDictFormSubmit from "./hooks/useOnDictFormSubmit";
import useOnSourceKeyDown from "./hooks/useOnSourceKeyDown";
import {useOnForcedSubmit} from "./hooks/useOnStateChange";
// import {useOnDictOrLangChange} from "./hooks/useOnStateChange";
import useInitialDictState from "./hooks/useInitialDictState";
import useOnOcrResult from "./hooks/useOnOcrResult";

import "./_dictionary.scss";
import "./_entry-style-print.scss";
import "./_entry-style.scss";

const {enterKeyModeFewWordsLen} = sourceAreaSetup;

const autoSubmit = true;

unicodeHelper.setAbc('hu');

const headerButton = {
  href: "/user/signin",
  theme: "grimm-ghost",
  text: messages.get('Dictionary.signinBtn')
};

const Dictionary = ({appPub /* ,...props */}) => {
  // const {api} = appPub;
  const refSource = useRef();
  const refForm = useRef();
  const refTrsSourceArea = useRef();
  const refOrcFileInput = useRef();

  const isJustMounted = useIsJustMounted();
  const isMounted = useIsMounted();
  const dictList = useContext(DictListContext);
  const userData = useContext(UserContext);

  const [state, dispatch] = useReducer(reducer, useInitialDictState());

  const {curSrc, curTrg, curDictId, forcedSubmits} = state;

  useEffect(() => {
    if (userData.loggedin) {
      dispatch({type: 'userData', payload: {userData}});
    }
  }, [userData]);

  useOnDictValidation({dictList, dictId: state.curDictId, userData}, (valid, dict) => {
    if (!valid) dispatch({ type: 'validate_dict', payload: { dict } });
  });

  const onSourceTextChange = useCallback((e, {html, text, cleanHtml}) => {
    text = normalizeSourceText(text);
    dispatch({type: 'trSourceText', payload: {html, text, cleanHtml}});
    // console.log('onSourceTextChange', {html, text});
  }, []);

  const onSpecCharsVisibilityChange = useCallback((e, visible) => {
    e.preventDefault();
    const newValue = typeof visible === 'boolean' ? visible : !state.specCharsVisible;
    dispatch({type: 'specCharsVisible', payload: newValue});
  }, [state.specCharsVisible]);

  const onEnterKeyModeChange = useCallback(() => {
    dispatch({type: 'enterKeyMode', payload: getEnterKeyMode(state.enterKeyMode)});
  }, [state.enterKeyMode]);

  const onSourceWordSelectionChange = useCallback((e, word, index) => {
    dispatch({type: 'trSourceWord', payload: {word, index}});
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // console.log('onSourceWordSelectionChange', {word, index});
  }, []);

  const onSourceTextReset = useCallback(() => {
    dispatch({type: 'resetSearch', payload: null});
  }, []);

  const onOcrResult = useOnOcrResult({isMounted, dispatch});

  const onOcrClick = useCallback((e) => {
    e.preventDefault();
    refOrcFileInput.current.click();
  }, []);

  const onSubmit = useOnDictFormSubmit({
    refSource, refTrsSourceArea, state, userData, dispatch, isMounted
  });

  const onSourceTextBlur = useCallback((_e, {editingEndInFocus}) => {
    if (!autoSubmit || editingEndInFocus) return;
    const wordsWithDupes = unicodeHelper.getWords(state.curInputText.toLowerCase(), false);
    const words = unicodeHelper.uniqueArray(wordsWithDupes);
    const submittable = isSubmittableState(state, words, userData);
    if (submittable && !state.specCharsVisible) {
      onSubmit();
    }
  }, [onSubmit, state, userData]);


  const onLanguageChange = useCallback(({src, trg, dictId}) => {
    dispatch({ type: 'dict', payload: { src, trg, dictId } });
  }, []);

  useOnForcedSubmit(onSubmit,
    {forcedSubmits, isJustMounted}
  );

  // useOnDictOrLangChange(
  //   {curSrc, curTrg, curDictId, userData, isJustMounted},
  //   onSubmit
  // );

  const baseClass = 'trs-dict';
  const enterKeyMode = getEnterKeyMode();
  const html = state.curInputCleanHtml;
  const wordsWithDupes = unicodeHelper.getWords(state.curInputText.toLowerCase(), false);
  const words = unicodeHelper.uniqueArray(wordsWithDupes);
  const numOfUniqueWords = words.length;
  const numOfWordsNotUnique = wordsWithDupes.length;
  const htmlWithNoLastBr = html.replace(unicodeHelper.preg.last_br, '');
  const hasWhiteSpace = (
    htmlWithNoLastBr.search(unicodeHelper.preg.lb_tab_dspc_endspc_nonword) > -1 &&
    htmlWithNoLastBr.search(unicodeHelper.preg.br_only) === -1
  );
  const isEmpty = !html || html.search(unicodeHelper.preg.br_only) > -1 || (
    html.length === 1 && html.search(unicodeHelper.preg.lnb) === 0 // \n only
  );

  const entriesLen = state?.trResult?.resultInstance?.entries?.length;
  const submittable = isSubmittableState(state, words, userData);
  const hasMultipleWord = numOfWordsNotUnique > (
    enterKeyMode === 'submitFewWords' || enterKeyMode === 'submitAlways' ? enterKeyModeFewWordsLen : 1
  );
  const a11ymultiline = enterKeyMode === 'breakLine' || (
    enterKeyMode === 'submitFewWords' && numOfWordsNotUnique > enterKeyModeFewWordsLen
  );

  const onSourceKeyDown = useOnSourceKeyDown({
    refSource, onSubmit, isMounted, curSrc, curTrg, curDictId
  });

  // console.log('htmlWithNoLastBr', hasWhiteSpace, {
  //   htmlWithNoLastBr,
  //   rx: unicodeHelper.preg.lb_tab_dspc_endspc_nonword,
  //   'htmlWithNoLastBr.search(unicodeHelper.preg.lb_tab_dspc_endspc_nonword)': htmlWithNoLastBr.search(unicodeHelper.preg.lb_tab_dspc_endspc_nonword),
  //   'htmlWithNoLastBr.search(unicodeHelper.preg.br_only)': htmlWithNoLastBr.search(unicodeHelper.preg.br_only)
  // });

  return (
    // <UserContext.Consumer>{({loggedin, email}) => (
    <Layout
      appPub={appPub}
      amedia={state.layoutState === LAYOUT_STATES.SUBMITTED}
      className={classNames(
        baseClass,
        `layout-state-${state.layoutState}`,
        state.layoutState === LAYOUT_STATES.SUBMITTED ? 'white-right' : 'dark-right',
        numOfUniqueWords > 1 ? `has-multiple-unique-word` : null,
        hasWhiteSpace ? `has-whitespace` : null,
        isEmpty ? `its-empty` : 'its-not-empty',
        submittable ? `its-submittable` : null,
        numOfWordsNotUnique > 1 ? `has-morethanone-word` : null,
        hasMultipleWord ? `has-multiple-word` : !hasWhiteSpace ? 'propably-single-line' : null,
        state.specCharsVisible ? `spec-chars-visible` : null,
        state.trResult?.resultInstance ? `has-result` : null,
        state.trResult && state.trResult?.error ? `has-error` : null,
        state?.trResult && !entriesLen ? `has-no-result` : null,
        submitButtonEnabled ? `submit-button-enabled` : null

      )}
      headerPrimaryButton={
        appPub.isLoggedIn() ? null : headerButton
      }
      contentLeftRole="search"
      contentLeft={(
        <form ref={refForm} onSubmit={onSubmit} className={`${baseClass}__form`}>
          <LanguageSwitcher
            src={state.curSrc}
            trg={state.curTrg}
            dictId={state.curDictId}
            onChange={onLanguageChange}
            appPub={appPub}
          />
          <div className={`${baseClass}__source-area-wrp`}>
            <TrsSourceArea
              ref={refTrsSourceArea}
              onChange={onSourceTextChange}
              a11ymultiline={a11ymultiline}
              enterKeyModeChange={onEnterKeyModeChange}
              specCharsVisible={state.specCharsVisible || false}
              onSpecCharsVisibilityChange={onSpecCharsVisibilityChange}
              onBlurLate={onSourceTextBlur}
              onWordSelect={onSourceWordSelectionChange}
              onReset={onSourceTextReset}
              onKeyDown={onSourceKeyDown}
              id="trs-sourceText"
              innerRef={refSource}
              dictId={state.curDictId}
              loading={state.loadingQuery || state.loading}
              submittable={submittable}
              showPreview={
                !submittable &&
                !!state.lastQuery.text &&
                !!state.trResult && !state.loadingQuery
              }
              error={
                state.trResult?.error || false
              }
              resultInstance={
                state.trResult?.resultInstance || null
              }
              isEmpty={isEmpty}
              onOcrClick={onOcrClick}
              activeWord={state.curWord}
              activeWordIndex={state.curWordIndex}
              numOfWordsNotUnique={numOfWordsNotUnique}
              numOfUniqueWords={numOfUniqueWords}
              numOfMaxWords={numOfMaxWords}
              expanded={(hasWhiteSpace || hasMultipleWord)}
              hasWhiteSpace={hasWhiteSpace}
              onSubmit={onSubmit}
              value={state.curInputHtml}
              appPub={appPub}
              placeholder={messages.get('tr.placeholder')}
              sourceLang={state.curSrc}
            />

            <MaxWordNotify
              numOfUniqueWords={numOfUniqueWords}
              numOfMaxWords={numOfMaxWords}
              hasResult={!!state.trResult?.resultInstance}
            />
          </div>

          {submitButtonEnabled ? (
            <FormSubmit baseClass={baseClass} submittable={submittable} />
          ) : null}

        </form>
      )}
      contentRight={(
        <>
          <div className={`${baseClass}__rightemsizer`} ></div>
          <ResultEntries
            baseClass={baseClass}
            lastQuery={state.lastQuery}
            loadingQuery={state.loadingQuery}
            trResult={state.trResult}
            onSourceWordSelectionChange={onSourceWordSelectionChange}
            curWord={state.curWord}
          />
        </>
      )}
    >
      <div className={`${baseClass}__form`}>
        <div className="page-content-in page-width">
          <Preloader loading={state.loading} error={state.error} errormsg={state.errormsg} />
          <OcrTool
            onOcrResult={onOcrResult}
            srcLang={state.curSrc}
            appPub={appPub}
            refFileInput={refOrcFileInput}
            baseClass={baseClass}
          />
        </div>
      </div>
    </Layout>
    // )}</UserContext.Consumer>
  );
};
export default Dictionary;

Dictionary.propTypes = {
  appPub: PropTypes.object.isRequired
};
