import { matchPath } from "react-router";
import unicodeHelper from "./unicode_helper";
import { sesStore } from "./BrowserStore";
import {mobileBreakPoint} from "../setup";

const getGlobal = () => {
  return (typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : {});
};

const keyCodes = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  ESC: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
};

const konsole = (function(win) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {};
  return win?.console || Object.fromEntries([
    "debug",
    "error",
    "info",
    "log",
    "warn",
    "dir",
    "dirxml",
    "table",
    "trace",
    "group",
    "groupCollapsed",
    "groupEnd",
    "clear",
    "count",
    "countReset",
    "assert",
    "profile",
    "profileEnd",
    "time",
    "timeLog",
    "timeEnd",
    "timeStamp",
    "context",
    "memory"
  ].map(v => [v, noop]));
})(getGlobal());

const logOnce = (function() {
  const logged = {};
  return (msg, ...args) => {
    if (logged[msg]) { return; }
    logged[msg] = true;
    konsole.warn(msg, ...args);
  };
})();


function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}


const isExternalUrl = (function() {
  const rx_url = /^([^:/?#]+:)?(a)?([^?#]+)?(\?[^#]*)?(#.*)?/;
  const rx_hasProt = /^https?:\/\//i;

  return (url) => {
    if (!url) {
      return false;
    }

    if (url.search(rx_hasProt) > -1) {
      return true;
    }
    var match = (url || "").match(rx_url);
    if (
      typeof match[1] === "string" && match[1].length > 0 &&
      match[1].toLowerCase() !== getGlobal().location.protocol
    ) {
      return true;
    }

    if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(
      new RegExp(":(" + {"http:": 80, "https:": 443}[getGlobal().location.protocol] + ")?$"),
      ""
    ) !== getGlobal().location.host) {
      return true;
    }
    return false;
  };
})();



const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const isMobile = () => getWindowWidth() <= mobileBreakPoint;



const debounce = (callback, delay = 250) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;
      callback(...args);
    }, delay);
  };
};


function throttle(func, wait = 100) {
  let timer = null;
  return function(...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}



const parseParams = (function() {
  return (locSearch, fn) => {
    locSearch = (locSearch || window.location.search);
    if (!locSearch) return {};

    locSearch = locSearch.replace(/&+/g, "&").replace(/\?+/g, "?").replace(/&\?/g, "&");

    const value = locSearch.substr(1).split('&');
    const params = {};
    for (let i = 0; i < value.length; ++i) {
      const p = value[i].split('=', 2);
      if (p.length === 1) {
        params[p[0]] = "";
      }
      else {
        const v = decodeURIComponent(p[1].replace(/\+/g, " "));
        params[p[0]] = typeof fn === "function" ? fn(v) : v;
      }
    }

    return params;
  };
})();

const encodeParams = (obj) => {
  let str = "";
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && key) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
  }
  return str ? `?${str}` : '';
};

// import { useLocation } from "react-router-dom";
// const location = useLocation();
// urlMatchByLocPath.match(lintAttrs.data.href, location.pathname);
const urlMatchByLocPath = {
  cache: {},
  match(href, locationPath, options = {exact: false, strict: false}) {
    const cacheProp = locationPath + '<--->' + href;
    if (this.cache[cacheProp]) {
      return this.cache[cacheProp];
    }

    return (this.cache[cacheProp] = matchPath(locationPath, {
      path: href,
      ...options
    }));
  }
};


const notEmpty = (v) => (
  typeof v !== "undefined" &&
  v !== null &&
  v.length > 0
);


const fixHashFormat = (function() {
  const charMap = [
    ['a', /[áăặằǎâậäǟȧǡạȁàȃāąåḁãɐ]/ig],
    ['e', /[éĕěȩḝêệḙëėẹȅèȇēęẽɛɘǝ]/ig],
    ['i', /[íĭǐîïịȉìȋīįɨĩ]/ig],
    ['o', /[óŏǒôộöȫȯȱọőȍòơớợờỡȏⱺōǫǭøǿõṍȭ]/ig],
    ['u', /[úŭǔûṷüǘǚǜǖṳụűȕùưứựừữȗūųůũṹṵ]/ig],
    ['-', /[\s\n.,§'"+!%/=()?:_~ˇ^˘°˛`˙´˝÷×¤ß;*<>@&<>[]{}]/g],
    ['', /#/g]
  ];
  return (s) => {
    if (!s) return s;
    s = (s + '').toLowerCase();
    for (let i = 0; i < charMap.length; i++) {
      const [to, from] = charMap[i];
      s = s.replace(from, to);
    }
    return s;
  };
})();


const filterInput = (function() {
  const escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;'
  };
  const rx_others = /javascript:|data:|https?:|mailto:/ig;

  const rx = /[&<>'"]/g;
  const mapFn = c => escape[c];
  return (s, remove) => (s + '').replace(rx_others, '').replace(rx, remove ? '' : mapFn);
})();

const getLocation = () => getGlobal().location || {};
const getLocHash = () => (getLocation().hash || '').replace('#', '');
const getLocPath = () => (getLocation().pathname || '');
// const getLocSearch = () => (getLocation().search || '');

const supScriptMap = {
  "0": "⁰",
  "1": "¹",
  "2": "²",
  "3": "³",
  "4": "⁴",
  "5": "⁵",
  "6": "⁶",
  "7": "⁷",
  "8": "⁸",
  "9": "⁹",
  _rx: /\d/g,
  _fn: d => supScriptMap[d] || d,
  convert(s) { return (s || '').replace(this._rx, this._fn); }
};



const isCssSelectorSupported = (function() {
  const cache = {};

  return (selector) => {
    if (typeof cache[selector] === "boolean") {
      return cache[selector];
    }

    const style = document.createElement('style');
    document.head.appendChild(style);

    let supported = true;

    try {
      style.sheet.insertRule(selector + '{}', 0);
    }
    catch (e) {
      supported = false;
    }
    finally {
      document.head.removeChild(style);
    }

    return (cache[selector] = supported);
  };
})();


const firstBool = (arr, defBool) => {
  if (typeof arr === "boolean") {
    return arr;
  }
  else if (Array.isArray(arr)) {
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === "boolean") {
        return arr[i];
      }
    }
  }
  return !!defBool;
};


const locReload = (function() {
  let timeoutId = null;

  return (urlOnce = window.location.href, fallbackUrl) => {
    if (sesStore.get('locReloadId') !== urlOnce) {
      sesStore.set('locReloadId', urlOnce);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
    else if (!timeoutId) {
      timeoutId = setTimeout(() => {
        timeoutId = null;
        sesStore.remove('locReloadId');
        if (fallbackUrl) {
          window.location.href = fallbackUrl;
        }
      }, 1500);
    }
  };
})();


const meta = {
  setTitle: (title) => {
    document.title = process.env.RAZZLE_INITIAL_PAGE_TITLE + (title ? ' - ' + title : '');
  }
};


const getAccurateDate = (function() {
  const offset = 0; // TODO: implement server time
  return () => {
    var date = new Date();
    date.setTime(date.getTime() + offset);
    return date;
  };
})();


const preventContextmenuEventHandler = (e) => {
  if (e.target.className.indexOf('no-context-menu') !== -1) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
};

const firstLetterToUpperCase = (text) => (
  text.charAt(0).toUpperCase() + text.slice(1)
);


function arrayToCsv(data) {
  return data.map(row =>
    row
      .map(String) // convert every value to String
      .map(v => v.replace(/"/g, '""')) // escape double colons
      .map(v => `"${v}"`) // quote it
      .join(',') // comma-separated
  ).join('\r\n'); // rows starting on new lines
}

function downloadBlob(content, filename, contentType) {
  // Create a blob
  // eslint-disable-next-line no-undef
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}

function downloadCsv(data, fileName = 'data.csv') {
  var csv = arrayToCsv(data);
  downloadBlob(csv, fileName, "data:text/csv;charset=utf-8,%EF%BB%BF");
}

function hashCode(str) {
  return str.split('').reduce((prevHash, currVal) =>
    (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
}

const getGrapheme = (function() {
  let graphemer = null;
  return async() => {
    if (!graphemer) {
      const {default: Graphemer} = await import('grapheme-splitter');
      graphemer = new Graphemer();
    }
    return graphemer;
  };
})();


const removeCombiningChars = async(text, charMap = {}) => {
  const graphemer = await getGrapheme();

  const chars = graphemer.splitGraphemes(text + '');
  const charMapNorm = Object.entries(charMap).map(([from, to]) => [
    graphemer.splitGraphemes('0' + from + '2')[1],
    to
  ]);

  const r = chars.map(
    (c) => {
      const cNorm = charMapNorm.find(([from]) => from === c);
      return cNorm ? cNorm[1] : c;
    }
  ).join('');

  return r;
};




export {
  removeCombiningChars,
  hashCode,
  downloadCsv,
  arrayToCsv,
  downloadBlob,
  firstLetterToUpperCase,
  isMobile,
  preventContextmenuEventHandler,
  logOnce,
  getAccurateDate,
  getGlobal,
  meta,
  locReload,
  firstBool,
  isCssSelectorSupported,
  debounce,
  throttle,
  isNumeric,
  getLocation,
  getLocHash,
  getLocPath,
  keyCodes,
  // getLocSearch,
  unicodeHelper,
  isExternalUrl,
  parseParams,
  encodeParams,
  supScriptMap,
  getWindowWidth,
  urlMatchByLocPath,
  fixHashFormat,
  notEmpty,
  konsole,
  filterInput
};
