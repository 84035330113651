/* eslint-disable no-use-before-define */

import React, {useRef, useReducer, useCallback} from 'react';

import PropTypes from 'prop-types';
import AuthRelatedPage from "./AuthRelatedPage";
import { useIsMounted } from '../../hooks';
import { userCheckMail } from './userHelper';
import { parseParams, isExternalUrl } from '../../lib/util';
// import TestPhaseWarn from './components/TestPhaseWarn';
import ActivateLink from './components/ActivateLink';
import * as schemes from './data/schemes';
import { formDataExtend } from './data/formDataHelper';
import messages from '../../messages';

function reducer(state, {type, payload}) {
  switch (type) {
    case 'page':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}

const formData = {
  formId: 'signin',
  title: messages.get('user.PageSignIn.title'),
  primaryBtn: {text: messages.get('user.PageSignIn.primaryBtn'), disabled: true },
  desc: messages.get('user.PageSignIn.desc'),
  // warn: <TestPhaseWarn />,
  fieldsStates: {
    emailNotRegistered: {
      primaryBtn: {text: messages.get('user.PageSignIn.emailNotRegistered.primaryBtn'), disabled: false }
    },
    emailRegistered: {
      primaryBtn: {text: messages.get('user.PageSignIn.emailRegistered.primaryBtn'), disabled: false }
    },
    emailRegistrationDisabled: {
      primaryBtn: {disabled: true }
    },
    emailRegisteredButNotActivated: {
      primaryBtn: {text: messages.get('user.PageSignIn.emailRegisteredButNotActivated.primaryBtn'), disabled: false }
    }
  },
  fields: [
    {
      name: "username",
      label: messages.get('user.fields.username'),
      autoComplete: "username",
      type: 'email',
      schema: schemes.username,
      fieldStates: {
        emailNotValid: (getEventData, {helperText}) => ({
          helperText: (
            <span style={{color: 'red'}}>
              {helperText}
            </span>
          )
        }),
        emailRegistered: {
          helperText: (
            <span style={{color: 'darkorange'}}>
              {messages.get('user.PageSignIn.emailRegistered.helperText')}
            </span>
          )
        },
        emailRegisteredButNotActivated: { // TODO: át kell fogalmazni
          helperText: (getEventData) => {
            const {value} = getEventData();
            return <>
              <span style={{color: 'darkorange'}}>
                {messages.get('user.PageSignIn.emailRegisteredButNotActivated.helperText')}
              </span>
              <ActivateLink username={value} />
            </>;
          }
        },
        emailNotRegistered: {
          helperText: (
            <span style={{color: 'darkorange'}}>
              {messages.get('user.PageSignIn.emailNotRegistered.helperText')}
            </span>
          )
        },
        emailRegistrationDisabled: {
          helperText: (
            <span style={{color: 'red'}}>
              {messages.get('user.PageSignIn.emailRegistrationDisabled.helperText')}
            </span>
          )
        }
      },
      helperText: (
        <span>
          {messages.get('user.PageSignIn.helperText')}
        </span>
      )
    }
  ]
};



const PageSignIn = ({appPub, history, location, ...props}) => {
  const params = parseParams(location.search);
  const paramUsername = location?.state?.username || params.username || '';
  const paramTarget = decodeURIComponent(location?.state?.target || params.target || '');
  const defUsername = schemes.email.validate(paramUsername) ? '' : paramUsername.toLowerCase();
  const targetParam = paramTarget && !isExternalUrl(paramTarget)
    ? `?target=${encodeURIComponent(paramTarget)}`
    : ''
  ;
  const refForm = useRef();

  const {api, toastErrorMsg} = appPub;
  const isMounted = useIsMounted();


  const setFieldsState = useCallback((fieldsState, fieldsStateArgs = null) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch({ type: 'page', payload: { fieldsState, fieldsStateArgs } });
  }, []);


  const onUserNameChange = useCallback((e, {
    value, schema, fieldState, setFieldState, ...rest}
  ) => {
    const email = value + ''.trim();
    const validFormat = !schema.validate(value);
    if (validFormat) {
      userCheckMail(email, {
        isMounted,
        api,
        toastErrorMsg
      }, ({
        email,
        fieldsState,
        fieldsStateArgs,
        fieldState,
        fieldStateArgs
      }) => {
        setFieldsState(fieldsState, fieldsStateArgs);
        setFieldState(fieldState, fieldStateArgs);
        if (fieldsState === "emailRegistered") {
          if (history.location.state?.divertedToLogin !== email) {
            history.replace(history.location.pathname, {divertedToLogin: email});
            history.push('/user/login' + targetParam, {username: email});
          }
        }
      });
    }
    else {
      setFieldsState(null, null);
      setFieldState(null, null);
    }

  }, [api, history, isMounted, setFieldsState, toastErrorMsg]);


  const [state, dispatch] = useReducer(reducer, {
    fieldsState: null,
    fieldsStateArgs: null,
    pageData: {
      defUsername
    },
    currentForm: (
      formDataExtend(formData, {
        fields: [
          {
            name: 'username',
            onChange: onUserNameChange,
            onBlur: onUserNameChange,
            onInit: onUserNameChange,
            defaultValue: defUsername
          }
        ]
      })
    )
  });

  const onSubmit = useCallback(({username}) => {
    switch (state.fieldsState) {
      case 'emailNotRegistered':
        history.push('/user/signup', {username});
        break;
      case 'emailRegistered':
        history.push('/user/login' + targetParam, {username});
        break;
      case 'emailRegisteredButNotActivated':
        history.push('/user/activate', {sentto: username});
        break;
      default:
        console.warn('fieldsState is not handled:', state.fieldsState);
        break;
    }
  }, [history, state.fieldsState, targetParam]);



  // console.log('APP PROPS', props);
  return (
    <AuthRelatedPage
      appPub={appPub}
      // appProps={props}
      loading={state.loading}
      refForm={refForm}
      onSubmit={onSubmit}
      fieldsState={state.fieldsState}
      fieldsStateArgs={state.fieldsStateArgs}
      formData={state.currentForm}
      pageData={state.pageData}
    />
  );
};

PageSignIn.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PageSignIn;
