

import React from 'react';
import PropTypes from 'prop-types';

import LoaderSpinner from '../../../components/misc/LoaderSpinner';
import Entries from '../Entries';
import ContentParagraph from '../../../components/contentParagraph';
import {messages} from '../../../messages';


const ResultEntries = React.memo(({baseClass, lastQuery, loadingQuery, trResult, onSourceWordSelectionChange, curWord}) => (
  (
    (lastQuery?.text && loadingQuery)
      ? <LoaderSpinner />
      : (trResult && !trResult?.error
        ? (
          <Entries
            baseClass={baseClass}
            onWordSelect={onSourceWordSelectionChange}
            lastQuery={lastQuery}
            resultInstance={
              trResult?.resultInstance || null
            }
            activeWord={curWord}
            {...trResult}
          />
        )
        : (trResult && trResult?.error
          ? <ContentParagraph className={`${baseClass}__result-error`} data={{theme: 'alert'}}>{
            trResult?.errormsg || messages.get('tr.unexpectedError')
          } </ContentParagraph>
          : null
        )
      )
  )
));

ResultEntries.displayName = 'ResultEntries';

ResultEntries.propTypes = {
  baseClass: PropTypes.string,
  lastQuery: PropTypes.shape({
    text: PropTypes.string
  }),
  loadingQuery: PropTypes.bool.isRequired,
  trResult: PropTypes.shape({
    resultInstance: PropTypes.object,
    error: PropTypes.bool,
    errormsg: PropTypes.array
  }),
  onSourceWordSelectionChange: PropTypes.func,
  curWord: PropTypes.string
};

export default ResultEntries;
