import React, {} from "react";
import AreaIcon, {TypeTooltip} from "./AreaIcon";
import messages from "../../../messages";

type TypeProps = {
  baseClass: string;
  id?: string;
  pos?: "top" | "bottom";
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  tabIndex?: number;
  tooltip: TypeTooltip;
};

const OcrIcon = ({
  baseClass,
  pos = "top",
  id,
  onClick,
  tooltip,
  tabIndex = null
}: TypeProps) => {
  return (
    <AreaIcon
      id={id}
      tabIndex={tabIndex}
      tooltip={tooltip}
      onClick={onClick}
      icon={'camera'}
      className={baseClass + '__ocr-' + pos}
      title={messages.get('TrsSourceArea.ocrIconTitle')}
      text={messages.get('TrsSourceArea.ocrIconTitle')}
    />
  );
};

OcrIcon.displayName = "OcrIcon";

export default OcrIcon;
