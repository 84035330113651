/* eslint-disable react/prop-types */
import React from 'react';

import StaticPageMustLogin from '../../../StaticPageMustLogin';
import ModuleLoader from '../../../components/ModuleLoader';
// import PageSubscription from '../../../base/subscription/';
import messages from '../../../messages';

import { Route } from 'react-router-dom';


// import PrivateRoute from '../../PrivateRoute';
import { meta } from '../../../lib/util';

const lazyImports = {
  subscription: () => React.lazy(() => import('../../../base/subscription/'))
};

const getSubscriptionRoutes = (pageProps) => {
  const isLoggedIn = pageProps.appPub.isLoggedIn;

  return [
    <Route path={["/subscription", "/subscription/checkout"]} key={'subscription'} exact render={(props) => {
      meta.setTitle(messages.get('meta.pageTitle.subscription'));
      return isLoggedIn()
        ? <ModuleLoader moduleImportFn={lazyImports.subscription}
          { ...{...props, ...pageProps} }
        />
        : <StaticPageMustLogin { ...{...props, ...pageProps} } />;
    }} />
  ];
};

export default getSubscriptionRoutes;
