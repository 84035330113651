/* eslint-disable react/prop-types */
import React, {useCallback, useState, useRef, useEffect} from 'react';
import classnames from "classnames";
import globals from "../../controller/globals";
import el_liveBind from '../../lib/dom/el_liveBind';
import {initialState} from "../../components/misc/AudioPlayer";
import el_toggleClass from '../../lib/dom/el_toggleClass';
import { PREVIEW_MODE } from '../../controller/devel';
import {selectorsToTts} from '../../setup';
import ttsMap from '../../ttsMap';
import messages from '../../messages';

const ttsSetup = {
  position: 'before' // 'after'
};
const joinStyleClass = s => s.split(/\s+/).map(c => 'tts-style-' + c);

class TtsButtonConnector {
  constructor(el) {
    this.elTtsButton = el;
    this.elTtsButton.__ttsButtonConnector = this;
    this.listeners = [];
    this.jsxAudioPlayer = null;
  }

  static getOrConstructByEl(el) {
    return (el && el?.__ttsButtonConnector) || (el && new TtsButtonConnector(el)) || null;
  }

  connect(jsxAudioPlayer) {
    if (this.garbage) {
      PREVIEW_MODE && console.warn("TtsButtonConnector.connect called after destroy");
      return;
    }
    this.jsxAudioPlayer = jsxAudioPlayer;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  unconnect(_jsxAudioPlayer) {
    if (this.garbage) {
      PREVIEW_MODE && console.warn("TtsButtonConnector.unconnect called after destroy");
      return;
    }
    this.stateChange({...initialState});
    this.jsxAudioPlayer = null;
  }


  subscribe(callback) {
    if (this.garbage) {
      PREVIEW_MODE && console.warn("TtsButtonConnector.subscribe called after destroy");
      return;
    }
    if (typeof callback === "function" && this.listeners.indexOf(callback) === -1) {
      this.listeners.push(callback);
    }
  }

  unsubscribe(callback) {
    if (this.garbage) {
      PREVIEW_MODE && console.warn("TtsButtonConnector.unsubscribe called after destroy");
      return;
    }
    this.listeners = this.listeners.filter((listener) => listener !== callback);
  }

  stateChange(state) {
    if (this.garbage) {
      PREVIEW_MODE && console.warn("TtsButtonConnector.stateChange called after destroy");
      return;
    }
    this.listeners.forEach((callback) => {
      typeof callback === "function" && callback(state);
    });
  }

  destroy() {
    if (this.garbage) {
      PREVIEW_MODE && console.warn("TtsButtonConnector.destroy called after destroy");
      return;
    }
    this.unconnect();
    if (this.elTtsButton && this.elTtsButton.__ttsButtonConnector) {
      delete this.elTtsButton.__ttsButtonConnector;
    }
    delete this.elTtsButton;
    this.listeners = [];
    this.garbage = true;
  }
}

const TtsSpeakButton = ({text, lang = "en", baseClass}) => {
  const appPub = globals.get('appPub');
  const refPlayBtn = useRef(null);
  const {ttsSpeak} = appPub;
  const txt = (
    typeof text === "function" ? (text(refPlayBtn, lang) + '') : (text + '')
  ).trim();
  const [state, setState] = useState({});
  const [ttsButtonConnector, setTtsButtonConnector] = useState(null);

  const onUpdate = useCallback((newState) => {
    // console.log("TtsSpeakButton.onUpdate", newState);
    setState((prevState) => {
      return {...prevState, ...newState};
    });
  }, []);

  useEffect(() => {
    if (ttsButtonConnector) {
      ttsButtonConnector.subscribe(onUpdate);
    }
    return () => {
      if (ttsButtonConnector) {
        ttsButtonConnector.unsubscribe(onUpdate);
      }
    };
  }, [ttsButtonConnector, onUpdate]);


  useEffect(() => {
    const _connector = TtsButtonConnector.getOrConstructByEl(refPlayBtn.current);
    _connector && setTtsButtonConnector(_connector);
    return () => {
      _connector && _connector.destroy();
    };
  }, []);


  const onTTS = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    ttsSpeak({
      text,
      lang,
      ttsButtonConnector,
      callback: (ttsSpeakState) => {
        ttsButtonConnector.stateChange(ttsSpeakState);
      }
    });
    return false;
  }, [lang, text, ttsButtonConnector, ttsSpeak]);

  const {className, error, loading, playing, paused} = state;

  return !txt ? null : (
    <button
      ref={refPlayBtn}
      onClick={onTTS}
      className={
        classnames(
          className,
          loading ? "loading" : "",
          error ? "error" : "",
          playing ? "playing" : "",
          paused ? "paused" : "",
          "tts-speak-inline",
          `${baseClass}__tts`,
          "fas fa-volume-off"
        )
      }
      aria-label={messages.get('TTS.button.ariaLabel', {text})}
      title={messages.get('TTS.button.title')}
    >
    </button>
  );
};

const ttsSpeakButtonDOM = ({
  text, lang = "en", baseClass = "resultItem", className, position = "after"
}) => {

  const el = document.createElement('button');
  el.className = classnames(
    className,
    "tts-speak-inline",
    "tts-speak-live",
    "tts-speak-" + position,
    `${baseClass}__tts`,
    "fas fa-volume-off"
  );

  el.setAttribute('title', messages.get('TTS.button.title'));
  if (text) {
    el.setAttribute('data-text', (text + '').trim());
  }
  el.setAttribute('data-lang', lang);
  return el;
};


const ttsSpeakButtonAttachToEntryDOM = ({
  el,
  lang = "en",
  position = ttsSetup.position
}) => {

  const ttsHtml = el.innerHTML.trim();
  // const text = ttsMap.replacer(elCopy.innerText, lang);
  el.innerHTML = `<span class="tts-wrp"><span class="tts-cont" lang="${lang}">${ttsHtml}</span></span>`;
  const elTtsWrp = el.firstChild;
  const elTtsCont = elTtsWrp.firstChild;
  el.classList.add('has-tts');

  const btn = ttsSpeakButtonDOM({
    text: null,
    lang: lang,
    parent: elTtsWrp,
    position: position,
    className: el.className ? joinStyleClass(el.className) : null
  });

  if (ttsSetup.position === 'after') {
    elTtsWrp.appendChild(btn);
  }
  else {
    el.firstElementChild.insertBefore(btn, el.firstElementChild.firstChild);
  }
  return elTtsCont;
};


const ttsSpeakButtonUpdate = (el, state) => {
  if (!el || el.parentNode === null) return;
  // console.log("ttsSpeakButtonUpdate", el, state);
  el_toggleClass(el, "fa-spinner", state.loading && !state.playing && !state.error); // loading icon
  el_toggleClass(el, "fa-volume-off", !state.playing && !state.loading && !state.error); // initial icon
  el_toggleClass(el, "fa-volume-down", state.playing && !state.loading && !state.error); // icon when playing
  el_toggleClass(el, "fa-exclamation-circle", state.error); // error icon

  el_toggleClass(el, "loading", state.loading);
  el_toggleClass(el, "error", state.error);
  el_toggleClass(el, "playing", state.playing);
  el_toggleClass(el, "paused", state.paused);
  el.setAttribute(
    'title', state.error
      ? messages.get('TTS.button.titleError', {error: state.error})
      : messages.get('TTS.button.title')
  );
  if (state.error) {
    el.setAttribute('data-error-msg', state.error);
  }
  else {
    el.removeAttribute('data-error-msg');
  }
  el.setAttribute('aria-label', state.loading ? 'Betöltés' : 'Lejátszás');
  el.setAttribute('aria-pressed', state.loading || state.playing ? "true" : "false");

};


const ttsSpeakResolveText = (el, lang = "en") => {
  const cachedText = el.getAttribute('data-tts-text');
  if (cachedText) return cachedText;

  const elTtsWrp = el.parentNode;
  const elCopy = elTtsWrp.querySelector('.tts-cont').cloneNode(true);
  const elCopyRemovables = [...elCopy.querySelectorAll(selectorsToTts.exclude)];

  while (elCopyRemovables.length) { // remove (RH) etc
    const elToDel = elCopyRemovables.pop();
    elToDel.parentNode?.removeChild(elToDel);
  }
  const text = ttsMap.replacer(elCopy.innerText, lang);

  el.setAttribute('data-tts-text', text);

  return text;
};

const ttsSpeakLiveBind = (parentElem, _ttsSpeak) => {
  const ttsSpeak = _ttsSpeak || globals.get('appPub').ttsSpeak;
  let lastClickedEl = null;
  const updater = (newState) => {
    lastClickedEl && ttsSpeakButtonUpdate(lastClickedEl, newState);
  };

  return el_liveBind(
    parentElem, 'click', '.tts-speak-live', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let text = e.target.getAttribute('data-text');
      const lang = e.target.getAttribute('data-lang');
      if (text === null) {
        text = ttsSpeakResolveText(e.target, lang);
      }

      const ttsButtonConnector = TtsButtonConnector.getOrConstructByEl(e.target);

      if (lastClickedEl && lastClickedEl !== e.target) {
        const c = TtsButtonConnector.getOrConstructByEl(lastClickedEl);
        if (c) {
          c.destroy();
        }
        lastClickedEl = null;
      }
      if (lastClickedEl === null) {
        lastClickedEl = e.target;
        ttsButtonConnector.subscribe(updater);
      }

      ttsSpeak({
        text,
        lang,
        ttsButtonConnector,
        callback: (ttsSpeakState) => {
          ttsButtonConnector.stateChange(ttsSpeakState);
        }
      });
    }
  );
};

export default TtsSpeakButton;

export {
  ttsSpeakLiveBind,
  ttsSpeakButtonDOM,
  TtsSpeakButton,
  ttsSpeakButtonAttachToEntryDOM,
  TtsButtonConnector
};
