const faMap = {
  'fa-magnifier': 'fa-search'
};

const rxFaAny = /(?:^|\s+)(far|fas)(?:$|\s+)/i;
const rxFaRegular = /(?:^|\s+)(far)(?:$|\s+)/i;
const rxFaPrefix = /fa-/i;

const rxDblSpc = /\s+/i;
const mapNormalize = (f) => {
  const icon = f.search(rxFaPrefix) === -1 ? `fa-${f}` : f;
  return faMap[icon] || icon;
};

// ha nincs a stringben far vagy fas, hozzá lesz adva az alap "fas"
// ha nincs az icon nevében fa- prefix, kiegészítésre kerül

const faIconMapper = (str) => {
  let type = 'fas';
  str = (str + '').trim();

  if (str.search(rxFaRegular) > -1) {
    type = 'far';
  }
  const icon = str.replace(rxFaAny, '').replace(rxDblSpc, ' ').trim()
    .split(' ').map(mapNormalize).join(' ')
  ;
  return `${type} ${icon}`;
};

export default faIconMapper;
