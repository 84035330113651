import React from 'react';
import Hyperlink from '../../../components/hyperlink';
import PropTypes from 'prop-types';

const ReferralLink = ({
  username,
  prefix = '',
  suffix = '',
  paramName = 'username',
  link = '/',
  linkText = 'link text'
}) => (
  <>{prefix} <Hyperlink className="referral-Link"
    href={link + (username ? `?${paramName}=` + encodeURI(username) : '')}>{linkText}</Hyperlink>
  {suffix}</>
);

ReferralLink.propTypes = {
  username: PropTypes.string,
  paramName: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.node.isRequired
};

export default ReferralLink;
