import { konsole } from "./util";
import { getUserData } from "../Contexts";

const getGlobal = () => {
  return (typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : {});
};


interface TypeStorage {
  clear(): void;
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
  __store__?: Record<string, unknown>;
}

export class BrowserStore {
  storage: TypeStorage;

  constructor(type: 'local' | 'session' = "local") {
    this.storage = getGlobal()[type + 'Storage'] || {
      __store__: {},
      clear() { this.__store__ = {}; },
      getItem(key) {
        return typeof this.__store__[key] === "undefined"
          ? null
          : this.__store__[key]
        ;
      },
      setItem(key, value) { this.__store__[key] = value; },
      removeItem(key) { delete this.__store__[key]; }
    };
  }

  get(prop: string, defaultValue: unknown, decodeJSON = false) {
    const s = this.storage.getItem(prop);
    let value = defaultValue;

    if (s) {
      if (decodeJSON) {
        try {
          value = JSON.parse(s);
        }
        catch (error) {
          konsole.error(`Localstorage item "${prop}" has unexpected json value`);
        }
      }
      else {
        value = s;
      }
    }
    return (value === "undefined" || value === null || typeof value === "undefined")
      ? (typeof defaultValue !== "undefined" ? defaultValue : null)
      : value;
  }

  set(prop: string, value: unknown, asJSON = false) {
    if (value === null || typeof value === "undefined") {
      this.storage.removeItem(prop);
      return;
    }

    this.storage.setItem(prop,
      asJSON && value && typeof value === "object"
        ? JSON.stringify(value)
        : value + ''
    );

    return value;
  }

  remove(prop: string) {
    this.storage.removeItem(prop);
  }

  clear() {
    this.storage.clear();
  }
}


export const locUserStore = (function() {
  const store = new BrowserStore('local');
  const rxPropMatch = /##\d+##[.]+/;

  const getUserIdPrefix = () => {
    const uid = getUserData()?.id || null;
    return uid ? `##${uid}##` : null;
  };
  const genProp = prop => {
    const prefix = getUserIdPrefix();
    return prefix ? `${prefix}${prop}` : null;
  };

  return {
    clear() {
      const prefix = getUserIdPrefix();
      if (!prefix) return;
      Object.keys(store.storage.__store__ || store.storage).forEach(prop => {
        if (prop.search(rxPropMatch) > -1) { this.removeItem(prop); }
      });
    },
    get(prop: string, defaultValue: unknown, decodeJSON = false) {
      const uprop = genProp(prop);
      return uprop
        ? store.get(uprop, defaultValue, decodeJSON)
        : typeof defaultValue !== 'undefined' ? defaultValue : null
      ;

    },
    set(
      prop: string,
      value: (unknown),
      asJSON: boolean
    ) {
      const uprop = genProp(prop);
      return uprop ? store.set(uprop, value, asJSON) : null;
    },
    remove(prop: string) {
      const uprop = genProp(prop);
      return uprop ? store.remove(uprop) : null;
    }
  };
})();

export const locStore = new BrowserStore('local');
export const sesStore = new BrowserStore('session');

export default BrowserStore;
