
import {letters, negnonword, negnonwords, nonword, dash} from './unicode_xrngs';
import { PREVIEW_MODE } from '../controller/devel';
export default (function() {
  /* @author Imre Ardelean <blastart@gmail.com> 2011 */

  const xrngs = {letters, negnonword, negnonwords, nonword, dash};

  class CachePropVal {
    constructor(valueGetter, maxLen = 10) {
      this.maxLen = maxLen;
      this._cache = {};
      this._cachedProps = [];
      this.valueGetter = valueGetter || (() => console.warn('valueGetter is missing'));
    }

    get(prop) {
      let cached = this._cache[prop];

      if (typeof cached === 'undefined') {
        cached = this._cache[prop] = this.valueGetter(prop);
        this._cachedProps.push(cached);

        if (this._cachedProps.length > this.maxLen) {
          const p = this._cachedProps.shift();
          delete this._cache[p];
        }
      }
      return cached;
    }
  }

  const escapeRegExp = (function() {
    const rx = /[.*+?^${}()|[\]\\]/g;
    return (str) => str.replace(rx, '\\$&');
  })();


  const u = {
    _uniques: (value, index, self) => self.indexOf(value) === index,

    uniq: arrArg => arrArg.filter(u._uniques),

    arraySortBy(arr, which, reverse, insensitive) {
      const t = typeof which;
      const r = (typeof reverse !== 'undefined' && reverse) ? -1 : 1;
      return arr.sort((a, b) => {
        const v = (t === 'function'
          ? { A: (which.call(arr, a) || 0), B: (which.call(arr, b) || 0) }
          : ((t === 'string' || t === 'number')
            ? { A: (a[which] || 0), B: (b[which] || 0) }
            : { A: a, B: b }
          )
        );
        if (insensitive) {
          if (typeof v.A === 'string') { v.A = v.A.toLowerCase(); }
          if (typeof v.B === 'string') { v.B = v.B.toLowerCase(); }
        }
        return ((v.A < v.B) ? -1 : ((v.A > v.B) ? 1 : 0)) * r;
      });
    }
  };

  const accents = { // lowercase sets
    // @credit: David Chan & Ed Sanders
    // <http://goo.gl/evwBM> | <http://www.unicode.org/charts/normalization/chart_Latin.html>
    a: 'áăắặằẳẵǎâấậầẩẫäǟȧǡạȁàảȃāąᶏẚåǻḁⱥãɐₐ',
    aa: 'ꜳ',
    ae: 'æǽǣᴂ',
    ao: 'ꜵ',
    au: 'ꜷ',
    av: 'ꜹꜻ',
    ay: 'ꜽ',
    b: 'ḃḅɓḇᵬᶀƀƃ',
    o: 'ɵóŏǒôốộồổỗöȫȯȱọőȍòỏơớợờởỡȏꝋꝍⱺōṓṑǫǭøǿõṍṏȭɔᶗᴑᴓₒ',
    c: 'ćčçḉĉɕċƈȼↄꜿ',
    d: 'ďḑḓȡḋḍɗᶑḏᵭᶁđɖƌꝺ',
    i: 'ıíĭǐîïḯịȉìỉȋīįᶖɨĩḭᴉᵢ',
    j: 'ȷɟʄǰĵʝɉⱼ',
    dz: 'ǳǆ',
    e: 'éĕěȩḝêếệềểễḙëėẹȅèẻȇēḗḕⱸęᶒɇẽḛɛᶓɘǝₑ',
    et: 'ꝫ',
    f: 'ḟƒᵮᶂꝼ',
    g: 'ǵğǧģĝġɠḡᶃǥᵹɡᵷ',
    h: 'ḫȟḩĥⱨḧḣḥɦẖħɥʮʯ',
    hv: 'ƕ',
    r: 'ꞃŕřŗṙṛṝȑɾᵳȓṟɼᵲᶉɍɽɿɹɻɺⱹᵣ',
    s: 'ꞅſẜẛẝśṥšṧşŝșṡṣṩʂᵴᶊȿ',
    t: 'ꞇťţṱțȶẗⱦṫṭƭṯᵵƫʈŧʇ',
    is: 'ꝭ',
    k: 'ḱǩķⱪꝃḳƙḵᶄꝁꝅʞ',
    l: 'ĺƚɬľļḽȴḷḹⱡꝉḻŀɫᶅɭłꞁ',
    lj: 'ǉ',
    m: 'ḿṁṃɱᵯᶆɯɰ',
    n: 'ńňņṋȵṅṇǹɲṉƞᵰᶇɳñ',
    nj: 'ǌ',
    oi: 'ƣ',
    oo: 'ꝏ',
    ou: 'ȣ',
    p: 'ṕṗꝓƥᵱᶈꝕᵽꝑ',
    q: 'ꝙʠɋꝗ',
    ss: 'ß',
    u: 'ᴝúŭǔûṷüǘǚǜǖṳụűȕùủưứựừửữȗūṻųᶙůũṹṵᵤ',
    th: 'ᵺ',
    oe: 'ᴔœ',
    v: 'ʌⱴꝟṿʋᶌⱱṽᵥ',
    w: 'ʍẃŵẅẇẉẁⱳẘ',
    y: 'ʎýŷÿẏỵỳƴỷỿȳẙɏỹ',
    tz: 'ꜩ',
    ue: 'ᵫ',
    um: 'ꝸ',
    vy: 'ꝡ',
    x: 'ẍẋᶍₓ',
    z: 'źžẑʑⱬżẓȥẕᵶᶎʐƶɀ',
    ff: 'ﬀ',
    ffi: 'ﬃ',
    ffl: 'ﬄ',
    fi: 'ﬁ',
    fl: 'ﬂ',
    ij: 'ĳ',
    st: 'ﬆ'
  };
  const mulitp = 10000000000000;
  const hexCache = {
    chrs: {},
    strs: {}
  };
  // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
  const strAsLatinCache = new CachePropVal(str => pub.getStrAsLatin(str), 10);
  // Both upper and lower case
  const accents_all = {};
  // for preg.has_accented
  let accents_all_str = '';
  const numbers = '0123456789'.split('');
  const ABC_by_lang = {
    default: 'abcdefghijklmnopqrstuvwxyz'.split('').concat(numbers),
    hu: 'aábcdeéfghiíjklmnoóöőpqrstuúüűvwxyz'.split('').concat(numbers)
  };


  for (const l in accents) {
    if (!Object.hasOwnProperty.call(accents, l)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const achars = accents[l].split('');
    const L = l.toUpperCase();
    let ux = 0;
    const ul = achars.length;

    for (; ux < ul; ux++) {
      const achar = achars[ux];
      const ACHAR = achar.toUpperCase();
      accents_all[achar] = l;
      if (achar !== ACHAR) {
        accents_all[ACHAR] = L;
      }
    }
    accents_all_str += accents[l]; // lower case only
  }

  const preg = {
    spc: /(\r\n|\n|\r|\s\s+)/gm,
    // lb_tab_dspc__endspc: /(\r\n|\r|\n)|\t\t+|<br\s*\/?>|\s\s+|[^^][\s\t]$/, // todo: kell bele az össze punct
    // lb_tab_dspc_endspc_nonword: new RegExp('(\\r\\n|\\r|\\n)|\\t\\t+|<br\\s*\\/?>|\\s\\s+|[^^][\\s\\t]$|' + xrngs.nonword + '{2}', 'gim'),
    lb_tab_dspc_endspc_nonword: new RegExp('(\\r\\n|\\r|\\n)|<br\\s*\\/?>|\\s{3,}|[^^][\\s\\t]{3,}$', 'gi'),
    br_only: /^<br\s*\/?>$/i,
    lnb: /(\r\n|\n|\r)/g,
    last_br: /[^\s]+<br\s*\/?>|\n$/i,
    zerowspc: /[\u200B-\u200D\uFEFF]/gm,
    accented: new RegExp(`[${accents_all_str}]`, 'gim'),
    all_word: new RegExp(xrngs.negnonwords, 'gim')
  };


  const filterNotEmptyStrs = (m) => typeof m === "string" && m.trim() !== "";

  const pub = {
    escapeRegExp,
    setAbc(abclang) {
      if (typeof abclang !== 'object') {
        return;
      }

      for (const lang in abclang) {
        if (!Object.hasOwnProperty.call(abclang, lang)) {
          // eslint-disable-next-line no-continue
          continue;
        }
        ABC_by_lang[lang] = abclang[lang].toLowerCase().split('').concat(numbers);
      }
    },
    getAbc(lang) {
      return ABC_by_lang[lang] || null;
    },
    xrngs,
    preg,
    accents_all_str,
    repeat(str, tms) {
      let r = '';
      for (let i = 0; i < tms; i++) {
        r += str;
      }
      return r;
    },
    toHex(str) {
      let ret = '';
      let i = 0;
      let charCode; let hexcC; let
        hexcI;

      if (hexCache.strs[str]) {
        return hexCache.strs[str];
      }

      // eslint-disable-next-line no-cond-assign
      while (!isNaN(charCode = str.charCodeAt(i++))) {
        hexcC = charCode.toString(16);
        hexcI = '_' + hexcC;
        if (hexCache.chrs[hexcI]) {
          ret += hexCache.chrs[hexcI];
        }
        else {
          ret += hexCache.chrs[hexcI] = ((charCode < 256)
            ? ('\\x' + (charCode > 15 ? '' : '0') + hexcC)
            : ('\\u' + this.repeat('0', 4 - hexcC.length) + hexcC)
          );
        }
      }

      return (hexCache.strs[str] = ret);
    },
    hasAccented(str) {
      return (typeof str === 'string' && str) ? preg.accented.test(str) : false;
    },
    getUchrAsLchr(chr) {
      return accents_all[chr] || chr;
    },
    getStrAsLatin(str, valid) {
      return (typeof str === 'string' && str && (valid || this.hasAccented(str))
        ? str.replace(preg.accented, this.getUchrAsLchr)
        : str
      );
    },
    getStrAsLatinMemoized(str) {
      return strAsLatinCache.get(str);
    },

    apostrophe: { // to preserving the logic of word separation along punctuation characters.
      _langSets: {
        en: {
          replaceRxs: [
            {
              name: 'apostrophes',
              rx: new RegExp(`(${xrngs.negnonword})(['’])(${xrngs.negnonword}|\\s|$)`, 'ig')
            },
            {
              name: 'hypens',
              rx: new RegExp(`(${xrngs.negnonword})(${xrngs.dash})(${xrngs.negnonword})`, 'ig')
            }
          ]
        }
      },
      _holder: {
        // the characters storing the numbers must remain alphabetic characters according to the unicode classification.
        rx: /一ᆖAPOST([O⒈⒉⒊⒋⒌⒍⒎⒏⒐]+)ROPHEᆖ一/g,
        get: (p1, p2, p3) => `${p1}一ᆖAPOST${p2}ROPHEᆖ一${p3}`
      },
      _mapNumToLetter: 'O⒈⒉⒊⒋⒌⒍⒎⒏⒐'.split(''),

      _convertReplaceFn: (match, p1, p2, p3) => {
        const encoded = (p2).charCodeAt().toString().split('').map(
          n => pub.apostrophe._mapNumToLetter[n]
        ).join('');
        return pub.apostrophe._holder.get(p1, encoded, p3);
      },

      _restoreReplaceFn: (match, p1) => {
        const decoded = String.fromCharCode((p1).split('').map(
          l => pub.apostrophe._mapNumToLetter.indexOf(l)
        ).join('') - 0);
        if (!decoded) {
          PREVIEW_MODE && console.warn('_restoreReplaceFn: decode failed', p1);
        }
        return decoded || "'";
      },

      convert(str, lang = "en") {
        const langSet = this._langSets[lang];
        if (!langSet) { PREVIEW_MODE && console.warn('langSet not found', lang); return str; }
        let s = str;

        for (let i = 0; i < langSet.replaceRxs.length; i++) {
          const {rx} = langSet.replaceRxs[i];
          s = s.replace(rx, this._convertReplaceFn);
        }
        return s;
      },

      restore(s) {
        return s.replace(pub.apostrophe._holder.rx, this._restoreReplaceFn);
      },

      test() {
        let pass = true;
        const sentences = [
          "John's car",
          "the girls' room",
          "the sailors' boat",
          "a month's pay",
          "Saint Mary's",
          "it's",
          "isn’t",
          "She won’t eat any vegetables",
          "You’ve broken my watch!",
          "What’s he doing? Who’d like some coffee?"
        ];
        const result = {};
        for (let i = 0; i < sentences.length; i++) {
          const sentence = sentences[i];
          const encoded = this.convert(sentence);
          const decoded = this.restore(encoded);
          const equal = sentence === decoded;
          if (!equal) {
            pass = false;
          }
          result[sentence] = {
            encoded,
            decoded,
            equal: sentence === decoded
          };
        }
        console.log(
          'TEST',
          {
            result,
            words: pub.getWords(Object.values(sentences).join(' '))
          }
        );
        return pass;
      }
    },

    uniqueArray(arr) {
      return u.uniq(arr);
    },

    getWords(str, unique = true, lang) {

      if (typeof str !== 'string' || !str) {
        return [];
      }
      let m = this.dedupeSpaces(
        this.apostrophe.convert(str, lang)
      ).match(preg.all_word);

      m = ((m !== null && typeof m === 'object' && m.length)
        ? m.filter(filterNotEmptyStrs)
        : []
      ).map(w => {
        const r = this.apostrophe.restore(w);
        return r;

      });
      return unique ? u.uniq(m) : m;
    },
    getWordCount(str, unique = true) {
      const words = this.getWords(str, unique);
      return words.length;
    },
    // getWordCount_(str) {
    //   if (typeof str !== 'string' || !str) {
    //     return 0;
    //   }
    //   const m = str.match(preg.all_word);
    //   return (m !== null && typeof m === 'object' && m.length) ? u.uniq(m).length : 0;
    // },
    getWordMatchReg(words) { // utf8-16 \b fix
      let ret = null;
      if (typeof words === 'string') {
        words = this.getWords(words);
      }
      if (Array.isArray(words) && !words.length) {
        return ret;
      }

      u.arraySortBy(words, 'length', true);
      const excapedWords = words.map(w => escapeRegExp(w));

      try {
        // const w = excapedWords.join('|').replace(/\s+/g, '[^\\S\\r\\n]+');
        const w = excapedWords.join('|').replace(/\s+/g, '[^\\S\\r\\n]{1,}');
        ret = new RegExp('(^|' + xrngs.nonword + ')(' + w + ')(?=$|' + xrngs.nonword + ')', 'gim');
        // console.log('getWordMatchReg', ret);
      }
      catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return ret;
    },
    getStrWeight(str, lang = ABC_by_lang.default) {
      if (typeof str !== 'string' || !str) {
        return 0;
      }

      let weight = 0;
      let divid = '1';
      const latin26Only = typeof ABC_by_lang[lang] !== 'object';
      const abc = latin26Only ? ABC_by_lang.default : ABC_by_lang[lang];
      const wBoundary = abc.length;
      const aSrt = (latin26Only ? this.getStrAsLatin(str) : str).toLowerCase().split('');
      let x = 0;
      const l = (aSrt.length - 0);

      for (; x < l; x++) {
        const chr = aSrt[x];
        let n = abc.indexOf(chr);
        if (n === -1) { //  try as latin
          n = this.hasAccented(chr) ? abc.indexOf(this.getUchrAsLchr(chr)) : wBoundary;
        }
        divid += '00';
        weight += ((n + 1) * mulitp) / ((x + 1) * (divid - 0));
      }
      return weight;
    },
    sortAbc(words, lang = "hu", _prop) {
      const w = [];
      const weightProp = '__weight__';

      for (let i = 0; i < words.length; i++) {
        if (_prop) {
          words[i].__weight__ = this.getStrWeight(words[i][_prop], lang);
        }
        else {
          w.push({
            word: words[i],
            [weightProp]: this.getStrWeight(words[i], lang)
          });
        }
      }
      const r = u.arraySortBy(_prop ? words.slice(0) : w, weightProp);

      if (_prop) {
        for (let i = 0; i < r.length; i++) { delete r[i][weightProp]; }
      }
      return _prop ? r : r.map(w => w.word);
    },
    sortByLength(words) {
      return u.arraySortBy(words, 'length', true);
    },
    dedupeSpaces(s) {
      return (s + '')
        .replace(preg.zerowspc, '')
        .replace(preg.spc, ' ')
        .replace(preg.spc, ' ')
        .trim()
      ;
    }
  };

  // pub.apostrophe.test();

  return pub;
}());
