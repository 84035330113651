import { EventHandler } from "react";

const trapFocus = (function() {

  const FOCUSABLES = (
    '[role="button"], a[href], area[href], input, select, textarea, button, iframe, object, [tabindex="0"], [contenteditable]'
  ).split(/,\s+?/).join(':not([disabled]), ');

  const isVisible = function(el: HTMLElement) { return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length); }

  return function(el_wrapper: HTMLElement, el_for_initial_focus: HTMLElement) {
    if (!el_wrapper) {
      throw new Error('Could not initialize focus-trapping - Element Missing');
    }

    const focusableElements = Array.prototype.slice.call(
      el_wrapper.querySelectorAll(FOCUSABLES), 0
    ).filter(isVisible);

    let keyboardHandler;

    if (focusableElements.length > 0) {
      const firstFocusableEl = focusableElements[0];
      const lastFocusableEl = focusableElements[focusableElements.length - 1];
      const el_autofocus = el_wrapper.querySelector('[autofocus]');
      const elementToFocus = el_for_initial_focus ? el_for_initial_focus : (
        el_autofocus ? el_autofocus : firstFocusableEl
      );
      elementToFocus.focus();

      keyboardHandler = function keyboardHandler(e: KeyboardEvent) {
        if (e.keyCode === 9) { // Tab key
          if (e.shiftKey && document.activeElement === firstFocusableEl) {
            e.preventDefault();
            lastFocusableEl.focus();
          }
          else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
            e.preventDefault();
            firstFocusableEl.focus();
          }
        }
      };
      el_wrapper.addEventListener('keydown', keyboardHandler);
    }

    return function cleanUp() {
      if (keyboardHandler) {
        el_wrapper.removeEventListener('keydown', keyboardHandler);
      }
    };
  };
})();


export default trapFocus;
