import { getDeepProp } from './lib/objects/deepOps';
import jsxFormatter, {cleanFormatterMarkups} from './lib/jsxFormatter';

const cache = {};
const regx_plainText = /##PLAINTEXT##$/;


export const messages = {
  data: {
    Branding: {
      fullName: 'GRIMM Online Szótári Rendszer',
      shortName: 'GRIMM Online Szótár'
      // , subTitle: 'ONLINE SZÓTÁR'
    },
    user: {
      logout: {
        logoutSuccess: 'Sikeresen kijelentkeztél a oldalról.'
      },
      login: {
        loginSuccess: 'Sikeresen beléptél a oldalra.'
      },
      form: {
        backToHome: 'Vissza a főoldalra',
        defPrimaryBtn: 'beküldés',
        defSecondaryBtn: 'vissza'
      },
      forgotPwLink: {
        prefix: 'Ha ***elfelejtetted jelszavad***, új beállításához ',
        linkText: '***kattints ide***',
        suffix: '!'
      },
      activateLink: {
        prefix: 'Amennyiben szeretnéd aktiválni, ',
        linkText: 'kattints ide',
        suffix: '!'
      },
      registerLink: {
        prefix: 'Amennyiben szeretnéd regisztrálni, ',
        linkText: 'kattints ide',
        suffix: '!'
      },
      loginLink: {
        prefix: 'Ha szeretnél belépni, ',
        linkText: 'kattints ide',
        suffix: '!'
      },
      resendEmail: {
        text: 'Megerősítő kód újraküldése',
        loading: 'Megerősítő kód küldése folyamatban...',
        pwVerifyCodeJustSent: 'A megerősítő kódot elküldtük.',
        pwVerifyCodeAlreadySent: 'A megerősítő kód már újraküldésre került, ezért $[timeLeft] másodpercig ismételt újraküldése nem lehetséges.',
        regVerifyCodeJustSent: 'A megerősítő kódot elküldtük.',
        regVerifyCodeAlreadySent: 'A megerősítő kód már újraküldésre került, ezért $[timeLeft] másodpercig ismételt újraküldése nem lehetséges.'
      },
      testPhaseWarn: {
        label: 'Figyelem!',
        text: 'Az új Grimm Online Szótári Rendszerbe történő regisztráció jelenleg kizárólag azok számára érhető el, akik a régi szótári rendszerben aktív előfizetéssel rendelkeznek.'
      },
      fields: {
        username: 'Felhasználói e-mail',
        password: 'Jelszó',
        passwordRepeat: "Jelszó ismét",
        newPassword: 'Új jelszó',
        newPasswordHelper: 'minimum 8 karakter',
        newPasswordRepeat: "Új jelszó ismét",
        verificationCode: 'Megerősítő kód',
        verificationCodePlaceholder: '-----',
        terms: {
          iAccept: 'Elfogadom a Grimm Online Szótári Rendszer ',
          terms: 'általános szerződési feltételeit',
          and: ' és ',
          privacy: 'adatkezelési irányelveit',
          end: '.'
        }
      },
      PageSignIn: {
        title: 'Belépés / Regisztráció',
        primaryBtn: 'Tovább',
        desc: 'Add meg e-mail címed, amellyel belépni vagy regisztrálni szeretnél a Grimm Online Szótári Rendszerbe!',
        helperText: 'Ha nincs még hozzáférésed, a következő lépésben regisztrálhatod e-mail címed!',
        emailNotRegistered: {
          primaryBtn: 'Regisztráció',
          helperText: ' Az e-mail még nem regisztrált, indítsd el az alábbi gombra kattintva a regisztrációt!'
        },
        emailRegistered: {
          primaryBtn: 'Belépés',
          helperText: 'A tovább gomb megnyomását követően jelszavad kell megadnod a belépéshez.'
        },
        emailRegisteredButNotActivated: {
          primaryBtn: 'Regisztráció véglegesítése',
          helperText: 'Az e-mail regisztrációja már el lett indítva, de befejezéséhez még felhasználói megerősítésre van szükség.'
        },
        emailRegistrationDisabled: {
          helperText: 'Az e-mailhez nem tartozik aktív előfizetés a régi rendszerben, ezért még ***nem regisztrálható***!'
        }
      },
      pageReg: {
        title: 'Regisztráció',
        primaryBtn: 'Tovább',
        desc: 'A tovább gombra kattintva egy ***aktiváló kódot fogunk kiküldeni e-mail címedre***, amelynek segítségével véglegesítheted regisztrációdat.',
        emailRegistered: {
          helperTextLine1: 'A megadott e-mail cím már regisztrált.',
          helperTextLine2: 'Elfelejtetted jelszavad?'
        },
        emailRegisteredButNotActivated: {
          helperText: 'Az e-mail regisztrációja már el lett indítva, de befejezéséhez még felhasználói megerősítésre van szükség.'
        },
        emailRegistrationDisabled: {
          helperText: 'Az e-mail jelenleg nem regisztrálható, mert nem tartozik hozzá aktív szótári előfizetés a régi szótári rendszerben.'
        }
      },
      PageRegActivate: {
        title: "Regisztráció megerősítése",
        primaryBtnFinishRegWithLogin: 'Regisztráció befejezése és belépés',
        primaryBtnFinishReg: 'Regisztráció befejezése',
        warn: `Több percbe is telhet, amíg a megerősítő kódot tartalmazó e-mail megérkezik.
              Ha beérkezett üzeneteid között nem találod az e-mailt, akkor ellenőrizd, hogy nem került-e levélszemétbe!`,
        given: 'megadott',
        desc: 'Add meg $[article] ***$[email]*** címre küldött megerősítő kódot, hogy véglegesítsd regisztrációdat!',
        activationSuccess: 'Regisztráció befejezése sikeresen megtörtént.',
        descInvalid: 'Ha szeretnéd a regisztrációt újrakezdeni, ',
        codeNotValid: 'A megadott megerősítő kód nem érvényes / lejárt / vagy már megerősítésre került.'
      },
      pageLogIn: {
        title: "Belépés",
        primaryBtn: 'Belépés',
        pleaseEnterPwToSignIn: 'Add meg e-mail címed és jelszavad a belépéshez.',
        apiUnexpectedError: "Váratlan kiszolgálói hiba történt.",
        emailRegisteredButNotActivated: {
          helperText: 'Az e-mail regisztrációja már el lett indítva, de befejezéséhez még felhasználói megerősítésre van szükség.'
        },
        emailNotRegistered: {
          helperText: 'Az e-mail még nem regisztrált.'
        },
        emailRegistrationDisabled: {
          helperText: 'Az e-mail nem regisztrált, és nem is regisztrálható még jelenleg, mert nem tartozik hozzá aktív szótári előfizetés a régi szótári rendszerben.'
        }
      },
      PagePwRequest: {
        title: 'Új jelszó beállítása',
        primaryBtn: 'Tovább',
        desc: "Az új jelszavad beállításához egy ***aktiváló kódra*** lesz szükség, amely a tovább gombra való kattintást követően felhasználói e-mail címedre lesz kiküldve.",
        emailRegisteredButNotActivated: {
          helperText: 'A megadott e-mail regisztációja már el lett indítva, de a befejezéséhez még felhasználói megerősítésre van szükség.'
        },
        emailRegistrationDisabled: {
          helperText: 'A megadott e-mail nem szerepel a regisztráltak között, de sajnos jelenleg nem regisztrálható, mivel nem tartok hozzá aktív előfizetés a régi Grimm OSZR rendszerben.'
        },
        emailNotRegistered: {
          helperText: 'A megadott e-mail nem szerepel a regisztráltak között.'
        }
      },
      PagePwReset: {
        title: "Új jelszó beállításának véglegesítése",
        primaryBtnSaveAndLogin: 'Mentés és belépés',
        primaryBtnSave: 'Mentés',
        warn: `Több percbe is telhet, amíg a megerősítő kódot tartalmazó e-mail megérkezik.
              Ha beérkezett üzeneteid között nem találod az e-mailt, akkor ellenőrizd, hogy nem került-e levélszemétbe!`,
        desc: 'Add meg $[article] ***$[email]*** e-mailre küldött megerősítő kódot és új jelszavad, hogy elmentsük és beléphess a rendszerbe!',
        given: 'megadott',
        pwsetSuccess: 'Az új jelszó beállítása sikeresen megtörtént.',
        descInvalid: 'Ha szeretnéd a jelszó beállítását újra kezdeményezni, ',
        codeNotValid: 'A megadott megerősítő kód nem érvényes / lejárt / vagy már beállításra került vele a jelszó.'
      },
      PageProfile: {
        title: "Felhasználói adatok",
        youSureToDeleteProfile: 'Biztosan törölni szeretnéd hozzáférésedet a rendszerhez?',
        confirmProfileDelete: 'A regisztráció törlése gombra való kattintást követően a ***REGISZTRÁCIÓD VÉGLEGESEN TÖRLÉSRE KERÜL*** és hozzáférésed megszűnik a rendszerhez. ¤¤¤Ez a művelet nem visszavonható!¤¤¤',
        delUserSuccess: 'A regisztrációd törlése sikeresen megtörtént.',
        delUserEmailNotMatch: 'A megadott e-mail cím nem egyezik a bejelentkezett felhasználó e-mail címével.',
        delUserEmailRequired: 'Az E-mail cím megadása kötelező',
        passwordReset: 'Új jelszó beállítása',
        deleteUserProfileBtn: 'Regisztráció törlése'
      },
      PageLogout: {
        title: '---Kilépés...---'
      }
    },
    inputScheme: {
      invalidTaxId: 'Érvénytelen adószám. Formátum minta: 00000000-0-00',
      passwordNotMatch: 'A megadott jelszavak nem egyeznek.',
      termsUnchecked: 'Az ÁSZF elfogadása kötelező a továbblépéshez.',
      tooShort: 'A megadott érték túl rövid',
      tooLong: 'A megadott érték túl hosszú',
      tooShortPassword: 'A jelszó hossza nem éri el a 8 karaktert!',
      requiredField: 'Kötelező mező.',
      invalidEmail: 'Az e-mail cím formátuma nem megfelelő.',
      invalidCode: 'Événytelen kód.',
      invalidZip: 'Hibás irányítószám'
    },
    Page404: {
      headline: "+++404+++/// | ///---Az oldal nem található.---",
      action: 'Vissza a főoldalra'
    },
    PageMustLogin: {
      headline: "Az oldal megtekintéséhez be kell lépni.",
      action: 'Belépés / Regisztráció'
    },
    PageMustLogout: {
      headline: "Az oldal megtekintéséhez ki kell lépni.",
      action: 'Kilépés'
    },

    PageSubscription: {
      cartCouponsLabel: 'Kedvezménykupon',
      cartCouponFormLabel: 'Kedvezménykupon érvényesítése',
      cartCouponItemAlreadyAdded: 'A kedvezménykupon már hozzá van adva a kosárhoz.',
      cartCouponLoading: 'Lekérdezés...',
      cartCouponItemLoading: 'aktiválás folyamatban...',
      cartCouponInputLabel: 'kuponkód',
      cartCouponReplaceCartItemTitle: 'Megfelelő elem behelyezése / cseréje a kosárban',
      cartCouponReplaceCartItemText: 'javítás',
      sidebarTitle: "Kosár",
      sidebarButton: "Tovább a fizetéshez ---($[cartItems] tétel = $[summary])---",
      formTitle: 'Számlázási adatok',
      formPrimaryBtn: 'Bankkártyás fizetés indítása',
      formDesc: 'Adja meg számlázási adatait...',
      back: 'vissza',
      removePackage: 'Eltávolítás',
      periodsTitleSubscript: 'Előfizetés időtartama',
      periodsTitleExtend: 'Meghosszabbítás időtartama',
      package: 'Szótárcsomag',
      packageIcon: {
        accessibleAllDir: 'Mindkét nyelvirány elérhető',
        accessibleOneDir: '$[from]-$[to] irány érhető el'
      },
      expireTooLate: 'Az előfizetési csomag megosszabbítása $[days] nappal lépné túl a megengedett előfizetési időt.',
      cartSubtotal: 'Részösszeg',
      cartDiscount: 'Kedvezmény',
      totalIncludesVat: 'A végösszeg tartalmazza az áfát.', // transaction-re is jó kell legyen
      cartTotal: 'Fizetendő összesen',
      currentPackageExpiry: 'Jelenlegi előfizetés lejárata: $[date]',
      dictAccessIconLabel: 'Hozzáférés állapota a szótárhoz.',
      dictPairAccess: {
        'no-access': 'Még nincs hozzáférésed',
        'partial-access': 'Részleges hozzáférésed van ehhez a szótárhoz.\n $[fromTo] irányhoz $[expiry]-ig',
        'full-access': '$[expiry]-ig hozzáférésed van ehhez a szótárhoz',
        'full-access-different-expiry': `Hozzáférésed van ehhez a szótárhoz, irányonként különböző időpontokig.
          ÷÷÷$[foreignToNativeText] irányhoz $[foreignToNativeExpiry]-ig.÷÷÷
          ÷÷÷$[nativeToForeignText] irányhoz $[nativeToForeignExpiry]-ig.÷÷÷
        `
      }
    },

    PageCheckout: {
      sidebarTitle: "Összegzés",
      sidebarButton: "Kosár tartalmának módosítása",
      sidebarTerms: "A fieztés elindításához a számlázási adatok kitöltése szükséges.",
      // sidebarTerms: (
      //   `A fizetés kizárólag online bankkártyás fizetés útján lehetséges, amelynek lehetőségét partnerünk, a ***K&H Bank*** biztosítja.
      //   \nA vásárlást követően a számla kiállítása a kifizetett végösszegről, a ***Számlázz.hu*** rendszerén keresztül történik és a felhasználói e-mail címre lesz kiküldve.`
      // ),
      customer_Name: "Számlázási név",
      customer_ZipCode: "Irányítószám",
      customer_Country: "Ország",
      customer_City: "Település",
      customer_Address: "Utca, házszám",
      customer_Individual: "Magánszemélyként, adószám megadása nélkül vásárolom az előfizetést.",
      customer_TaxNumber: "Adószám",
      customer_Terms: {
        iAccept: 'Megerősítem, hogy elfogadom az ÁSZF-ben rögzített ',
        terms: 'vásárlási feltételeket',
        and: ' és ',
        privacy: 'adatkezelési irányelveket',
        end: '.'
      },
      unexpectedError: "Nem várt kiszolgálói hiba történt.",
      primaryBtn: 'Bankkártyás fizetés indítása'
    },

    PageTransactions: {
      sidebarTitle: "Élő előfizetéseim",
      newSubscription: "Új előfizetés",
      title: "Előfizetés történet",
      trTime: "időpontja",
      trTimePending: "folyamatban",
      trTransaction: "Tranzakció",
      trPrevTransactionsHide: "Korábbi tranzakciók elrejtése",
      trPrevTransactionsShow: "Korábbi tranzakciók megjelenítése",
      trCodes: {
        "NAK": "Sikertelen fizetés.",
        "UTX": "Ismeretlen tranzakcióazonosító.",
        "PEN": "A tranzakció folyamatban van.",
        "ERR": "Kapcsolati hiba.",
        "CAN": "A tranzakció meg lett szakítva.",
        "EXP": "Lejárt fizetési kísérlet.",
        "ACK": "Sikeres tranzakció!",
        "_NP": "Sikeres tranzakció!"
      },
      summaryTotal: 'Fizetve összesen',
      itemTypes: {
        dictionary: 'szótár',
        package: 'szótárcsomag'
      },
      types: {
        freecode: 'Térítésmentes hozzáférés',
        papercode: 'Kódaktiválás',
        khpayment: 'Előfizetés',
        legacycode: 'Öröklött hozzáférés',
        nopayment: 'Előfizetés tranzakció nélkül '
      },
      payment: {
        trAuthority: "Authorizációs kód",
        trId: "Tranzakciós azonosító",
        trDate: "Tranzakció időpontja",
        trDateFaild: "Időpont"
      },
      downloadBill: 'Számla letöltése',
      emptySubscriptionList: 'Nincs még előfizetésed'
    },

    PagePaperCode: {
      title: "Nyomtatott szótárhoz kapcsolódó vagy térítésmentes online szótári előfizetés aktiválása",
      formWarn: "Figyelem! Aktiváló kódot nem minden nyomtatott Grimm szótár tartalmaz.  A szótárakban elhelyezett kódok egyszer aktiválhatók, ezért csak saját tulajdonodban lévő szótárban található kódot használj fel!",
      formDesc: "Add meg az alábbi mezőkbe beírva az aktiváló kódodat.",
      formSubmitLabel: "Szótári előfizetés aktiválása",
      fieldDesc: '[link:/legacycode]Itt[/link] tudsz megadni más formátumú, nem nyomtatott szótárból származó vagy a régi szótári rendszerhez kiadott aktiváló kódot',
      success: 'Sikeres aktiválás!'
    },

    PageLegacyCode: {
      title: "Nyomtatott szótárhoz kapcsolódó online szótári előfizetés aktiválása",
      formWarn: "Figyelem! Aktiváló kódot nem minden nyomtatott Grimm szótár tartalmaz.  A szótárakban elhelyezett kódok egyszer aktiválhatók, ezért csak saját tulajdonodban lévő szótárban található kódot használj fel!",
      formDesc: "Add meg az alábbi mezőkbe beírva az aktiváló kódodat.",
      formSubmitLabel: "Szótári előfizetés aktiválása",
      fieldDesc: '[link:/papercode]Itt[/link] tudsz megadni más formátumú, nyomtatott szótárból származó kódot',
      success: 'Sikeres aktiválás!'
    },

    CodeInput: {
      emptyCode: 'Törlés',
      ariaLabelForChar: "A kód $[segment]. részének $[index]. karaktere"
    },

    PageEntryList: {
      sidebarTitle: "Szótárnapló"
    },

    Dictionary: {
      signinBtn: 'BELÉPÉS / REGISZTRÁCIÓ',
      textNotChanged: 'Új szótárazás indításához a forrásszöveg módosítása szükséges.',
      languageSwitcher: {
        switchDirection: {
          title: 'Szótárazás irányának cseréje',
          currentDirection: 'Jelenlegi szótárazás iránya: $[sourceLang] - $[targetLang]'
        },
        sourceLanguage: {
          title: 'Forrásnyelv'
        },
        targetLanguage: {
          title: 'Célnyelv'
        }
      },
      entry: {
        logEntry: {
          removeLog: 'Szócikk eltávolítása a naplóból',
          addLog: 'Szócikk naplózása',
          justRemoved: 'Törölt napló elem',
          removeAll: 'Összes eltávolítása'
        }
      },
      entries: {
        results: 'Találatok',
        article: 'Találat $[index].: "$[name]"',
        entriesWhereFound: 'Találatok helye',
        entryWhereFound: 'Találat helye',
        resultsOthers: 'Egyéb találatok',
        resultsOthersHide: 'elrejtése',
        resultsOthersShow: 'megjelenítése',
        style: {
          accessible: 'Struktúrált megjelenésre váltás',
          printformat: 'Kompakt megjelenésre váltás',
          printformatAria: 'A jelenlegi kompakt megjelenés kisegítő technológiát használó eszközöket nem támogat.'
        }
      }
    },
    resultEntry: {
      pventryLabel: 'Frazális igék',
      dwExtSepaLabel: "Példák, szókapcsolatok",
      sensenumAriaLabel: 'Jelentés száma',
      posAriaLabel: 'Szófaj: $[pos]',
      irregTitle: 'rendhagyó ige'
    },
    TrsSourceArea: {
      lineBreakMode: {
        optionListHeader: 'Enter billentyű hatása a szöveges mezőben',
        title: 'Enter billentyű viselkedése',
        options: {
          'submitFewWords': {
            label: 'Kombinált',
            desc: '$[fewWordsLen] szóig az ˘˘˘Enter˘˘˘ billentyű elküldi a szöveget, ilyenkor sortörést a ˘˘˘Shift+Enter˘˘˘ billentyűk együttes lenyomásával lehet beiktatni.'
          },
          'submitAlways': {
            label: 'Beküldés › ˘˘˘Enter˘˘˘ +++|+++ Új sor › ˘˘˘Shift+Enter˘˘˘',
            desc: 'Az ˘˘˘Enter˘˘˘ billentyű beküldi a szöveget. Sortörést a ˘˘˘Shift+Enter˘˘˘ billentyűk együttes lenyomásával lehet beiktatni.'
          },
          'breakLine': {
            label: 'Beküldés › ˘˘˘Shift+Enter˘˘˘ +++|+++ Új sor › ˘˘˘Enter˘˘˘',
            desc: 'Az ˘˘˘Enter˘˘˘ billentyű sortörést iktat be. A szöveget a ˘˘˘Shift+Enter˘˘˘ billentyűk együttes lenyomásával lehet beküldeni.'
          }
        }
      },
      specialChars: {
        title: 'Speciális karakterek',
        menu: 'Speciális karakterek listája',
        titleOpen: 'Speciális karakterek megnyitása',
        titleClose: 'Speciális karakterek bezárása',
        close: 'Bezárás',
        toUpperCase: 'Váltás nagybetűkre',
        toLowerCase: 'Váltás kisbetűkre',
        noFocus: 'A karakter beszúrásához előbb tedd a kúrzort a kívánt helyre!',
        insert: '"$[char]" karakter beszúrása a kúrzor helyére. Beszúrás után fókusz a szövegmezőre kerül.'
      },
      ocrIconTitle: "Szövegfelismerés",
      resetTitle: 'Szöveg törlése',
      resetText: 'Visszaállítás',
      editSubmit: 'Szótárazás',
      editEnd: 'Szerkesztés befejezése',
      editStart: 'Szerkesztés',
      numOfUniqueWords: 'Alakilag különböző szavak száma',
      numOfMaxUniqWordsQueriedOnce: 'Egyszerre szótárazható alakilag különböző szavak száma'
    },

    TTS: {
      button: {
        title: 'Szövegfelolvasás idegen nyelven',
        titleError: 'Szövegfelolvasás: $[error]',
        loading: 'Betöltés',
        play: 'Lejátszás',
        ariaLabel: 'Szövegfelolvasás $[text]'
      }
    },

    ocrTool: {
      altText: 'Szövegfelismeréshez kiválasztott kép',
      scannAsLang: 'Szövegfelismerés $[lang] nyelven',
      currentLang: 'Szövegfelismerés nyelve',
      selectAreaToCrop: 'Jelölj ki képrészletet a szövegfelismerés elvégzéséhez!',
      errorNoTextDetected: 'A képfájl feldolgozása nem járt eredménnyel.',
      errorNoSelection: "A szövegfelismerés elvégzéséhez ki kell jelölni egy képrészletet!",
      errorOnFileRead: "Hiba történt a fájl feldolgozásakor.",
      errorInvalidImage: "A kiválasztott fájl formátuma nem megfelelő. A rendszer által támogatott fájlformátumok: .jpg, .png",
      errorFileIsEmpty: "A kiválasztott fájl üres."
    },

    tr: {
      entriesSelectedResult: 'Szótári találatok',
      entriesA11yAriaLabel: 'Választott találat:',
      entriesA11yAriaLabelOne: 'Találat:',
      entriesSelectDictA11yAriaLabel: 'Szótár találat kiválasztása',
      noResult: 'A szótárazott szövegre nincs a választott szótárban találat, ÷÷÷$[sourceLang] → $[targetLang]÷÷÷ nyelvirányban.',
      submittable: 'Szótárazás indítása',
      placeholder: 'Szótárazandó szó, szöveg',
      warnNotSubmittable: 'Új szótárazás indításához a forrásszöveg módosítása szükséges.',
      // ebből kell egy másik is, amikor nincs result, vagy a preview nem aktív
      noteNummOfwordsExceeded: 'A pirossal jelölt szavak már túllépik az egyszerre szótárazható szavak számát. (max. $[numOfMaxWords])',
      noteNumOfwordsWillExceed: 'Egyszerre max. $[numOfMaxWords] alakilag különbőző szó szótárazható.',
      unexpectedError: 'Váratlan hiba történt...',
      errorStateDefaultDictNotFound: 'Állapothiba: az alapértelmezett szótár nem található vagy nem hozzáférhető: $[dictId]',
      errorDictNotFoundOrNotAccessible: 'A szótár nem található vagy nem hozzáférhető: $[dictId]'
    },

    access: {
      langRequiresLogin: '$[article] $[lang] nyelv használatához előfizetés és bejelentkezés szükséges.',
      langRequiresSubscription: '$[article] $[lang] nyelv használatához előfizetés szükséges.',
      dictRequiresLogin: '$[article] $[dict] használatához előfizetés és bejelentkezés szükséges.',
      dictRequiresSubscription: '$[article] $[dict] használatához előfizetés szükséges.'
    },


    layout: {
      QrPreloader: {
        bounceText: 'Betöltés',
        loading: 'Betöltés...'
      },
      Header: {
        ariaLabel: 'Fejléc'
      },
      PageHeader: {
        togglerArialabelOpen: 'Főmenü elrejtése',
        togglerArialabelClose: 'Főmenü megjelenítése'
      }
    },

    components: {
      LayoutUnavailable: {
        btnToFront: 'Főoldal'
      },
      Preloader: {
        error: 'Hiba:',
        loading: 'Betöltés:'
      }
    },
    mainMenu: {
      about: 'Grimm OSZR bemutatása',
      dict: 'Szótár',
      entrylog: 'Szótárnapló',
      terms: 'Felhasználási feltételek',
      privacy: 'Adatvédelmi irányelvek',
      prices: 'Díjszabás',
      cookies: 'Süti Beállítások',
      bookstore: 'Könyvesbolt',
      login: 'Belépés / Regisztráció'
    },
    userMenu: {
      togglerAriaLabel: 'Felhasználói menü',
      loginAriaLabel: 'Belépés / Regisztráció',
      listAriaLabel: 'Felhasználói menü',
      profile: 'Profil',
      subscriptions: 'Új előfizetés',
      papercode: 'Hozzáférési kód aktiválása',
      transactions: 'Előfizetés történet',
      logout: 'Kilépés'
    },
    meta: {
      pageTitle: {
        logEntry: 'Szótárnapló',
        about: 'Grimm szótárak 20 éve az oktatásban.',
        notFound: '404 | Az oldal nem található.',
        frontPage: 'Főoldal',
        papercode: 'Nyomtatott szótárhoz kapcsolódo vagy térítésmentes online szótári előfizetés aktiválása',
        legacycode: 'Nyomtatott szótárhoz kapcsolódo előfizetés aktiválása',
        subscription: 'Előfizetés',
        signin: 'Belépés / Regisztráció',
        login: 'Belépés',
        signup: 'Regisztráció',
        activate: 'Regisztráció megerősítése',
        forgot: 'Elfelejtett jelszó',
        changepass: 'Új jelszó megerősítése',
        profile: 'Felhasználói profil',
        logout: 'Kilépés'
      }
    }
  },

  getAll() {
    return this.data;
  },

  getAllFlatTuple() {
    return this.getAllFlat().map(({key, value}) => [key, value]);
  },

  getAllFlat() {
    const flatten = (data, parentKey = "") => {
      return Object.entries(data).map(([key, value]) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        return typeof value === "object" && value !== null
          ? flatten(value, fullKey)
          : {key: fullKey, value}
        ;
      }).flat();

    };
    return flatten(this.data);
  },

  getStrict(prop, replacements, clearCache) {
    return this.get(prop, replacements, clearCache, true);
  },

  get(prop, replacements, clearCache, strict) {
    const hasReplacements = replacements && typeof replacements === "object";
    const cacheId = `${prop}--${hasReplacements ? JSON.stringify(replacements) : 'raw'}`;
    const asPlainText = prop.search(regx_plainText) > -1;
    prop = prop.replace(regx_plainText, '');

    if (!clearCache && cache[cacheId]) {
      return cache[cacheId];
    }

    let msg = getDeepProp(this.data, prop);
    if (msg && typeof msg === 'string' && hasReplacements) {
      for (const prop in replacements) {
        if (Object.hasOwnProperty.call(replacements, prop)) {
          const value = replacements[prop];
          const token = new RegExp('\\$\\[' + prop + '\\]', 'g');
          if (typeof msg === "string") {
            msg = msg.replace(token, value);
          }
        }
      }
    }

    const value = typeof msg === "string" ? (
      asPlainText ? cleanFormatterMarkups(msg) : jsxFormatter(msg)
    ) : msg;


    const valid = strict || typeof value === "string" || Array.isArray(value);
    if (!valid) {
      console.warn('invalid message request: ' + prop, {
        valid, value, strict, prop, clearCache, replacements
      });
    }
    return !valid
      ? prop
      : (cache[cacheId] = value)
    ;
  }
};

export default messages;
