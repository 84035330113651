import {useEffect, useRef} from 'react';
import UAC from '../../../UAC';
import { DEV_MODE } from '../../../controller/devel';

const useOnDictValidation = ({dictList, dictId, userData}, callback) => {
  const refCallback = useRef();

  useEffect(() => {
    refCallback.current = callback || (() => console.warn('useOnDictValidation: callback is not defined'));
  }, [callback]);

  useEffect(() => {
    if (dictList) {
      const dict = dictList.find(d => d.id === dictId);
      if (dict) {
        if (!UAC.hasAccessToDict(dict) || !UAC.getAccessibleInitialDict()) {
          // validation not ok
          refCallback.current(false, dict);
          DEV_MODE && console.log("useOnDictValidation: validation NOT OK", dictId);
        }
      }
      else {
        // validation  ok
        refCallback.current(true, dict);
        DEV_MODE && console.log("useOnDictValidation: validation OK", dictId);
      }
    }
  }, [userData, dictList, dictId]);
};

export default useOnDictValidation;



// useEffect(() => {
//   if (dictList) {
//     const dict = dictList.find(d => d.id === state.curDictId);
//     if (dict) {
//       if (!UAC.hasAccessToDict(dict) || !UAC.getAccessibleInitialDict()) {
//         dispatch({ type: 'validate_dict', payload: { dict } });
//         console.log("useEffect: validation NOT OK", state.curDictId);
//       }
//     }
//     else {
//       // validation  ok
//     }
//   }
// }, [userData, dictList, state.curDictId]);
