import React, {useRef, useReducer, useCallback} from 'react';

import PropTypes from 'prop-types';
import AuthRelatedPage from "./AuthRelatedPage";
import { useIsMounted } from '../../hooks';
import { parseParams } from '../../lib/util';
import {ResendRegVerify} from './components/ResendEmail';
// import WithGrammarArticle from './components/WithGrammarArticle';

import * as schemes from './data/schemes';
import { formDataExtend } from './data/formDataHelper';
import grammarHelper from '../../lib/grammarHelper';

import messages from '../../messages';
import { userCheckCode, logoutHelper } from './userHelper';
import RegisterLink from './components/RegisterLink';



function reducer(state, {type, payload}) {
  switch (type) {
    case 'page':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}

const formData = {
  formId: 'verify-registration',
  title: messages.get('user.PageRegActivate.title'),
  primaryBtn: {text: messages.get('user.PageRegActivate.primaryBtnFinishReg')},
  warn: (getEventData) => {
    const {sentToEmail} = getEventData().pageData;
    return <>
      {messages.get('user.PageRegActivate.warn')}
      {' '} <ResendRegVerify email={sentToEmail} />
    </>;
  },

  desc: (getEventData) => {
    const {
      sentToEmail = messages.get('user.PageRegActivate.given')
    } = getEventData().pageData;

    return messages.get('user.PageRegActivate.desc', {
      article: grammarHelper.article.hu(sentToEmail, false),
      email: sentToEmail
    });
    // return <>
    //   <WithGrammarArticle email={sentToEmail || 'megadott'} /> címre <b>küldött megerősítő kód</b> megadásával beléphetsz a rendszerbe.
    // </>;
  },
  fieldsStates: {
    apiError: {
      error: (getEventData, {msg}) => msg
    },
    apiSuccess: {
      success: messages.get('user.PageRegActivate.activationSuccess')
    },
    sessionMatch: {
      primaryBtn: {
        text: messages.get('user.PageRegActivate.primaryBtnFinishRegWithLogin')
      }
    },
    invalid_code: {
      warn: messages.get('user.PageRegActivate.codeNotValid'),
      desc: (getEventData) => {
        const {
          sentToEmail = messages.get('user.PageRegActivate.given')
        } = getEventData().pageData;

        return <>
          <RegisterLink prefix={messages.get('user.PageRegActivate.descInvalid', {
            article: grammarHelper.article.hu(sentToEmail, false),
            email: sentToEmail
          })} username={sentToEmail} />
        </>;

        // return <>
        //   <WithGrammarArticle email={sentToEmail || 'megadott'} /> címre <b>küldött megerősítő kód</b> megadásával beléphetsz a rendszerbe.
        // </>;
      },
      primaryBtn: {
        disabled: true,
        text: messages.get('user.PageRegActivate.primaryBtnFinishReg')
      }
    }
  },
  fields: [
    {
      name: "verification-code",
      label: messages.get('user.fields.verificationCode'),
      autoComplete: "one-time-code",
      schema: schemes.verification_code,
      helperText: null,
      placeholder: messages.get('user.fields.verificationCodePlaceholder'),
      compact: false,
      maxLength: 5,
      fieldStates: {
        invalid_code: {
          errorMsg: messages.get('inputScheme.invalidCode')
        },
        sessionMatch: {

        },
        sessionNotMatch: {

        }
      }
    }
  ]
};


const PageRegActivate = ({appPub, history, location, match, ...props}) => {
  const {verifyKey} = match.params;
  const paramSentTo = location?.state?.sentto || parseParams(location.search).sentto || '';
  const sentToEmail = schemes.email.validate(paramSentTo) ? '' : paramSentTo.toLowerCase();
  const refForm = useRef();

  const {api, toastErrorMsg, toastSuccessMsg} = appPub;
  const isMounted = useIsMounted();

  const setFieldsState = useCallback((fieldsState, fieldsStateArgs = null) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch({ type: 'page', payload: { fieldsState, fieldsStateArgs } });
  }, []);

  const onCodeChange = useCallback((e, {
    value, schema, fieldState, setFieldState, ...rest}
  ) => {

    const code = value + ''.trim();
    if (code && !schema.validate(value)) {
      userCheckCode(code, 'ACTIVATE', {
        isMounted,
        api,
        toastErrorMsg
      }, ({
        fieldsState,
        fieldsStateArgs,
        fieldState,
        fieldStateArgs
      }) => {
        setFieldsState(fieldsState, fieldsStateArgs);
        setFieldState(fieldState, fieldStateArgs);
      });
    }
    else {
      setFieldsState(null, null);
      setFieldState(null, null);
    }
  }, [api, isMounted, setFieldsState, toastErrorMsg]);

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    fieldsState: null,
    fieldsStateArgs: null,
    pageData: {
      sentToEmail
    },
    currentForm: (
      formDataExtend(formData, {
        fields: [
          {
            name: 'verification-code',
            onChange: onCodeChange,
            onBlur: onCodeChange,
            onInit: onCodeChange,
            defaultValue: verifyKey || ''
          }
        ]
      })
    )
  });


  const onSubmit = useCallback((formData) => {
    if (!state.loading) {

      dispatch({ type: 'page', payload: { loading: true } });
      const code = formData['verification-code'].trim().toUpperCase();

      api.getEndpointByAlias('user-activateuser').fetch({
        requestData: {
          params: {
            code
          }
        }
      }, (status, data) => api.fetchResponseHelper({
        status,
        data,
        isMounted: () => isMounted(),
        onAny: () => dispatch({ type: 'page', payload: { loading: false } }),
        onSuccess: () => {
          const msg = messages.get('user.PageRegActivate.activationSuccess');
          toastSuccessMsg(msg, 'user-activate');
          setFieldsState('apiSuccess', msg);
          if (data.loggedin) {
            appPub.login(data.user, true, true);
          }
          else {
            if (data.session_auth) {
              logoutHelper({notify: false, redirect: false}, (success) => {
                if (!success) {
                  console.warn('Unexpected state: login failed');
                }
                history.push('/user/login', {username: sentToEmail});
              });
            }
            else {
              history.push('/user/login', {username: sentToEmail});
            }
          }
        },
        onErrorMsg: (msg) => {
          toastErrorMsg(msg, 'user-activate');
          setFieldsState('apiError', [{msg}]);
        }
      }), true);
    }
  }, [state, api, isMounted, toastSuccessMsg, setFieldsState, appPub, history, sentToEmail, toastErrorMsg]);



  // console.log('APP PROPS', props);
  return (
    <AuthRelatedPage
      appPub={appPub}
      // appProps={props}
      loading={state.loading}
      refForm={refForm}
      onSubmit={onSubmit}
      fieldsState={state.fieldsState}
      fieldsStateArgs={state.fieldsStateArgs}
      formData={state.currentForm}
      pageData={state.pageData}
    />
  );
};

PageRegActivate.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PageRegActivate;
