import React, { useState, useCallback } from 'react';
import './helper_grid.scss';
import {DEV_MODE} from '../controller/devel';

let HelperGrid = () => null;

if (DEV_MODE) {
  const ls = window.localStorage || null;

  HelperGrid = () => {
    const baseClass = 'grid-helper';
    const lsPropOpacity = baseClass + '-opacity';
    const [opacity, setOpacity] = useState(
      (ls && ls.getItem(lsPropOpacity)) || 0
    );

    const onChange = useCallback((e) => {
      const {value} = e.target;
      ls && ls.setItem(lsPropOpacity, value);
      setOpacity(value - 0);
    }, [lsPropOpacity]);

    return (
      <div className={baseClass}>
        <div className={`${baseClass}__inner`}>
          <div className="page-width clearfix" style={
            {opacity: Math.max(0, Math.min(1, opacity))}
          }>
            <div className="row">
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
              <div className="col col-xs-1"><span></span></div>
            </div>
          </div>
        </div>
        <div className={`${baseClass}__break-points`}>
          <input
            aria-label="Grig áttetszősége"
            type="range"
            name={`${baseClass}-opacity`}
            className={`${baseClass}__opacity`}
            value={opacity}
            onChange={onChange}
            min="0"
            max="1"
            step="0.1"
          />
          <div className="current-view">
            {/* <span>Current view:</span> */}
            <span className="d-block d-sm-none">XS</span>
            <span className="d-none d-sm-block d-md-none">SM</span>
            <span className="d-none d-md-block d-lg-none">MD</span>
            <span className="d-none d-lg-block d-xl-none">LG</span>
            <span className="d-none d-xl-block">XL</span>
          </div>
        </div>
      </div>
    );
  };
}

HelperGrid.displayName = 'HelperGrid';

export default HelperGrid;
