
type TextOrHTMLTag = HTMLElement & string;

function insertContentToFocusedEditable(textOrTag: TextOrHTMLTag, asTag = false) {
  let ret = false;

  if (document.execCommand) { // try with deprecated execCommand first
    ret = document.execCommand('insertHTML', false, (
      asTag && textOrTag?.nodeType === 1 ? textOrTag.innerHTML : textOrTag + ''
    ));
  }

  if (!ret && window.getSelection) {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    const node: Node = !asTag
      ? document.createTextNode(textOrTag)
      : textOrTag?.nodeType === 1
        ? textOrTag
        : document.createElement(textOrTag + '')
    ;
    range.deleteContents();
    range.insertNode(node);
    range.setStartAfter(node);
    range.setEndAfter(node);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    ret = true;
  }
  return ret;
}

export default insertContentToFocusedEditable;
