
import React from 'react';
import PropTypes from 'prop-types';
import Dictionary from "./base/dictionary/index";

const PageFront = ({appPub, ...props}) => {

  return (
    <Dictionary appPub={appPub} appProps={props} />
  );
};

PageFront.propTypes = {
  appPub: PropTypes.object.isRequired
};

export default PageFront;
