

const jsLinkActions = {
  _actions: {
    toggleA11Y: {
      onClick(e) {}
    }
  },
  bind(name) {
    return this._actions[name] || {};
  }
};

export default jsLinkActions;
