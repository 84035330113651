import React, {useCallback} from "react";
import ContentButton from "../../../components/contentButton";
import { keyCodes } from '../../../lib/util';
import classNames from "classnames";
import "./AreaIcon.scss";

export type TypeTooltip = boolean | string | {
  text?: string,
  placement?: string,
  autoHide?: number,
  className?: string,
  overlayProps?: {},
  tooltipProps?: {},
}

type TypeProps = {
  className: string;
  tabIndex?: number;
  id?: string;
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  accessKey?: string;
  tooltip?: TypeTooltip,
  icon: string;
  text?: string;
  title?: string;
};


const AreaIconFwRef = React.forwardRef((
  {
    className,
    onClick,
    onBlur,
    id,
    tabIndex = 0,
    tooltip = {
      placement: 'bottom'
    },
    accessKey,
    icon,
    text,
    title
  }: TypeProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-undef
  ref: React.Ref<HTMLButtonElement>
) => {

  const handleClick = useCallback((e) => {
    if (e.type === "keydown" && e.which !== keyCodes.ENTER && e.which !== keyCodes.SPACE) {
      return;
    }
    e.preventDefault();
    if (typeof onClick === "function") {
      onClick(e);
    }
  }, [onClick]);

  return (
    <ContentButton tabIndex={tabIndex} ref={ref} id={id} data={{
      attrs: {
        onClick: handleClick,
        onKeyDown: handleClick,
        className: classNames(className, "area-icon"),
        accessKey,
        // 'aria-label': tooltip ? text : undefined,
        title,
        onBlur
      },
      tooltip,
      theme: "icon",
      link: { iconLeft: icon },
      text
    }}
    />
  );
});

const AreaIcon = React.memo(AreaIconFwRef);

// React.memo(;

AreaIcon.displayName = "AreaIcon";

export default AreaIcon;
