import PropTypes from "prop-types";
import React, {useState, useCallback} from 'react';
import classNames from 'classnames';
import ContentIconItem from '../../components/contentIconItem';
import { entryList } from './dictHelpers';
import { pubFeature } from '../../setup';
import messages from '../../messages';
import { DEV_MODE } from "../../controller/devel";



const Entry = ({
  index,
  baseClass,
  accessibleStyle = true,
  onRemoveFromWordList,
  onAddToWordList,
  entry = {
    fid: "32432432",
    sid: "32432432_id_word",
    justRemoved: false,
    data: {
      dict_format: "2",
      expr: false,
      headword_id: "9999999",
      headword_text: "a",
      html: "",
      id: "99999999",
      src: "hu",
      trg: "en",
      uuid: "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX",
      xml: null
    }
  }
}) => {
  const [inWordList, setInWordList] = useState(
    entryList.get(entry.fid)
  );


  const onInWordList = useCallback(() => {
    const newVal = !inWordList;
    setInWordList(newVal);
    entryList.toggle(entry, newVal, (value) => {
      if (value !== newVal) {
        setInWordList(value); // revert previous value
      }
      if (value) {
        if (typeof onAddToWordList === "function") {
          onAddToWordList(entry, value);
        }
      }
      else {
        if (typeof onRemoveFromWordList === "function") {
          onRemoveFromWordList(entry, value);
        }
      }
    });
  }, [entry, inWordList, onAddToWordList, onRemoveFromWordList]);

  // console.log(entry);

  const swIncludesThisEntry = entry.getSearchWords();
  const mergedEntry = entry.isMergedEntry();

  return (
    <article
      id={'fid-' + entry.fid}
      tabIndex={0}
      // role="document"
      aria-label={messages.get('Dictionary.entries.article', {
        name: entry.getHeadWord() || '',
        index: index + 1
      })}
      className={
        classNames(
          `${baseClass}__Entry`,
          'gcont',
          mergedEntry ? 'merged' : null,
          entry.isExpr() ? 'expr' : null,
          entry.justRemoved ? `just-removed` : null,
          `src-${entry.data.src}`,
          `trg-${entry.data.trg}`
        )
      }
      data-headword={entry.getHeadWord()}
      data-words={swIncludesThisEntry.join(',')}
      data-index={index}
    >
      {pubFeature.szotarnaplo ? (
        <ContentIconItem onClick={onInWordList}
          className={
            classNames(
              `${baseClass}__Entry__wordlist`,
              inWordList ? 'active' : null
            )
          }

          title={inWordList
            ? messages.get('Dictionary.entry.logEntry.removeLog')
            : messages.get('Dictionary.entry.logEntry.addLog')
          }
          data={{icon: 'bookmark', formButton: 'button'}}
        />
      ) : null}

      {entry.justRemoved ? (
        <small className={`${baseClass}__Entry__justRemoved`}>
          {messages.get('Dictionary.entry.logEntry.justRemoved')}
        </small>
      ) : null}

      <div className={`${baseClass}__Entry__content`}
        dangerouslySetInnerHTML={{__html: entry.getHtml(accessibleStyle)}}
      />
      {DEV_MODE ? (
        <div className={`${baseClass}__Entry__dev`}>
          <button onClick={() => console.log(entry) }>debug info:</button>
          <span>Fid:&nbsp;{entry.fid} </span>
          <span>Sid:&nbsp;{entry.sid} </span>
          <span>hw:&nbsp;{entry.data.headword_text} </span>
          <span>sw:&nbsp;{entry.data.searched_word} </span>
          <span>orh:&nbsp;{entry.getOrthvar(true)} </span>
          <div>
            <span>hws: {entry.getHeadWords().map((h, i) => <i key={i}>{h}</i>)}</span>
          </div>
          <div>
            <span>sw incl: {swIncludesThisEntry.map((h, i) => <i key={i}>{h}</i>)}</span>
          </div>
        </div>
      ) : null}
    </article>
  );
};

Entry.propTypes = {
  baseClass: PropTypes.string,
  accessibleStyle: PropTypes.bool,
  entry: PropTypes.shape({
    data: PropTypes.shape({
      dict_format: PropTypes.string,
      expr: PropTypes.bool,
      headword_id: PropTypes.string,
      headword_text: PropTypes.string,
      html: PropTypes.string,
      id: PropTypes.string,
      src: PropTypes.string,
      trg: PropTypes.string,
      uuid: PropTypes.string,
      xml: PropTypes.any
    }),
    fid: PropTypes.string,
    sid: PropTypes.string,
    getHeadWord: PropTypes.func,
    getHtml: PropTypes.func,
    getSearchWords: PropTypes.func,
    isExpr: PropTypes.func,
    justRemoved: PropTypes.bool
  }),
  index: PropTypes.number,
  onAddToWordList: PropTypes.func,
  onRemoveFromWordList: PropTypes.func
};

export default Entry;
