/* eslint-disable no-use-before-define */

import React, {useRef, useReducer, useCallback} from 'react';

import PropTypes from 'prop-types';
import AuthRelatedPage from "./AuthRelatedPage";
import { useIsMounted } from '../../hooks';
import { userCheckMail } from './userHelper';
import { parseParams } from '../../lib/util';
// import TestPhaseWarn from './components/TestPhaseWarn';
import ActivateLink from './components/ActivateLink';
import LoginLink from './components/LoginLink';
import globals from '../../controller/globals';
import Hyperlink from '../../components/hyperlink';

import * as schemes from './data/schemes';
import { formDataExtend } from './data/formDataHelper';
import { href_pdfTerms, href_pdfPrivacy } from '../../setup';

import messages from '../../messages';

function reducer(state, {type, payload}) {
  switch (type) {
    case 'page':
      return { ...state, ...payload };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}

const formData = {
  formId: 'registration',
  title: messages.get('user.pageReg.title'),
  primaryBtn: {text: messages.get('user.pageReg.primaryBtn') },
  // warn: <TestPhaseWarn />,
  secondaryBtn: (getEventData) => {
    const {formData} = getEventData();
    const defUsername = formData.fields.find(f => f.name === 'username')?.defaultValue;
    const history = globals.get('history');
    return {
      // vissza lép a loginra, amennyiben onnan jött, az ott átadott címmel
      action: !defUsername ? null : () => {
        history.push('/user/signin', {
          username: defUsername,
          divertedToReg: defUsername
        });
      }
    };
  },
  desc: messages.get('user.pageReg.desc'),
  fieldsStates: {
    emailNotRegistered: {
      primaryBtn: {disabled: false }
    },
    emailRegistered: {
      primaryBtn: {disabled: true }
    },
    emailRegistrationDisabled: {
      primaryBtn: {disabled: true }
    },
    emailRegisteredButNotActivated: {
      primaryBtn: {disabled: true }
    }
  },
  fields: [
    {
      name: "username",
      label: messages.get('user.fields.username'),
      autoComplete: "username",
      type: 'email',
      schema: schemes.username,
      helperText: null,
      fieldStates: {
        emailNotValid: ({helperText}) => ({
          helperText: (
            <span style={{color: 'red'}}>
              {helperText}
            </span>
          )
        }),
        emailRegistered: {
          helperText: (getEventData) => {
            const {value} = getEventData();
            return <>
              <span style={{color: 'red'}}>
                {messages.get('user.pageReg.emailRegistered.helperTextLine1')}
              </span><br />
              <LoginLink username={value} />
            </>;
          }
        },
        emailRegisteredButNotActivated: {
          helperText: (getEventData) => {
            const {value} = getEventData();
            return <>
              <span style={{color: 'darkorange'}}>
                {messages.get('user.pageReg.emailRegisteredButNotActivated.helperText')}
              </span>{' '}
              <ActivateLink username={value} />
            </>;
          }
        },
        emailNotRegistered: {

        },
        emailRegistrationDisabled: {
          helperText: (
            <span style={{color: 'red'}}>
              {messages.get('user.pageReg.emailRegistrationDisabled.helperText')}
            </span>
          )
        }
      }
    },
    {
      name: "new-password",
      label: messages.get('user.fields.password'),
      autoComplete: "new-password",
      type: 'password',
      schema: schemes.password,
      helperText: messages.get('user.fields.newPasswordHelper')
    },
    {
      name: "verify-password",
      label: messages.get('user.fields.passwordRepeat'),
      autoComplete: "new-password",
      type: 'password',
      schema: schemes.password_verify
    },
    {
      name: "terms",
      // label: (<>Elfogadom a Grimm Online Szótári Rendszer <Hyperlink href="/docs/terms-of-use.pdf">felhasználási feltételeit</Hyperlink> és <Hyperlink href="/docs/privacy.pdf">adatkezelési irányelveit</Hyperlink>.</>),
      label: (<>
        {messages.get('user.fields.terms.iAccept')}
        <Hyperlink href={href_pdfTerms} target="_blank" rel="noreferrer">{messages.get('user.fields.terms.terms')}</Hyperlink>
        {messages.get('user.fields.terms.and')}
        <Hyperlink href={href_pdfPrivacy} target="_blank" rel="noreferrer">{messages.get('user.fields.terms.privacy')}</Hyperlink>
        {messages.get('user.fields.terms.end')}</>),
      type: 'checkbox',
      autoComplete: null,
      schema: schemes.terms,
      helperText: null
    }
  ]
};



const PageReg = ({appPub, history, location, ...props}) => {
  const paramEmail = location?.state?.username || parseParams(location.search).username || '';
  const defUsername = schemes.email.validate(paramEmail) ? '' : paramEmail.toLowerCase();
  const refForm = useRef();

  const {api, toastErrorMsg} = appPub;
  const isMounted = useIsMounted();


  const setFieldsState = useCallback((fieldsState, fieldsStateArgs = null) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch({ type: 'page', payload: { fieldsState, fieldsStateArgs } });
  }, []);

  const onUserNameChange = useCallback((e, {
    value, schema, fieldState, setFieldState, ...rest}
  ) => {
    const email = value + ''.trim();
    const validFormat = !schema.validate(value);
    if (validFormat) {
      userCheckMail(email, {
        isMounted,
        api,
        toastErrorMsg
      }, ({
        fieldsState,
        fieldsStateArgs,
        fieldState,
        fieldStateArgs
      }) => {
        setFieldsState(fieldsState, fieldsStateArgs);
        setFieldState(fieldState, fieldStateArgs);
      });
    }
    else {
      setFieldsState(null, null);
      setFieldState(null, null);
    }
  }, [api, isMounted, setFieldsState, toastErrorMsg]);

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    fieldsState: null,
    fieldsStateArgs: null,
    currentForm: (
      formDataExtend(formData, {
        fields: [
          {
            name: 'username',
            onChange: onUserNameChange,
            onBlur: onUserNameChange,
            onInit: onUserNameChange,
            defaultValue: defUsername
          }
        ]
      })
    )
  });


  const onSubmit = useCallback((formData) => {
    if (!state.loading && (
      state.fieldsState === 'emailNotRegistered' ||
      state.fieldsState === 'apiError'
    )) {

      dispatch({ type: 'page', payload: { loading: true } });

      api.getEndpointByAlias('user-registeruser').fetch({
        requestData: {
          params: {
            login: formData['username'],
            password: formData['new-password'],
            password2: formData['verify-password'],
            terms: formData['terms']
          }
        }
      }, (status, data) => api.fetchResponseHelper({
        status,
        data,
        isMounted: () => isMounted(),
        onAny: () => dispatch({ type: 'page', payload: { loading: false } }),
        onSuccess: () => {
          history.push('/user/activate', {sentto: formData.username });
        },
        onErrorMsg: (msg) => toastErrorMsg(msg, 'user-registeruser')
      }), true);
    }
  }, [api, history, isMounted, state, toastErrorMsg]);



  // console.log('APP PROPS', props);
  return (
    <AuthRelatedPage
      appPub={appPub}
      // appProps={props}
      loading={state.loading}
      refForm={refForm}
      onSubmit={onSubmit}
      fieldsState={state.fieldsState}
      fieldsStateArgs={state.fieldsStateArgs}
      formData={state.currentForm}
    />
  );
};

PageReg.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PageReg;
