import React, {useRef, useState, useContext} from 'react';
import PropTypes from 'prop-types';

import { UserContext } from '../../../Contexts';
import ContentModal from '../../../components/contentModal';
import ContentParagraph from '../../../components/contentParagraph';
import { logoutHelper } from '../userHelper';
import { useIsMounted } from '../../../hooks';
import LoaderSpinner from '../../../components/misc/LoaderSpinner';
import messages from '../../../messages';

const DeleteUserModal = ({appPub, history, show, onHide}) => {
  const refDelUserEmail = useRef();
  const userData = useContext(UserContext);
  const {api, toastErrorMsg, toastSuccessMsg} = appPub;
  const isMounted = useIsMounted();
  const [errorMsg, serErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDeleteUserClick = (e) => {
    if (loading) return;

    const delUserEmail = refDelUserEmail.current.value.trim().toLowerCase();

    if (delUserEmail === userData.email) {
      serErrorMsg(null);

      setLoading(true);

      api.getEndpointByAlias('user-deleteuser').fetch({
        requestData: { params: { email: userData.email } }
      }, (status, data) => api.fetchResponseHelper({
        status,
        data,
        isMounted: () => isMounted(),
        onAny: () => setLoading(false),

        onSuccess: () => {
          toastSuccessMsg(messages.get('user.PageProfile.delUserSuccess'), 'user-userdel-success');
          onHide();
          history.push('/', {});
          logoutHelper();
        },
        onErrorMsg: (msg) => {
          serErrorMsg(msg);
          toastErrorMsg(msg, 'user-userdel-error');
        }
      }), true);
    }
    else {
      serErrorMsg(
        delUserEmail
          ? messages.get('user.PageProfile.delUserEmailNotMatch')
          : messages.get('user.PageProfile.delUserEmailRequired')
      );
    }
  };

  return (
    <ContentModal
      show={show}
      onHide={onHide}
      onAction={onDeleteUserClick}
      actionText="Regisztráció törlése"
      actionVariant="outline-danger"
      header={
        messages.get('user.PageProfile.youSureToDeleteProfile')
      }
    >
      <p>{messages.get('user.PageProfile.confirmProfileDelete')}</p>
      <p>Amennyiben törölni szeretnéd regisztrációt, az alábbi mezőbe írd be regsiztrált e-mail címed a megerősítéshez!</p>
      <div className="form-item">
        <label htmlFor="profileDelUserEmail">E-mail címed <i>({userData.email})</i></label>
        <input if="profileDelUserEmail" disabled={loading} ref={refDelUserEmail} type="email" />
        {loading && <LoaderSpinner style={"inline-loading"} /> }
      </div>
      {errorMsg ? (
        <ContentParagraph id={'para-test-2'} data={{theme: 'alert-slim'}}>
          <b>Hiba:</b> {errorMsg}
        </ContentParagraph>
      ) : null}
    </ContentModal>
  );
};

DeleteUserModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  history: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default DeleteUserModal;
