
import {
  getAnAccessibleDictOption
} from './dictHelpers';
import UAC from '../../UAC';
import { sesStore } from '../../lib/BrowserStore';
import {LAYOUT_STATES, QUERY_STATUSES} from './enums';
import Results from './Results';
import { nativeLang, lsPropNames, sourceAreaSetup} from '../../setup';
// import { htmlFilterRangy } from '../../lib/filterHtml';
import globals from '../../controller/globals';
import {DEV_MODE} from '../../controller/devel';

import messages from '../../messages';
import {
  // TypeInitialValues,
  TypeDictState
} from '../../types/Dict';

const appPub = globals.get('appPub');


const initialSubmitState = {
  specCharsVisible: false,
  enterKeyMode: sourceAreaSetup.enterKeyMode,
  trResult: null,
  curInputHtml: '',
  curInputText: '',
  curInputCleanHtml: '',
  curWord: null,
  curWordIndex: null,
  curPreviewHtml: null,
  layoutState: LAYOUT_STATES.INITIAL,
  lastOcrText: null,
  lastQuery: {
    status: null,
    text: null,
    dict: null,
    src: null,
    trg: null,
    userData: null,
    words: null,
    wordsWithDupes: null,
    wordsLimited: null,
    wordsOverLimit: null
  }
};


export default function reducer(state: TypeDictState, {type, payload}) {
  switch (type) {
    case 'specCharsVisible': {
      return {
        ...state,
        specCharsVisible: payload
      };
    }

    case 'enterKeyMode': {
      return {
        ...state,
        enterKeyMode: payload
      };
    }

    case 'trSourceWord': {
      return {
        ...state,
        curWord: payload.word,
        curWordIndex: payload.index
      };
    }

    case 'ocrRead': {
      const {src, ocrText, cleanHtml, text} = payload;

      sesStore.set(lsPropNames.getSess('curInputHtml'), cleanHtml);
      sesStore.set(lsPropNames.getSess('curInputText'), text);

      return {
        ...reducer(state, {
          type: 'dict',
          payload: {
            src,
            trg: (
              (src === nativeLang && state.curTrg === nativeLang) ||
              (src !== nativeLang && state.curTrg !== nativeLang)
                ? state.curSrc
                : state.curTrg
            ),
            dictId: state.curDictId
          }
        }),
        forcedSubmits: state.forcedSubmits + 1,
        lastOcrText: ocrText,
        curInputCleanHtml: cleanHtml,
        curInputHtml: cleanHtml,
        curInputText: text
      };
    }
    case 'trSourceText': {
      const {html, text, cleanHtml} = payload;
      // DEV_MODE && console.log('trSourceText', {html, text, cleanHtml});
      sesStore.set(lsPropNames.getSess('curInputHtml'), cleanHtml);
      sesStore.set(lsPropNames.getSess('curInputText'), text);

      return {
        ...state,
        curInputCleanHtml: cleanHtml,
        curInputHtml: html,
        curInputText: text
      };
    }
    case 'resetSearch': {
      sesStore.set(lsPropNames.getSess('curInputHtml'), '');
      sesStore.set(lsPropNames.getSess('curInputText'), '');
      return {
        ...state,
        ...initialSubmitState,
        curInputCleanHtml: '',
        curInputHtml: '',
        curInputText: '',
        layoutState: LAYOUT_STATES.INITIAL
      };
    }

    case 'lastQuery': {
      return {
        ...state,
        layoutState: LAYOUT_STATES.SUBMITTED,
        loadingQuery: true,
        lastQuery: {
          userData: payload.userData,
          status: QUERY_STATUSES.pending,
          words: payload.words,
          text: payload.text,
          dict: payload.dict,
          src: payload.src,
          trg: payload.trg,
          wordsWithDupes: payload.wordsWithDupes,
          wordsLimited: payload.wordsLimited,
          wordsOverLimit: payload.wordsOverLimit
        }
      };
    }

    case 'lastQueryEnd': {
      return {
        ...state,
        lastQuery: {
          ...state.lastQuery,
          status: payload.succeeded
            ? QUERY_STATUSES.success
            : QUERY_STATUSES.error
        }
      };
    }

    case 'trResult': {
      const {
        errormsg, error, result, dict, fullWord
      } = payload || {};


      const resByDict = result?.find(q => q.dict_id === dict)?.queries || null;

      const resultInstance = resByDict?.length
        ? new Results(resByDict, {dict, fullWord, ...state.lastQuery})
        : null
      ;


      let curWord = null;
      let curWordIndex = null;

      if (resultInstance) {
        if (resultInstance.hasFullTextResult()) {
          // DEV_MODE && console.log('hasFullTextResult', {
          //   fullTextResult: resultInstance.fullTextResult,
          //   curWord: resultInstance.fullTextResult.searched_word
          // });
          curWord = resultInstance.fullTextResult.searched_word;
          curWordIndex = 0;
        }
        else {
          const {queryWordsLimited: wordsLimited} = resultInstance || {};
          const {queryWordsLimited: prevWordsLimited} = state?.trResult?.resultInstance || {};

          const newWord = prevWordsLimited?.length && wordsLimited?.length
            ? wordsLimited.filter(w => !prevWordsLimited.includes(w)).pop()
            : null
          ;
          if (newWord && resultInstance.wordExists(newWord)) { // set new word active in last position if it was added
            curWord = newWord;
            curWordIndex = resultInstance.getLastWordIndex(newWord);
          }
          else {
            curWord = state.curWord && resultInstance.wordExists(state.curWord)
              ? state.curWord
              : resultInstance.initialWord
            ;
            curWordIndex = curWord === state.curWord && resultInstance.isValidWordIndex(curWordIndex)
              ? state.curWordIndex
              : resultInstance.initialWordIndex
            ;
          }

          DEV_MODE && console.log('-- trResult.selection', {
            state,
            newWord,
            wordsLimited,
            prevWordsLimited,
            curWordIndex,
            resultInstance,
            curWord
          });
        }
      }

      const _errormsg = errormsg && Array.isArray(errormsg) ? errormsg : [errormsg];

      if (state?.trResult?.resultInstance instanceof Results) {
        state.trResult.resultInstance.destroy();
      }

      const newState = {
        ...state,
        lastQuery: error ? initialSubmitState.lastQuery : state.lastQuery,
        layoutState: error ? state.layoutState : LAYOUT_STATES.SUBMITTED,
        loadingQuery: false,
        curWord,
        curWordIndex,
        // alul jelenik meg
        // errormsg: _errormsg,
        // error,
        trResult: payload === null ? null : {
          errormsg: _errormsg,
          error,
          result,
          dict,
          resultInstance
        }
      };

      DEV_MODE && console.log('-- trResult.newState', {
        state,
        newState,
        resultInstance,
        curWord
      });

      return newState;
    }
    case 'validate_dict': {
      const { dict } = payload;
      const accessToDict = UAC.hasAccessToDict(dict);

      if (accessToDict && state.curSrc === dict.src && state.curTrg === dict.trg) {
        return state;
      } // keep unmutated to prevent rerender.

      const altDict = UAC.getAnAccessibleDict(state.curSrc, state.curTrg);

      if (altDict) {
        return {
          ...reducer(state, {
            type: 'dict',
            payload: {
              src: altDict.src,
              trg: altDict.trg,
              dictId: altDict.id
            }
          }),
          forcedSubmits: state.forcedSubmits + 1
        };
      }

      const initialDict = UAC.getAccessibleInitialDict();

      if (!initialDict) {

        appPub.toastErrorMsg(
          messages.get('tr.errorStateDefaultDictNotFound', initialDict),
          `choose-dict-initial`
        );
        return state;
      }
      // DEV_MODE && console.warn('altDict nincs', {
      //   altDict,
      //   initialDict,
      //   state,
      //   reseted: {
      //     ...state,
      //     curDictId: initialDict.id,
      //     curSrc: initialDict.src,
      //     curTrg: initialDict.trg
      //   }
      // });

      return {
        ...state,
        forcedSubmits: state.forcedSubmits + 1,
        curDictId: initialDict.id,
        curSrc: initialDict.src,
        curTrg: initialDict.trg
      };
    }

    case 'dict': {
      const { src, trg, dictId } = payload;
      const [valid, dict] = getAnAccessibleDictOption(dictId, src, trg);
      if (!valid || !dict) {
        appPub.toastErrorMsg(
          messages.get('tr.errorDictNotFoundOrNotAccessible', {dictId}),
          `choose-dict-${dict}`
        );
      }

      return !valid
        ? {...state} // revert choice
        : {
          ...state,
          forcedSubmits: state.forcedSubmits + 1,
          curDictId: sesStore.set(lsPropNames.getSess('curDictId'), dict.id),
          curSrc: sesStore.set(lsPropNames.getSess('curSrc'), dict.src),
          curTrg: sesStore.set(lsPropNames.getSess('curTrg'), dict.trg)
        }
      ;
    }
    case 'userData': {
      const { userData } = payload;
      return {
        ...state,
        forcedSubmits: state.forcedSubmits + 1,
        currentUserData: userData
      };
    }

    case 'extend':
      return {
        ...state,
        ...payload
      };
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}
