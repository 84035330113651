export default function getFocusedElement() {
  if (
    document.hasFocus() &&
    document.activeElement !== document.body &&
    document.activeElement !== document.documentElement
  ) {
    return document.activeElement;
  }
  return null;
}
