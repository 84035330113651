import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import normalizeTag from '../lib/html/normalizeTag';
import { filterHtml } from '../lib/filterHtml';

const ContentParagraph = ({
  _parentId,
  id,
  type = "contentParagraph",
  passedData = {},
  baseClass = "cmp-ContentParagraph",
  className,
  children,
  region,
  items,
  data = {},
  kbd
}) => {

  const {
    tag = 'p',
    inlineHtml,
    filterHtmlOptions,
    theme = "regular",
    level = 1
  } = data;

  const {className: attrClassName, ...attrs} = data.attrs || {};

  const Tag = normalizeTag(tag, kbd + '-' + type);

  if (typeof inlineHtml === "string") {
    attrs.dangerouslySetInnerHTML = {
      __html: filterHtml(inlineHtml, filterHtmlOptions)
    };
  }

  return (
    <>
      <Tag data-id={id || null} id={id || null}
        className={
          classNames(
            className,
            baseClass,
            `${baseClass}--${theme}`,
            attrClassName,
            `par-${Math.max(1, Math.min(4, level - 0 || 1))}`
          )
        } {...attrs}>
        {attrs.dangerouslySetInnerHTML ? null : (
          <>
            {children ? children : null }
          </>
        )}
      </Tag>
    </>
  );
};


ContentParagraph.propTypes = {
  _item: PropTypes.shape({
    getItemData: PropTypes.func
  }),
  _parentId: PropTypes.string,

  id: PropTypes.string,
  type: PropTypes.string,
  region: PropTypes.string,
  kbd: PropTypes.string,
  baseClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  passedData: PropTypes.object,
  // @items: child text itemek
  items: PropTypes.array,
  data: PropTypes.shape({
    // @level: pragraph level 1-4-ig
    level: PropTypes.number,
    // @tag: felülírható a default p
    tag: PropTypes.string,
    // @theme: megjelenés:
    //   - "regular": default, sima áttetsző háttér
    //   - "highlighted": háttérrel kiemelt pl: https://projects.invisionapp.com/d/main#/console/20522464/431099756/preview (E-ügyintézés nyomtatvány)
    //   - "alert": figyelmeztetés piros
    //   - "warning": figyelmeztetés sárga, pl: https://projects.invisionapp.com/d/main#/console/20522464/431099752/preview
    theme: PropTypes.oneOf([
      'regular',
      'highlighted',
      'alert',
      'alert-slim',
      'alert-grey',
      'alert-grey-slim',
      'warning',
      'warning-slim',
      'success',
      'success-slim',
      'centered-box']
    ),
    // @inlineHtml használható inline html-ként, csak inline tagek enegélyezettek: a, b, i, em
    inlineHtml: PropTypes.string,
    // @filterHtmlOptions:  options for utils.filterHtml
    filterHtmlOptions: PropTypes.object,
    // @attrs: elem attribútumok, (href, target felülírja a fent megadottat)
    attrs: PropTypes.object
  })

};



export default ContentParagraph;
