const grammarHelper = {
  article: {
    hu(str: string = "", capitalize: boolean = false) {
      const firstCar = (str + '').toLowerCase()[0];
      const vowels = 'aáeéiíoóöőuúüűy';
      const consonants = 'bcdfghjklmnpqrstvwxz';
      let r = 'a(z)';

      if (vowels.indexOf(firstCar) > -1) {
        r = 'az';
      }
      else if (consonants.indexOf(firstCar) > -1) {
        r = 'a';
      }
      return capitalize ? [r[0].toUpperCase(), ...r.slice(1)].join('') : r;
    }
  }
};

export default grammarHelper;
