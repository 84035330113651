import React from 'react';
import PropTypes from 'prop-types';
import LayoutUnavailable from './base/layout/LayoutUnavailable';

import messages from './messages';
const baseClass = "PageMustLogin";

const PageMustLogin = ({appPub, location, ...props}) => {
  const target = encodeURIComponent(
    location.pathname + location.search + location.hash
  );

  return <LayoutUnavailable
    enableFrontPageLink={true}
    headline={messages.get('PageMustLogin.headline')}
    actionHref={`/user/signin?target=${target}`}
    actionText={messages.get('PageMustLogin.action')}
    baseClass={baseClass}
    appPub={appPub}
  />;
};

PageMustLogin.propTypes = {
  location: PropTypes.object.isRequired,
  appPub: PropTypes.object.isRequired
};

export default PageMustLogin;
