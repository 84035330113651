import React from 'react';
import classNames from 'classnames';
import messages from '../../../messages';

type TypeProps = {
  baseClass: string,
  active: boolean,
  numOfUniqueWords: number,
  numOfMaxWords: number
};

const WordCount = (
  {baseClass, active = false, numOfUniqueWords = 0, numOfMaxWords = 0}: TypeProps
) => (
  <div className={
    classNames(
      baseClass + '__wordcount',
      active ? 'active' : null
    )
  }>
    <span
      title={messages.get('TrsSourceArea.numOfUniqueWords')}
      className={classNames(
        "num-of-words",
        numOfUniqueWords >= numOfMaxWords ? 'error' : null
      )}
    >{numOfUniqueWords}</span>/
    <span
      title={messages.get('TrsSourceArea.numOfMaxUniqWordsQueriedOnce')}
      className="num-of-max-words"
    >{numOfMaxWords}</span>
  </div>
);

export default WordCount;
