import './base/App.scss';
// import './unsported.css';

import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import initAppPub from './App.appPub';
import { Toaster } from 'react-hot-toast';
import scrollIntoView from 'scroll-into-view-if-needed';

import StaticPageLoader from './StaticPageLoader';
import StaticPage404 from './StaticPage404';

import GridHelper from './base/helper_grid';
import globals from './controller/globals';
import {DEV_MODE, DEV_DEBUG_LOCATION} from './controller/devel';
import { scrollTo as scolljsTo } from 'scroll-js';

import ScrollDir from './lib/scrolldir';
import ScrollPos from './lib/scrollpos';
import EventEmitter from './lib/EventEmitter';
import el_toggleClass from './lib/dom/el_toggleClass';
import LoginObserver from './lib/LoginObserver';
import getFocusedElement from './lib/dom/getFocusedElement';
import AudioPlayer from './components/misc/AudioPlayer';

import {
  getTopHeaderHeight,
  getHeadersHeight,
  winResizeEmitter
} from './app.libs';

import {
  konsole,
  fixHashFormat,
  locReload,
  meta
} from './lib/util.js';

import getUserRoutes from './base/user/data/UserRoutes';
import getSubscriptionRoutes from './base/subscription/data/subscriptionRoutes';
import getTransactionRoutes from './base/transactions/data/transactionRoutes';
import getPaperCodeRoutes from './base/paperCode/data/paperCodeRoutes';
import getEntryListRoutes from './base/entryList/data/entryListRoutes';
import StaticPageFront from './StaticPageFront';
import { UserContext, DictListContext } from './Contexts';
import api from './api/api.instance';
import {
  previewMode,
  stickyBlockOffset,
  scrolljsToOptions
} from './setup';

import messages from './messages';

try {
  // @ts-ignore
  import("./base/vendor.css").then(() => {
    // DEV_MODE && konsole.log('vendor.css is loaded sucessfully');
  });
}
catch (e) {
  console.warn('App.js -> Loading ./vendor.css failed', e);
}

const appEventEmitter = new EventEmitter('appEmitter');
const resizeEmitter = winResizeEmitter(appEventEmitter);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.api = api;
    this.eventEmitter = appEventEmitter;
    this.refApp = React.createRef();
    this.refTTSAudio = React.createRef();
    this._mounted = false;
    this.historyUnlisten = null;
    this.locationData = {
      debug: false,
      location: props.location,
      lastLocStr: null
    };
    this.htmlClassState = {
      stickyMenu: window.scrollY > getTopHeaderHeight(),
      userMenu: false,
      showMenu: false,
      loading: false,
      scrollDir: 'down',
      stickyBlock: window.scrollY > stickyBlockOffset
    };

    this.state = {
      user: {
        pwRequest: {
          lastSentTo: null,
          lastSentAt: 0
        },
        regActivate: {
          lastSentTo: null,
          lastSentAt: 0
        },
        data: {
          loggedin: null,
          user_dict_access: null
        }
      },
      dictList: null
    };


    this.appPub = initAppPub(this);

    this.pageProps = {
      appPub: this.appPub
    };

    this.scrollPos = new ScrollPos((prevPos, curPos) => {
      if (!this._mounted) { return; }

      const newState = {
        stickyMenu: curPos.y > getTopHeaderHeight(),
        stickyBlock: curPos.y > stickyBlockOffset
      };

      if (
        this.htmlClassState.stickyMenu !== newState.stickyMenu ||
        this.htmlClassState.stickyBlock !== newState.stickyBlock
      ) {
        const oldState = this.htmlClassState;
        this.setHtmlClasses(newState);
        this.eventEmitter.emit('scrollPos.htmlClassChange', curPos, oldState, newState);
      }
    });

    this.scrollDir = new ScrollDir({
      attribute: false,
      el: '#root',
      onChange: (dir) => {
        if (!this._mounted) { return; }
        this.setHtmlClasses({ scrollDir: dir });
      }
    });

    globals.init({
      appPub: this.appPub,
      location: this.props?.location,
      history: this.props?.history
    });

    this.loginObserver = new LoginObserver({api}, ({loggedin, user}, notify = false) => {
      if (loggedin) {
        this.appPub.login(user, false, notify);
      }
      else {
        this.appPub.logout(user, false, notify);
      }
    });

    this.onDictListChange = (_prop, dictList) => this._setState({dictList});
  }

  setHtmlClasses(classState) {
    const elHtml = document.documentElement;
    this.htmlClassState = {
      ...this.htmlClassState,
      ...classState
    };
    el_toggleClass(elHtml, 'sticky-menu', this.htmlClassState.stickyMenu);
    el_toggleClass(elHtml, 'mobilemenu-on', this.htmlClassState.showMenu);
    el_toggleClass(elHtml, 'user-menu', this.htmlClassState.userMenu);
    el_toggleClass(elHtml, 'scroll-dir-up', this.htmlClassState.scrollDir === 'up');
    el_toggleClass(elHtml, 'scroll-dir-down', this.htmlClassState.scrollDir === 'down');
    el_toggleClass(elHtml, 'sticky-block', this.htmlClassState.stickyBlock);
    el_toggleClass(elHtml, 'loading', this.htmlClassState.loading);
  }

  scrollTo(elemId, options, debug) {
    let elTo = elemId ? document.getElementById((elemId + '').replace('#', '')) : null;
    if (!elTo && options && options.forceScroll) {
      elTo = document.getElementById('root');
    }

    if (elTo) {
      // elTo.setAttribute('data-scrollview', 'target');
      const o = {
        skipOverflowHiddenElements: true,
        skipHeader: false, // if it's true, scrollTo used (instead of scrolltoView)
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest',
        // behavior: "instant",
        behavior: "smooth",
        ...(options || {})
      };

      DEV_DEBUG_LOCATION && konsole.log(`scrollTo debug: ${debug}`, {elTo, options});

      if (o.skipHeader) {
        const offset = getHeadersHeight(this.htmlClassState.stickyMenu);
        scolljsTo(window, {
          ...scrolljsToOptions,
          top: elTo.getBoundingClientRect().top + window.pageYOffset - offset,
          duration: typeof o.duration === "number" ? o.duration : scrolljsToOptions.duration,
          behavior: o.behavior
        });
      }
      else {
        scrollIntoView(elTo, o);
      }
    }
    else {
      DEV_DEBUG_LOCATION && konsole.log(`scrollTo elem not exist debug: ${debug}`, {elTo, options});
    }
  }

  scrollToIfNeeded(location, firstLoad, pathChange, debug) {
    const hash = fixHashFormat(location?.hash);
    // const hash = location?.hash;
    if (hash) {
      if (pathChange) {
        DEV_DEBUG_LOCATION && konsole.log('PATCH CAHNGE WITH HASH (rescroll next after the page rendered)');
        this.eventEmitter.once('pageData.render', (eventName, pageData, hash) => {
          setTimeout(() => {
            this.scrollTo(hash, {skipHeader: true, forceScroll: true }, `scrollToIfNeeded pathchange ${debug} ` + hash);
          }, 50);
          if (firstLoad) {
            setTimeout(() => {
              this.scrollTo(hash, {skipHeader: true}, 'scrollToIfNeeded pathchange + first load + repos ' + hash);
            }, 750);
          }
        }, hash);
      }
      else {
        this.scrollTo(hash, {skipHeader: true}, 'scrollToIfNeeded no_pathchange ' + hash);
      }
      return;
    }
    else {
      DEV_DEBUG_LOCATION && konsole.log('scrollToIfNeeded: no hash');
    }

    if (!firstLoad) {
      if (getFocusedElement()) {
        DEV_DEBUG_LOCATION && konsole.log('scrollToIfNeeded: focus cancelled because of an element is focused');
      }
      scolljsTo(window, {
        ...scrolljsToOptions,
        top: 0
      });
    }

  }

  _setState(state, fn) {
    if (this._mounted) {
      this.setState(state, fn);
    }
  }

  componentDidMount() {
    this._mounted = true;
    this.loginObserver.start();

    this.api.dataEmitter.on(['dictList'], this.onDictListChange);


    const location = this.props?.location || this.props?.history?.location;
    if (location) {
      if (this.updateLocation(location).any) {
        this.scrollToIfNeeded(location, true, true, 'componentDidMount');
      }
    }
    // eslint-disable-next-line react/prop-types
    this.historyUnlisten = this.props.history.listen((location) => {
      const changed = this.updateLocation(location);
      DEV_DEBUG_LOCATION && konsole.log('PATH CAHNGED: (do scroll) ', changed);
      if (!location?.state?.noScroll) {
        DEV_DEBUG_LOCATION && konsole.log('componentDidMount.history.listen: (do scroll) ', changed);
        this.scrollToIfNeeded(location, false, changed.pathname, 'componentDidMount.history.listen');
      }
      else {
        DEV_DEBUG_LOCATION && konsole.log('componentDidMount.history.listen: (no scroll)', changed);
      }

      this.eventEmitter.emit('location.change', location, changed);
      this.setHtmlClasses({ showMenu: false });
    });

    this.setHtmlClasses(this.htmlClassState);
    resizeEmitter.emit();
  }

  componentWillUnmount() {
    this._mounted = false;
    this.loginObserver.stop();

    this.api.dataEmitter.off(['dictList'], this.onDictListChange);

    if (typeof this.historyUnlisten === 'function') {
      this.historyUnlisten();
    }
  }

  getLocationString(l = {}) {
    return l.pathname + l.search + l.hash + (
      l.state && typeof l.state === "object" ? JSON.stringify(l.state) : ''
    );
  }

  updateLocation(location) {
    const locStr = this.getLocationString(location);
    const lastLocation = this.locationData.location;
    this.locationData.location = location;
    this.setHtmlRoot(location);

    if (locStr && this.locationData.lastLocStr === locStr) {
      return {any: false, hash: false, pathname: false };
    }

    this.locationData.lastLocStr = locStr;
    return {
      any: true,
      pathname: lastLocation.pathname !== location.pathname,
      hash: lastLocation.hash !== location.hash
    };
  }

  setHtmlRoot(location) {
    if (typeof document !== "undefined") {
      const path = (location.pathname.split('/')[1] || 'front')
        .toLowerCase()
        .replace(/[\s_-]+/g, '-')
        .replace(/[^a-z0-9-]/g, '')
      ;
      document.documentElement.setAttribute('data-root', path);
    }
  }

  // componentDidUpdate(prevProps, prevState) {

  // }

  render() {
    // console.log('APP RENDER', this.state.user.data);
    return (
      <DictListContext.Provider value={this.state.dictList}>
        <UserContext.Provider value={this.state.user.data}>
          {/* <pre>{undefined}</pre> */}
          <div className={
            classNames(
              'app page-wrapper',
              this.appPub.isLoggedIn() ? 'loggedin' : 'loggedout',
              previewMode ? 'preview' : null
            )
          } ref={this.refApp}>
            <Switch>
              {/* {this.api.getPublicPaths().map((path, i) =>
                <Route key={i} exact path={path} render={(props) => {
                  return <Page
                    { ...{...props, ...this.pageProps} }
                  />;
                }} />
              )} */}

              {previewMode ? (
                <Route path="/qr/:code" key={'qrcode'} exact render={(props) => {
                  return <StaticPageLoader
                    moduleImportFn={() => React.lazy(() => import('./StaticPageQrDataView'))}
                    { ...{...props, ...this.pageProps} }
                  />;
                }} />
              ) : null}

              {previewMode ? (
                <Route path="/typog" key={'typo'} exact render={(props) => {
                  return <StaticPageLoader
                    moduleImportFn={() => React.lazy(() => import('./StaticPageTypo'))}
                    { ...{...props, ...this.pageProps} }
                  />;
                }} />
              ) : null}

              {previewMode ? (
                <Route path="/i18n" key={'i18n'} exact render={(props) => {
                  return <StaticPageLoader
                    moduleImportFn={() => React.lazy(() => import('./StaticPagei18n'))}
                    { ...{...props, ...this.pageProps} }
                  />;
                }} />
              ) : null}

              {previewMode ? (
                <Route path="/tts" key={'tts'} exact render={(props) => {
                  return <StaticPageLoader
                    moduleImportFn={() => React.lazy(() => import('./StaticPageTTS'))}
                    { ...{...props, ...this.pageProps} }
                  />;
                }} />
              ) : null}

              <Route path="/about" key={'about'} exact render={(props) => {
                meta.setTitle(messages.get('meta.pageTitle.about'));
                return <StaticPageLoader
                  moduleImportFn={() => React.lazy(() => import('./StaticPageAboutGrimm'))}
                  { ...{...props, ...this.pageProps} }
                />;
              }} />

              <Route path="/" key={'front-page'} exact render={(props) => {
                meta.setTitle(messages.get('meta.pageTitle.frontPage'));
                return <StaticPageFront { ...{...props, ...this.pageProps} } />;
              }} />

              <Route path="/docs/:file" key={'docs_files'} exact render={(props) => {
                locReload(props.match.url, '/404');
                return null;
              }} />

              <Route path="/static/media/:file" key={'static_files'} exact render={(props) => {
                locReload(props.match.urll, '/404');
                return null;
              }} />


              {getUserRoutes(this.pageProps)}
              {getSubscriptionRoutes(this.pageProps)}
              {getTransactionRoutes(this.pageProps)}
              {getPaperCodeRoutes(this.pageProps)}
              {getEntryListRoutes(this.pageProps)}

              <Route render={(props) => {
                meta.setTitle(messages.get('meta.pageTitle.notFound'));
                return <StaticPage404 { ...{...props, ...this.pageProps} } />;
              } } />

              {/* <Route render={(props) => {
                meta.setTitle(messages.get('meta.pageTitle.notFound'));
                return <StaticPageMustLogin { ...{...props, ...this.pageProps} } />;
              } } />
              <Route render={(props) => {
                meta.setTitle(messages.get('meta.pageTitle.notFound'));
                return <StaticPageMustLogin { ...{...props, ...this.pageProps} } />;
              } } /> */}

            </Switch>

            <Toaster
              position="top-right"
              reverseOrder={false}
              gutter={8}
            />

            <AudioPlayer hidden ref={this.refTTSAudio} />

            { DEV_MODE ? <GridHelper /> : null }
          </div>
        </UserContext.Provider>
      </DictListContext.Provider>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object
  }),
  location: PropTypes.object
};

export default withRouter(App);
