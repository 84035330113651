/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, {useState, useEffect, useCallback} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// = "auto" | "auto-start" | "auto-end";

const defaultSettings = {
  delay: { show: 500, hide: 100 },
  overlayProps: {},
  tooltipProps: {},
  trigger: ['hover', 'focus'],
  placement: 'auto',
  autoHide: 0
};


const getRectJson = (el, show) => (
  show && el && el.nodeType === 1 ? JSON.stringify(el.getBoundingClientRect()) : ''
);



const TooltipUpdater = ({
  id,
  className,
  overlayProps = {},
  tooltipProps = {},
  children
}) => {
  const {popper, show} = overlayProps;
  const el = popper?.state?.elements?.reference;
  const rectJson = getRectJson(el, show);
  const [elemPosJson, setElemPosJson] = useState(rectJson);

  if (rectJson !== elemPosJson && show) {
    popper.scheduleUpdate();
    setElemPosJson(rectJson);
  }

  return (
    <Tooltip
      show={show}
      {...overlayProps}
      className={className}
      id={`tooltip-lang-${id}`}
      {...tooltipProps}
    >{children}</Tooltip>
  );
};


const TooltipWrapper = ({
  id,
  children,
  title,
  autoHide = defaultSettings.autoHide,
  placement = defaultSettings.placement,
  overlayProps = defaultSettings.overlayProps,
  tooltipProps = defaultSettings.tooltipProps,
  className,
  trigger = defaultSettings.trigger
}) => {
  const autoHideDelay = typeof autoHide === "number" && autoHide > 0 ? autoHide : 0;
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
        setTimerId(null);
      }
    };
  }, [timerId]);

  const onEnter = useCallback((node) => {
    if (autoHideDelay > 0) {
      node.setAttribute('data-custom-a11y-hidden', 'false');
    }
  }, [autoHideDelay]);

  const onEntered = useCallback((node) => {
    if (autoHideDelay > 0) {
      if (timerId) { clearTimeout(timerId); }
      setTimerId(setTimeout(() => {
        node.setAttribute('data-custom-a11y-hidden', 'true');
      }, autoHideDelay));
    }
  }, [autoHideDelay, timerId]);

  const onExit = useCallback(() => {
    if (autoHideDelay > 0) {
      if (timerId && autoHideDelay) {
        clearTimeout(timerId);
        setTimerId(null);
      }
    }
  }, [autoHideDelay, timerId]);

  const onExited = useCallback((node) => {
    if (autoHideDelay > 0) {
      node.removeAttribute('data-custom-a11y-hidden');
    }
  }, [autoHideDelay]);

  return (
    title ? (
      <OverlayTrigger
        placement={placement}
        transition={true}
        rootClose={true}
        rootCloseEvent="click"
        target={children}
        delay={defaultSettings.delay}
        onEnter={onEnter}
        onEntered={onEntered}
        onExit={onExit}
        onExited={onExited}
        trigger={trigger}
        {...overlayProps}
        overlay={(overlayProps) => (
          <TooltipUpdater
            id={id}
            className={className}
            overlayProps={overlayProps}
            tooltipProps={tooltipProps}
          >
            {title}
          </TooltipUpdater>
        )}
      >{children}</OverlayTrigger>
    ) : children
  );
};

export const proptypePlacement = PropTypes.oneOf([
  'auto', 'auto-start', 'auto-end',
  'top', 'right', 'bottom', 'left',
  "top-start", "top-end", "bottom-start", "bottom-end", "right-start", "right-end", "left-start", "left-end"
]);

TooltipWrapper.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  placement: proptypePlacement,
  autoHide: PropTypes.number,
  overlayProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  className: PropTypes.string,
  trigger: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.node
};


export default TooltipWrapper;
