import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import ContentLink from "../../components/contentLink";
import { tldMap } from '../../setup';
import messages from '../../messages';
import { keyCodes } from '../../lib/util';
import TooltipWrapper from "../../components/misc/TooltipWrapper";


const LanguageSwitcherButtons = ({
  dictId, src, trg, dictList, dispatch, onKeyDown, onChange, refToTarget, onClickOrKeyDown, show, visible, baseClass
}) => {

  const mergeClickAndKeyDown = useCallback((e) => {
    onClickOrKeyDown(e);
    if (e.type === 'keydown') {
      if (typeof onKeyDown === 'function') onKeyDown(e);
    }
  }, [onClickOrKeyDown, onKeyDown]);

  const switchClick = (e) => {

    if (
      e.type !== "keydown" ||
      (e.type === "keydown" && (e.which === keyCodes.SPACE || e.which === keyCodes.ENTER))
    ) {
      if (visible) {
        dispatch({
          type: 'timerSwitchClick', payload: setTimeout(() => show(), 250)
        });
      }
      onChange({dictId, src: trg, trg: src, dictList });
      e.preventDefault();
    }

    if (e.type === 'keydown') {
      if (typeof onKeyDown === 'function') onKeyDown(e);
    }
  };
  return <>
    <ContentLink
      onClick={mergeClickAndKeyDown}
      aria-haspopup="true"
      aria-controls={'lang-select-popover'}
      aria-expanded={visible ? 'true' : 'false'}
      title={messages.get('Dictionary.languageSwitcher.sourceLanguage.title', {sourceLang: tldMap[src]})}
      tabIndex={0}
      accessKey={"n"}
      onKeyDown={mergeClickAndKeyDown}
      className={`${baseClass}__src active`} role="button"
    >{tldMap[src]}</ContentLink>
    <TooltipWrapper
      id={`lang-direction-switch`}
      title={messages.get('Dictionary.languageSwitcher.switchDirection.title')}
      placement="top"
    >
      <ContentLink
        tabIndex={0}
        ref={refToTarget}
        // title={messages.get('Dictionary.languageSwitcher.switchDirection.title')}
        aria-label={
          messages.get('Dictionary.languageSwitcher.switchDirection.currentDirection', {
            sourceLang: tldMap[src],
            targetLang: tldMap[trg]
          })}
        onClick={switchClick}
        accessKey={"c"}
        onKeyPress={switchClick}
        className={`${baseClass}__switcher`}
        data={{
          iconLeft: "exchange-alt"
          // iconLeft: "fa-long-arrow-alt-right"
        }} role="button"
      />
    </TooltipWrapper>
    <ContentLink
      tabIndex={0}
      className={`${baseClass}__trg`}
      aria-haspopup="true"
      aria-controls={'lang-select-popover'}
      aria-expanded={visible ? 'true' : 'false'}
      title={messages.get('Dictionary.languageSwitcher.targetLanguage.title', {targetLang: tldMap[src]})}
      onClick={mergeClickAndKeyDown}
      onKeyDown={mergeClickAndKeyDown}
      role="button"
    >{tldMap[trg]}</ContentLink>
  </>;
};

LanguageSwitcherButtons.propTypes = {
  dispatch: PropTypes.func,
  onClickOrKeyDown: PropTypes.func,
  refToTarget: PropTypes.object,
  show: PropTypes.func,
  visible: PropTypes.any,
  src: PropTypes.string.isRequired,
  trg: PropTypes.string.isRequired,
  dictList: PropTypes.array,
  dictId: PropTypes.string.isRequired,
  baseClass: PropTypes.string.isRequired,
  srcText: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func
};

export default LanguageSwitcherButtons;
