import PropTypes from "prop-types";
import React from 'react';
import classnames from "classnames";
import messages from "../../../../messages";

const getCharData = (char) => {
  if (Array.isArray(char)) {
    return [char[0] + '', char[1] + ''];
  }
  return [char + '', null];
};

const SpecCharsInlineButton = React.forwardRef(({
  classSpace,
  disabled,
  char,
  ariadescribedby,
  ariadisabled,
  onClick,
  onKeyDown,
  onFocus
}, ref) => {
  const [text, desc] = getCharData(char);
  return (
    <button
      ref={ref}
      dangerouslySetInnerHTML={{__html: text}}
      title={desc || null}
      role="menuitem"
      className={
        classnames(classSpace + '__item', disabled ? 'disabled' : null)
      }
      aria-disabled={ariadisabled ? 'true' : 'false'}
      aria-describedby={ariadescribedby}
      aria-label={messages.get('TrsSourceArea.specialChars.insert', {char: text})}
      data-char={text}
      tabIndex="0"
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onClick={onClick}
    />
  );
});

SpecCharsInlineButton.propTypes = {
  ariadescribedby: PropTypes.string,
  char: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      // 0 - char, 1 - description
      PropTypes.string
    )
  ]),
  classSpace: PropTypes.string,
  ariadisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func
};

export default SpecCharsInlineButton;
