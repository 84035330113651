
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GrimmDragon = ({color = '#180b39', className}) => {

  return (
    <div className={classNames("gimm-dragon", className)}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 155.95 299.76" style={{enableBackground: "new 0 0 155.95 299.76"}}>
        <path style={{ fill: color}} d="M88.72,264.63c0.9-10.81,8.56-20.73,17.57-35.15C93.22,244.8,85.11,253.81,88.72,264.63
          M90.97,138.48c7.21,11.26,17.57,13.06,26.57,28.38C112.14,152.89,101.78,148.83,90.97,138.48 M67.96,204.01
          c-2.48-0.15-4.93,0.6-6.9,2.11c-0.58,0.37-5.12,4.61-0.51,8.74c3.51,3.14,7.15-0.73,7.15-0.73c-3.73-4-0.52-7.64,2.39-6.78
          c1.5,0.45,4.93,3.13,3.36,8.91c-0.95,3.5-4.94,9.64-6.2,11.17c-1.24,1.51-0.75,1.48,0.58,0.51c0,0,9.98-10.52,8.39-18.06
          C75.43,206.09,72.27,204.01,67.96,204.01 M14.99,231.17c0,0-4.67,7.33-1.78,14.61c4.19,10.54,7.99,16.39,8.5,28.83
          c0.09,2.17,2.42-8.77-1.52-16.64C13.84,245.28,12.7,239.56,14.99,231.17 M27.19,222.28c-0.87,5.19-9.28,10.16-0.88,25.17
          C21.97,230.16,28.05,227.48,27.19,222.28 M35.75,226.81c1.19,4.91,1.39,10,0.6,14.99c0,0,7.44-7.44,2.38-12.66L35.75,226.81z
          M35.61,263.93c-1.63-4.76,21.24-19.75,20.19-24.36C31.44,256.94,33.07,260.81,35.61,263.93 M22.41,284.63l-1.79,2.48
          c0,0,1.04,1.64,7.98,3.43C23.29,287.11,22.41,284.63,22.41,284.63 M20.32,290.69c0,0,0.14,2.38,2.97,4.76l0.45-1.63L20.32,290.69z
          M42,289.79c-2.07,0,1.05,3.73,1.05,5.52c0,1.49,1.8,0.65,2.22-1.04l1.05-4.02C45.13,289.5,43.79,289.79,42,289.79 M50.64,289.64
          c0,0,2.68,2.82,2.68,4.76c0,1.89,4.42-0.89,3.67-2.97C56.4,289.77,50.64,289.64,50.64,289.64 M103.13,177.22
          c44.16,31.99-29.29,83.8-7.21,107.24c-11.27-22.53,25.02-49.2,25.69-78.85C122.06,185.33,104.93,176.32,103.13,177.22 M83.76,191.64
          c0.42,0.5,0.9,0.95,1.43,1.34c22.35,21.79-1.97,36.36-6.8,41.25c0,0,5.35,0.06,13.94-9.71c7.47-8.52,3.15-21.17,3.15-21.17
          c-8.6-11.22-8.48-9.1-10.29-10.37L83.76,191.64z M89.16,140.28c-3.15,18.47,41.45,31.09,42.35,62.17
          C136.02,169.12,93.21,158.3,89.16,140.28 M83.38,121.53c-2.42,11.96,20.28,12.62,27.86,28.65
          C109.07,134.15,90.65,135.71,83.38,121.53 M92.77,106.49c-7.1,15.45,3.39,19.78,9.46,24.18C93.47,123.74,91.47,120.23,92.77,106.49
          M113.28,95.39c-3.04,0.34-4.9,4.39-4.05,5.9c2.19,0.34,3.54,0.84,6.29-0.17c-3.07-0.5-4.55-1.97-2.74-3.03
          C114.5,97.08,116.35,97.58,113.28,95.39 M80.6,123.62c2.2,12.32,6.13,23.36-6.77,34.9c-9,2.28-18,10.83-18,15.23
          c0,4.33,1.73,3.46,2.27,5.99c4.89-9.19,8.47-11.04,16.45-21.18c5.88-2.99,9.97-8.61,11.01-15.13
          C86.46,137.12,84.21,133.52,80.6,123.62 M40.5,82.6c7.21,36.95,39.65,31.09,29.74,72.09c0,0,10.88-14.26,2.25-31.54
          C67.99,114.14,49.51,105.14,40.5,82.6 M46.36,112.34c2.26,9.01,25.23,23.43,10.82,45.51c4.58-3.56,8.11-4.05,8.56-13.52
          C66.34,131.66,48.62,115.5,46.36,112.34 M40.76,189.46c0,0-2.45,7.22-0.26,17.73c1.18,5.7,6.14,11.68,8.9,13.56
          C43.8,207.83,41.01,199.32,40.76,189.46 M69.21,172.04c-3.29,0.4-5.86,3.02-6.2,6.32c4.38-1.81,15.49-3.48,28.83-3.1
          C84.8,171.92,76.91,170.79,69.21,172.04 M73.14,244.67c0-4,1.56-6.87,3.13-9.5c0.19-0.16,0.32-0.37,0.37-0.61l-0.37,0.61
          c-0.48,0.45-1.57,0.96-4.1,0.82c-4.28-0.24-4.87-0.73-4.92-0.94c-4.38,28.75-27.87,35.49-29.87,46.23
          c-0.68,3.62-0.94,5.53,2.32,7.69c1.32,0.63,13.72-0.6,18.63,0.81s2.98,6.02,2.82,9.98H21.67c-3.49-3.32-5.51-7.89-5.61-12.7
          c0-4.33,3.46-1.73,3.46-6.05c0-9.95-13.84-20.33-13.84-38.06c0-6.15,0.17-11.51-0.59-15.58c1.81-4.28,6.75-4.2,17.18-13.43
          c0.73,0.72,5.07,4.56,15.67,10.78c12.76,7.05,25.04,9.39,24.17,8.99c-13.65-6.26-19.67-7.41-25.1-13.16
          c-3.64-3.84-12.31-19.03-6.12-37.28c-2.69,5.77-5.69,14.88-4.46,19.03c2.01,6.74,4.54,13.35,7.36,17.43
          c-4.15-1.92-7.99-4.47-11.36-7.57c-4.19-7.69-8.83-13.05-6.15-22.69c1.09-3.94,2.77-9.08,11.99-14.66
          c11.16-6.74,16.41-8.5,20.13-12.87c-16.44,5.72-31.46,13.53-31.68,17.25c-1.96,4.81,0.88,2.4-2.18,7.65
          c-1.61-1.72-9.19-5.91-7.65-15.97c1.21-7.97,27.08-10.9,34.95-16.8c0.96-0.73-9.4,2.84-21.84,3.94c-4.48,0.4-5.2,3.56-5.46,3.5
          c-1.52-0.35-2.41-3.5-1.75-5.91c0.91-3.33,10.49-19.43,26.64-11.8c-2.4-6.31-13.61-6.72-23.92,1.86c-1.39,0.54,1.11-4.59,1.11-7.47
          c0-1.48-1.11-2.58-1.11-8.84c0-4.33-4.97-5.71-0.91-8.65c4.41,8.1,15.02,10.5,24.59,12.71c-1.1-11.04-33.5-13.25-27.97-31.4
          c3.32,11.53,21.68,14.91,26.13,15.31c-3.29-3.66-37.47-20.09-32.52-31.8c-0.19,2.2,16.98,15.29,32.07,23.24
          c1.96-2.57-21.61-20.53-23.6-22.96C9.62,81.26-2.31,84.44,0.4,60.56c4.96,12.17,20.27,12.17,35.84,40.34
          C31.96,77.2-1.56,62.39,0.06,47.63c0.66-5.47,2.15-10.81,4.42-15.84c-0.46,24.24,24.9,40.17,31.07,53.52
          C29.7,65.04,10.32,58.72,6.31,26.26c-1.1-9.02,1.48-14.48,18.93-24.43L31.83,0c-7.89,12.52-8.87,27.54-8.87,31.86
          c0,30.06,38.36,59.66,56.09,89.11c1.02-6.27,4.72-13.6,11.4-20.08c-6.1-1.98-20.24-8.59-19.93-17.84c0.03-1.04,0.7-1,1.85-1.56
          c1.71,13.57,38.08,6.69,44.55,11.43c2.14,1.57,1.89,3.16,3,3.85c8.84-0.86,18.68,6.55,18.91,8.05c-1.94-0.5-2.92-1.92-6.65-0.5
          c-5.7,2.2-18.13-0.32-19.85,4.13c5.16-0.28,14.43,3.85,18.76,3.85c7.13,0,21.37-1.27,19.37-12.12c-1.22-6.57-5.56-10.41-11.44-9.84
          c0.43,1.99,0.84,3.79-3.27,5.56c0,0-5.52,0.83-5.52-3.49s4.33-6.06,8.65-6.06c8.21,0,15.05,4.49,16.86,13.41
          c1.57,7.7-5.98,12.12-3.99,20.42c-2.34,0.25-5.69-1.22-6.69-2.71c-1.09,3-3.9,8.12-7.61,10.83c-12.4-7.71-17.83-19.14-25.28-18.57
          c-1.42,1,7.45,5.6,7.88,9.73c0.43,1.7,2.28,1.99,3.43,3.14c-4.71,1.56-8.7-4.57-11.31-4.15c-3.7,2.58-4.44,13.95-4.44,14.6
          c0,8.65,10.33,21.23,22.45,21.4c-0.86,3.82-9.49,2.82-13.91,2.37c10.34,14.69,19.13,31.81,19.13,41.97
          c0,32.01-32.69,36.97-31.39,68.97c1.81,44.14,17.58,2.25,27.74,23.16l0.21,8.82H101.3c-14.74-17.29-19.53-26.77-19.53-39.52
          c0-4.33,1.77-12.38,3.47-17.23c0.07-0.99-0.28-1.96-0.97-2.67c-7.44,5.06-3.86,9.86-6.8,15.58C74.96,251.99,73.14,249,73.14,244.67
          M85.56,185.79c8.43,6.85,27.93,13.51,15.76,34.24C121.15,201.55,93.99,187.68,85.56,185.79"
        />
      </svg>
    </div>
  );
};

GrimmDragon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default GrimmDragon;
