import PropTypes from "prop-types";
import React, {useCallback} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import TooltipWrapper from "./misc/TooltipWrapper";
import { keyCodes } from '../lib/util';


const ContentDropDownList = React.memo(({
  id, onSelect, items = [], activeKey, refFirstItem, ariaListLabel, refOpener, mode = 'manual'
}) => {
  const focusOpener = useCallback((tiggerClick) => {
    if (refOpener.current) {
      refOpener.current.focus();
      if (tiggerClick) {
        refOpener.current.click();
      }
    }
  }, [refOpener]);

  const _onSelect = useCallback((e) => {
    if (
      e.type !== "keydown" || (
        e.type === "keydown" && (e.which === keyCodes.SPACE || e.which === keyCodes.ENTER)
      )
    ) {
      e.preventDefault();
      if (typeof onSelect === "function") {
        focusOpener(true);
        onSelect(e.currentTarget.getAttribute('data-eventkey'), e);
      }
      return;
    }

    const index = e.target.getAttribute('data-itemindex') - 0;
    const isFirst = index === 0;
    const isLast = index === items.length - 1;

    if (e.type === "keydown" && (
      (index === 0 && e.which === keyCodes.TAB && e.shiftKey) ||
      (isLast && e.which === keyCodes.TAB && !e.shiftKey) ||
      (e.which === keyCodes.ESC)
    )) {
      e.preventDefault();
      focusOpener(true);
    }

    if (e.type === "keydown" && mode === 'manual' && e.which === keyCodes.DOWN) {
      e.preventDefault();
      if (!isLast) {
        e.target.nextElementSibling.focus();
      }

    }
    if (e.type === "keydown" && mode === 'manual' && e.which === keyCodes.UP) {
      e.preventDefault();
      if (!isFirst) {
        e.target.previousElementSibling.focus();
      }
      else {
        focusOpener(true);
      }
    }
  }, [items.length, mode, onSelect, focusOpener]);

  return (
    <ListGroup
      id={id}
      variant="flush"
      as="ul"
      className={`${mode}-dropdown-list`}
      defaultActiveKey={mode === "uncontrolled" ? activeKey : void 0}
      activeKey={mode === "controlled" ? activeKey : void 0}
      aria-label={ariaListLabel}
    >
      {Array.isArray(items) ? items.map((item, i) => (
        <TooltipWrapper title={item.title} key={i} id={`${item.eventKey}-${i}`}>
          <ListGroup.Item
            as={item.tag || "li"}
            data-itemindex={i}
            key={i}
            aria-label={item.ariaLabel}
            aria-current={item.eventKey === activeKey ? 'true' : void 0}
            className={item.eventKey === activeKey ? 'current' : void 0}
            onKeyDown={_onSelect}
            ref={i === 0 || item.eventKey === activeKey ? refFirstItem : null}
            // onFocus={(e) => { console.log('FOCUS', e, e.target); }}
            onClick={_onSelect}
            href={item.href}
            eventKey={item.eventKey}
            data-eventkey={item.eventKey}
            disabled={item.disabled}
            active={item.active}
            role="button"
            tabIndex={0}
          >
            {item.icon ? <i className={`icon fas fa-${item.icon}`}></i> : null}
            {" "}
            {item.content}
          </ListGroup.Item>
        </TooltipWrapper>
      )) : null}
    </ListGroup>
  );
});

ContentDropDownList.propTypes = {
  ariaListLabel: PropTypes.string,
  activeKey: PropTypes.string,
  id: PropTypes.string.isRequired,
  refFirstItem: PropTypes.object,
  mode: PropTypes.oneOf(['controlled', 'uncontrolled', 'manual']),
  refOpener: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape({
    eventKey: PropTypes.string,
    icon: PropTypes.string,
    tag: PropTypes.string,
    content: PropTypes.node,
    onKeyDown: PropTypes.func,
    onClick: PropTypes.func
  })),
  onSelect: PropTypes.func
};

ContentDropDownList.displayName = 'ContentDropDownList';

export default ContentDropDownList;
