
const el_trigger = function(
    el: HTMLElement,
    event: string,
    data: any,
    bubbles: boolean = true,
    cancelable: boolean = false
  ) {

  var eventNames = event.trim().split(/\s+/);
  if (!el || !el.dispatchEvent) {
    return;
  }
  for (var i = 0; i < eventNames.length; i++) {
    el.dispatchEvent(
      new window.CustomEvent(eventNames[i], {
        bubbles: !!bubbles,
        cancelable: !!cancelable,
        detail: { customEvent: true, customData: data || null }
      })
    );
  }

  return el;
};

export default el_trigger;
