const el_toggleClass = (el:HTMLElement, className:string, toogleBool:boolean) => {
  // IE11 does not support the second parameter for the classList.toggle() method.
  if (el?.classList && className) {
    if (toogleBool) {
      if (!el.classList.contains(className)) {
        el.classList.add(className);
      }
    }
    else {
      if (el.classList.contains(className)) {
        el.classList.remove(className);
      }
    }
  }
};

export default el_toggleClass;
